import { FC, ReactNode } from "react";
import styles from './InstructionCard.module.scss';

interface Props {
  title: string | ReactNode,
}

const InstructionCard: FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export default InstructionCard;