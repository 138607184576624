import React from 'react';
import classNames from 'classnames';
import { Info } from '../../assets/svgs';
import styles from './Input.module.scss';

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  label: string;
  className?: string;
  error?: string;
};

const EmailInput: React.FC<Props> = ({ label, className = '', error = '', ...rest }) => {
  return (
    <div className={classNames(styles.form_group, className)}>
      <input
        {...rest}
        className={classNames(styles.form_control, styles.form_control_input)}
        type="email"
        placeholder=" "
      />
      <label className={styles.control_label}>{label}</label>
      {error && <span className={styles.error}><Info className={styles.error_icon} />{error}</span>}
    </div>
  )
}

export default EmailInput;