import { FC, MouseEvent, useCallback } from "react";
import classNames from "classnames";
import CheckButton from "../checkbutton/CheckButton";
import styles from './CheckBox.module.scss';

interface Props {
  checked: boolean;
  label: string;
  count?: string | number;
  onCheck: () => void;
  className?: string;
  reversed?: boolean;
}

const CheckBox: FC<Props> = ({ checked, label, count, onCheck, className, reversed }) => {
  const handleClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onCheck();
  }, [onCheck]);

  return (
    <span className={
      classNames(
        styles.container, {
          [styles.container_reversed]: reversed
        },
        className
      )}
      onClick={handleClick}
    >
      <span className={classNames(styles.label, { [styles.label_reversed]: reversed })}>{label}</span>
      <div className={styles.right}>
        {count && <span className={styles.count}>{count}</span>}
        <CheckButton className={styles.icon} checked={checked} fillStyle="cover" />
      </div>
    </span>
  )
}

export default CheckBox