import { useEffect } from "react";
import { Cross, ErrorIcon, Success } from "../../assets/svgs";
import { useMessage } from "../../providers/MessageProvider";
import styles from './Message.module.scss';

const Messages = () => {
  const { state: { warnings }, removeWarning } = useMessage();

  useEffect(() => {
    let interval: any;

    if (warnings.length > 0) {
      interval = setInterval(() => {
        removeWarning(warnings[0].message);
      }, 5000);
    } else {
      clearInterval(interval)
    }

    return () => clearInterval(interval);
  }, [warnings, removeWarning])

  if (warnings.length === 0) return <></>;

  return (
    <>
    {
      warnings.map(({ type, message, title }) => {
        return (
          <div key={message} className={styles.messages}>
            <div className={styles.left_side}>
              {
                type === 'Failed' 
                ? <ErrorIcon className={styles.icon} />
                : <Success className={styles.icon} />
              }
              <div className={styles.content}>
                <span className={styles.title}>{title}</span>
                <span>{message}</span>
              </div>
            </div>
            <Cross className={styles.cancel} onClick={() => removeWarning(message)} />
          </div>
        )
      })
    }
    </>
  )
}

export default Messages;