import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Menu, ThreeDots, Cross, Dropdown, Disconnect, Online, Cart } from '../../assets/svgs';
import Wallet, { ConnectButton } from '../wallet/Wallet';
import MenuItem from './MenuItem';
import ThemeToggle from '../toggle/ThemeToggle';
import ClickOutSide from '../click-outside/ClickOutside';
import Logo from '../../assets/images/logo.png';
import { RARITY_LINK, SWAP_WCRO_LINK, HIDE_MERCHANDISE } from '../../configs';
import { useModal } from '../../providers/ModalProvider';
import { useUser } from '../../providers/UserProvider';
import { useWalletHook } from '../../hooks/useWalletHook';
import { ModalTypes, SignInTypes } from '../../types/enums';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_CART } from '../../constants/labels';
import { SOCIALS } from '../../constants/socials';
import styles from './Navigation.module.scss';

const NAV_MENU = [
  {
    to: '/market',
    label: 'Marketplace',
    isHide: false,
    expandMemu: '',
  },
  {
    to: '/chimpay',
    label: 'Chimpay x Scroll',
    isHide: false,
    expandMemu: 'chimpay',
  },
  {
    to: '/shop',
    label: 'Shop',
    isHide: HIDE_MERCHANDISE,
    expandMemu: '',
  },
  {
    to: '/about',
    label: 'About',
    isHide: false,
    expandMemu: '',
  },
];

const EXPAND_MENU = {
  menu: [
    {
      to: '/faq',
      label: 'FAQ',
      isHide: true,
    },
    // {
    //   to: '/mint-chimp',
    //   label: 'Adopt a Chimp',
    //   isHide: HIDE_MINT,
    // },
  ],
  chimpay: [
    {
      to: '/chimpay',
      label: 'Main',
      isHide: false,
    },
    {
      to: '/chimpay/scroll',
      label: 'Scrolls',
      isHide: false,
    },
    {
      to: '/chimpay/dashboard',
      label: 'My Dashboard',
      isHide: false,
    },
    {
      to: '/chimpay/faqs',
      label: 'FAQ',
      isHide: false,
    },
  ],
  account: [
    {
      to: '/my-chimps',
      label: 'My Chimps',
      isHide: false,
    },
    {
      to: '/my-chimps?view=scroll-nft',
      label: 'Scroll NFT',
      isHide: false,
    },
    {
      to: '/my-chimps?view=offers-received',
      label: 'Offers received',
      isHide: true,
    },
    {
      to: '/my-chimps?view=offers-made',
      label: 'Offers made',
      isHide: false,
    },
  ],
  externalLinks: [
    {
      to: RARITY_LINK,
      label: 'Rarity sniper',
      isHide: false,
      isExternal: true,
    },
    {
      to: SWAP_WCRO_LINK,
      label: 'Get WCRO',
      isHide: false,
      isExternal: true,
    }
  ],
  socialLinks: SOCIALS.map((item) => ({ ...item, isHide: false, isExternal: true })),
};

const SUB_ROUTES = [/^\/chimpay\/faqs$/, /^\/chimpay\/dashboard$/, /^\/chimpay\/scroll$/, /^\/shop\/redeem-gift$/, /^\/shop\/details\/\d{1,}$/];

type MenuItemType = {
  to: string,
  label: string,
  isHide: boolean,
  isExternal?: boolean,
  Icon?: FC,
}

const Navigation: React.FC<{}> = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { active } = useWeb3React();
  const { openModal } = useModal();
  const { disconnectWallet } = useWalletHook();
  const { updateOrderCart, state: { orderCart, orders } } = useUser();

  const [showMenu, setShowMenu] = useState(false);
  const [showTlMenu, setShowTlMenu] = useState(false);
  const [expandMenu, setExpandMenu] = useState('');

  // Handle wallet updates
  useWalletHook();

  useEffect(() => {
    const cart = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_CART) || '[]');
    if (cart.length > 0) {
      updateOrderCart(cart);
    }
  }, [updateOrderCart]);

  const closeMemu = useCallback(() => {
    setShowMenu(false);
    setShowTlMenu(false);
    setExpandMenu('');
  }, []);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleOpenMyWallet = useCallback(() => {
    openModal(ModalTypes.CONNECT_WALLET);
  }, [openModal]);

  const handleDisconnect = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
    disconnectWallet();
    closeMemu();
  }, [closeMemu, disconnectWallet]);

  const handleOpenCart = useCallback(() => {
    openModal(ModalTypes.VIEW_CART);
    closeMemu();
  }, [closeMemu, openModal]);

  const renderExpandMenu = (data: MenuItemType[], isMobile: boolean) => data.map((item: MenuItemType) => (
    <React.Fragment key={item.to}>
      {
        isMobile ?
        <MenuItem
          to={item.to}
          onClick={closeMemu}
          label={item.label}
          isHide={item.isHide}
          isExternal={item.isExternal}
          Icon={item.Icon}
        /> :
        <li>
          <MenuItem
            to={item.to}
            label={item.label}
            isHide={item.isHide}
            isExternal={item.isExternal}
            isExpandMenu
            Icon={item.Icon}
          />
        </li>
      }
    </React.Fragment>
  ));

  const renderNavbar = () => {
    return NAV_MENU.map((item) => {
      if (item.expandMemu) {
        return (
          <div key={item.to} className={styles.nav_tree}>
            <div className={styles.nav_item_menu_expand}>
              <MenuItem
                to={item.to}
                onClick={closeMemu}
                label={item.label}
                isHide={item.isHide}
              />
              <div className={styles.menu_expand_wrap}>
                <div className={styles.menu_expand}>
                  <ul>
                    {renderExpandMenu(EXPAND_MENU[item.expandMemu as (keyof typeof EXPAND_MENU)], false)}
                  </ul>
                </div>
              </div>
            </div>
            <Dropdown onClick={() => !item.expandMemu ? undefined : setExpandMenu(item.expandMemu) } />
          </div>
        )
      }

      return (
        <MenuItem
          key={item.to}
          to={item.to}
          onClick={closeMemu}
          label={item.label}
          isHide={item.isHide}
        />
      );
    });
  };

  // const activeOrdersCount = orders.data && orders.data.filter(({ status }) => status !== 'CANCELED')

  const isSubRoute = useMemo(() => SUB_ROUTES.some((r) => r.test(pathname)), [pathname]);

  const renderMenuMobile = () => <div className={classNames(styles.container, styles.container_mb)}>
    <Link to="/" className={styles.nav_item_logo} onClick={closeMemu}>
      <img src={Logo} alt="Cronos Chimp Club Logo" />
    </Link>
    <span className={styles.home}>CRONOS CHIMP CLUB</span>
    {orderCart.length > 0 &&
      <span className={styles.cart_order_icon} onClick={handleOpenCart}>
        <Cart className={styles.cart_icon} />
        <span className={styles.cart_online_icon}>
          <Online />
        </span>
      </span>
    }
    {
      !showMenu && !isSubRoute 
      ? <Menu className={styles.memu} onClick={() => setShowMenu(true)} /> 
      : <Cross className={styles.memu} onClick={isSubRoute ? goBack : closeMemu} />
    }
    {showMenu &&
      <div className={styles.bars}>
        <Wallet />
        {active && <div className={styles.sub_bar_divider} />}
        {renderNavbar()}
        {renderExpandMenu(EXPAND_MENU.menu, true)}
        <div className={classNames(styles.nav, styles.nav_tree)} onClick={() => setExpandMenu('externalLinks')}>Links <Dropdown /></div>
        {!active ? <div className={styles.expand_menu_divider} /> : <div className={styles.sub_bar_divider} />}
        {!active ?
          <ConnectButton /> :
          <>
            <div className={classNames(styles.nav, styles.nav_tree)} onClick={handleOpenMyWallet}>My Wallet <Dropdown /></div>
            <div className={classNames(styles.nav, styles.nav_tree)} onClick={() => setExpandMenu('account')}>My Account <Dropdown /></div>
          </>
        }
        {!active ? <div className={styles.expand_menu_divider} /> : <div className={styles.sub_bar_divider} />}
        <ThemeToggle />
        <div className={styles.expand_menu_social}>
          {renderExpandMenu(EXPAND_MENU.socialLinks.map((item) => ({ ...item, label: '' })), true)}
        </div>
      </div>
    }
    {expandMenu === 'chimpay' &&
      <div className={classNames(styles.bars, styles.sub_bar)}>
        <div className={styles.sub_bar_title} onClick={() => setExpandMenu('')}><Dropdown /> Chimpay</div>
        <div className={styles.sub_bar_divider} />
        {EXPAND_MENU.chimpay.length > 0 && renderExpandMenu(EXPAND_MENU.chimpay, true)}
      </div>
    }
    {expandMenu === 'externalLinks' &&
      <div className={classNames(styles.bars, styles.sub_bar)}>
        <div className={styles.sub_bar_title} onClick={() => setExpandMenu('')}><Dropdown /> Links</div>
        <div className={styles.sub_bar_divider} />
        {EXPAND_MENU.externalLinks.length > 0 && renderExpandMenu(EXPAND_MENU.externalLinks, true)}
      </div>
    }
    {expandMenu === 'account' &&
      <div className={classNames(styles.bars, styles.sub_bar)}>
        <div className={styles.sub_bar_title} onClick={() => setExpandMenu('')}><Dropdown /> My Account</div>
        <div className={styles.sub_bar_divider} />
        {EXPAND_MENU.account.length > 0 && renderExpandMenu(EXPAND_MENU.account, true)}
        <div className={styles.sub_bar_divider} />
        {!HIDE_MERCHANDISE && orders.data.length > 0 &&
          <Link to="/shop/orders" className={styles.nav} onClick={closeMemu}>
            <strong>{`Orders (${orders.data.length})`}</strong>
            <Online />
          </Link>
        }
        {!HIDE_MERCHANDISE && orderCart.length > 0 &&
          <div className={styles.nav} onClick={handleOpenCart}>
            <strong>{`Cart (${orderCart.length})`}</strong>
            <Online />
          </div>
        }
        {!HIDE_MERCHANDISE && (orders.data.length > 0 || orderCart.length > 0) && <div className={styles.sub_bar_divider} />}
        <MenuItem
          to="/profile"
          label="Profile"
          onClick={closeMemu}
          isHide={true}
        />
        {
          canSignIn && <MenuItem
            label="Sign In"
            to="#"
            onClick={handleSignIn}
          />
        } 
        <div
          className={styles.nav}
          onClick={handleDisconnect}>
          Disconnect <Disconnect className={styles.powerIcon} />
        </div>
      </div>
    }
  </div>;

  const renderMenuTablet = () => <div className={styles.container_tl}>
    <ClickOutSide className={styles.container} onClickOutside={() => setShowTlMenu(false)}>
      <Link className={styles.home} to="/" onClick={() => setShowTlMenu(false)}>
        CRONOS CHIMP CLUB
      </Link>
      <Menu className={styles.memu} onClick={() => setShowTlMenu((prev) => !prev)} />
      {
        showTlMenu && (
          <div className={styles.bars}>
            <ConnectButton />
            <Wallet />
            <br />
            {renderNavbar()}
          </div>
        )
      }
    </ClickOutSide>
  </div>;

  const canSignIn = active && !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
  
  const handleSignIn = () => {
    openModal(ModalTypes.SIGN_IN, { message: SignInTypes.GENERIC });
  }

  const renderMenu = () => <div className={classNames(styles.container, styles.container_dk)}>
    <Link to="/" className={styles.nav_item_logo}>
      <img src={Logo} alt="" />
    </Link>
    <div className={classNames(styles.bars, styles.nav_item_menu)}>
      {renderNavbar()}
      <div className={styles.nav_item_menu_expand}>
        <span className={styles.nav}>Social</span>
        <div className={styles.menu_expand_wrap}>
          <div className={styles.menu_expand}>
            <ul>
              {renderExpandMenu(EXPAND_MENU.socialLinks, false)}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.nav_item_menu_expand}>
        <ThreeDots className={styles.menu_expand_ellipsis} />
        <div className={styles.menu_expand_wrap}>
          <div className={styles.menu_expand}>
            {EXPAND_MENU.menu.filter(item => item.isHide === false).length > 0 &&
              <>
                <ul>
                  {renderExpandMenu(EXPAND_MENU.menu, false)}
                </ul>
                <div className={styles.expand_menu_divider}></div>
              </>
            }
            {EXPAND_MENU.externalLinks.length > 0 &&
              <ul>
                {renderExpandMenu(EXPAND_MENU.externalLinks, false)}
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
    <ThemeToggle />
    <div className={styles.wallet}>
      {!active ?
        <ConnectButton /> :
        <>
          <Wallet />
          <div className={styles.wallet_expand_wrap}>
            <div className={styles.wallet_expand}>
              <MenuItem
                label="My Wallet"
                isExpandMenu
                to="#"
                onClick={handleOpenMyWallet}
              />
              <div className={styles.expand_menu_divider} />
              {EXPAND_MENU.account.filter(item => item.isHide === false).length > 0 &&
                <>
                  <ul>
                    {renderExpandMenu(EXPAND_MENU.account, false)}
                  </ul>
                  <div className={styles.expand_menu_divider}></div>
                </>
              }
              {!HIDE_MERCHANDISE && orders.data.length > 0 &&
                <Link to="/shop/orders" className={classNames(styles.nav, styles.expand_menu_item)} onClick={closeMemu}>
                  <strong>{`Orders (${orders.data.length})`}</strong>
                  <Online />
                </Link>
              }
              {!HIDE_MERCHANDISE && orderCart.length > 0 &&
                <div className={classNames(styles.nav, styles.expand_menu_item)} onClick={handleOpenCart}>
                  <strong>{`Cart (${orderCart.length})`}</strong>
                  <Online />
                </div>
              }
              {!HIDE_MERCHANDISE && (orders.data.length > 0 || orderCart.length > 0) && <div className={styles.expand_menu_divider} />}
              <MenuItem
                to="/profile"
                label="Profile"
                isHide={true}
                isExpandMenu
              />
              {
                canSignIn &&
                <MenuItem
                  label="Sign In"
                  to="#"
                  onClick={handleSignIn}
                  isExpandMenu
                />
              }
              <div
                className={classNames(styles.nav, styles.expand_menu_item)}
                onClick={handleDisconnect}
              >
                Disconnect <Disconnect className={styles.powerIcon} />
              </div>
            </div>
          </div>
        </>
      }
    </div>
  </div >;

  return (
    <>
      {renderMenuMobile()}
      {renderMenuTablet()}
      {renderMenu()}
    </>
  )
}

export default Navigation;