
import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { MediaPlayer, MediaPlayerClass } from 'dashjs';
import { Locked, Play, Unlocked } from '../../assets/svgs';
import styles from './VideoFrame.module.scss';

interface Props {
  isUnlocked?: boolean;
  src?: string;
  poster?: string;
  className?: string;
  isGallery?: boolean;
  lockedMsg?: string;
}

const VideoFrame: FC<Props> = (props) => {
  const { src = '', poster = '', isUnlocked = false, className, isGallery = false, lockedMsg } = props;
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const playVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.play();
      setPlaying(true);
      videoRef.current.controls = true;
    }
  }, []);

  const handleVideoEnded = useCallback(() => {
    setPlaying(false);
    if (videoRef.current) {
      videoRef.current.controls = false;
    }
  }, []);

  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    let player: MediaPlayerClass;
    let videoEle = videoRef.current;

    if (src && videoEle) {
      player = MediaPlayer().create();
      player.initialize(videoEle, src, false);
    }

    return () => {
      setPlaying(false);

      if (videoEle) {
        videoEle.controls = false;
      }
      player?.destroy();
    }
  }, [src])

  return (
    <div className={`${styles.component} ${className}`}>
      {
        isUnlocked && 
        <video
          className={styles.video}
          ref={videoRef}
          onEnded={handleVideoEnded}
          onContextMenu={handleContextMenu}
          poster={poster}
          preload="auto"
        >
          {/* <source src={src} type="application/dash+xml" /> */}
        </video>
      }
      {
        !isUnlocked && 
        <img className={styles.video} src={poster} alt="" />
      }
      {!playing && <Play className={styles.play_icon} onClick={playVideo} />}
      {
        !isUnlocked && 
        <div className={styles.locked}>
          <Locked className={styles.locked_icon} />
          <div className={styles.locked_txt}>
            {lockedMsg}
          </div>
        </div>
      }
      {
        !isGallery && 
        <div className={styles.state}>
          {
            isUnlocked ?
            <>
            <Unlocked />
            <span>Unlocked</span>
            </> : 
            <>
              <Locked />
              <span>Locked</span>
            </>
          }
        </div>
      }
    </div>
  )
}

export default VideoFrame;
