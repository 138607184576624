import { ethers } from 'ethers';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useContract } from '../hooks/useContract';
import { convertPriceToDecimal } from '../utils/price';

interface IContext {
  mintPrice: number,
}

const Context = createContext<IContext>({
  mintPrice: 0,
})

export const useMintPrice = (): number => {
  const { mintPrice } = useContext(Context);

  return mintPrice;
}

const PriceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [mintPrice, setMintPrice] = useState(0);

  const { contract } = useContract();

  useEffect(() => {
    contract.mintPrice().then((price: ethers.BigNumber) => {
      setMintPrice(+convertPriceToDecimal(price.toString()));
    })
  }, [contract])

  const context = {
    mintPrice,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default PriceProvider