import BigNumber from 'bignumber.js'
import { ethers } from 'ethers';
import { MAX_PER_MINT } from '../configs';
import { SaleChimpMeta } from '../types/interfaces';

const CONVERT_TO_INTEGER = new BigNumber(10 ** 18);
const USDC_CONVERT_TO_INTEGER = new BigNumber(10 ** 6)

export function convertPriceToDecimal(price: number | string): string {
  if (price === 0 || price === '0' || !price) return '0';

  return new BigNumber(price).dividedBy(CONVERT_TO_INTEGER).toFixed();
}

export function convertUSDCPriceToDecimal(price: number | string): string {
  if (price === 0 || price === '0' || !price) return '0';

  return new BigNumber(price).dividedBy(USDC_CONVERT_TO_INTEGER).toFixed();
}

export function convertPriceToInteger(price: number | string): string {
  if (price === 0 || price === '0' || !price) return '0';

  return new BigNumber(price).dividedBy(CONVERT_TO_INTEGER).integerValue().toFixed();
} 

export function convertPriceToUint(amount: number | string): string {
  if (amount === 0 || amount === '0' || !amount) return '0';

  return new BigNumber(amount).multipliedBy(CONVERT_TO_INTEGER).toFixed();
}

export function convertUSDCPriceToUint(amount: number | string): string {
  if (amount === 0 || amount === '0' || !amount) return '0';

  return new BigNumber(amount).multipliedBy(USDC_CONVERT_TO_INTEGER).toFixed();
} 

export function computePrice(amount: number, price: number): string {
  return new BigNumber(amount).multipliedBy(price).toFixed();
}

export function computeEstimateGas(data: ethers.BigNumber): number {
  return Math.round(data.toNumber() * 1.2);
}

export function getMintingAmountForBalance(balance: number | string, price: number | string): string {
  if (Number.isNaN(+price) || +price === 0) return MAX_PER_MINT.toString();

  return new BigNumber(balance).dividedBy(price).toFixed(0, BigNumber.ROUND_FLOOR);
}

export function getFloorPrice(data: SaleChimpMeta[]): string {
  if (data.length <= 0) return '0';

  return data.reduce((lowestPrice: string, current: SaleChimpMeta) => {
    if (+lowestPrice > +current.price) {
      return current.price;
    }

    return lowestPrice;
  }, data[0].price);
}

