import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import MarketIndex from '../../components/market-index/MarketIndex';
import TraitFilters from '../../components/filter/TraitFilters';
import Dropdown from '../../components/dropdown/Dropdown';
import SortingTabs from '../../components/sorting/SortingTabs';
import PriceFilter from '../../components/filter/PriceFilter';
import ChimpList from '../../components/chimps/ChimpList';
import SearchChimps from '../../components/search/SearchChimps';
import { useContractMethods } from '../../hooks/useContractMethods';
import { useChimpsHook } from '../../hooks/useChimpsHook';
import { useChimp } from '../../providers/ChimpProvider';
import { useLoadChimp } from '../../providers/LoadChimpProvider';
import SearchMarketProvider, { useSearchMarket } from '../../providers/SearchMarketProvider';
import { convertPriceToDecimal, convertPriceToInteger, getFloorPrice } from '../../utils/price';
import { formatNumberWithComma } from '../../utils/number';
import { MarketSearchParams, SaleFilterTabs, SortingTypes } from '../../types/enums';
import styles from './Market.module.scss';

const Market = () => {
  const [volume, setVolume] = useState('-');

  const { loadingSalingChimps, loadingSalingHonoraryChimps } = useLoadChimp();
  const { getMarketVolume } = useContractMethods();

  const { getMarketChimps } = useChimpsHook();
  const { state: { allSaleChimps } } = useChimp();
  const { filterParams, filterForListed, sortChimp, filterByChimpId } = useSearchMarket();

  const getVolume = useCallback(async () => {
    try {
      const data = await getMarketVolume();

      setVolume(data.toString());
    } catch (error) {
      console.log(error)
    }
  }, [getMarketVolume])

  useEffect(() => {
    getVolume();
  }, [getVolume])

  useEffect(() => {
    getMarketChimps();
  }, [getMarketChimps]);

  const listType = useMemo(() => { return filterParams[MarketSearchParams.LIST_TYPE] || SaleFilterTabs.LISTED }, [filterParams])

  const sortingType = useMemo(() => { return filterParams[MarketSearchParams.SORT] || SortingTypes.LOWEST_PRICE }, [filterParams])

  const searchChimpId = useMemo(() => { return filterParams[MarketSearchParams.CHIMP_ID] || '' }, [filterParams]);

  const sortingOptions = useMemo(() => {
    const opts = [SortingTypes.LOWEST_ID, SortingTypes.HIGHEST_ID];

    if (listType === SaleFilterTabs.LISTED || listType === SaleFilterTabs.HONORARY) {
      opts.push(...[SortingTypes.LOWEST_PRICE, SortingTypes.HIGHEST_PRICE])
    }

    return opts.map((key) => ({ text: key, value: key }));
  }, [listType])

  const floorPrice = useMemo(() => {
    if (loadingSalingChimps || loadingSalingHonoraryChimps) {
      return '0';
    }

    return convertPriceToDecimal(getFloorPrice([...allSaleChimps]));
  }, [loadingSalingChimps, loadingSalingHonoraryChimps, allSaleChimps]);

  const listed = useMemo(() => {
    if (loadingSalingChimps || loadingSalingHonoraryChimps) {
      return '-';
    }

    return allSaleChimps.length;
  }, [loadingSalingChimps, loadingSalingHonoraryChimps, allSaleChimps])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>Marketplace</span>
        <span className={styles.text}>A place to view all Cronos Chimps, listed or otherwise</span>
        <div className={styles.index}>
          <MarketIndex className={styles.index_item} label="Floor Price" value={`${!(+floorPrice) ? '-' : formatNumberWithComma(floorPrice)} CRO`} />
          <MarketIndex className={styles.index_item} label="Currently Listed" value={`${listed} Chimps`} />
          <MarketIndex className={styles.index_item} label="Volume" value={`${volume === '-' ? volume : formatNumberWithComma(convertPriceToInteger(volume))} CRO`} />
        </div>
      </div>
      <div className={styles.row}>
        <span className={styles.row_left}>
          <Dropdown
            className={styles.list_filter}
            select={listType}
            onSelect={filterForListed as any}
            options={
              [
                SaleFilterTabs.ALL,
                SaleFilterTabs.LISTED,
                SaleFilterTabs.UNLISTED,
                SaleFilterTabs.HONORARY,
                SaleFilterTabs.FAVORITES
              ].map((key) => ({
                text: key,
                value: key,
              }))
            }
          />
          <span className={classNames(styles.price_filter_dk, { [styles.price_filter_hiden]: listType !== SaleFilterTabs.LISTED })}><PriceFilter /></span>
          <span className={styles.search_ntl}><SearchChimps value={searchChimpId} onChange={filterByChimpId} /></span>
        </span>

        <span className={styles.row_right}>
          <span className={styles.search_tl}><SearchChimps value={searchChimpId} onChange={filterByChimpId} /></span>
          <span className={styles.sorts_dk}><SortingTabs sorting={sortingType} options={sortingOptions} onSort={sortChimp} /></span>
        </span>
      </div>
      <div className={styles.divider} />
      <div className={classNames(styles.row2, { [styles.row2_pd]: listType !== SaleFilterTabs.HONORARY })}>
        <span className={classNames(styles.filters, { [styles.filters_hiden]: listType === SaleFilterTabs.HONORARY || listType === SaleFilterTabs.FAVORITES })}><TraitFilters /></span>
        <span className={classNames(styles.price_filter_mb, { [styles.price_filter_hiden]: listType !== SaleFilterTabs.LISTED })}><PriceFilter /></span>
      </div>
      <span className={styles.sorts_mb}><SortingTabs sorting={sortingType} options={sortingOptions} onSort={sortChimp} /></span>
      <ChimpList />
    </div>
  )
}

const MarketWithProvider = () => {
  return (
    <SearchMarketProvider>
      <Market />
    </SearchMarketProvider>
  )
}

export default MarketWithProvider;