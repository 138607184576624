import AmazonLogo from '../assets/images/logo_amazon.png';
import AppleLogo from '../assets/images/logo_apple.png';
import GooglePlayLogo from '../assets/images/logo_google_play.png';
import PlaystationLogo from '../assets/images/logo_playstation.png';
import RazerGoldLogo from '../assets/images/logo_razer_gold.png';
import VisaLogo from '../assets/images/logo_visa.png';
import { GiftRedemptionTiers } from "../types/enums";

export const GIFT_TYPES = ['Amazon', 'App Store', 'Google Play', 'Razer Gold', 'Visa', 'Playstation Store'];

export const EXCLUDED_GIFT_PRODUCTS = ['Vanilla® eGift Visa'];

export const MAX_STAKING_ALLOWED = 20;

export const REDEMPTION_TIER_PRICE = {
  [GiftRedemptionTiers.GOLD]: 50,
  [GiftRedemptionTiers.SILVER]: 20,
  [GiftRedemptionTiers.BRONZE]: 5,
}

export const BANANA_TIER_RATE = {
  [GiftRedemptionTiers.GOLD]: 32,
  [GiftRedemptionTiers.SILVER]: 65,
  [GiftRedemptionTiers.BRONZE]: 175,
}

export const GIFT_CARD_LOGO = {
  'Amazon': AmazonLogo,
  'App Store': AppleLogo,
  'Google Play': GooglePlayLogo,
  'Razer Gold': RazerGoldLogo,
  'Visa': VisaLogo,
  'Playstation Store': PlaystationLogo,
}

export const BOOSTER_REFERENCES = [
  { number: 1, ptd: '15.00', booster: '1.000*'},
  { number: 2, ptd: '16.14', booster: '1.076*'},
  { number: 3, ptd: '17.29', booster: '1.153*'},
  { number: 4, ptd: '18.43', booster: '1.229*'},
  { number: 5, ptd: '19.57', booster: '1.305*'},
  { number: 6, ptd: '20.71', booster: '1.381*'},
  { number: 7, ptd: '21.86', booster: '1.457*'},
  { number: 8, ptd: '23.00', booster: '1.533*'},
  { number: 9, ptd: '24.14', booster: '1.609*'},
  { number: 10, ptd: '25.29', booster: '1.686*'},
  { number: 11, ptd: '26.43', booster: '1.762*'},
  { number: 12, ptd: '27.57', booster: '1.838*'},
  { number: 13, ptd: '28.71', booster: '1.914*'},
  { number: 14, ptd: '29.86', booster: '1.991*'},
  { number: 15, ptd: '31.00', booster: '2.067*'},
  { number: 16, ptd: '32.14', booster: '2.143*'},
  { number: 17, ptd: '33.29', booster: '2.219*'},
  { number: 18, ptd: '34.43', booster: '2.295*'},
  { number: 19, ptd: '35.57', booster: '2.371*'},
  { number: 20, ptd: '36.72', booster: '2.447*'}
];

export const PRODUCT_DESCRIPTION = {
  'Amazon': `
    Amazon Gift Cards can be used to purchase millions of items sold on Amazon.com today - 
    from electronics to digital books to household items.
    To learn more: 
    <a href="https://amzn.to/3zdK9Fe" target="_blank" rel="noopener noreferrer">
      https://amzn.to/3zdK9Fe
    </a>
  `,
  'App Store': `
    As part of Wallet, your Apple Account balance is a quick, convenient way to pay when shopping at an Apple Store or apple.com. 
    Available to use for the App Store, Apple TV, Apple Music, iTunes, Apple Arcade, the Apple Store app, apple.com and the Apple Store.
    To learn more: 
    <a href="https://www.apple.com/shop/gift-cards" target="_blank" rel="noopener noreferrer">
      https://www.apple.com/shop/gift-cards 
    </a>
  `,
  'Google Play': `
    With millions of apps, games, and more to discover, there's something for everyone on Google Play. 
    Use a Google Play gift code to explore a world of endless play, 
    from your go-to games to the apps you can’t live without.
    To learn more: 
    <a href="https://play.google.com/about/card-terms/" target="_blank" rel="noopener noreferrer">
      https://play.google.com/about/card-terms/ 
    </a>
  `,
  'Razer Gold': `
    Razer Gold is the unified virtual credits for gamers worldwide. 
    Use Razer Gold to buy games and in-game content to get more bang for your buck—including 
    getting rewarded with Razer Silver and exclusive game deals. 
    You can make a purchase using Razer Gold in over 2,000 games and entertainment content. 
    To learn more: 
    <a href="https://gold.razer.com/gold/about" target="_blank" rel="noopener noreferrer">
      https://gold.razer.com/gold/about
    </a>
  `,
  'Visa': `
    Visa Gift Cards Can Be Used Nationwide & Online Anywhere Visa Debit Cards Are Accepted. 
    Available in virtual or physical versions. 
    To learn more: 
    <a href="https://bit.ly/ccc-visa-gift-card " target="_blank" rel="noopener noreferrer">
      https://bit.ly/ccc-visa-gift-card
    </a>
  `,
  'Playstation Store': `
    PlayStation gift card is a prepaid digital card that allows you to add funds to your 
    PlayStation Network (PSN) account. PlayStation gift cards make for great gifts for gamers 
    or can be a convenient way to add funds to your account, 
    allowing you to enjoy a vast selection of digital entertainment available on the PlayStation Store.
    To learn more: 
    <a href="https://www.playstation.com/en-us/playstation-gift-cards/" target="_blank" rel="noopener noreferrer">
      https://www.playstation.com/en-us/playstation-gift-cards/
    </a>
  `,
}

export const REDEEM_LINKS = {
  'Amazon': 'https://www.amazon.com/redeem',
  'App Store': 'https://support.apple.com/en-us/HT201209',
  'Google Play': 'https://play.google.com/store/games?code',
  'Razer Gold': 'https://gold.razer.com/gold/reload',
  'Visa': 'https://www.yourrewardcard.com/#/using-your-card',
  'Playstation Store': 'https://store.playstation.com/',
};
