import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, Link } from "react-router-dom";
import VideoFrame from "../../components/lore/VideoFrame";
import { GalleryFrame } from "../../components/lore/GalleryFrame";
import { useLore } from "../../providers/LoreProvider";
import GalleryPlacholder1x from '../../assets/images/gallery-placeholder-1x.png';
import GalleryPlaceholder4x from '../../assets/images/gallery-placeholder-4x.png';
import useLoreHook from "../../hooks/useLoreHook";
import { Arrow, Dropdown as DropdownIcon } from "../../assets/svgs";
import { SceneDetails } from "../../types/interfaces";
import styles from './PlayList.module.scss';

const MOVE_DISTANCE = 200;

const PlayList = () => {
  const params = useParams<{ id: string }>();

  const [loading, setLoading] = useState(false);
  const [playingId, setPlayingId] = useState(0);
  const [showScrollIcon, setShowScrollIcon] = useState({ left: false, right: true });
  const [scrollList, setScrollList] = useState<SceneDetails[]>([]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { state: { activeChapterId } } = useLore();
  const { loadChapterScrolls } = useLoreHook();

  const handleScroll = useCallback((e: MouseEvent, direction: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (scrollRef.current) {
      const move = direction * MOVE_DISTANCE;
      scrollRef.current.scrollBy({ left: move, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    let scrollEle = scrollRef.current;

    const listner = () => {
      if (scrollEle) {
        let left = scrollEle.scrollLeft > 10 ? true : false;
        let right = scrollEle.scrollLeft < (scrollEle.scrollWidth - scrollEle.clientWidth - 10) ? true : false;

        if (showScrollIcon.left !== left || showScrollIcon.right !== right) {
          setShowScrollIcon((prev) => ({
            ...prev,
            left,
            right,
          }))
        }
      }
    }

    scrollEle?.addEventListener('scroll', listner);

    return () => {
      scrollEle?.removeEventListener('scroll', listner);
    }
  }, [showScrollIcon])

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      loadChapterScrolls(+params.id).then((data) => {
        if (data) {
          setScrollList(data);
        }
        setLoading(false);
      });
    }
  }, [params.id, loadChapterScrolls])

  const scrollRequired = scrollRef.current && 
    scrollRef.current.clientWidth < (scrollRef.current.children[0]?.clientWidth || 0);

  const playingVideo = useMemo(() => scrollList[playingId]?.video, [scrollList, playingId]);

  const backUrl = useMemo(() => {
    if (params.id && +params.id === activeChapterId) {
      return '/chimpay/dashboard?view=active-gallery';
    } else if (params.id && +params.id !== activeChapterId) {
      return '/chimpay/dashboard?view=archived-gallery';
    }
    return '/chimpay/dashboard';
  }, [params.id, activeChapterId]);

  return (
    <div className={styles.container}>
      <Link className={styles.back} to={backUrl}>
        <Arrow/>
        <span>Go back</span>
      </Link>
      <div className={styles.title}>
        Chapter {params.id || 0}
      </div>
      {loading && <div className={styles.loading}>loading...</div>}
      {
        scrollList.length > 0 && 
        <div className={styles.body}>
          <VideoFrame 
            isUnlocked={true} 
            isGallery={true}
            src={playingVideo?.dashManifestUrl} 
            poster={playingVideo?.thumbnail ? `${playingVideo?.thumbnail}?time=1s&height=720` : GalleryPlaceholder4x} 
          />
          <div className={styles.scroll_wrap}>
            <div 
              className={styles.scroll_frames_wrap} 
              ref={scrollRef} 
            >
              <div className={styles.scroll_frames}>
                {
                  scrollList.map(({ id, title, video }, index) => (
                    <GalleryFrame 
                      className={styles.scroll_frame}
                      key={id}
                      src={video?.thumbnail ? `${video?.thumbnail}?time=3s&width=200` : GalleryPlacholder1x} 
                      name={title}
                      part={id}
                      duration={video?.duration}
                      selected={index === playingId}
                      onClick={() => setPlayingId(index)}
                    />
                  ))
                }
              </div>
            </div>
            {
              scrollRequired && showScrollIcon.left && 
              <div
                className={`${styles.scroll_icon} ${styles.scroll_icon_prev}`}
                onClick={(e) => handleScroll(e, -1)}
              >
                <DropdownIcon />
              </div>
            }
            {
              scrollRequired && showScrollIcon.right && 
              <div
                className={`${styles.scroll_icon} ${styles.scroll_icon_next}`}
                onClick={(e) => handleScroll(e, 1)}
              >
                <DropdownIcon />
              </div>
            }
          </div>
        </div>
      }
    </div>
  )
}

export default PlayList;
