import { createContext, FC, ReactElement, useCallback, useContext, useState } from 'react';
import { CountryProps, GiftCardMeta, RecipientFormProps, ChimpStakeMeta } from '../types/interfaces';
import { GiftRedemptionTiers } from '../types/enums';

interface GiftsType {
  [GiftRedemptionTiers.GOLD]: GiftCardMeta[];
  [GiftRedemptionTiers.SILVER]: GiftCardMeta[];
  [GiftRedemptionTiers.BRONZE]: GiftCardMeta[];
}

interface GiftQuotaState {
  [GiftRedemptionTiers.GOLD]: number;
  [GiftRedemptionTiers.SILVER]: number;
  [GiftRedemptionTiers.BRONZE]: number;
}

type TStakedTokens = {
  normal: ChimpStakeMeta[],
  honorary: ChimpStakeMeta[],
}

export interface State {
  stakedTokens: TStakedTokens; 
  availableBananas: number;
  countries: CountryProps[];
  gifts: GiftsType;
  claimingGift: GiftCardMeta;
  giftOrderForm: RecipientFormProps;
  giftQuota: GiftQuotaState;
  seasonStartTime: number;
  stakePauseTime: number;
};

interface IContext {
  state: State;
  getStakedTokens: (key: 'normal' | 'honorary', chimps: ChimpStakeMeta[]) => void,
  getAvailableBananas: (value: number) => void;
  setCountries: (data: CountryProps[]) => void;
  setGifts: (data: GiftsType) => void;
  setClaimingGift: (data: GiftCardMeta) => void;
  updateGiftOrderForm: (key: string, value: any) => void;
  getGiftQuota: (value: GiftQuotaState) => void;
  getSeasonStartTime: (value: number) => void;
  getStakePauseTime: (value: number) => void;
};

export const initialState: State = {
  stakedTokens: {
    normal: [],
    honorary: [],
  },
  availableBananas: 0,
  countries: [],
  gifts: {
    [GiftRedemptionTiers.GOLD]: [],
    [GiftRedemptionTiers.SILVER]: [],
    [GiftRedemptionTiers.BRONZE]: [],
  },
  claimingGift: {} as any,
  giftOrderForm: {
    recipientName: '',
    recipientEmail: '',
    claimingTier: GiftRedemptionTiers.BRONZE,
    country: 'US', // Default set as United States
  },
  giftQuota: {
    [GiftRedemptionTiers.GOLD]: 0,
    [GiftRedemptionTiers.SILVER]: 0,
    [GiftRedemptionTiers.BRONZE]: 0,
  },
  seasonStartTime: 0,
  stakePauseTime: 0,
};

const Context = createContext<IContext>({
  state: initialState,
  getStakedTokens: () => undefined,
  getAvailableBananas: () => undefined,
  setCountries: () => undefined,
  setGifts: () => undefined,
  setClaimingGift: () => undefined,
  updateGiftOrderForm: () => undefined,
  getGiftQuota: () => undefined,
  getSeasonStartTime: () => undefined,
  getStakePauseTime: () => undefined,
});

export const useChimpay = () => {
  return useContext(Context);
};

const ChimpayProvider: FC<{ children: ChildNode | ReactElement }> = ({ children }) => {
  const [stakedTokens, setStakedTokens] = useState(initialState.stakedTokens);
  const [availableBananas, setAvailableBananas] = useState(initialState.availableBananas);
  const [countries, setCountries] = useState(initialState.countries);
  const [gifts, setGifts] = useState(initialState.gifts);
  const [claimingGift, setClaimingGift] = useState(initialState.claimingGift);
  const [giftOrderForm, setGiftOrderForm] = useState(initialState.giftOrderForm);
  const [giftQuota, setGiftQuota] = useState(initialState.giftQuota);
  const [seasonStartTime, setSeasonStartTime] = useState(initialState.seasonStartTime);
  const [stakePauseTime, setStakePauseTime] = useState(initialState.stakePauseTime);

  const getStakedTokens = useCallback((key: 'normal' | 'honorary', chimps: ChimpStakeMeta[]) => {
    setStakedTokens((prevTokens) => ({
      ...prevTokens,
      [key]: chimps,
    }));
  }, [setStakedTokens]);

  const updateGiftOrderForm = useCallback((key: string, value: any) => {
    setGiftOrderForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, [setGiftOrderForm]);

  const context = {
    state: {
      stakedTokens,
      availableBananas,
      countries,
      gifts,
      claimingGift,
      giftOrderForm,
      giftQuota,
      seasonStartTime,
      stakePauseTime,
    },
    getStakedTokens,
    getAvailableBananas: setAvailableBananas,
    setCountries,
    setGifts,
    setClaimingGift,
    updateGiftOrderForm,
    getGiftQuota: setGiftQuota,
    getSeasonStartTime: setSeasonStartTime,
    getStakePauseTime: setStakePauseTime,
  };

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
};

export default ChimpayProvider;