
export const OPTIONS_EARRINGS = {
  'none': 6487,
  'silver ring': 1002,
  'silver earstud': 1000,
  'punk spiked earstud': 661,
  'golden stud': 270,
  'pirate gold rings': 254,
  'caveman bone': 187,
  'diamond stud': 139,
}

export const OPTIONS_MOUTH = {
  'basic mean mug': 912,
  'disco smirk': 912,
  'vacation smile': 749,
  'foreman cheesy grin': 424,
  'caesar proud': 372,
  'angel laugh': 356,
  'baller mean': 350,
  'cymbal chimp scared': 347,
  'surgical mask': 346,
  'unicorn ooh': 340,
  'biting lip': 339,
  'veteran toothy grin': 330,
  'wukong grin': 323,
  'kawaii smile': 323,
  'blowing raspberries': 312,
  'caveman bite': 305,
  'unshaven': 304,
  'punk piercings': 300,
  'dracula fangs': 293,
  'gamer chip': 283,
  'royal moustache': 264,
  'detective pipe': 217,
  'ninja mask': 206,
  'lollipop': 185,
  'samurai mask': 140,
  'stoner joint': 126,
  'respirator': 124,
  'gold tooth': 119,
  'don cigar': 113,
  'pizza': 60,
  'biting gold chain': 46,
  'shogun mask': 41,
  'rainbow barf': 40,
  'golden grin': 33,
  'ice-cream': 66,
}

export const OPTIONS_EYES = {
  'basic bulging': 1057,
  'gamer bored': 774,
  'foreman wink': 765,
  'closed': 749,
  'baller mad': 560,
  'bunny lashes': 507,
  'prisoner eyebags': 402,
  'pirate eyepatch': 329,
  'dead': 313,
  'caveman unibrow': 284,
  'ninja cautious': 278,
  'geisha make-up': 262,
  'angel blue eyes': 252,
  'kawaii shine': 250,
  'caesar proud': 246,
  'royal stare': 246,
  'don quizzical': 243,
  'stoner baked': 234,
  'cymbal chimp stare': 228,
  'wukong make-up': 225,
  '3d glasses': 222,
  'dracula bloodshot': 212,
  'detective monocle': 211,
  'punk hollow': 209,
  'disco star shades': 207,
  'unicorn stars': 173,
  'cyborg': 134,
  'pop-out': 117,
  'pixel glasses': 114,
  'golden shades': 93,
  'laser beams': 60,
  'glowing': 44,
}

export const OPTIONS_HEADGEAR = {
  'none': 1436,
  'basic 5-panel cap': 763,
  'headband': 740,
  'cymbal chimp fez': 540,
  'gamer headset': 513,
  'detective hat': 439,
  'kawaii ribbon': 378,
  'dracula hair': 373,
  'prisoner hat': 357,
  'pirate hat': 320,
  'ninja headband': 286,
  'veteran helmet': 279,
  'bunny headband': 272,
  'foreman hat': 263,
  'caveman hairdo': 245,
  'don fedora': 235,
  'geisha hairdo': 233,
  'unicorn horn': 227,
  'stoner beanie': 221,
  'punk mohawk': 217,
  'beer hat': 204,
  'artist beret': 188,
  'top hat': 174,
  'chef hat': 169,
  'samurai helmet': 169,
  'horns': 128,
  'rasta hat': 121,
  'disco afro': 114,
  'sports helmet': 109,
  'angel halo': 84,
  'shogun helmet': 78,
  'royal crown': 45,
  'caesar laurel wreath': 42,
  'wukong crown': 38,
}

export const OPTIONS_CLOTHES = {
  'none': 1405,
  'basic t-shirt black': 777,
  'vacation aloha shirt': 743,
  'basic t-shirt white': 750,
  'gamer hoodie': 556,
  'bunny costume': 378,
  'detective coat': 378,
  'kawaii uniform': 378,
  'prisoner uniform': 363,
  'ninja gi': 359,
  'caveman pelt': 354,
  'punk jacket': 341,
  'veteran vest': 333,
  'disco tux': 314,
  'stoner tracksuit': 314,
  'unicorn suit': 307,
  'geisha kimono': 250,
  'wukong robe': 214,
  'dracula cloak': 191,
  'boxer belt': 189,
  'politician blue tie': 187,
  'don fur coat': 165,
  'bone necklace': 117,
  'jersey blue': 112,
  'samurai armor': 105,
  'hippie tie-dye': 100,
  'angel wings': 93,
  'shogun armor': 84,
  'caesar garment': 59,
  'bling chain': 45,
  'royal robe': 39,
}

export const OPTIONS_BODY = {
  'brown': 1095,
  'black': 1034,
  'blue': 1012,
  'green': 956,
  'pink': 788,
  'purple': 685,
  'snow monkey': 644,
  'snub nosed': 612,
  'silverback': 591,
  'leopard': 524,
  'brass': 472,
  'tiger': 353,
  'wooden': 318,
  'camouflage': 309,
  'jelly aqua': 258,
  'jelly blood': 121,
  'rainbow swirl': 99,
  'cyborg': 50,
  'jelly grape': 47,
  'solid gold': 32,
}

export const OPTIONS_BACKGROUND = {
  'deep blue': 1351,
  'khaki': 1342,
  'orange': 1342,
  'yellow': 1324,
  'teal': 1321,
  'light blue': 1311,
  'purple': 1238,
  'gold': 399,
  'holo foil': 244,
  'prismatic foil': 128,
}





// For Punk
export const OPTIONS_FACE = {
  None: 'None',
  Alien: 'Alien',
  Ape: 'Ape',
  Dark: 'Dark',
  Light: 'Light',
  Albano: 'Albano',
  Zombie: 'Zombie',
  Skeleton: 'Skeleton',
  Orc: 'Orc',
  Female: 'Female',
  Vampire: 'vampire',
  'Alien Pink': 'Alien Pink',
}

export const OPTIONS_EYES_1 = {
  None: 'None',
  'Red Laser': 'Red Laser',
  'Gold Laser': 'Gold Laser',
}

export const OPTIONS_HAIR = {
  None: 'None',
  'Mohawk Green': 'Mohawk Green',
  'Mohawk Orange': 'Mohawk Orange',
  'Mohawk Aqua': 'Mohawk Aqua',
  'Shaggy Black': 'Shaggy Black',
  'Shaggy Fushia': 'Shaggy Fushia',
  'Shaggy Orange': 'Shaggy Orange',
  'Wild Blond': 'Wild Blond',
  'Wild Orange': 'Wild Orange',
  'Wild Red': 'Wild Red',
}

export const OPTIONS_HAT = {
  None: 'None',
  Bandana: 'Bandana',
  Beanie: 'Beanie',
  "Cap Green": 'Cap Green"',
  "Cap Purple": 'Cap Purple',
  Cowboy: 'Cowboy',
  Driver: 'Driver',
  Fedora: 'Fedora',
  "Knitted Cap": 'Knitted Cap',
  "Top Hat": 'Top Hat',
}

export const OPTIONS_GLASSES = {
  None: 'None',
  '3D': '3D',
  Boss: 'Boss',
  Hipster: 'Hipster',
  Nerd: 'Nerd',
  Pirate: 'Pirate',
  'Small Shades': 'Small Shades',
}

export const OPTIONS_EARRING = {
  None: 'None',
  Pink: 'Pink',
  Blue: 'Blue',
}

export const OPTIONS_ACCESSORY = {
  None: 'None',
  Mask: 'Mask',
  Pipe: 'Pipe',
  Cigarette: 'Cigarette',
}
