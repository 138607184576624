import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import BananaValue from '../../components/chimpay/BananaValue';
import { TierStar, Dropdown as DropdownIcon } from '../../assets/svgs';
import { useChimpay } from '../../providers/ChimpayProvider';
import { useModal } from '../../providers/ModalProvider';
import { GiftRedemptionTiers, ModalChimpStatus, ModalTypes, SignInTypes } from '../../types/enums';
import { GiftCardMeta } from '../../types/interfaces';
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../../constants/labels";
import { REDEMPTION_TIER_PRICE } from '../../constants/chimpay';
import { getVoucherBananaPrices } from '../../utils/shop-price';
import { getLogoType } from "../../utils/gift";
import useChimpayHook from '../../hooks/useChimpayHook';
import styles from './GiftsShop.module.scss';

const TIERS = [
  GiftRedemptionTiers.GOLD,
  GiftRedemptionTiers.SILVER,
  GiftRedemptionTiers.BRONZE,
];

const GiftCard: FC<{
  gift: GiftCardMeta;
  tier: GiftRedemptionTiers;
  disabled: boolean;
}> = ({ gift, tier, disabled }) => {
  const { active } = useWeb3React();

  const { setClaimingGift, updateGiftOrderForm } = useChimpay();
  const { openModal, updateOperationStep } = useModal();

  const { productName, recipientCurrencyCode } = gift;

  const openClaimModal = useCallback(() => {
    if (disabled) return;

    if (active && !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
    }

    setClaimingGift({ ...gift });

    updateGiftOrderForm('claimingTier', tier);

    openModal(ModalTypes.CLAIM_GIFT);
    updateOperationStep(ModalChimpStatus.INITIAL);
  }, [
    active, gift, tier, disabled, 
    openModal, setClaimingGift, updateGiftOrderForm, updateOperationStep
  ]);

  return (
    <div className={styles.giftCard} onClick={openClaimModal}>
      <div className={styles.giftCardTop}>
        <span className={styles.giftValue}>
          {recipientCurrencyCode} {REDEMPTION_TIER_PRICE[tier]}
        </span>
        <div className={styles.giftLogo}>
          <img src={getLogoType(productName)} alt="" />
        </div>
      </div>
      <div className={styles.giftCardBottom}>
        <span className={styles.giftName}>
          {productName}
        </span>
        <BananaValue
          value={getVoucherBananaPrices(tier, gift)} 
          style={{
            fontSize: '0.875em',
            justifyContent: 'flex-end',
          }}
        />
      </div>
    </div>
  )
}

const LIMIT_DISTANCE = 24;
const MOVE_DISTANCE = 162;

const GiftsShop = ({ loading }: { loading: boolean }) => {
  const giftRefs = useRef<{
    [GiftRedemptionTiers.GOLD]: HTMLDivElement | null;
    [GiftRedemptionTiers.SILVER]: HTMLDivElement | null;
    [GiftRedemptionTiers.BRONZE]: HTMLDivElement | null;
  }>({
    [GiftRedemptionTiers.GOLD]: null,
    [GiftRedemptionTiers.SILVER]: null,
    [GiftRedemptionTiers.BRONZE]: null,
  });

  const [count, setCount] = useState({
    [GiftRedemptionTiers.GOLD]: 0,
    [GiftRedemptionTiers.SILVER]: 0,
    [GiftRedemptionTiers.BRONZE]: 0,
  });

  const { state: { gifts, giftOrderForm: { country }, giftQuota } } = useChimpay();

  const { isRedemptionPeriod } = useChimpayHook();

  const isCoolDown = useMemo(() => !isRedemptionPeriod(), [isRedemptionPeriod]);

  const countryGifts = useMemo(() => {
    const goldGifts = gifts.Gold.filter((item) => item?.country?.isoName === country);
    const silverGifts = gifts.Silver.filter((item) => item?.country?.isoName === country);
    const bilverGifts = gifts.Bronze.filter((item) => item?.country?.isoName === country);

    return {
      [GiftRedemptionTiers.GOLD]: goldGifts,
      [GiftRedemptionTiers.SILVER]: silverGifts,
      [GiftRedemptionTiers.BRONZE]: bilverGifts,
    };
  }, [gifts, country]);

  const handleScroll = useCallback((direction: number, tier: GiftRedemptionTiers) => {
    const ele = giftRefs.current && giftRefs.current[tier];

    if (!ele) return;

    const { clientWidth, scrollLeft, scrollWidth } = ele;

    if (
      (direction === -1 && scrollLeft === 0)
      || (direction === 1 && Math.abs(clientWidth + scrollLeft - scrollWidth) < LIMIT_DISTANCE)
    ) return;

    const move = MOVE_DISTANCE * direction;

    ele?.scrollBy({ left: move, behavior: 'smooth' });

    setCount((prev) => ({
      ...prev,
      [tier]: prev[tier] + direction,
    }));
  }, [giftRefs]);

  return (
    <>
      {
        !isCoolDown && !loading &&
        TIERS.map((tier) => (
          <div key={tier} className={styles.giftTier}>
            <div className={styles.tierTop}>
              <div className={styles.tier}>
                <TierStar className={`${styles.tierIcon} ${styles[`tierIcon_${tier.toLowerCase()}`]}`} />
                <span>
                  {tier} Tier
                </span>
              </div>
              {
                giftQuota[tier] <= 0 &&
                <div className={styles.noAvailableQuota}>
                  Fully Redeemed!
                </div>
              }
            </div>
            <div className={styles.tierGifts}>
              {
                countryGifts[tier].length > 5 && count[tier] > 0 && 
                <div
                  className={`${styles.scrollButton} ${styles.scrollButton_previous}`}
                  onClick={() => handleScroll(-1, tier)}
                >
                  <DropdownIcon />
                </div>
              }
              {
                countryGifts[tier].length > 0 && 
                <div className={styles.giftTierList} ref={(el) => giftRefs.current[tier] = el as HTMLDivElement}>
                  {
                    countryGifts[tier].map((item) => (
                      <GiftCard
                        key={item.productId}
                        gift={item}
                        tier={tier}
                        disabled={giftQuota[tier] <= 0}
                      />
                    ))
                  }
                </div>
              }
              {
                countryGifts[tier].length > 5 && countryGifts[tier].length - 5 > count[tier] && 
                <div
                  className={`${styles.scrollButton} ${styles.scrollButton_next}`}
                  onClick={() => handleScroll(1, tier)}
                >
                  <DropdownIcon />
                </div>
              }
              {
                countryGifts[tier].length === 0 &&
                <div className={styles.noTierGifts}>
                  No gift cards found
                </div>
              }
            </div>
            {
              giftQuota[tier] <= 0 && 
              <div className={styles.giftTierCover} />
            }
          </div>
        ))
      }
    </>
  )
}

export default GiftsShop;
