import { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "../../components/button/ProgressButton";
import BananaValue from "../../components/chimpay/BananaValue";
import { Arrow } from '../../assets/svgs';
import { useModal } from "../../providers/ModalProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import useChimpayHook from "../../hooks/useChimpayHook";
import { ModalTypes, ModalChimpStatus } from "../../types/enums";
import { calculateTotalCostBananas } from "../../utils/shop-price";
import { getLogoType, getRedeemLink } from "../../utils/gift";
import { REDEMPTION_TIER_PRICE } from "../../constants/chimpay";
import styles from './RedeemGift.module.scss';
import AsyncPolling from "../../utils/polling";
import { GiftOrder } from "../../types/interfaces";

enum OrderStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  REFUNDED = 'REFUNDED',
  FAILED = 'FAILED',
}

const RedeemGift = () => {
  const navigate = useNavigate();
  const {
    state: { claimingGift, giftOrderForm },
    updateGiftOrderForm
  } = useChimpay();
  const { claimGift, getGiftTransaction, getAvailableRewards, getAvailableGiftQuota } = useChimpayHook();
  const { openModal, closeModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { productId, productName, country, recipientCurrencyCode } = claimingGift;
  const { recipientName, recipientEmail, claimingTier } = giftOrderForm;

  const unitPrice = useMemo(() => REDEMPTION_TIER_PRICE[claimingTier], [claimingTier]);

  const totalCostInBanana = useMemo(() => 
    calculateTotalCostBananas(claimingTier, claimingGift), [claimingTier, claimingGift]);

  const handleChangeInput = useCallback((
    e: ChangeEvent<HTMLInputElement>, key: string
  ) => {
    e.preventDefault();
    let value = e.target.value;
    if (key === 'recipientEmail') {
      value = e.target.value.trim();
    }

    updateGiftOrderForm(key, value);
  }, [updateGiftOrderForm]);

  const handleRedeem = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);
    openModal(ModalTypes.CLAIM_GIFT);

    let polling: AsyncPolling | null;
    let timer: NodeJS.Timeout | null;

    const clearPolling = () => {
      if (polling) {
        polling.terminatePolling();
        polling = null;
      }
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      closeModal();
    }

    const pollingTransaction = (orderId: number) => async () => {
      const tx: GiftOrder = await getGiftTransaction(orderId);

      if (tx.status === OrderStatus.COMPLETED) {
        getAvailableRewards();
        getAvailableGiftQuota();

        onChangeWarnings({
          type: 'Success',
          title: 'Cheers!',
          message: 'You have successfully redeemed a gift.',
        });

        navigate('/shop');

        clearPolling();
      } else if (tx.status === OrderStatus.REFUNDED || tx.status === OrderStatus.FAILED) {
        onChangeWarnings({
          type: 'Failed',
          message: `Your redemption has been ${tx.status.toString().toLowerCase()}.`,
        });
        clearPolling();
      }
    }

    try {
      const order: GiftOrder = await claimGift(productId, getRedeemLink(productName), giftOrderForm);

      polling = new AsyncPolling(pollingTransaction(order.id), 5000);
      polling.startPolling();

      timer = setTimeout(() => {
        onChangeWarnings({
          type: 'Failed',
          message: 'Card redemption is pending confirmation from supplier. Please check again later.',
        });

        clearPolling();
      }, 60000);
    } catch (err) {
      onChangeWarnings({
        message: 'Something went wrong, please try again.',
      });
      closeModal();
    }
  }, [
    productId, productName, giftOrderForm, 
    updateOperationStep, openModal, closeModal, onChangeWarnings, claimGift, getGiftTransaction, getAvailableRewards, getAvailableGiftQuota, navigate
  ]);

  useEffect(() => {
    updateGiftOrderForm('recipientName', '');
    updateGiftOrderForm('recipientEmail', '');
  }, [updateGiftOrderForm])

  if (!productId) {
    return (
      <div className={styles.noGiftSelected}>
        Please first select a gift card.
      </div>  
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Link to="/shop" className={styles.goBack}>
          <Arrow />
            Back to Shop
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.formTitle}>
          Gift Redemption
        </div>
        <div className={styles.claimForm}>
          <div className={styles.formBody}>
            <div className={styles.formCol}>
              <div className={styles.formItem}>
                <span className={styles.formLabel}>
                  1. Name/alias
                </span>
                <input
                  type="text"
                  className={styles.formInput}
                  placeholder="Name"
                  value={recipientName}
                  onChange={(e) => handleChangeInput(e, 'recipientName')}
                />
              </div>
              <div className={styles.formItem}>
                <span className={styles.formLabel}>
                  2. Recipient email
                </span>
                <input
                  type="email"
                  className={styles.formInput}
                  placeholder="Email"
                  value={recipientEmail}
                  onChange={(e) => handleChangeInput(e, 'recipientEmail')}
                />
                <span className={styles.inputSpec}>
                  Gift redemption details will be sent to this email address.
                </span>
              </div>
            </div>
            <div className={styles.formCol}>
              <div>
                <span className={styles.formLabel}>
                  3. Review
                </span>
                <div className={styles.giftInfo}>
                  <div className={styles.logoImg}>
                    <img src={getLogoType(productName)} alt="" />
                  </div>
                  <div>
                    <div className={styles.giftName}>
                      {recipientCurrencyCode} {unitPrice} {productName}
                    </div>
                    <span className={styles.giftCountry}>
                      {country.name}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.divLine} />

              <div className={styles.total}>
                <span className={styles.totalLabel}>
                  Total cost
                </span>

                <BananaValue
                  value={totalCostInBanana} 
                  style={{
                    width: 'min-content',
                  }}
                />
              </div>
            </div>
          </div>
          <Button
            onClick={handleRedeem}
            disabled={+totalCostInBanana > 0 && (!recipientName || !recipientEmail)}
          >
            Redeem
          </Button>
        </div>
      </div>
    </div>
  )
}

export default RedeemGift;
