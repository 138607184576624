import { createContext, FC, ReactChild, useCallback, useContext, useReducer } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useContractMethods } from '../hooks/useContractMethods';
import { NFTActionTypes } from '../types/enums';
import { convertPriceToDecimal } from '../utils/price';

interface State {
  amount: string,
  maxSupply: number,
  remainingSupply: number,
  startTimestamp: number,
  balance: number,
  newChimps: {
    trxnId: string,
    tokenIds: string[],
  },
}

interface IContext {
  state: State,
  updateAmount: (val: string) => void,
  getPoolInfos: () => void,
  getBalanceOf: () => void,
  updateNewTrxnChimps: (trxnId: string, tokenIds: string[]) => void,
  updateRemainingSupply: () => void,
}

const initialState = {
  amount: '1',
  maxSupply: 0,
  remainingSupply: 0,
  startTimestamp: 0,
  balance: 0,
  newChimps: {
    trxnId: '',
    tokenIds: [],
  },
}

const Context = createContext<IContext>({
  state: initialState,
  updateAmount: () => {},
  getPoolInfos: () => {},
  getBalanceOf: () => {},
  updateNewTrxnChimps: () => {},
  updateRemainingSupply: () => {},
})

export const useNFTMint = () => {
  return useContext(Context);
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case NFTActionTypes.UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.value,
      }
    case NFTActionTypes.GET_POOL_INFOS:
      return {
        ...state,
        maxSupply: action.max,
        remainingSupply: action.remaining,
        startTimestamp: action.timestamp,
      }
    case NFTActionTypes.GET_BALANCE:
      return {
        ...state,
        balance: action.amount
      }
    case NFTActionTypes.UPDATE_NEW_CHIMPS:
      return {
        ...state,
        newChimps: {
          trxnId: action.trxnId,
          tokenIds: action.tokenIds,
        }
      }
    case NFTActionTypes.UPDATE_REMAINING_SUPPLY:
      return {
        ...state,
        remainingSupply: action.amount
      }
    default:
      throw new Error();
  }
}

const NFTProvider: FC<{ children: ReactChild }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { active, account } = useWeb3React();
  const { getPoolDetails, getAccountBalance, getRemainingQuota } = useContractMethods();

  const updateAmount = useCallback((value: string) => {
    if (/^[1-9]{0,1}\d{0,2}$/.test(value)) {
      dispatch({
        type: NFTActionTypes.UPDATE_AMOUNT,
        value: value,
      })
    }
  }, [])

  const getPoolInfos = useCallback(async () => {
    try {
      const data = await getPoolDetails();

      dispatch({
        type: NFTActionTypes.GET_POOL_INFOS,
        max: +(data[0].toString()),
        remaining: +(data[1].toString()),
        timestamp: +(data[2].toString()),
      })
    } catch (error) {
      console.log(error);
    }
  }, [getPoolDetails])

  const getBalanceOf = useCallback(async () => {
    if (active) {
      try {
        const amount = await getAccountBalance(account || '');

        dispatch({
          type: NFTActionTypes.GET_BALANCE,
          amount: convertPriceToDecimal(amount),
        });
      } catch (error) {
        // ignore
      }
    }
  }, [getAccountBalance, active, account])

  const updateNewTrxnChimps = useCallback((trxnId: string, tokenIds: string[]) => {
    dispatch({
      type: NFTActionTypes.UPDATE_NEW_CHIMPS,
      trxnId,
      tokenIds,
    });
  }, [])

  const updateRemainingSupply = useCallback(async () => {
    const amount = await getRemainingQuota();

    dispatch({
      type: NFTActionTypes.UPDATE_REMAINING_SUPPLY,
      amount: +amount,
    });
  }, [getRemainingQuota])

  const context = {
    state,
    updateAmount,
    getPoolInfos,
    getBalanceOf,
    updateNewTrxnChimps,
    updateRemainingSupply,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default NFTProvider;