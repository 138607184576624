import { FC } from "react";
import Dropdown from "../dropdown/Dropdown";
import styles from './Sorting.module.scss';

interface Props {
  sorting: any,
  options: {
    text: any;
    value: any;
  }[],
  onSort: (val: any) => void,
}

const SortingTabs: FC<Props> = ({ sorting, options, onSort }) => {
  return (
    <div className={styles.tabs}>
      <div className={styles.label}>Sort by</div>
      <Dropdown className={styles.dropdown} select={sorting} onSelect={onSort} options={options} />
    </div>
  )
}

export default SortingTabs;