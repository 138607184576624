import { useCallback } from "react";
import { GraphQLClient, gql } from 'graphql-request';
import { useMerchandise } from "../providers/MerchandiseProvider";
import { DISCORD_API } from "../configs";
import { CheckoutInputMeta, OrderMeta, RequestShippingPriceMeta } from "../types/interfaces";
import { useLogin } from "./useLogin";

const endpoint = `${DISCORD_API}/graphql`;

export const useMerchandiseHook = () => {
  const { getMerchandiseList, getMerchandiseDetail, getAvailableQuotaList, getAvailableQuota } = useMerchandise();
  const { checkValidToken } = useLogin();

  const getMerchList = useCallback(async () => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query {
          products {
            id
            contractId
            title
            imageUrl
            description
            price
            currency
            bananaPrice
            variantName1
            variantName2
            variantName3
            variantOptions1
            variantOptions2
            variantOptions3
          }
        }
      `;

      const data = await graphQLClient.request(query);

      let products = data?.products || [];

      products.sort((a: any, b: any) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
      getMerchandiseList(products);
    } catch (e) {
      getMerchandiseList([]);
    }

  }, [getMerchandiseList]);

  const getMerchDetail = useCallback(async (id: number) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query product($id: Int!) {
          product(id: $id) {
            id
            contractId
            title
            imageUrl
            description
            price
            currency
            bananaPrice
            variantName1
            variantName2
            variantName3
            variantOptions1
            variantOptions2
            variantOptions3
          }
        }
      `;

      const result = await graphQLClient.request(query, { id });
      const product = result?.product;
      getMerchandiseDetail(product);
    } catch (e) {
    }
  }, [getMerchandiseDetail]);

  const calculateShippingPrice = useCallback(async (data: RequestShippingPriceMeta) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const mutation = gql`
        mutation requestShippingPrice($data: RequestShippingPriceInput!) {
          requestShippingPrice(data: $data) {
            shippingPrice
            shippingPriceSecret
          }
        }
      `;

      const results = await graphQLClient.request(mutation, { data });
      return results?.requestShippingPrice
    } catch (e: any) {}
  }, []);

  const orderCheckout = useCallback(async (payload: CheckoutInputMeta): Promise<OrderMeta> => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation orderCheckout($data: CheckoutInput!) {
        orderCheckout(data: $data) {
          id
          firstName
          lastName
          mobile
          email
          shippingCountry
          shippingCountryCode
          shippingAddress1
          shippingAddress2
          shippingCity
          shippingPostCode
          status
          items {
            ...on OrderItem {
              id
              contractId
              title
              variantName1
              variantOption1
              variantName2
              variantOption2
              variantName3
              variantOption3
              price
              currency
              quantity
            }
          }
          shippedAt
          updatedAt
          createdAt
        }
      }
    `;

    const variables = {
      data: payload
    };

    const results = await graphQLClient.request(mutation, variables);
    return results?.orderCheckout;
  }, [checkValidToken]);

  const verifyOrderCheckout = useCallback(async (orderId: number) => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation orderVerifyCheckout($id: Int!) {
        orderVerifyCheckout(id: $id) {
          id
          firstName
          lastName
          mobile
          email
          shippingCountry
          shippingAddress1
          shippingAddress2
          shippingCity
          shippingPostCode
          status
          items {
            ...on OrderItem {
              id
              contractId
              title
              variantName1
              variantOption1
              variantName2
              variantOption2
              variantName3
              variantOption3
              price
              currency
              quantity
            }
          }
          shippedAt
          updatedAt
          createdAt
        }
      }
    `;

    const variables = {
      id: orderId
    };

    const results = await graphQLClient.request(mutation, variables);
    return results?.orderVerifyCheckout;
  }, [checkValidToken]);

  const getQuotaList = useCallback(async (productIds: number[], accountId: string) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query orderAvailableQuota($accId : String! , $productIds : [Int!]!){
          orderAvailableQuota(accountId: $accId,productIds: $productIds) { 
            productId
            availableQuota
          }
        }
      `;
      const variables = {
        accId: accountId,
        productIds

      }
      const data = await graphQLClient.request(query, variables);

      let quotas = data?.orderAvailableQuota || [];
      getAvailableQuotaList(quotas);
    } catch (e) {
      getAvailableQuotaList([]);
    }

  }, [getAvailableQuotaList]);

  const getQuotaDetail = useCallback(async (propductId: number, accountId: string) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query orderAvailableQuota($accId : String! , $productIds : [Int!]!) {
          orderAvailableQuota(accountId: $accId,productIds: $productIds) { 
            productId
            availableQuota
          }
        }
      `;
      const variables = {
        accId: accountId,
        productIds: [propductId]
      }
      const data = await graphQLClient.request(query, variables);
      let quotas = data?.orderAvailableQuota || [];
      getAvailableQuota(quotas);
    } catch (e) {
      getAvailableQuota([]);
    }

  }, [getAvailableQuota]);

  const getProductRemainingSupplies = useCallback(async (productId: number) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query OrderRemainingSupply($productId: Int!) {
          orderRemainingSupply(productId: $productId) {
            remainingTotal
            subProducts
          }
        }
      `;

      const data = await graphQLClient.request(query, { productId });
	  return data.orderRemainingSupply;
    } catch (e) {}
  }, []);

  const cancelOrder = useCallback(async (orderId: number) => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation orderCancel($id: Int!) {
        orderCancel(id: $id) {
          id
          firstName
          lastName
          mobile
          email
          shippingCountry
          shippingAddress1
          shippingAddress2
          shippingCity
          shippingPostCode
          status
          shippedAt
          updatedAt
          createdAt
        }
      }
    `;

    const variables = {
      id: orderId
    };

    const results = await graphQLClient.request(mutation, variables);
    return results?.orderCancel;
  }, [checkValidToken]);

  const getOrdersByTime = useCallback(async (from: string | number, to?: string | number) => {
    try {
      const accessToken = await checkValidToken();
      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });
  
      const query = gql`
        query Orders($fromTime: String!, $toTime: String) {
          orders(fromTime: $fromTime, toTime: $toTime) {
            id
            firstName
            lastName
            mobile
            email
            shippingCountry
            shippingCountryCode
            shippingAddress1
            shippingAddress2
            shippingCity
            shippingState
            shippingPostCode
            shippingTrackingCode
            shipmentWaybill
            shipmentLabel
            subtotalPrice
            subtotalBananaPrice
            shippingPrice
            status
            processingAt
            shippedAt
            cancelledAt
            updatedAt
            createdAt
            items {
              id
              productId
              contractId
              title
              variantName1
              variantOption1
              variantName2
              variantOption2
              variantName3
              variantOption3
              price
              currency
              quantity
            }
          }
        }
      `;

      const variables = {
        fromTime: new Date(from).toISOString(),
        toTime: to ? new Date(to).toISOString() : null
      };
  
      const data = await graphQLClient.request(query, variables);
      return data?.orders;
    } catch (error) {}
  }, [checkValidToken]);

  const getOrderById = useCallback(async (id: number): Promise<OrderMeta> => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const query = gql`
      query OrderById($orderId: Int!) {
        orderById(orderId: $orderId) {
          id
          firstName
          lastName
          mobile
          email
          shippingCountry
          shippingCountryCode
          shippingAddress1
          shippingAddress2
          shippingCity
          shippingState
          shippingPostCode
          shippingTrackingCode
          subtotalPrice
          subtotalBananaPrice
          shippingPrice
          status
          processingAt
          shippedAt
          cancelledAt
          updatedAt
          createdAt
        }
      }
    `;

    const data = await graphQLClient.request(query, {
      orderId: id
    });

    return data?.orderById;
  }, [checkValidToken]);

  return {
    getMerchList,
    getMerchDetail,
    calculateShippingPrice,
    verifyOrderCheckout,
    getQuotaList,
    getQuotaDetail,
	  getProductRemainingSupplies,
    orderCheckout,
    cancelOrder,
    getOrdersByTime,
    getOrderById,
  }
}

