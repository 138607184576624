import React, { Suspense, useCallback, useEffect, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import LinkButton from '../../components/button/LinkButton';
import BananaValue from '../../components/chimpay/BananaValue';
import Tooltip from '../../components/Tooltip/Tooltip';
import { useChimpay } from '../../providers/ChimpayProvider';
import { useLore } from '../../providers/LoreProvider';
import useChimpayHook from '../../hooks/useChimpayHook';
import useLoreHook from '../../hooks/useLoreHook';
import { Arrow } from '../../assets/svgs';
import Speaker from '../../assets/images/trumpet.png';
import { roundNumberWithFix } from '../../utils/number';
import { SCROLL_CHIMPION_AMOUNT } from '../../constants/lore';
import styles from './Dashboard.module.scss';

const TABS = [
  {
    label: 'Stake',
    query: '',
  },
  {
    label: 'Current Season',
    query: 'active-gallery',
  },
  {
    label: 'Archived season',
    query: 'archived-gallery',
  }
];

const StakedChimpsLazyContainer = React.lazy(() => import('./StakedChimps'));
const ActiveGalleryLazyContainer = React.lazy(() => import('../lore/ActiveGallery'));
const ArchivedGalleryLazyContainer = React.lazy(() => import('../lore/ArchivedGallery'));

const Dashboard = () => {
  const { state: { availableBananas } } = useChimpay();
  const { state: { activeChapterId, myContributions, rank, loreNFTBalances } } = useLore();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { getSeasonPeriod, isRedemptionPeriod } = useChimpayHook();
  const { getContributionRanking } = useLoreHook();

  const { redemption } = useMemo(() => getSeasonPeriod(), [getSeasonPeriod]);

  const tabs = useMemo(() => activeChapterId > 1 ? TABS : TABS.slice(0, 2), [activeChapterId]);

  const mySeasonContributions = useMemo(() => {
    return myContributions.reduce((sum, { amount }) => {
      sum += amount;
      return sum;
    }, 0)
  }, [myContributions]);

  const isWinner = useMemo(() => {
    const currentToken = activeChapterId - 1;
    if (currentToken < 0 || !loreNFTBalances[currentToken]) {
      return false;
    }
    return rank.ranking <= SCROLL_CHIMPION_AMOUNT && loreNFTBalances[currentToken] > 0;
  }, [activeChapterId, loreNFTBalances, rank]);

  const activeTab = useMemo(() => {
    const view = searchParams.get('view');

    if (view) {
      return view;
    }
    return '';
  }, [searchParams])

  const switchTab = useCallback((tabId: string) => {
    const to = tabId ? `/chimpay/dashboard?view=${tabId}` : '/chimpay/dashboard';
    navigate(to, { replace: true });
  }, [navigate]);

  useEffect(() => {
    getContributionRanking();
  }, [getContributionRanking])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Link to="/chimpay" className={styles.goBack}>
            <Arrow />
            <span>Back to Chimpay x Scroll</span>
          </Link>
          <div className={styles.dataContent}>
            <div className={styles.dataItem}>
              <span className={styles.dataLabel}>
                My Banana Sack
              </span>
              <BananaValue
                value={roundNumberWithFix(availableBananas, 2)} 
                className={styles.bananasOwned}
              />
              <div className={styles.dataDesc}>
                <span>Expires on {moment(redemption.end).format('DD/MM/YY')}</span>
              </div>
            </div>
            <div className={styles.dataItem}>
              <span className={styles.dataLabel}>
                My Ranking
                <Tooltip>
                  Top 50 Chimpions on the Leaderboard will co-own the lore NFT. 
                  You can increase your ranking by contributing more Bananas.
                </Tooltip>
              </span>
              <div className={styles.dataValue}>
                <span className={styles.dataValue_main}>
                  #{!rank.ranking ? '-' : rank.ranking}
                </span>
                <span className={styles.dataValue_sub}>
                  / {!rank.total ? '-' : rank.total}
                </span>
              </div>
              <div className={styles.dataDesc}>
                <span>Chimpions</span>
                {
                  isWinner && 
                  <LinkButton
                    isGhost={false}
                    className={styles.redirect}
                    to="/my-chimps?view=scroll-nft"
                  >
                    View NFT
                  </LinkButton>
                }
              </div>
            </div>
            <div className={styles.dataItem}>
              <span className={styles.dataLabel}>
                My Season Contribution
                <Tooltip>
                  Season contribution is the total amount of Bananas you have contributed to all the Scrolls.
                </Tooltip>
              </span>
              <div className={styles.dataValue}>
                <span className={styles.dataValue_main}>
                  {mySeasonContributions}
                </span>
              </div>
              <div className={styles.dataDesc}>
                <span>Bananas</span>
                <LinkButton
                  isGhost={false}
                  className={styles.redirect}
                  to="/chimpay/scroll"
                  disabled={!isRedemptionPeriod()}
                >
                  Contribute
                </LinkButton>
              </div>
            </div>
          </div>
          {
            isRedemptionPeriod() && !isWinner && 
            <div className={styles.stakeAlert}>
              <img className={styles.alertSpeaker} src={Speaker} alt="" />
              <div className={styles.stakeAlertText}>
                Top 50 Chimpions on the leaderboard will co-own the Scroll NFT. Event ends in {Math.floor((Math.max(redemption.end - Date.now(), 0)) / (24 * 60 * 60 * 1000))} days!
              </div>
            </div>
          }
          {
            !isRedemptionPeriod() && isWinner && 
            <div className={styles.stakeAlert}>
              <span>🎉</span>
              <div className={styles.stakeAlertText}>
                Season have ended, you have been rewarded shared ownership of the Scroll NFT Chapter 1!
              </div>
            </div>
          }
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.switches}>
          {
            tabs.map(({ query, label }, index) => (
              <span 
                key={index}
                className={classNames(styles.switchTab, { [styles.switchTab_active]: activeTab === query })}
                onClick={() => switchTab(query)}
              >
                {label}
              </span>
            ))
          }
        </div>
        <Suspense fallback={<div className={styles.loading}>Loading...</div>}>
          {activeTab === tabs[0].query && <StakedChimpsLazyContainer />}
          {activeTab === tabs[1].query && <ActiveGalleryLazyContainer />}
          {activeChapterId > 1 && activeTab === tabs[2].query && <ArchivedGalleryLazyContainer />}
        </Suspense>
      </div>
    </div>
  )
}

export default Dashboard;
