import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useReducer } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useContractMethods } from '../hooks/useContractMethods';
import {
  SaleChimpMeta,
  FilterCountsForSales,
  OfferChimpMeta,
  OfferUserMade,
  FavoriteCountChimp,
  ChimpTransaction
} from '../types/interfaces';
import { ChimpActionTypes, ChimpOperateTypes, TraitTypes } from '../types/enums';
import { addOrUpdateChimp, mergeChimps } from '../utils/chimps-array';
import { buildForChimpsMeta, countTraitsForListedChimps } from '../utils/chimp-traits';
import { TOTAL_SUPPLY } from '../configs';
import { buildForOffersMeta, buildForOneOfferMeta } from '../utils/chimp-offer';

export interface State {
  traitsCountForSales: FilterCountsForSales,
  allChimps: SaleChimpMeta[],
  allSaleChimps: SaleChimpMeta[],
  marketHonoraryChimps: SaleChimpMeta[],
  ownedChimps: SaleChimpMeta[],
  ownedHonoraryChimps: SaleChimpMeta[],
  forBuyOrSaleChimp: SaleChimpMeta,
  offers: OfferChimpMeta[],
  offerChimp: OfferChimpMeta,
  offerUserMade: { indexValue: string, offerMade: boolean };
  allOfferUserMade: OfferUserMade[],
  transactions: ChimpTransaction[],
}

interface IContext {
  state: State,
  getOwnedChimps: (sales: SaleChimpMeta[]) => void,
  getOwnedHonoraryChimps: (sales: SaleChimpMeta[]) => void,
  getChimpForBuyOrSale: (val: any) => void,
  getSalingChimps: (sales: SaleChimpMeta[]) => void,
  getSalingHonoraryChimps: (sales: SaleChimpMeta[]) => void,
  getAllChimpsInMarket: (favoriteCountChimps: FavoriteCountChimp[]) => void,
  updateChimps: (id: string, chimpId: string, type: ChimpOperateTypes) => void,
  updateHonoraryChimps: (id: string, chimpId: string, type: ChimpOperateTypes) => void,
  cleanChimpsData: (key: string) => void,
  getAllOffersChimp: (offers: OfferChimpMeta[]) => void,
  getOfferChimpByIndex: (offer: OfferChimpMeta) => void,
  getOfferUserMade: (data: { indexValue: string, offerMade: boolean }) => void,
  getAllOfferUserMade: (data: OfferUserMade[]) => void,
  updateFavoriteCount: (favoriteCounAllChimps: { normalChimps: FavoriteCountChimp[], honoraryChimps: FavoriteCountChimp[] }, isForSale: boolean, isHonorary: boolean) => void,
  getChimpTransactions: (data: ChimpTransaction[]) => void,
}

export const initialState = {
  traitsCountForSales: {
    [TraitTypes.Earrings]: {},
    [TraitTypes.Mouth]: {},
    [TraitTypes.Eyes]: {},
    [TraitTypes.Headgear]: {},
    [TraitTypes.Clothes]: {},
    [TraitTypes.Body]: {},
    [TraitTypes.Background]: {},
  },
  allChimps: [],
  allSaleChimps: [],
  marketHonoraryChimps: [],
  ownedChimps: [],
  ownedHonoraryChimps: [],
  forBuyOrSaleChimp: {} as any,
  offers: [],
  offerChimp: {} as any,
  offerUserMade: { indexValue: '0', offerMade: false },
  allOfferUserMade: [],
  transactions: [],
}

const Context = createContext<IContext>({
  state: initialState,
  getOwnedChimps: () => {},
  getOwnedHonoraryChimps: () => {},
  getChimpForBuyOrSale: () => {},
  getSalingChimps: () => {},
  getSalingHonoraryChimps: () => {},
  getAllChimpsInMarket: () => {},
  updateChimps: () => {},
  updateHonoraryChimps: () => {},
  cleanChimpsData: () => {},
  getAllOffersChimp: () => {},
  getOfferChimpByIndex: () => {},
  getOfferUserMade: () => {},
  getAllOfferUserMade: () => {},
  updateFavoriteCount: () => {},
  getChimpTransactions: () => {},
})

export const useChimp = () => {
  return useContext(Context);
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case ChimpActionTypes.MARKET_CHIMPS:
      const allChimps = mergeChimps(state.allChimps, action.data);

      const allSaleChimps = mergeChimps(state.allSaleChimps, action.data);
      const traitsCountForSales = countTraitsForListedChimps(allSaleChimps);

      return {
        ...state,
        allChimps,
        allSaleChimps,
        traitsCountForSales,
      }
    case ChimpActionTypes.MARKET_ALL_CHIMPS:
      return {
        ...state,
        allChimps: mergeChimps(state.allSaleChimps, action.data),
      }
    case ChimpActionTypes.MARKET_HONORARY_CHIMPS:
      const forSales = action.data.filter((it: any) => it.isForSale);

      return {
        ...state,
        marketHonoraryChimps: mergeChimps(state.marketHonoraryChimps, action.data),
        allSaleChimps: mergeChimps(state.allSaleChimps, forSales),
      }
    case ChimpActionTypes.GET_OWNED_CHIMPS:
      return {
        ...state,
        ownedChimps: mergeChimps(state.ownedChimps, action.data),
      }
    case ChimpActionTypes.GET_OWNED_FAV_CHIMPS:
      return {
        ...state,
        ownedHonoraryChimps: mergeChimps(state.ownedHonoraryChimps, action.data),
      }
    case ChimpActionTypes.UPDATE_CHIMPS:
      return {
        ...state,
        allChimps: action.all,
        allSaleChimps: action.allSales,
        ownedChimps: action.owns,
        traitsCountForSales: countTraitsForListedChimps(action.allSales)
      }
    case ChimpActionTypes.UPDATE_FAV_CHIMPS:
      return {
        ...state,
        allSaleChimps: action.allSales,
        ownedHonoraryChimps: action.myHonoraries,
        marketHonoraryChimps: action.marketHonoraries,
      }
    case ChimpActionTypes.GET_CHIMP_FOR_BUY_OR_SELL:
      return {
        ...state,
        forBuyOrSaleChimp: action.value,
      }
    case ChimpActionTypes.CLEAN_CHIMP_DATA:
      return {
        ...state,
        [action.key]: (initialState as any)[action.key],
      }
    case ChimpActionTypes.GET_ALL_OFFERS_OF_CHIMP:
      return {
        ...state,
        offers: action.data
      }
    case ChimpActionTypes.GET_OFFER_CHIMP_BY_INDEX:
      return {
        ...state,
        offerChimp: action.data,
      }
    case ChimpActionTypes.GET_OFFER_USER_MADE:
      return {
        ...state,
        offerUserMade: action.data,
      }
    case ChimpActionTypes.GET_ALL_OFFER_USER_MADE:
      return {
        ...state,
        allOfferUserMade: action.data,
      }
    case ChimpActionTypes.GET_CHIMP_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data,
      }
    default:
      throw new Error();
  }
}

const ChimpProvider: FC<{ children: ChildNode | ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const { allChimps, allSaleChimps, marketHonoraryChimps, ownedChimps, ownedHonoraryChimps, forBuyOrSaleChimp } = state;

  const { account } = useWeb3React();
  const { checkOfferedForSale } = useContractMethods();

  const getAllChimpsInMarket = useCallback((favoriteCountChimps: FavoriteCountChimp[]) => {
    const chimps: any = Array.from({ length: TOTAL_SUPPLY }, (_, i) => ({ tokenId: i.toString() }));
    const sales = chimps.map((sale: { tokenId: string }) => {
      const favoriteChimpById: any = favoriteCountChimps.filter((item: any) => item.tokenId === sale.tokenId);
      const favoriteCount = favoriteChimpById.length > 0 ? favoriteChimpById[0].favoriteCount : 0
      return {
        ...sale,
        favoriteCount
      }
    });

    const data = buildForChimpsMeta(sales);

    dispatch({
      type: ChimpActionTypes.MARKET_ALL_CHIMPS,
      data,
    })
  }, []);

  const getSalingChimps = useCallback((sales: SaleChimpMeta[]) => {
    try {
      const data = buildForChimpsMeta(sales);
      dispatch({
        type: ChimpActionTypes.MARKET_CHIMPS,
        data,
      });

    } catch (error) {
      console.log(error);
    }
  }, []);

  const getSalingHonoraryChimps = useCallback(async (sales: SaleChimpMeta[]) => {
    try {
      const data = buildForChimpsMeta(sales);
      dispatch({
        type: ChimpActionTypes.MARKET_HONORARY_CHIMPS,
        data,
      })
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getOwnedChimps = useCallback(async (sales: SaleChimpMeta[]) => {
    try {
      const data = buildForChimpsMeta(sales);

      dispatch({
        type: ChimpActionTypes.GET_OWNED_CHIMPS,
        data,
      })
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getOwnedHonoraryChimps = useCallback(async (sales: SaleChimpMeta[]) => {
    try {
      const data = buildForChimpsMeta(sales);

      dispatch({
        type: ChimpActionTypes.GET_OWNED_FAV_CHIMPS,
        data,
      })
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getChimpForBuyOrSale = useCallback((value: any) => {
    dispatch({
      type: ChimpActionTypes.GET_CHIMP_FOR_BUY_OR_SELL,
      value,
    });
  }, []);

  const updateChimps = useCallback(async (tokenId: string, chimpId: string, operate: ChimpOperateTypes) => {
    try {
      let allSales = [...allSaleChimps];
      let owns = [...ownedChimps];

      const sales = await checkOfferedForSale(tokenId, false);
  
      const updates = {
        ...forBuyOrSaleChimp,
        ...sales,
      }

      getChimpForBuyOrSale(updates);

      const all = addOrUpdateChimp([...allChimps], updates);

      if (operate === ChimpOperateTypes.TRANSFER) {
        allSales = allSales.filter((item) => item.id !== chimpId)
        owns = owns.filter((item) => item.id !== chimpId);
      } else if (operate === ChimpOperateTypes.BUY || operate === ChimpOperateTypes.REMOVE) {
        allSales = allSales.filter((item) => item.id !== chimpId)
        owns = addOrUpdateChimp(owns, updates)
      } else if (operate === ChimpOperateTypes.SELL || operate === ChimpOperateTypes.ADJUST) {
        allSales = addOrUpdateChimp(allSales, updates)
        owns = addOrUpdateChimp(owns, updates);
      }

      dispatch({
        type: ChimpActionTypes.UPDATE_CHIMPS,
        allSales,
        owns,
        all,
      })
    } catch (error) {
      console.log(error)
    }
  }, [allChimps, allSaleChimps, ownedChimps, forBuyOrSaleChimp, checkOfferedForSale, getChimpForBuyOrSale]);

  const updateHonoraryChimps = useCallback(async (tokenId: string, chimpId: string, operate: ChimpOperateTypes) => {
    let myHonoraries = [...ownedHonoraryChimps]
    let allSales = allSaleChimps.filter((item) => item.id !== chimpId);

    const sales = await checkOfferedForSale(tokenId, true);

    const updates = {
      ...forBuyOrSaleChimp,
      ...sales,
    }

    getChimpForBuyOrSale(updates);

    const marketHonoraries = addOrUpdateChimp(marketHonoraryChimps, updates)

    if (operate === ChimpOperateTypes.TRANSFER) {
      myHonoraries = myHonoraries.filter((item) => item.id !== chimpId)
    } else if (operate === ChimpOperateTypes.SELL || operate === ChimpOperateTypes.ADJUST) {
      myHonoraries = addOrUpdateChimp(myHonoraries, updates)
      allSales = [updates, ...allSales]
    } else if (operate === ChimpOperateTypes.BUY || operate === ChimpOperateTypes.REMOVE) {
      myHonoraries = addOrUpdateChimp(myHonoraries, updates)
    }

    dispatch({
      type: ChimpActionTypes.UPDATE_FAV_CHIMPS,
      myHonoraries,
      marketHonoraries,
      allSales,
    })
  }, [allSaleChimps, ownedHonoraryChimps, marketHonoraryChimps, forBuyOrSaleChimp, checkOfferedForSale, getChimpForBuyOrSale]);

  const cleanChimpsData = useCallback((key: string) => {
    dispatch({
      type: ChimpActionTypes.CLEAN_CHIMP_DATA,
      key,
    })
  }, []);

  const getAllOffersChimp = useCallback((offers) => {
    const data = buildForOffersMeta(offers);
    dispatch({
      type: ChimpActionTypes.GET_ALL_OFFERS_OF_CHIMP,
      data,
    })
  }, []);

  const getOfferChimpByIndex = useCallback((offer) => {
    const data = buildForOneOfferMeta(offer);
    dispatch({
      type: ChimpActionTypes.GET_OFFER_CHIMP_BY_INDEX,
      data,
    })
  }, []);

  const getOfferUserMade = useCallback((data) => {
    dispatch({
      type: ChimpActionTypes.GET_OFFER_USER_MADE,
      data,
    })
  }, []);

  const getAllOfferUserMade = useCallback((data) => {
    dispatch({
      type: ChimpActionTypes.GET_ALL_OFFER_USER_MADE,
      data,
    })
  }, []);

  const getChimpTransactions = useCallback((data) => {
    dispatch({
      type: ChimpActionTypes.GET_CHIMP_TRANSACTIONS,
      data,
    });
  }, []);

  const updateFavoriteCount = useCallback((favoriteCounAllChimps: { normalChimps: FavoriteCountChimp[], honoraryChimps: FavoriteCountChimp[] }, isForSale: boolean, isHonorary: boolean) => {
    const chimps = isForSale ? allSaleChimps : allChimps;
    const marketChimps = isHonorary ? marketHonoraryChimps : chimps;
    const favoriteCountChimps = isHonorary ? favoriteCounAllChimps.honoraryChimps : favoriteCounAllChimps.normalChimps;

    const sales = marketChimps.map((sale: SaleChimpMeta) => {
      const updateChimp: FavoriteCountChimp[] = favoriteCountChimps.filter((item: FavoriteCountChimp) => item.tokenId === sale.tokenId);
      return {
        ...sale,
        favoriteCount: updateChimp.length > 0 ? updateChimp[0].favoriteCount : 0
      }
    });

    const data = buildForChimpsMeta(sales);

    if (isHonorary) {
      dispatch({
        type: ChimpActionTypes.MARKET_HONORARY_CHIMPS,
        data,
      })
    } else {
      if (isForSale) {
        dispatch({
          type: ChimpActionTypes.MARKET_CHIMPS,
          data,
        })
      } else {
        dispatch({
          type: ChimpActionTypes.MARKET_ALL_CHIMPS,
          data,
        })
      }
    }
  }, [allChimps, allSaleChimps, marketHonoraryChimps]);

  useEffect(() => {
    if (account) {
      cleanChimpsData('ownedChimps');
      cleanChimpsData('ownedHonoraryChimps');
    }
  }, [account, cleanChimpsData])

  const context = {
    state,
    getAllChimpsInMarket,
    getSalingChimps,
    getSalingHonoraryChimps,
    getOwnedChimps,
    getOwnedHonoraryChimps,
    updateChimps,
    updateHonoraryChimps,
    getChimpForBuyOrSale,
    cleanChimpsData,
    getAllOffersChimp,
    getOfferChimpByIndex,
    getOfferUserMade,
    getAllOfferUserMade,
    updateFavoriteCount,
    getChimpTransactions,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default ChimpProvider;