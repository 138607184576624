import { useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useNFTMint } from "../../providers/NFTProvider";
import { useContractMethods } from "../../hooks/useContractMethods";
import { useChimpsHook } from "../../hooks/useChimpsHook";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from "./ChimpSuccessModal";
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC, WARNING_ALREADY_OWNED, WARNING_NOT_ENOUGH_BALANCE, WARNING_WALLET_UNCONNECTED } from "../../constants/errors";
import { Cross, Info } from "../../assets/svgs";
import { convertPriceToDecimal } from "../../utils/price";
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import styles from './ChimpModal.module.scss';
import { getChimpDisplayTitle } from "../../utils/title";

const BuyChimpModal = () => {
  const [trxnId, setTrxnId] = useState('');

  const { active, account } = useWeb3React();
  const { step, closeModal, openModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { updateChimpData } = useChimpsHook();
  const { state: { forBuyOrSaleChimp } } = useChimp();
  const { state: { balance } } = useNFTMint();

  const { tokenId, id: chimpId, image, price, seller, isHonorary, name } = forBuyOrSaleChimp;

  const { buyChimp } = useContractMethods();

  const handleBuy = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      const result = await buyChimp(tokenId, price, isHonorary);

      if (result.transactionHash) {
        setTrxnId(result.transactionHash);
      }

      updateOperationStep(ModalChimpStatus.SUCCEESS);

      updateChimpData(tokenId, chimpId, ChimpOperateTypes.BUY, isHonorary);
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);
      
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [buyChimp, onChangeWarnings, updateChimpData, updateOperationStep, tokenId, chimpId, price, isHonorary]);

  const handleClick = useCallback(() => {
    if (!active) {
      openModal(ModalTypes.CONNECT_WALLET);
    } else {
      switch (step) {
        case ModalChimpStatus.READY:
          handleBuy();
          break;
        case ModalChimpStatus.FAILED:
          updateOperationStep(ModalChimpStatus.READY);
          break;
        default:
          break;
      }
    }
  }, [step, active, handleBuy, openModal, updateOperationStep])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep])

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;

    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;

    return ButtonStatus.NORMAL;
  }, [step])

  const disableButton = useMemo(() => {
    return active && (+roundNumberWithFix(balance) < +convertPriceToDecimal(price) || seller === account);
  }, [active, balance, price, seller, account])

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {
        step !== ModalChimpStatus.SUCCEESS &&
        <div className={styles.container}>
          <Cross className={styles.cancel} onClick={closeModal} />
          <div className={styles.header}>
            <span className={styles.title}>Review</span>
            <div className={styles.summary}>
              <img className={styles.img} src={image || ''} alt="" />
              <div>
                <div className={styles.chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
                <div className={styles.token_id}>Token ID: {tokenId}</div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.content_row}>
              <span className={styles.content_label}>Current price</span>
              <span>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(price), 3))} CRO</span>
            </div>
            <div className={styles.content_row}>
              <span className={styles.content_label}>CRO in wallet</span>
              <span>{formatNumberWithComma(roundNumberWithFix(balance, 3))} CRO</span>
            </div>
            <div className={styles.warnings}>
              {
                !active &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_WALLET_UNCONNECTED}</span>
                </div>
              }
              {
                active && +roundNumberWithFix(balance) < +convertPriceToDecimal(price) &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_NOT_ENOUGH_BALANCE}</span>
                </div>
              }
              {
                seller === account && 
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_ALREADY_OWNED}</span>
                </div>
              }
            </div>
            <div className={styles.buttons}>
              <ProgressButton disabled={disableButton} status={buttonStatus} onClick={handleClick}>
                {!active && 'Connect Wallet'}
                {active && step === ModalChimpStatus.DISABLED && 'Buy Chimp'}
                {active && step === ModalChimpStatus.READY && 'Buy Chimp'}
                {active && step === ModalChimpStatus.CONFIRMING && 'Confirming...'}
                {active && step === ModalChimpStatus.FAILED && 'Retry'}
              </ProgressButton>
            </div>
            {
              step === ModalChimpStatus.CONFIRMING && 
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Please confirm the transaction in your wallet.  
                </span>
              </div>
            }
          </div>
        </div>
      }
      {
        step === ModalChimpStatus.SUCCEESS && 
        <ChimpSuccessModal 
          closeModal={closeModal}
          title="Congratulations!"
          subTitle={`You bought Chimp #${tokenId}`} 
          trxnId={trxnId}
          type={ChimpOperateTypes.BUY}
        />
      }
    </Modal>
  )
}

export default DisplayModal(BuyChimpModal, ModalTypes.BUY_CHIMP);