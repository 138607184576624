import React, { Suspense, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useModal } from '../../providers/ModalProvider';
import { shortenAddress } from '../../utils/address';
import { ModalTypes } from '../../types/enums';
import WalletCoin from '../../assets/images/wallet-coin.png';
import WalletIcon from '../../assets/images/wallet-icon.png';
import styles from './TabView.module.scss';
import classNames from 'classnames';
import { useUser } from '../../providers/UserProvider';
import ProgressButton from '../../components/button/ProgressButton';

const TABS = [
  {
    label: 'My Chimps',
    query: '',
  },
  {
    label: 'Scroll NFT',
    query: 'scroll-nft',
  },
  {
    label: 'Offers Made',
    query: 'offers-made',
  }
];

const OwnChimpsLazyComponent = React.lazy(() => import('./OwnChimps'));
const OwnLoresLazyComponent = React.lazy(() => import('./OwnLores'));
const OfferMadeLazyComponent = React.lazy(() => import('./OfferMade'));

const TabView: React.FC<{}> = () => {
  const { active, account } = useWeb3React();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { openModal } = useModal();

  const { state: { avatar } } = useUser();

  const activeTab = useMemo(() => {
    const view = searchParams.get('view');

    if (view) {
      return view;
    }
    return '';
  }, [searchParams])

  const switchTab = useCallback((e: React.TouchEvent | React.MouseEvent) => {
    const tabId = e.currentTarget.getAttribute('id') || '';
    const to = tabId ? `/my-chimps?view=${tabId}` : '/my-chimps';
    navigate(to, { replace: true });
  }, [navigate]);

  return (
    <div>
      <div className={styles.bar_contrainer}>
        <div className={styles.bar}>
          {!active && <img className={styles.wallet_icon} src={WalletCoin} alt="" />}
          {active && <div className={styles.bar_icon_wrap}><img className={styles.bar_icon} src={avatar ? avatar : WalletIcon} alt="" /></div>}
        </div>
      </div>
      <div className={styles.container}>
        {
          !active &&
          <div className={styles.connect_wallet}>
            <span>You have to connect to wallet before viewing your chimps</span>
            <ProgressButton classname={styles.connect_button} onClick={() => openModal(ModalTypes.CONNECT_WALLET)}>
              Connect Wallet
            </ProgressButton>
          </div>
        }
        {
          active &&
          <>
            <div className={styles.address}>{shortenAddress(account || '', 6)}</div>
            <div className={styles.tabs}>
              {
                TABS.map(({ label, query }, index) => (
                  <div
                    key={label}
                    id={query} 
                    className={classNames(styles.tab, { [styles.tab_active]: activeTab === query })} 
                    onClick={switchTab}
                  >
                    {label}
                  </div>
                ))
              }
            </div>

            <Suspense fallback={<div>Loading...</div>}>
              {activeTab === TABS[0].query && <OwnChimpsLazyComponent />}
              {activeTab === TABS[1].query && <OwnLoresLazyComponent />}
              {activeTab === TABS[2].query && <OfferMadeLazyComponent />}
            </Suspense>
          </>
        }
      </div >
    </div >
  )
}

export default TabView;