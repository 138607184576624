import { META_ARW } from '../configs';


export function chimpImage(id: number, hd = false) {
  return hd ? 
    `${META_ARW}KOnXn6F7zRoLrJYu-SIrY9wJZATcQG2fYnXwG5xMBQA/${id}.png` : 
    `${META_ARW}Vr2TIrbf43ki6RDLQJXCQYAEpNy5rRHZ2rDljo_gLBA/${id}.png`;
}

export function honoraryChimpImage(arUrl: string) {
  return arUrl.replace('ar://', META_ARW);
}