import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Navigation.module.scss';
import { HrefLink } from '../../assets/svgs';

interface Props {
  className?: string;
  to?: string;
  label?: string;
  isHide?: boolean;
  isExpandMenu?: boolean;
  isExternal?: boolean;
  onClick?: (e: MouseEvent) => void;
  Icon?: FC;
}

const MenuItem: React.FC<Props> = ({ className = '', to = '', label = '', isHide = false, isExpandMenu = false, isExternal = false, onClick, Icon }) => {
  return (
    <>
      {!isHide &&
        <>
        {
	        isExternal ?
          <a
            className={classNames(styles.nav, {
              [styles.expand_menu_item]: isExpandMenu,
              [styles.expand_menu_item_external]: isExternal
            }, className)}
            href={to}
            rel="noreferrer noopener"
            target="_blank"
          >
          	{label}
          	{Icon ? <Icon /> : <HrefLink />}
          </a> :
          <Link
            className={classNames(styles.nav, {
              [styles.expand_menu_item]: isExpandMenu
            }, className)}
            to={to}
            onClick={onClick}
          >
            {label}
          </Link>
	      }
        </>
      }
    </>
  );
}

export default MenuItem;