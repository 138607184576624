import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import Modal, { DisplayModal } from "./Modal";
import ConnectWallet from "../wallet/ConnectWallet";
import { ModalTypes } from "../../types/enums";
import { useNFTMint } from "../../providers/NFTProvider";
import { useOfferContractMethods } from "../../hooks/useOfferContractMethods";


const ConnectWalletModal: React.FC<{}> = () => {
  const { state: { balance } } = useNFTMint();
  const { getWCROBalance } = useOfferContractMethods();
  const { account } = useWeb3React();
  const [WCROBalance, setWCROBalance] = useState('');

  useEffect(() => {
    if (account) {
      getWCROBalance().then((balance) => {
        setWCROBalance(balance);
      });
    }
  }, [account, getWCROBalance]);

  return (
    <Modal>
      <ConnectWallet CROBalance={balance.toString()} WCROBalance={WCROBalance} />
    </Modal>
  )
}

export default DisplayModal(ConnectWalletModal, ModalTypes.CONNECT_WALLET);