import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import LinkButton from '../../components/button/LinkButton';
import { FAQItem } from '../../components/chimpay/ChimpayFAQ';
import Steve1 from '../../assets/images/steve_work_1.png';
import Steve2 from '../../assets/images/steve_work_2.png';
import Steve4 from '../../assets/images/steve_work_4.png';
import SteveRaffle from '../../assets/images/steve_raffle.png';
import SteveDirector from '../../assets/images/steve_director.png';
import GalleryPlaceholder from '../../assets/images/gallery-placeholder-1x.png';
import useChimpayHook from '../../hooks/useChimpayHook';
import useLoreHook from '../../hooks/useLoreHook';
import { useLore } from '../../providers/LoreProvider';
import CHIMP_FAQS from '../../data/chimpay-faq.json';
import { formatUTCSeasonDate } from '../../utils/time';
import { shortenAddress } from '../../utils/address';
import { roundNumberWithFix } from '../../utils/number';
import { ChimpionState } from '../../types/interfaces';
import { SEASON_ON_LIVE } from '../../configs';
import { SCROLL_CHIMPION_AMOUNT } from '../../constants/lore';
import { isMobile } from '../../utils/userAgent';
import styles from './ChimpayMain.module.scss';

const MainAnimation = () => {
  return(
    <div className={styles.animation}>
      <video className={styles.animation_dt} muted autoPlay playsInline>
        <source src="lore-lander/animation_3x.webm" type="video/webm" />
      </video>
      <video className={styles.animation_mb} muted autoPlay playsInline>
        <source src="lore-lander/animation_1x.mp4" type="video/mp4" />
      </video>
    </div>
  )
}

const WinnerAnnouncement = () => {
  const { state: { activeChapterId, myContributions, rank, nftShared, loreNFTBalances } } = useLore();

  const { getContributionRanking } = useLoreHook();

  useEffect(() => {
    getContributionRanking();
  }, [getContributionRanking])

  const isWinner = useMemo(() => {
    const currentToken = activeChapterId - 1;
    if (currentToken < 0 || !loreNFTBalances[currentToken]) {
      return false;
    }
    return (
      rank.ranking !== 0 
      && rank.ranking <= SCROLL_CHIMPION_AMOUNT 
      && loreNFTBalances[currentToken] > 0
    );
  }, [activeChapterId, loreNFTBalances, rank]);

  const myContributedAmount = useMemo(() => {
    return myContributions.reduce((sum, { amount }) => {
      sum += amount;
      return sum;
    }, 0)
  }, [myContributions]);

  if (!nftShared) return <></>;

  return (
    <div className={styles.winner}>
      <div className={styles.winner_wrap}>
        <div className={styles.winner_left}>
          <div className={styles.winner_chapter}>
            Chapter {activeChapterId}
          </div>
          <div className={styles.winner_content}>
            <img className={styles.winner_img} src={GalleryPlaceholder} alt="" />
            {
              isWinner && 
              <div>
                <p className={styles.winner_msg}>
                  Chimpay x Scroll Season {SEASON_ON_LIVE} has officially ended.
                  <br />
                  {' '}You rank <strong>#{rank.ranking}</strong> by contributing <strong>{myContributedAmount}</strong> Bananas.
                  <br />
                  {' '}Good job, you have earned a shared ownership of the Scroll NFT Chapter {activeChapterId} 🌟
                </p>
              </div>
            }
            {
              !isWinner && 
              <div>
                <p className={styles.winner_msg}>
                  Chimpay x Scroll Season {SEASON_ON_LIVE} has officially ended.
                  {
                    myContributedAmount > 0 ? 
                    <>
                      <br />
                      {' '}You rank <strong>#{rank.ranking}</strong> by contributing <strong>{myContributedAmount}</strong> Bananas.
                      <br />
                      Thank you for participating, see you next season!
                    </> : 
                    <>
                      <br />
                      {' '}You have not contributed any Bananas. We hope to see you next season!
                    </>
                  }
                </p>
              </div>
            }
          </div>
        </div>
        {
          isWinner && 
          <div className={styles.winner_right}>
            <LinkButton
              isGhost={false}
              className={styles.winner_view}
              to="/my-chimps?view=scroll-nft"
            >
              View NFT 🎉
            </LinkButton>
          </div>
        }
      </div>
    </div>
  )
}

const Features = () => {
  const { getSeasonPeriod, isWorkingPeriod } = useChimpayHook();
  const { stake, redemption } = useMemo(() => getSeasonPeriod(), [getSeasonPeriod]);

  return (
    <div className={styles.features}>
      <div className={styles.feature_item}>
        <div className={styles.feature_item_inner}>
          <div className={styles.feature_item_title}>
            Stake & Earn
          </div>
          <div className={styles.feature_item_time}>
            {formatUTCSeasonDate(stake.start)}  -  {formatUTCSeasonDate(stake.end)}
          </div>
          <div className={styles.feature_item_desc}>
            Send your Chimps to work and reap Bananas, 
            the exclusive currency fuelling your adventures in Cronos Chimp Club. 
          </div>
          <LinkButton
            isGhost={false}
            className={styles.redirect}
            to="/chimpay/dashboard"
          >
            {isWorkingPeriod() ? 'Stake Now' : 'Recall now'}
          </LinkButton>
        </div>
      </div>

      <div className={styles.feature_item}>
        <div className={styles.feature_item_inner}>
          <div className={styles.feature_item_title}>
            Lore’s Scroll
            <span className={styles.feature_item_new}>
              NEW
            </span>
          </div>
          <div className={styles.feature_item_time}>
            {formatUTCSeasonDate(redemption.start)}  -  {formatUTCSeasonDate(redemption.end)}
          </div>
          <div className={styles.feature_item_desc}>
            Peel back the layers, one banana at a time.
          </div>
          <LinkButton
            isGhost={false}
            className={styles.redirect}
            to="/chimpay/scroll"
          >
            View Scrolls
          </LinkButton>
        </div>
      </div>

      <div className={styles.feature_item}>
        <div className={styles.feature_item_inner}>
          <div className={styles.feature_item_title}>
            Grand NFT ownership
            <span className={styles.feature_item_new}>
              NEW
            </span>
          </div>
          <div className={styles.feature_item_time}>
            Announce on {formatUTCSeasonDate(redemption.end)}
          </div>
          <div className={styles.feature_item_desc}>
            Ascend your Chimp status with Scroll NFT and join the esteemed Council of Elders, 
            shaping the future of your adventurous realm. 
          </div>
          <LinkButton
            isGhost={false}
            className={styles.redirect}
            to="/chimpay/faqs"
          >
            Learn More
          </LinkButton>
        </div>
      </div>

      <div className={styles.feature_imgs}>
        <img src={SteveRaffle} alt="" className={styles.feature_img_raffle} />
        <img src={SteveDirector} alt="" className={styles.feature_img_director} />
      </div>
    </div>
  )
}

const WorkFlow = () => {
  return (
    <div className={styles.work}>
      <div className={styles.work_item}>
        <div>
          <div className={styles.work_item_title}>
            Chimpay Stake & Earn
          </div>
          <div className={styles.work_item_desc}>
            Send your Chimp NFT to work during the season and reap a bountiful harvest of Bananas. 
          </div>
        </div>
        <img src={Steve1} className={styles.work_item_img} alt="" />
      </div>
      <div className={styles.work_item}>
        <img src={Steve2} className={styles.work_item_img} alt="" />
        <div>
          <div className={styles.work_item_title}>
            Unlock Banana Scroll
          </div>
          <div className={styles.work_item_desc}>
            Contribute your Bananas to reveal the Lore chapters. 
            These chapters will remain exclusive to contributors only.
          </div>
        </div>
      </div>
      <div className={styles.work_item}>
        <div>
          <div className={styles.work_item_title}>
            Together We Grow 
          </div>
          <div className={styles.work_item_desc}>
            It takes 100 unique contributors to unlock each scroll, 
            which is a testament to our unity and strength of our community.  
          </div>
        </div>
        <img src={Steve4} className={styles.work_item_img} alt="" />
      </div>
    </div>
  )
}


const HEADERS = ['Ranking', 'User', 'Chimps Owned', 'Scrolls Contributed', 'Banana Contribution'];

const Leaderboard = () => {
  const [chimpionsList, setChimpionsList] = useState<ChimpionState[]>([]);
  const [pageStart, setPageStart] = useState(0);

  const { state: { activeChapterId } } = useLore();

  const { account } = useWeb3React();
  const { getChimpionList } = useLoreHook();

  useEffect(() => {
    if (activeChapterId) {
      getChimpionList(activeChapterId).then((data: ChimpionState[]) => {
        if (data && Array.isArray(data)) {
          setChimpionsList(data)
        }
      });
    }
  }, [activeChapterId, getChimpionList])

  const ranking = useMemo(() => {
    if (!account) return '';

    const index = chimpionsList.findIndex((item) => item.account === account);

    if (index === -1) {
      return '';
    }

    return index + 1;
  }, [chimpionsList, account]);

  const displayList = useMemo(() => {
    if (!isMobile) {
      return chimpionsList;
    }

    return chimpionsList.slice(pageStart, pageStart + 10);
  }, [chimpionsList, pageStart]);

  const handlePaginate = useCallback((d: 'prev' | 'next') => {
    let start = pageStart;
    if (d === 'prev') {
      start = pageStart - 10 < 0 ? 0 : pageStart - 10;
    } else {
      start = pageStart + 10 >= chimpionsList.length ? pageStart : pageStart + 10;
    }

    setPageStart(start);
  }, [pageStart, chimpionsList.length]);

  return (
    <section className={styles.section}>
      <div className={styles.section_title}>
        Leaderboard
      </div>
      <div className={styles.section_desc}>
        Top 50 Chimpions will be rewarded with Scroll NFT ownership
      </div>

      <div className={styles.leadboard_table}>
        <div className={styles.table_hr}>
          {
            HEADERS.map((h) => (
              <span key={h}>
                {h}
              </span>
            ))
          }
        </div>
        <div className={styles.table_body}>
          {
            displayList.map(({ rank, account, chimpAmountOwned, amountContributed, scollsAmount }) => (
              <div key={account} className={styles.table_tr}>
                <span>
                  #{rank}
                </span>
                <span>
                  {shortenAddress(account, 7, 2)}
                </span>
                <span>
                  {chimpAmountOwned}
                </span>
                <span>
                  {scollsAmount}
                </span>
                <span>
                  {roundNumberWithFix(amountContributed, 2)}
                </span>
              </div>
            ))
          }
        </div>
      </div>
      {
        isMobile && chimpionsList.length >= 10 && 
        <div className={styles.pagination}>
          <span className={pageStart - 10 < 0 ? styles.pagination_disabled : ''} onClick={() => handlePaginate('prev')}>
            {'< Previous 10'}
          </span>
          <span className={pageStart + 10 >= chimpionsList.length ? styles.pagination_disabled : ''} onClick={() => handlePaginate('next')}>
            {'Next 10 >'}
          </span>
        </div>
      }
      {
        ranking && 
        <div className={styles.ranking}>
          My Ranking: <strong>#{ranking}</strong>
        </div>
      }
      <LinkButton
        isGhost={false}
        className={styles.redirect}
        to="/chimpay/dashboard"
      >
        Go to Dashboard
      </LinkButton>
    </section>
  )
}

const PartFaqs = () => {
  return (
    <div className={styles.faqs}>
      <div className={styles.faq_col}>
        {
          [0, 1, 3].map((id) => (
            <FAQItem {...CHIMP_FAQS.scrolls[id]} key={CHIMP_FAQS.scrolls[id].q} className={styles.faqWrap} />
          ))
        }
      </div>
      <div className={styles.faq_col}>
        {
          [4, 6, 7, 10].map((id) => (
            <FAQItem {...CHIMP_FAQS.scrolls[id]} key={CHIMP_FAQS.scrolls[id].q} className={styles.faqWrap} />
          ))
        }
      </div>
    </div>
  )
}

const ChimpayMain = () => {
  const { isRedemptionPeriod } = useChimpayHook();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <MainAnimation />
        {!isRedemptionPeriod() && <WinnerAnnouncement />}
      </div>
      <div className={styles.body}>
        <section className={styles.section}>
          <div className={styles.main_title}>
            Chimpay x Scroll
          </div>
          <div className={styles.main_intro}>
            Chimpay is back! This new season introduces the Lore,
            <br />
            where every Banana earned unravels a chapter of the ancient tales.
          </div>
          <LinkButton
            isGhost={false}
            className={styles.redirect}
            to="/chimpay/scroll"
          >
            View Scrolls
          </LinkButton>
        </section>

        <section className={styles.section_full}>
          <div className={styles.section_title}>
            Rewarding Our Community
          </div>
          <div className={styles.section_desc}>
            Venture through the jungles of Chimpay, 
            <br />
            uncovering treasures and immersing in communal triumph.  
          </div>
          <Features />
        </section>

        <section className={styles.section}>
          <div className={styles.section_title}>
            How Does It Work?
          </div>
          <div className={styles.section_desc}>
            Learn How Chimpay and Banana Lore go hand in hand in 3 simple steps.
          </div>
          <WorkFlow />
          <LinkButton
            isGhost={false}
            className={styles.redirect}
            to="/chimpay/faqs"
          >
            Learn More
          </LinkButton>
        </section>

        {isRedemptionPeriod() && <Leaderboard />}

        <section className={styles.section}>
          <div className={styles.section_title}>
            Frequently Asked Questions
          </div>
          <div className={styles.section_desc}>
            Can't find what you are looking for?
            <br />
            Check out the full <Link to="/chimpay/faqs">Chimpay x Scroll FAQ</Link>.
          </div>
          <PartFaqs />
        </section>
      </div>
    </div>
  )
}

export default ChimpayMain;
