export const HIDE_NAV = process.env.REACT_APP_HIDE_NAV === 'true';
export const HIDE_MARKETPLACE = process.env.REACT_APP_HIDE_MARKETPLACE === 'true';
export const HIDE_MINT = process.env.REACT_APP_HIDE_MINT === 'true';
export const HIDE_MERCHANDISE = process.env.REACT_APP_HIDE_MERCHANDISE === 'true';
export const CRONOS_EXPLORER = process.env.REACT_APP_EXPLORER; 
export const DEFAULT_RPC = process.env.REACT_APP_DEFAULT_RPC!;
export const CHIMP_CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS!;
export const HONORARY_CHIMP_CONTRACT_ADDRESS = process.env.REACT_APP_HONORARY_CONTRACT_ADDRESS!;
export const BATCH_CONTRACT_ADDRESS = process.env.REACT_APP_BATCH_ADDRESS!;
export const OFFER_CONTRACT_ADDRESS =  process.env.REACT_APP_OFFER_CONTRACT_ADDRESS!;
export const HONORARY_OFFER_CONTRACT_ADDRESS = process.env.REACT_APP_HONORARY_OFFER_CONTRACT_ADDRESS!;
export const WCRO_CONTRACT_ADDRESS = process.env.REACT_APP_WCRO_CONTRACT_ADDRESS!;
export const MERCHANDISE_CONTRACT_ADDRESS = process.env.REACT_APP_MERCHANDISE_CONTRACT_ADDRESS!;
export const MERCHANDISE_ERC20_CONTRACT_ADDRESS = process.env.REACT_APP_MERCHANDISE_ERC20_CONTRACT_ADDRESS!;
export const STAKE_CHIMP_CONTRACT_ADDRESS = process.env.REACT_APP_STAKE_CHIMP_CONTRACT_ADDRESS!;
export const STAKE_HONORARY_CHIMP_CONTRACT_ADDRESS = process.env.REACT_APP_STAKE_HONORARY_CHIMP_CONTRACT_ADDRESS!;
export const BATCH_STAKE_DATA_CONTRACT_ADDRESS = process.env.REACT_APP_BATCH_STAKE_DATA_CONTRACT!;
export const STAKE_CHIMP_CONTRACT_ADDRESS_UPGRADED = process.env.REACT_APP_STAKE_CHIMP_CONTRACT_UPGRADED!;
export const CHIMP_LORE_CONTRACT_ADDRESS = process.env.REACT_APP_CHIMP_LORE_CONTRACT!;
export const DISCORD_API = process.env.REACT_APP_DISCORD_API!;
export const RPC_ID = +process.env.REACT_APP_RPC_ID!;
export const RPC_CURRENCY = process.env.REACT_APP_RPC_CURRENCY!;
export const RPC_NAME = process.env.REACT_APP_RPC_NAME!;
export const OAUTH_REDIRECT = process.env.REACT_APP_OAUTH_REDIRECT!;
export const GA_TAG = process.env.REACT_APP_GA_TAG!;

export const TOTAL_SUPPLY = 10000;
export const MAX_PER_MINT = 10;
export const ROYALTY_PERCENTAGE = 0.05

export const LAUNCH_TIME = '9 Nov, 7am UTC'
export const DISCORD_LINK = 'https://discord.gg/dzUbQdApPe';
export const TWITTER_LINK = 'https://twitter.com/CronosChimpClub';
export const INSTAGRAM_LINK = 'https://www.instagram.com/cronoschimp.club';
export const TIKTOK_LINK = 'https://t.co/g5jogGw4r6';
export const IMAGE_URL = '/chimps';
export const HD_IMAGE_URL = ''
export const NFT_CALENDAR_LINK = 'https://nftcalendar.io/event/cronos-chimps-club';
export const META_ARW = 'https://arweave.net/'
export const SWAP_WCRO_LINK = 'https://vvs.finance/swap?outputCurrency=0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23';
export const RARITY_LINK = 'https://raritysniper.com/cronos-chimp-club';

export const BANANA_SCALE_MULTIPLIER = +process.env.REACT_APP_BANANA_SCALE_MULTIPLIER!;

export const SEASON_ON_LIVE = 3;
export const SEASON_WORKING_ENDTIME = +process.env.REACT_APP_SEASON_WORKING_ENDTIME!;
export const SEASON_REDEMPTION_ENDTIME = +process.env.REACT_APP_SEASON_REDEMPTION_ENDTIME!;