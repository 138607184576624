import { ChangeEvent, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import ClickOutSide from "../click-outside/ClickOutside";
import { Dropdown as DropdownIcon } from "../../assets/svgs";
import styles from './Dropdown.module.scss';

interface Props {
  select: string;
  options: {
    text: string;
    value: string;
  }[];
  onSelect: (value: string) => void;
  className?: string;
  searchable?: boolean;
}

const Dropdown = ({ select, options, onSelect, className, searchable }: Props) => {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');

  const handleOpen = useCallback(() => {
    setSearch('');
    setShow(true);
  }, []);

  const handleSelect = useCallback((value: string) => {
    onSelect(value)
    setShow(false);
  }, [onSelect]);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.target.value);
  }, [setSearch]);

  const selectText = useMemo(() => {
    if (searchable && show) {
      return search;
    }

    if (Array.isArray(options) && options.length > 0) {
      return options.filter(({ value }) => value === select)[0]?.text || '';
    }

    return '';
  }, [show, searchable, search, select, options]);

  return (
    <ClickOutSide className={className} onClickOutside={() => setShow(false)}>
      <div className={classNames(styles.tab, { [styles.tab_opened]: show })}>
        <div className={styles.header} onClick={handleOpen}>
          <input
            type="text"
            value={selectText}
            onChange={handleSearch}
            placeholder="Search"
            disabled={!show || !searchable}
            className={styles.input}
          />
          <DropdownIcon className={styles.drop_icon} style={{ transform: `rotate(${show ? 180 : 0}deg)` }} />
        </div>
        {
          show && 
          <ul className={styles.options}>
            {
              options.filter((item) => {
                const lowerCaseText = item.text.toLowerCase();
                const lowerCaseValue = item.value.toLowerCase();

                return item.value !== select &&
                  (lowerCaseText.includes(search.toLowerCase()) 
                    || lowerCaseValue.includes(search.toLowerCase()));
              }).map(({ text, value }) => (
                <li 
                  key={text}
                  className={styles.li} 
                  onClick={() => handleSelect(value)}
                >
                  {text}
                </li>
              ))
            }
          </ul>
        }
      </div>
    </ClickOutSide>
  )
}

export default Dropdown;