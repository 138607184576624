import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser';
import Modal, { DisplayModal } from "../modal/Modal";
import Button from "../button/ProgressButton";
import Warning from "../messages/Warning";
import BananaValue from "./BananaValue";
import { useModal } from "../../providers/ModalProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import { Cross } from "../../assets/svgs";
import BannerGifts from '../../assets/images/banners_gifts.png';
import { ModalTypes, ModalChimpStatus } from "../../types/enums";
import { WARNING_NOT_ENOUGH_BANANAS } from "../../constants/errors";
import { REDEMPTION_TIER_PRICE } from "../../constants/chimpay";
import { getVoucherBananaPrices, calculateTotalCostBananas } from "../../utils/shop-price";
import { getProductDescription, getLogoType } from "../../utils/gift";
import styles from './ClaimGift.module.scss';

const ClaimGiftModal = () => {
  const navigate = useNavigate();
  const { state: { claimingGift, giftOrderForm, availableBananas } } = useChimpay();
  const { step, closeModal } = useModal();

  const { productName, country, recipientCurrencyCode } = claimingGift;
  const { claimingTier } = giftOrderForm;

  const unitPrice = useMemo(() => REDEMPTION_TIER_PRICE[claimingTier], [claimingTier]);

  const priceInBanana = useMemo(() => 
    getVoucherBananaPrices(claimingTier, claimingGift), [claimingTier, claimingGift]);

  const totalCostInBanana = useMemo(() => 
    calculateTotalCostBananas(claimingTier, claimingGift), [claimingTier, claimingGift]);

  const noEnoughBananas = useMemo(() => 
    +availableBananas < +totalCostInBanana, [availableBananas, totalCostInBanana]);

  const handleContinueNavigate = useCallback(() => {
    closeModal();
    navigate('/shop/redeem-gift');
  }, [navigate, closeModal]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING}>
      <div className={styles.container}>
        <Cross className={styles.close} onClick={closeModal} />
        {
          step === ModalChimpStatus.INITIAL &&
          <div className={styles.preClaim}>
            <div className={styles.preHeader}>
              <div className={styles.logoImg}>
                <img src={getLogoType(productName)} alt="" />
              </div>
              <div>
                <div className={styles.giftName}>
                  {recipientCurrencyCode} {unitPrice} {productName}
                </div>
                <span className={styles.giftCountry}>
                  {country.name}
                </span>
                <BananaValue
                  value={priceInBanana} 
                  style={{
                    marginTop: '0.5em'
                  }}
                />
              </div>
            </div>
            <div className={styles.preBody}>
              <p className={styles.parag}>
                <span className={styles.specLabel}>
                  Product Description:
                </span>
                {parse(getProductDescription(productName))}
              </p>

              <p className={styles.parag}>
                <span className={styles.specLabel}>
                  Disclaimer:
                </span>
                As gift cards are region specific, please select your current location and check before you proceed. 
                No change can be made after redemption. 
                The merchants represented are not sponsors of the reward or otherwise affiliated with Cronos Chimps Club. 
                The logos and trademarks are owned by each company and/or its affiliates. 
                Please visit the company’s website for more T&C.
              </p>
              <Warning className={styles.blockTip} show={noEnoughBananas}>
                {WARNING_NOT_ENOUGH_BANANAS}
              </Warning>
              <Button
                onClick={handleContinueNavigate}
                disabled={noEnoughBananas}
              >
                Continue
              </Button>
            </div>
          </div>
        }
        {
          step === ModalChimpStatus.CONFIRMING && 
          <div className={styles.confirmingWrap}>
            <img className={styles.confirmingImg} src={BannerGifts} alt="" />
            <div className={styles.confirmingTitle}>
              Gifts coming your way...
            </div>
            <div className={styles.confirmingSubTitle}>
              You will receive a confirmation email soon.
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default DisplayModal(ClaimGiftModal, ModalTypes.CLAIM_GIFT);
