import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GA_TAG } from '../../configs';

const GATrackingPageView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', GA_TAG, {
        page_path: pathname,
      });
    }
  }, [pathname]);

  return null;
}

export default GATrackingPageView;