
export const WALLET_ERROR_NO_EXTENSION = "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
export const WALLET_ERROR_UNSUPPORTED_NETWORK = "Please switch to Cronos Chain";
export const WALLET_ERROR_UNAUTHORIZED = "Please authorize this website to access your Ethereum account.";
export const WALLET_ERROR_UNKNOWN = "Error connecting. Try refreshing the page.";
export const WALLET_WRONG_NETWORK = 'Please connect to the appropriate network'

export const MINT_ERROR_GENERIC = 'Sorry, looks like something failed!';
export const MINT_ERROR_REJECTED = 'User cancelled the transaction!';

export const CHIMP_ERROR_TRXN_GENERIC = 'Please confirm the transaction in your wallet';
export const CHIMP_ERROR_REJECTED = 'User cancelled the transaction!';
export const CHIMP_ERROR_GENERIC = 'Something went wrong, please try again.';
export const CHIMP_ERROR_DENIED_SIGN = 'User denied message signature.';

export const CHECKOUT_ERROR_GENERIC = 'Something went wrong, please try again.';

export const WARNING_SALING_NOT_START = 'The Saling has not started yet. Please wait patiently.';
export const WARNING_NOT_ENOUGH_BALANCE = 'Not enough CRO in your wallet. Please add balance.';
export const WARNING_ALREADY_OWNED = 'Sorry, you can not buy the chimp already owned.';
export const WARNING_WALLET_UNCONNECTED = 'Please use your wallet to buy a chimp.';
export const WARNING_NOT_ENOUGH_WCRO_BALANCE = 'Not enough WCRO in your wallet.';
export const WARNING_CAN_NOT_MAKE_OFFER_OWNED_CHIMP = 'Sorry, you can not make offer the chimp already owned.';
export const WARNING_SIGN_IN_TO_FAVORITE = 'Almost there! To favorite a Chimp, we will ask you to digitally sign in with your wallet to link with Cronos Chimp Club. There are no fees to sign in.';
export const WARNING_SIGN_IN_TO_CHECKOUT = 'Almost there! To complete the checkout process, we will ask you to digitally sign in with your wallet to link with Cronos Chimp Club. There are no fees to sign in.';
export const WARNING_SIGN_IN_GENERIC = 'Almost there! As a final step, we will ask you to digitally sign in with your wallet to link with Cronos Chimp Club. There are no fees to sign in.';
export const WARNING_SIGN_IN_TO_MERCH = 'Almost there! To purchase merch, we will ask you to digitally sign in with your wallet to link with Cronos Chimp Club. There are no fees to sign in.';
export const WARNING_CANNOT_GET_SHIPPING_PRICE = 'Sorry, we cannot calculate shipping cost from your country.';
export const WARNING_NOT_ENOUGH_BANANAS = 'You don\'t have enough Bananas to redeem.';
export const WARNING_EXCEED_MAX_STAKE = 'Number of Chimps at work cannot exceed 20.';
