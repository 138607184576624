import { useCallback, useRef, useState } from 'react';
import ImageModal from '../modal/ImageModal';
import ChristmasBase from '../../assets/images/christmas-base.png';
import { fetchImage } from '../../utils/fetch';
import Button from '../button/ProgressButton';
import styles from './HolidayCard.module.scss';

const HolidayCard = ({ hdImage, chimpId }: { hdImage: string, chimpId: string }) => {
  const [opened, setOpened] = useState(false)
  const [imageUrl, setImageUrl] = useState('');
  const [error, setError] = useState('');

  const canvasRef = useRef<any>();

  const openImageModal = useCallback(async () => {
    setOpened(true);
    const ctx = canvasRef.current.getContext('2d');

    try {
      if (hdImage && chimpId) {
        // Start drawing chimp image
        const chimpImage = new Image(4000, 4000);
        const chimpImageUrl: any = await fetchImage(hdImage)
        chimpImage.src = chimpImageUrl;

        chimpImage.onload = function() {
          ctx.drawImage(chimpImage, 0, 0, 4000, 4000);

          // Start drawing base image
          const baseImage = new Image(4000, 4000);
          baseImage.src = ChristmasBase;
          baseImage.onload = function() {
            ctx.drawImage(baseImage, 0, 0, 4000, 4000);
    
            // Start drawing chimp id
            const digits = chimpId.split('');
            let count = digits.length;
            digits.forEach((d: string, index: number) => {
              const digitImage = new Image(60, 60);
              digitImage.src = `number/${d}.png`;
      
              digitImage.onload = function() {
                ctx.drawImage(digitImage, index * 140 + 3230, 3650);
                count -= 1;

                if (count === 0) {
                  const dataUrl = canvasRef.current.toDataURL('image/png', 1.0);
                  setImageUrl(dataUrl);
                }
              }
            })
          }
        }
      }
    } catch (err) {
      setError('Failed to chimp chistmas card');
    }
  }, [hdImage, chimpId])

  return (
    <div className={styles.container}>
      <Button classname={styles.button} isGhost onClick={openImageModal}>
        Holiday Card
      </Button>
      <canvas ref={canvasRef} width={4000} height={4000} style={{ display: 'none' }}></canvas>
      <ImageModal error={error} show={opened} title={`My Chimp #${chimpId}`} imageUrl={imageUrl} onClose={() => setOpened(false)} />
    </div>
  )
}

export default HolidayCard;