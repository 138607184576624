export function formatNumberWithComma(num: number | string): string {
  if (Number.isNaN(+num) || +num === 0) return '0';

  let [s1, s2] = num.toString().split('.');

  s1 = s1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return !s2 ? s1 : `${s1}.${s2}`;
}

export function roundNumberWithFix(num: number | string, fix: number = 4): number {
  if (num === undefined || num === null) {
    num = 0;
  }

  return +Number(num).toFixed(fix);
}