import { MerchandiseMeta, OrderCartMeta } from "../types/interfaces";

export function getProductImage(contractId: string) {
  const images: any = {
    bundle: ['/merch/bundle.jpg'],
    plushie: ['/merch/plushie.jpg']
  };
  return images[contractId] || [];
}

export function generateOrderItem(
  item: MerchandiseMeta,
  extraProps: {
    orderId: number;
    size: string;
    quantity: number;
    maxLimit: number;
  }
) {
  const orderItem: OrderCartMeta = {
    id: item.id,
    contractId: item.contractId,
    title: item.title,
    imageUrl: item.imageUrl,
    price: item.price,
    currency: item.currency,
    ...extraProps,
  };

  return orderItem;
}

export function convertOrderStatus(status: string) {
  const statusList: any = {
    PAYMENT_PENDING: "Payment pending",
    PAYMENT_COMPLETED: "Pending confirm",
    PROCESSING: "Order confirmed",
    SHIPPED: "Shipped",
    PAYMENT_FAILED: "Failed",
    CANCELED: "Canceled",
    REFUNDED: "Refunded",
  };
  return statusList[status];
}

const INCH_TO_CM = 2.54;

export function getMeasurementByUnit(
  value: string | number,
  unit: 'inches' | 'cm',
) {
  if (!value) return '0';

  if (unit === 'inches') return value.toString();

  const valueInCM = Number(+value * INCH_TO_CM).toFixed(1);

  const floor = Math.floor(+valueInCM);
  const ceil = Math.ceil(+valueInCM);

  if (+valueInCM >= floor && +valueInCM < floor + 0.4) {
    return floor.toString();
  } else if (+valueInCM <= ceil && +valueInCM > ceil - 0.4) {
    return ceil.toString();
  }

  return valueInCM;
}

export function getMerchCountInCart(
  orders: OrderCartMeta[],
  type: string,
) {
  if (orders.length === 0) return 0;

  const count = orders.reduce((sum, curr) => {
    if (curr.contractId === type) {
      return sum + curr.quantity;
    }
    return sum;
  }, 0)

  return count;
}
