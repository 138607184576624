import {DISCORD_API} from "../configs";
import {GraphQLClient} from "graphql-request";
import {ChimpTransaction} from "../types/interfaces";

const endpoint = `${DISCORD_API}/graphql`;
const graphQLClient = new GraphQLClient(endpoint);

export async function fetchChimpTransactions(tokenId: string | number, isHonorary: boolean): Promise<ChimpTransaction[]> {
  try {
    const data = await graphQLClient.request(
        `
        query transactions($tokenId: TokenId!, $isHonorary: Boolean!) {
          transactions(tokenId: $tokenId, isHonorary: $isHonorary) {
            hash
            type
            amount
            timestamp
          }
        }
    `,
        {tokenId, isHonorary}
    );

    return data.transactions;
  } catch (e) {
    throw new Error('Failed to get chimp transactions');
  }
}
