import { FC } from "react";
import classNames from "classnames";
import styles from './MarketIndex.module.scss';

interface Props {
  label: string,
  value: string,
  className?: string,
}

const IndexCard: FC<Props> = ({ label, value, className }) => {
  return (
    <div className={classNames(styles.index, className)}>
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </div>
  )
}

export default IndexCard;