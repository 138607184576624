export function getChimpDisplayTitle(id: string, name?: string, isHonorary?: boolean) {
  let displayName = `Chimp #${id}`;

  if (isHonorary) {
    if (+id <= 6) {
      // This is because the metadata does not include # for 0-6
      displayName = `Honorary Chimp #${id}`;
    } else {
      if (name)
        displayName = name;
      displayName = `Honorary Chimp #${id}`;
    }
  }

  return displayName;
}