import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Footer from '../../components/footer/Footer';
import ImageLoop from '../../components/image-loop/ImageLoop';
import FluitDrop from '../../assets/images/fluit-drop.png';
import RandomChimp from '../../assets/images/random-chimp.gif';
import { SOCIALS } from '../../constants/socials';
import styles from './About.module.scss';

const About = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.club} src="club.png" alt="" />
      </div>
      <div className={styles.divider}>
        <img className={styles.fluit_drop} src={FluitDrop} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.section}>
          <span className={styles.title}>FROM THE CHIMPIONS</span>
          <p className={styles.desc}>
            We are a club of 10,000 uniquely generated Chimps on the Cronos Chain. 
            <br className={styles.hide_for_mobile} />{' '}
            Created from over 140 possible traits, each Chimp you adopt can be truly unique. 
          </p>
          <p className={styles.desc}>
            Chimp ownership grants you exclusive access to the Cronos Chimp Club. 
            <br className={styles.hide_for_mobile} />{' '}
            As a Chimpion, you can participate in activities hosted regularly such as weekly 
            <br className={styles.hide_for_mobile} />{' '}
            quizzes, community giveaways, merch launch, metaverse and more! 
          </p>
          <p className={styles.desc}>
            You hold the <Link to="/privacy">IP rights</Link> to the Chimps you own. 
            <br className={styles.hide_for_mobile} />{' '}
            Be empowered, and show it off however you like! 
          </p>

          <div className={styles.theme_images}>
            {
              Array.from({ length: 9 }, (_, i) => i * 9 + 1).map((startId, index) => {
                return (
                  <ImageLoop
                    key={startId}
                    className={classNames(styles.theme_img, { [styles.hide_for_tablet]: index > 6, [styles.hide_for_mobile]: index > 4 })} 
                    images={[0, 1, 2, 3, 4, 5, 6, 7, 8].map((step) => `loop/${startId + step}.png`)}
                  />
                )
              })
            }
            <img className={styles.theme_img} src={RandomChimp} alt="" />
          </div>
        </div>

        <div className={styles.section}>
          <span className={styles.title}>JOIN OUR COMMUNITY</span>
          <p className={styles.desc}>
            Thanks to your support, we will continue to build on this tight-knitted community, 
            where magic and bananas happen. 
          </p>
          <p className={styles.desc}>
            You can still adopt a Chimp from our very own <Link to="/market">Marketplace</Link>, 
            and be part of the NFT enthusiasts on Cronos Chain. 
          </p>
          <p className={`${styles.desc} ${styles.desc_highlighted}`}>
            CHIMPS ROAM TOGETHER! 
          </p>

          <div className={styles.socials}>
            {
              SOCIALS.map(({ to, label, Icon }) => (
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  key={label}
                  href={to}
                  className={styles.linkButton}
                >
                  {label} <Icon />
                </a>
              ))
            }
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default About;