import { useCallback, useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import classNames from "classnames";
import ChimpCard from "./ChimpCard";
import { useChimp } from '../../providers/ChimpProvider';
import { useLoadChimp } from "../../providers/LoadChimpProvider";
import { useSearchMarket } from "../../providers/SearchMarketProvider";
import { useUser } from "../../providers/UserProvider";
import { ChimpStakeMeta } from "../../types/interfaces";
import { CardTypes, SaleFilterTabs } from "../../types/enums";
import { filterMarketChimpsForDisplay } from "../../utils/filter-data";
import styles from './Chimp.module.scss';

const SCROLL_LISTED_STEP = 8;
const SCROLL_STEP = 16;

const ChimpList = () => {
  const { loadingSalingChimps, loadingSalingHonoraryChimps, moreSaleChimps, setMoreSaleChimps } = useLoadChimp();
  const { state } = useChimp();
  const { state: { favoriteChimps } } = useUser();
  const { filterParams } = useSearchMarket();
  const { listType } = filterParams;

  const displayChimps = useMemo(() => filterMarketChimpsForDisplay(state, filterParams, favoriteChimps ), [state, filterParams, favoriteChimps]);

  const fetch = useCallback(() => {
    const step = listType === SaleFilterTabs.LISTED ? SCROLL_LISTED_STEP : SCROLL_STEP;
    setTimeout(() => {
      setMoreSaleChimps(moreSaleChimps + step);
    }, 500)
  }, [listType, moreSaleChimps, setMoreSaleChimps])

  const [infiniteRef] = useInfiniteScroll({
    loading: false,
    hasNextPage: moreSaleChimps < displayChimps.length,
    onLoadMore: fetch,
    disabled: false,
    rootMargin: '0px 0px 0px 0px',
  });

  if (
    (
      (listType === SaleFilterTabs.LISTED && loadingSalingChimps) 
      || (listType === SaleFilterTabs.HONORARY && loadingSalingHonoraryChimps)
    ) && displayChimps.length === 0
  ) {
    return (
      <div className={styles.list}>
        <p className={styles.list_bottom}>
          <b>Loading...</b>
        </p>
      </div>
    )
  }

  return (
    <div className={classNames(styles.list, { [styles.list_npt]: listType === SaleFilterTabs.HONORARY })}>
      {
        displayChimps.slice(0, moreSaleChimps).map((chimp) => {
          return (
            <ChimpCard 
              key={chimp.tokenId} 
              type={CardTypes.MARKET} 
              data={{...chimp} as ChimpStakeMeta}
            />
          )
        })
      }
      {moreSaleChimps < displayChimps.length && (
        <p className={styles.list_bottom} ref={infiniteRef}>
          <b>Loading...</b>
        </p>
      )}
      {moreSaleChimps >= displayChimps.length && (
        <p className={styles.list_bottom}>
          <b>Yay! You have reached the end of the page.</b>
        </p>
      )}
    </div>
  )
}

export default ChimpList