import { BANANA_SCALE_MULTIPLIER } from "../configs";
import { REDEMPTION_TIER_PRICE, BANANA_TIER_RATE } from "../constants/chimpay";
import { GiftRedemptionTiers } from "../types/enums";
import { GiftCardMeta } from "../types/interfaces";
import { roundNumberWithFix } from "./number";

export const BANANA_FEE_RATE = 10;

export function getDailyBananaProduction(stakedNumber: number) {
  if (stakedNumber === 0) return '0.00';
  return Number(stakedNumber * 1.1429 + 13.8571).toFixed(2);
}

export function getVoucherBananaPrices(
  tier: GiftRedemptionTiers,
  giftData: GiftCardMeta,
  isFixed: boolean = true,
) {
  if (!tier) return 0;

  const price = REDEMPTION_TIER_PRICE[tier];
  const rate = BANANA_TIER_RATE[tier] * BANANA_SCALE_MULTIPLIER;

  const {
    denominationType,
    fixedRecipientToSenderDenominationsMap,
    maxRecipientDenomination,
    maxSenderDenomination,
  } = giftData;

  let bananaPrices = 0;

  if (denominationType === 'FIXED') {
    const key = Object.keys(fixedRecipientToSenderDenominationsMap).find((p) => +p === +price) || '';
    const senderDenomination = fixedRecipientToSenderDenominationsMap[key] || 0;

    bananaPrices = senderDenomination * rate;
  } else {
    const exchangeRate = 
      maxRecipientDenomination ? (maxSenderDenomination || 0) / maxRecipientDenomination : 1;

    bananaPrices = +price * exchangeRate * rate;
  }

  if (isFixed) {
    return roundNumberWithFix(bananaPrices, 2);
  }

  return bananaPrices;
}

export function calculateTotalCostBananas(tier: GiftRedemptionTiers, giftData: GiftCardMeta,) {
  if (!tier) return 0;

  const { senderFee } = giftData;
  
  const voucherBananaPrices = getVoucherBananaPrices(tier, giftData, false);

  const fee = Number(senderFee || 0);
  const rate = BANANA_FEE_RATE * BANANA_SCALE_MULTIPLIER;

  const total = voucherBananaPrices + fee * rate;
  return roundNumberWithFix(total, 2);
}
