import { useCallback, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import { useNFTMint } from '../../providers/NFTProvider';
import { useModal } from '../../providers/ModalProvider';
import { useWalletHook } from '../../hooks/useWalletHook';
import WalletIcon from '../../assets/images/wallet-icon.png';
import { Dropdown, ErrorIcon, Online } from '../../assets/svgs';
import { HIDE_MERCHANDISE } from '../../configs';
import { ModalTypes } from '../../types/enums';
import { shortenAddress } from '../../utils/address';
import styles from './Wallet.module.scss';
import { useUser } from '../../providers/UserProvider';


export const ConnectButton: React.FC<{}> = () => {
  const { active } = useWeb3React();
  const { openModal } = useModal();
  const { isWrongNetwork } = useWalletHook();

  const handleSwitchChain = useCallback(() => {
    openModal(ModalTypes.WRONG_NETWORK);
  }, [openModal])

  const handleConnectWallet = useCallback(() => {
    openModal(ModalTypes.CONNECT_WALLET);
  }, [openModal]);

  return (
    <>
      {
        !active &&
        <>
          {isWrongNetwork ?
            <div className={classNames(styles.connect_button, styles.connect_button_wrong)} onClick={handleSwitchChain}>
              <span className={styles.icon_warning}><ErrorIcon /></span>
              <span>Wrong Network</span>
            </div> :
            <div className={styles.connect_button} onClick={handleConnectWallet}>
              Connect wallet
            </div>
          }
        </>
      }
    </>
  )
};

const Wallet: React.FC<{}> = () => {
  const { active, account } = useWeb3React();
  const { state: { avatar } } = useUser();
  const { getBalanceOf } = useNFTMint();
  const { state: { orderCart, orders } } = useUser();

  useEffect(() => {
    if (active) {
      getBalanceOf();
    }
  }, [active, getBalanceOf]);


  return (
    <>
      {active &&
        <div className={classNames(styles.container, { [styles.container_show]: active })}>
          <img className={styles.icon} src={avatar ? avatar : WalletIcon} alt="" />
          <span>{shortenAddress(account || '', 4, 4)}</span>
          <div className={styles.expand_arrow}><Dropdown /></div>
          {!HIDE_MERCHANDISE && (orderCart.length > 0 || orders.data.length > 0) && <span className={styles.cart_online_icon}><Online /></span>}
        </div>
      }
    </>
  )
}

export default Wallet;