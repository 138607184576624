import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Polygon, Info } from '../../assets/svgs';

import styles from './Input.module.scss';

export type OptionType = {
  label: string;
  value: string;
}

interface Props extends React.ComponentPropsWithoutRef<"input"> {
  id: string;
  label: string;
  className?: string;
  option?: OptionType[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  showLabel?: boolean,
};

const DataListInput: React.FC<Props> = ({ id, label, className = '', option = [], error = '', showLabel, onChange, ...rest }) => {
  const [value, setValue] = useState<string>('');

  const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const filterValues = option.filter((item: OptionType) => item.value === value);
    const filterLabel = filterValues.length > 0 ? filterValues[0].value : value;
    setValue(filterLabel)
    const filterValue = filterValues.length > 0 ? filterValues[0].value : '';
    e.target.value = filterValue;
    onChange && onChange(e);
  }, [onChange, option]);

  return (
    <div className={classNames(styles.form_group, className)}>
      <input
        {...rest}
        list={id}
        className={classNames(styles.form_control, styles.form_control_input)}
        value={value}
        onChange={onChangeInput}
        placeholder=" "
      />
      <datalist id={id}>
        {option.map((item: OptionType) => (
          <option key={item.label + item.value} value={item.value}>{showLabel ? item.label : ''}</option>
        ))}
      </datalist>
      <span className={styles.select_polygon}><Polygon /></span>
      <label className={styles.control_label}>{label}</label>
      {error && <span className={styles.error}><Info className={styles.error_icon} />{error}</span>}
    </div>
  )
}

export default DataListInput;