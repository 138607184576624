import { useEffect, useState } from 'react';
import { useNFTMint } from '../../providers/NFTProvider';
import { MintInput, MintButton } from '../../components/ntf/MintNFT';
import Success from '../../components/ntf/Success';
import Wallet from '../../components/wallet/Wallet';
import RunChimp from '../../assets/images/run-chimp.png';
import Spatter1 from '../../assets/images/spatter1.png';
import Spatter2 from '../../assets/images/spatter2.png';
import { MintStatus } from '../../types/enums';
import { formatNumberWithComma } from '../../utils/number';
import AsyncPolling from '../../utils/polling';
import styles from './Minting.module.scss';

const Minting = () => {
  const [status, setStatus] = useState(MintStatus.READY);

  const { state: { maxSupply, remainingSupply, startTimestamp }, getPoolInfos, updateRemainingSupply } = useNFTMint();

  const [showRemaining, setShowRemaining] = useState(false);  

  useEffect(() => {
    getPoolInfos()
  }, [getPoolInfos])

  useEffect(() => {
    let polling = new AsyncPolling(updateRemainingSupply, 5000);
    polling.startPolling();

    return () => polling.terminatePolling();
  }, [updateRemainingSupply])

  useEffect(() => {
    if (startTimestamp) {
      const now = +new Date();

      const startTime = startTimestamp * 1000;
  
      if (now > startTime) {
        setShowRemaining(true);
      } else {
        setTimeout(() => {
          setShowRemaining(true);
        }, startTime - now)
      }
    }
  }, [setShowRemaining, startTimestamp])

  return (
    <>
      <span className={styles.bar}><Wallet /></span>
      <img className={styles.run_chimp} src={RunChimp} alt="" />
      <img className={styles.spatter_one} src={Spatter1} alt="" />
      <img className={styles.spatter_two} src={Spatter2} alt="" />
      <div className={styles.container}>
        {
          status !== MintStatus.SUCCEESS &&
          <div className={styles.content}>
            <span className={styles.title}>Adopt Cronos Chimp</span>
            {showRemaining && (
              <span className={styles.pool}>
                <span>Chimps remaining</span>
                <span>
                  <span className={styles.remaining}>{formatNumberWithComma(remainingSupply)}&nbsp;</span>/&nbsp;{formatNumberWithComma(maxSupply)}
                </span>
              </span>
            )}
            <MintInput status={status} />
            <MintButton status={status} setStatus={setStatus} />
          </div>
        }
        {
          status === MintStatus.MINTING && 
          <div className={styles.remark}>
            <p>Please wait until the transaction has been confirmed.</p>
            <p>Your adopted Chimps will appear in <b>My Chimps</b> section. </p>
          </div>
        }
        {status === MintStatus.SUCCEESS && <Success setStatus={setStatus} />}
      </div>
    </>
  )
}

export default Minting;