import { useCallback } from "react";
import { ethers, BigNumber as BN } from "ethers";
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core';
import { useContract } from "./useContract";
import { fetchMetadata } from "../utils/fetch";
import { computeEstimateGas, convertPriceToUint } from "../utils/price";
import { OfferChimpMeta } from "../types/interfaces";
import { useChimp } from "../providers/ChimpProvider";
import { META_ARW, TOTAL_SUPPLY } from "../configs";
import { roundNumberWithFix } from "../utils/number";
import { URL_REPLACEMENT } from "../constants/general";
import { chimpImage, honoraryChimpImage } from "../utils/chimp-image";

interface Methods {
  makeOffer: (isHonorary: boolean, tokenId: string, amount: string, topupAmount: string, offerIndex: string) => any,
  acceptOffer: (isHonorary: boolean, tokenId: string, offerIndex: string, expectedAmount: string) => any;
  cancelOffer: (isHonorary: boolean, tokenId: string) => any;
  approveWCROForOffersContract: (isHonorary: boolean, amount?: string) => any;
  approveForAllOfferContract: (isHonorary: boolean, address: string) => any;
  getAllowanceWCROForOffersContract: (isHonorary: boolean) => any;
  getWCROBalance: () => Promise<string>;
  getValidOffers: (isHonorary: boolean, tokenId: string) => any,
  getOfferByIndex: (isHonorary: boolean, tokenId: string, offerIndex: string) => any,
  getUserOfferIndex: (isHonorary: boolean, tokenId: string) => any;
  getAllOfferMade: () => any;
}

export function useOfferContractMethods(): Methods {
  const { account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const { contract, honoraryContract, offerContract, honoraryOfferContract, WCROContract, } = useContract();
  const { getAllOffersChimp, getOfferChimpByIndex, getOfferUserMade, getAllOfferUserMade } = useChimp();

  const approveWCROForOffersContract = useCallback(async (isHonorary: boolean, amount?: string) => {
    const usingContract = isHonorary ? honoraryOfferContract : offerContract;
    const approveAmount = amount ? convertPriceToUint(amount) : BN.from("2").pow(BN.from("256").sub(BN.from("1")));
    const data = await WCROContract.estimateGas.approve(usingContract.address, approveAmount);
    const tx = await WCROContract.approve(usingContract.address, approveAmount, { gasLimit: computeEstimateGas(data) });
    return await tx.wait();
  }, [WCROContract, honoraryOfferContract, offerContract]);

  const getAllowanceWCROForOffersContract = useCallback(async (isHonorary: boolean) => {
    const usingContract = isHonorary ? honoraryOfferContract : offerContract;
    const allowanceAmount = (await WCROContract.allowance(account, usingContract.address)).toString();
    return allowanceAmount;
  }, [WCROContract, account, honoraryOfferContract, offerContract]);

  const getWCROBalance = useCallback(async () => {
    try {
      return (await WCROContract.balanceOf(account)).toString();
    } catch (error) {
      console.log(error);
    }
  }, [WCROContract, account]);


  const makeOffer = useCallback(async (isHonorary: boolean, tokenId: string, amount: string, topupAmount: string, offerIndex: string) => {
    try {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      const expiryTime = BN.from("2").pow(BN.from("256").sub(BN.from("1")));
      const offerAmount = convertPriceToUint(amount);
      const offerTopupAmount = convertPriceToUint(topupAmount);
      const data = await usingContract.estimateGas.makeOffer(expiryTime, tokenId, offerAmount, offerIndex, { value: offerTopupAmount });
      const tx = await usingContract.makeOffer(expiryTime, tokenId, offerAmount, offerIndex, { value: offerTopupAmount, gasLimit: computeEstimateGas(data) });
      return await tx.wait();
    } catch (error) {
      console.log(error);
    }
  }, [honoraryOfferContract, offerContract]);

  const approveForAllOfferContract = useCallback(async (isHonorary: boolean, address: string) => {
    const chimpContract = isHonorary ? honoraryContract : contract;
    const isApproved = await chimpContract.isApprovedForAll(account, address);
    if (!isApproved) {
      const data = await chimpContract.estimateGas.setApprovalForAll(address, true);
      const tx = await chimpContract.setApprovalForAll(address, true, { gasLimit: computeEstimateGas(data) });
      return await tx.wait();
    }
  }, [account, contract, honoraryContract])

  const acceptOffer = useCallback(async (isHonorary: boolean, tokenId: string, offerIndex: string, expectedAmount: string) => {
    try {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      await approveForAllOfferContract(isHonorary, usingContract.address);
      const data = await usingContract.estimateGas.acceptOffer(tokenId, offerIndex, expectedAmount);
      const tx = await usingContract.acceptOffer(tokenId, offerIndex, expectedAmount, { gasLimit: computeEstimateGas(data) });
      return await tx.wait();
    } catch (error) {
      console.log(error);
    }
  }, [honoraryOfferContract, offerContract, approveForAllOfferContract]);

  const cancelOffer = useCallback(async (isHonorary: boolean, tokenId: string) => {
    try {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      const data = await usingContract.estimateGas.withdrawOffer(tokenId);
      const tx = await usingContract.withdrawOffer(tokenId, { gasLimit: computeEstimateGas(data) });
      return await tx.wait();
    } catch (error) {
      console.log(error);
    }
  }, [honoraryOfferContract, offerContract]);

  const getValidOffers = useCallback(async (isHonorary: boolean, tokenId: string) => {
    try {
      const START = 0;
      const END = 100

      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      const data = await usingContract.estimateGas.getValidOffers(tokenId, START, END);
      const validOffers = await usingContract.getValidOffers(tokenId, START, END, { gasLimit: computeEstimateGas(data) });

      const offerIndexes = validOffers.offerIndexes.map((item: any) => item.toString());
      const amounts = validOffers.amounts.map((item: any) => item.toString());
      const expiryTimes = validOffers.expiryTimes.map((item: any) => item.toString());
      const buyers = validOffers.buyers.map((item: any) => item.toString());
      const highestOffer = BigNumber.max.apply(null, amounts).toString();
      let offers = offerIndexes.map((item: string, index: number) => {
        return {
          id: index,
          offerIndex: item,
          amount: amounts[index],
          expiryTime: expiryTimes[index],
          buyer: buyers[index],
          tokenId: tokenId,
          isHighestOffer: amounts[index] === highestOffer,
        }
      });
      offers.sort((a: OfferChimpMeta, b: OfferChimpMeta) =>
        (new BigNumber(a.amount).lt(new BigNumber(roundNumberWithFix(b.amount)))) ? 1 :
          (new BigNumber(b.amount).lt(new BigNumber(roundNumberWithFix(a.amount)))) ? -1 : 0);

      getAllOffersChimp(offers);
    } catch (error) {
      console.log(error);
    }

  }, [getAllOffersChimp, honoraryOfferContract, offerContract]);

  const getOfferByIndex = useCallback(async (isHonorary: boolean, tokenId: string, offerIndex: string) => {
    try {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      const data = await usingContract.estimateGas.getOffer(tokenId, offerIndex);
      let offer: OfferChimpMeta = await usingContract.getOffer(tokenId, offerIndex, { gasLimit: computeEstimateGas(data) });

      offer = {
        offerIndex: offerIndex.toString(),
        tokenId: offer.tokenId.toString(),
        buyer: offer.buyer.toString(),
        amount: offer.amount.toString(),
        expiryTime: offer.expiryTime.toString(),
        isHighestOffer: false
      }

      getOfferChimpByIndex(offer);
    } catch (error) {
      console.log(error);
    }
  }, [getOfferChimpByIndex, honoraryOfferContract, offerContract]);

  const getUserOfferIndex = useCallback(async (isHonorary: boolean, tokenId: string) => {
    try {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      if (account) {
        const data = await usingContract.estimateGas.getUserOfferIndex(account, tokenId);
        const result = await usingContract.getUserOfferIndex(account, tokenId, { gasLimit: computeEstimateGas(data) });
        const offerUserMade = {
          indexValue: result.indexValue.toString(),
          offerMade: result.offerMade
        };
        getOfferUserMade(offerUserMade);
      } else {
        getOfferUserMade({ indexValue: '0', offerMade: false });
      }
    } catch (error) {
      console.log(error);
      getOfferUserMade({ indexValue: '0', offerMade: false });
    }

  }, [account, getOfferUserMade, honoraryOfferContract, offerContract]);

  const getImage = useCallback(async (isHonorary: boolean, tokenId: number) => {
    let image = '';
    if (isHonorary) {
      const metaUrl = (await honoraryContract.tokenURI(tokenId)).toString();
      const meta = await fetchMetadata(metaUrl.replace(URL_REPLACEMENT, META_ARW));
      image = honoraryChimpImage(meta.image || '');
    } else {
      image = chimpImage(Number(tokenId), true);
    }
    return image
  }, [honoraryContract]);

  const getAllOfferMade = useCallback(async () => {

    let RANGE = 2000;
    const totalSupplyHonorary: ethers.BigNumber = await honoraryContract.totalSupply();

    let offerAmounts: string[] = [];
    let offerTokenIds: string[] = [];
    let offerIndexes: string[] = [];
    let honoraryIds: string[] = [];
    let totalOffers: number = 0;

    async function getAllOfferIndex(isHonorary: boolean, start: number) {
      const usingContract = isHonorary ? honoraryOfferContract : offerContract;
      const offers = await usingContract.getAllUserOfferIndex(account, start, start + RANGE);
      const amounts = offers.amounts.map((item: any) => item.toString());
      offerAmounts = [...offerAmounts, ...amounts];
      const tokenIds = offers.tokenIds.map((item: any) => item.toString());
      offerTokenIds = [...offerTokenIds, ...tokenIds];
      if (isHonorary) {
        honoraryIds = [...honoraryIds, ...tokenIds];
      }
      const indexes = offers.indexes.map((item: any) => item.toString());
      offerIndexes = [...offerIndexes, ...indexes];
      totalOffers = totalOffers + offers.totalOffers.toNumber();
    }

    try {
      if (account) {
        let promises = [];
        for (let i = 0; i <= TOTAL_SUPPLY; i += RANGE) {
          promises.push(getAllOfferIndex(false, i));
        }

        for (let i = 0; i <= totalSupplyHonorary.toNumber(); i += RANGE) {
          promises.push(getAllOfferIndex(true, i));
        }

        await Promise.all(promises).then(async () => {
          const offerMadePromises = offerIndexes.map(async (item: string, index: number) => {
            const tokenId = offerTokenIds[index];
            const isHonorary = honoraryIds.includes(tokenId);
            const image = await getImage(isHonorary, Number(tokenId));
            return {
              id: index,
              offerIndex: item,
              amount: offerAmounts[index],
              tokenId,
              isHonorary,
              image,
            }
          });

          await Promise.all(offerMadePromises).then((results: any) => {
            getAllOfferUserMade(results);
          });
        });
      }
      return;
    } catch (error) {
      console.log(error);
    }
  }, [account, getAllOfferUserMade, getImage, honoraryContract, honoraryOfferContract, offerContract]);

  return {
    makeOffer,
    acceptOffer,
    cancelOffer,
    approveWCROForOffersContract,
    approveForAllOfferContract,
    getAllowanceWCROForOffersContract,
    getWCROBalance,
    getValidOffers,
    getOfferByIndex,
    getUserOfferIndex,
    getAllOfferMade,
  }
}
