import React, { useMemo } from "react";
import Modal, { DisplayModal } from "./Modal";
import { ButtonStatus, ModalTypes } from "../../types/enums";
import styles from './ChimpModal.module.scss';
import { Cross, Info } from "../../assets/svgs";
import { useModal } from "../../providers/ModalProvider";
import ChimpFace from '../../assets/images/chimpface.png';
import ProgressButton from "../button/ProgressButton";

const CheckoutConfirmModal: React.FC<{}> = () => {
  const { closeModal, props } = useModal();

  const renderMessage = useMemo((): string => {
    const totalPrice = (props && props.totalPrice) ? props.totalPrice : '';
    const totalBananasPrice = (props && props.totalBananasPrice) ? props.totalBananasPrice : '';
    return `${totalPrice}${!totalPrice || !totalBananasPrice ? '' : ' + '}${totalBananasPrice}`
  }, [props]);

  return (
    <Modal allowClickOutside>
      <div className={styles.container}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <div className={styles.header}>
          <div className={styles.chimp_logo_wrap}>
            <span className={styles.chimp_logo}>
              <img src={ChimpFace} alt="" />
            </span>
          </div>
          <div className={styles.title}>Waiting for confirmation</div>
          <div className={styles.message}>{`Placing merch order worth ${renderMessage}`}</div>
          <div className={styles.buttons}>
            <ProgressButton disabled={false} status={ButtonStatus.LOADING} onClick={() => { }}>
              Confirming...
            </ProgressButton>
          </div>
          <div className={styles.info}>
            <Info className={styles.info_icon} />
            <span className={styles.info_text}>
              Please go to your wallet to confirm.
            </span>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(CheckoutConfirmModal, ModalTypes.CHECKOUT_CONFIRMING);