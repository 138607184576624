import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Info } from '../../assets/svgs';
import styles from './Tooltip.module.scss';

const Tooltip: FC = ({ children }) => {
  const ref: any = useRef();
  const [show, setShow] = useState(false);

  const handleHide = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setShow(false);
  }, []);

  const handleShow = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setShow(true);
  }, []);

  useEffect(() => {
    const ele = ref.current;
    ele?.addEventListener('mouseleave', handleHide);

    return () => ele?.removeEventListener('mouseleave', handleHide);
  }, [handleHide]);

  return (
    <div className={styles.component} ref={ref}>
      <Info className={styles.info} onMouseEnter={handleShow} />
      {show && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default Tooltip;
