import { useCallback, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { GraphQLClient, gql } from 'graphql-request';
import { useChimpay } from "../providers/ChimpayProvider";
import { useUser } from "../providers/UserProvider";
import { useLogin } from "./useLogin";
import { useContract } from "./useContract";
import { EXCLUDED_GIFT_PRODUCTS, GIFT_TYPES, REDEMPTION_TIER_PRICE } from "../constants/chimpay";
import { INVALID_OWNER } from "../constants/general";
import { computeEstimateGas } from "../utils/price";
import { roundNumberWithFix } from "../utils/number";
import { checkStakedTime, calculateSeasonPeriod } from "../utils/stake-time";
import { buildForOneChimpMeta } from "../utils/chimp-traits";
import { GiftRedemptionTiers } from "../types/enums";
import { ChimpStakeMeta, HonoraryChimpMeta, GiftCardMeta, RecipientFormProps, CountryProps } from "../types/interfaces";
import { DISCORD_API } from "../configs";
import { useChimpsHook } from "./useChimpsHook";

const endpoint = `${DISCORD_API}/graphql`;

type IStakeChimps = {
  timeChimpStaked: ethers.BigNumber;
  tokenId: ethers.BigNumber;
  staker: string;
  isHonorary: boolean;
  stakingExpired?: boolean; // TODO: will remove this after no chimps staked in old contract
};

function getStakedChimpState(
  stakedChimp: IStakeChimps,
  isHonorary: boolean,
  isConnectedDiscord: boolean,
  honoraryData?: HonoraryChimpMeta,
) {
  const { timeChimpStaked, tokenId, stakingExpired } = stakedChimp;
  const stakedAt = timeChimpStaked.mul(1000).toNumber();

  const meta = buildForOneChimpMeta({
    tokenId: tokenId.toString(),
    stakedAt,
    seller: INVALID_OWNER,
    isHonorary,
    isConnectedDiscord,
    stakingExpired: stakingExpired || false,
    ...honoraryData,
  } as any);

  return meta as ChimpStakeMeta;
}

function filterGiftTierData(data: GiftCardMeta, tier: GiftRedemptionTiers) {
  const price = REDEMPTION_TIER_PRICE[tier];

  return (
    data.denominationType === 'FIXED' && 
    data.fixedRecipientDenominations.includes(price)
  ) || (
    data.denominationType === 'RANGE' && 
    (price >= data.minRecipientDenomination && price <= data.maxRecipientDenomination)
  )
}

export default function useChimpayHook() {
  const { account } = useWeb3React();

  const {
    state: { seasonStartTime, stakePauseTime },
    setGifts, setCountries, getStakedTokens, getAvailableBananas, getGiftQuota, getSeasonStartTime, getStakePauseTime,
  } = useChimpay();

  const { getIds, getHonoraryMetas } = useChimpsHook();
  const {
    stakeChimpContract, contract, honoraryContract, 
    stakeChimpContractDeprecated, stakeHonoraryChimpContractDeprecated, batchStakeContract
  } = useContract();
  const { checkValidToken } = useLogin();
  const { state: { connectedDiscordChimps } } = useUser();

  const [loadingGifts, setLoadingGifts] = useState(false);

  const getSeasonTimestamps = useCallback(() => {
    try {
      const requestSeasonStart = async () => {
        const timestamp: ethers.BigNumber = await stakeChimpContract.seasonStartTime();
        getSeasonStartTime(timestamp.mul(1000).toNumber());
      }

      const requestPauseTime = async () => {
        const timestamp: ethers.BigNumber = await stakeChimpContract.pauseTimestamp();
        if (timestamp.gt(0)) {
          getStakePauseTime(timestamp.mul(1000).toNumber());
        }
      }

      requestSeasonStart();
      requestPauseTime();
    } catch (error) {}
  }, [getSeasonStartTime, getStakePauseTime, stakeChimpContract]);

  const getSeasonPeriod = useCallback(() => {
    return calculateSeasonPeriod(seasonStartTime);
  }, [seasonStartTime]);

  const isRedemptionPeriod = useCallback(() => {
    const now = Date.now();
    const { redemption } = getSeasonPeriod();

    return now >= redemption.start && now < redemption.end;
  }, [getSeasonPeriod]);
  
  const isWorkingPeriod = useCallback(() => {
    const now = Date.now();
    const { stake } = getSeasonPeriod();

    let periodEnd = stake.end;

    if (stakePauseTime > 0) {
      periodEnd = Math.min(stake.end, stakePauseTime);
    }

    return now >= stake.start && now < periodEnd;
  }, [getSeasonPeriod, stakePauseTime]);

  const checkLockChimpStaked = useCallback((stakedAt: number) => {
    const { isLock, time } = checkStakedTime(Math.max(seasonStartTime, stakedAt), stakePauseTime);

    return { isLock, displayStakeTime: time };
  }, [seasonStartTime, stakePauseTime]);

  const getAvailableRewards = useCallback(async () => {
    try {
      if (account) {
        if (!isRedemptionPeriod()) return;

        const graphQLClient = new GraphQLClient(endpoint);
        const query = gql`
          query availableBananas($accountId: String!) {
            rewards(accountId: $accountId)
          }
        `;

        const res = await graphQLClient.request(query, {
          accountId: account,
        });

        getAvailableBananas(roundNumberWithFix(res?.rewards));
      }
    } catch (error) {}
  }, [account, getAvailableBananas, isRedemptionPeriod])

  const getAvailableGiftQuota = useCallback(async () => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query GiftAvailableQuota {
          giftAvailableQuota {
            Gold
            Silver
            Bronze
          }
        }
      `;

      const res = await graphQLClient.request(query);

      getGiftQuota(res?.giftAvailableQuota);
    } catch (error) {}
  }, [getGiftQuota])

  const getStakedChimps = useCallback(async () => {
    try {
      if (account) {
        const stakedBatched: [IStakeChimps[], IStakeChimps[]] = await batchStakeContract.getStakedTokens(account);

        let stuckChimpsStaked: IStakeChimps[] = [];
        let honoraryStuckChimpsStaked: IStakeChimps[] = [];

        if (stakedBatched[0]) {
          stuckChimpsStaked = stakedBatched[0]
            .filter(({ staker }) => staker !== INVALID_OWNER)
            .map((chimp) => ({ ...chimp, stakingExpired: true }));
        }
        if (stakedBatched[1]) {
          honoraryStuckChimpsStaked = stakedBatched[1]
            .filter(({ staker }) => staker !== INVALID_OWNER)
            .map((chimp) => ({ ...chimp, stakingExpired: true }));
        }

        const chimpsStaked: IStakeChimps[] = await stakeChimpContract.getChimpsStaked(account);

        const queryChimpsStaked = () => {
          const tokenStaked = chimpsStaked.filter(({ staker, isHonorary }) => staker !== INVALID_OWNER && !isHonorary)
            .concat(stuckChimpsStaked)
            .map((chimp) => {
              const isConnectedDiscord = connectedDiscordChimps.chimpsTokenIds.includes(chimp.tokenId.toString());

              return getStakedChimpState(
                chimp,
                false,
                isConnectedDiscord,
              )
            });
          getStakedTokens('normal', tokenStaked);
        }

        const queryHonoraryChimpsStaked = async () => {
          const filteredChimps = chimpsStaked
            .filter(({ staker, isHonorary }) => staker !== INVALID_OWNER && isHonorary)
            .concat(honoraryStuckChimpsStaked);
          
          const ids = filteredChimps.map(({ tokenId }) => tokenId.toString());
          const honoraryMetas = await getHonoraryMetas(ids);

          const honoraryTokensStaked = filteredChimps.map((chimp, index) => {
            const isConnectedDiscord = connectedDiscordChimps.honoraryTokenIds.includes(chimp.tokenId.toString());

            return getStakedChimpState(
              chimp,
              true,
              isConnectedDiscord,
              honoraryMetas[index],
            );
          });
          getStakedTokens('honorary', honoraryTokensStaked);
        }

        queryChimpsStaked();
        queryHonoraryChimpsStaked();
      }
    } catch (error) {}
  }, [
    stakeChimpContract, batchStakeContract, account, connectedDiscordChimps,
    getStakedTokens, getHonoraryMetas,
  ]);

  const stakeChimps = useCallback(async (ids: string[], honoraryIds: string[]) => {
    try {
      const approvals: any[] = [];
      if (ids.length > 0) {
        ids.forEach((id) => {
          approvals.push(contract.approve(stakeChimpContract.address, id));
        });
      }
      if (honoraryIds.length > 0) {
        honoraryIds.forEach((id) => {
          approvals.push(honoraryContract.approve(stakeChimpContract.address, id));
        });
      }
      const approvalTxs = await Promise.all(approvals);

      const approvalsWaits = approvalTxs.map((tx) => tx.wait());
      await Promise.all(approvalsWaits);

      const data = await stakeChimpContract.estimateGas.massStake(ids, honoraryIds);
      const tx = await stakeChimpContract.massStake(ids, honoraryIds, { gasLimit: computeEstimateGas(data) });
      await tx.wait();

      const accessToken = await checkValidToken();
      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const mutation = gql`
        mutation ChimpsStake($ids: [String!]!, $honoraryIds: [String!]!) {
          chimpsStake(ids: $ids, honoraryIds: $honoraryIds)
        }
      `;

      await graphQLClient.request(mutation, {
        ids,
        honoraryIds,
      });

      const refreshTimer = setTimeout(() => {
        getStakedChimps();
        getAvailableRewards();
        getIds();
        clearTimeout(refreshTimer);
      }, 3000);
    } catch (error) {
      throw error;
    }
  }, [stakeChimpContract, contract, honoraryContract, getStakedChimps, getAvailableRewards, getIds, checkValidToken]);

  const recallChimpsFromWork = useCallback(async (
    ids: { id: string; suspended: boolean; }[], 
    honoraryIds: { id: string; suspended: boolean; }[]
  ) => {
    try {
      const idsSuspended = ids.filter(({ suspended }) => suspended).map(({ id }) => id);
      const honoraryIdsSuspended = honoraryIds.filter(({ suspended }) => suspended).map(({ id }) => id);

      if (idsSuspended.length > 0) {
        const tx = await stakeChimpContractDeprecated.unstakeAll(idsSuspended);
        await tx.wait();
      }

      if (honoraryIdsSuspended.length > 0) {
        const honoraryTx = await stakeHonoraryChimpContractDeprecated.unstakeAll(honoraryIdsSuspended);
        await honoraryTx.wait();
      }

      const idsStaked = ids.filter(({ suspended }) => !suspended).map(({ id }) => id);
      const honoraryIdsStaked = honoraryIds.filter(({ suspended }) => !suspended).map(({ id }) => id);

      if (idsStaked.length > 0 || honoraryIdsStaked.length > 0) {
        const stx = await stakeChimpContract.massUnstake(idsStaked, honoraryIdsStaked);
        await stx.wait();

        const accessToken = await checkValidToken();
        const graphQLClient = new GraphQLClient(endpoint, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
  
        const mutation = gql`
          mutation ChimpsRecall($ids: [String!]!, $honoraryIds: [String!]!) {
            chimpsRecall(ids: $ids, honoraryIds: $honoraryIds)
          }
        `;

        await graphQLClient.request(mutation, {
          ids: idsStaked,
          honoraryIds: honoraryIdsStaked,
        });
      }

      getStakedChimps();
      getAvailableRewards();
      getIds();
    } catch (error) {
      throw error;
    }
  }, [stakeChimpContract, stakeChimpContractDeprecated, stakeHonoraryChimpContractDeprecated, checkValidToken, getStakedChimps, getAvailableRewards, getIds]);

  const getGiftCountries = useCallback(async () => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query GiftCountries {
          giftCountries {
            isoName
            name
            currencyName
            currencyCode
            flagUrl
          }
        }
      `;

      const data = await graphQLClient.request(query);
      setCountries(data.giftCountries);
    } catch (error) {}
  }, [setCountries]);

  const getGiftProducts = useCallback(async () => {
    try {
      setLoadingGifts(true);

      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query GiftProducts($params: ProductQueryParams!) {
          giftProducts(params: $params) {
            productId
            productName
            senderFee
            senderFeePercentage
            discountPercentage
            denominationType
            recipientCurrencyCode
            senderCurrencyCode
            minRecipientDenomination
            maxRecipientDenomination
            minSenderDenomination
            maxSenderDenomination
            fixedRecipientDenominations
            fixedSenderDenominations
            fixedRecipientToSenderDenominationsMap
            country {
              isoName
              name
              flagUrl
            }
          }
        }
      `;

      const data = await graphQLClient.request(query, {
        params: {
          productNames: GIFT_TYPES,
          includeRange: true,
          includeFixed: true
        }
      });

      const products: GiftCardMeta[] = data.giftProducts;

      const gifts: {
        [GiftRedemptionTiers.GOLD]: GiftCardMeta[];
        [GiftRedemptionTiers.SILVER]: GiftCardMeta[];
        [GiftRedemptionTiers.BRONZE]: GiftCardMeta[];
      } = {
        Gold: [],
        Silver: [],
        Bronze: [],
      };

      const availableCountries: CountryProps[] = [];

      if (products && Array.isArray(products)) {
        products.filter(({ productName }) => !EXCLUDED_GIFT_PRODUCTS.includes(productName)).forEach((item) => {
          const goldIncluded = filterGiftTierData(item, GiftRedemptionTiers.GOLD);

          if (goldIncluded) {
            gifts[GiftRedemptionTiers.GOLD].push(item);
          }

          const silverIncluded = filterGiftTierData(item, GiftRedemptionTiers.SILVER);

          if (silverIncluded) {
            gifts[GiftRedemptionTiers.SILVER].push(item);
          }

          const bronzeIncluded = filterGiftTierData(item, GiftRedemptionTiers.BRONZE);

          if (bronzeIncluded) {
            gifts[GiftRedemptionTiers.BRONZE].push(item);
          }

          if (goldIncluded || silverIncluded || bronzeIncluded) {
            if (availableCountries.findIndex((ci) => ci.isoName === item.country.isoName) === -1) {
              availableCountries.push({ ...item.country });
            }
          }
        })
      }

      setGifts(gifts);
      setCountries(availableCountries);
      setLoadingGifts(false);
    } catch (error) {}
  }, [setGifts, setCountries, setLoadingGifts]);

  const claimGift = useCallback(async (productId: number, redemptionLink: string, orderForm: RecipientFormProps) => {
    if (!isRedemptionPeriod()) return;

    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation GiftClaim($data: ClaimCheckout!) {
        giftClaim(data: $data) {
          id
          giftId
          giftName
          giftPrice
          currency
          country
          quantity
          giftTier
          transactionId
          claimedAt
          costBananas
          recipientEmail
          recipientAccount
          recipientName
          status
        }
      }
    `;

    const data = await graphQLClient.request(mutation, { 
      data: {
        giftId: productId,
        recipientEmail: orderForm.recipientEmail,
        recipientName: orderForm.recipientName,
        giftTier: orderForm.claimingTier,
        redemptionLink,
      }
    });

    return data?.giftClaim;
  }, [checkValidToken, isRedemptionPeriod]);

  const getGiftTransaction = useCallback(async (orderId: number) => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      query GiftOrderById($orderId: Int!) {
        giftOrderById(orderId: $orderId) {
          id
          giftId
          giftName
          giftPrice
          currency
          country
          quantity
          giftTier
          transactionId
          claimedAt
          costBananas
          recipientEmail
          recipientAccount
          recipientName
          status
        }
      }
    `;

    const data = await graphQLClient.request(mutation, { 
      orderId
    });

    return data?.giftOrderById;
  }, [checkValidToken]);

  const getAccountsRewards = useCallback(async () => {
    try {
      const { season } = getSeasonPeriod();

      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query AllAccountsRewards($period: Period!) {
          allAccountsRewards(period: $period) {
            account
            rewards
            amountStaked
          }
        }
      `;

      const data = await graphQLClient.request(query, {
        period: {
          start: new Date(season.start).toISOString(),
          end: new Date(season.end).toISOString(),
        }
      });

      return data?.allAccountsRewards;
    } catch (error) {}
  }, [getSeasonPeriod])

  const getGiftOrders = useCallback(async (start: string, end: string) => {
    try {
      const accessToken = await checkValidToken();
      const graphQLClient = new GraphQLClient(endpoint, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      const query = gql`
        query GiftOrdersQuery($params: GiftOrdersQueryParams, $period: Period!) {
          giftOrders(params: $params, period: $period) {
            id
            giftId
            giftName
            giftPrice
            currency
            country
            transactionId
            claimedAt
            costBananas
            recipientEmail
            recipientAccount
            recipientName
            status
          }
        }
      `;

      const data = await graphQLClient.request(query, {
        period: {
          start,
          end,
        }
      });

      return data?.giftOrders;
    } catch (error) {}
  }, [checkValidToken])

  const sendRedemptionCode = useCallback(async (data: {
    orderId: number;
    redemptionLink?: string;
    receiverEmail?: string;
  }) => {
    const accessToken = await checkValidToken();

    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation EmailRedemptionCode($orderId: Int!, $redemptionLink: String, $receiverEmail: String) {
        emailRedemptionCode(
            orderId: $orderId
            redemptionLink: $redemptionLink
            receiverEmail: $receiverEmail
        ) {
          id
          giftId
          giftName
          giftPrice
          transactionId
          claimedAt
          costBananas
          recipientEmail
          recipientAccount
          recipientName
        }
      }
    `;

    await graphQLClient.request(mutation, data);
  }, [checkValidToken]);

  return {
    loadingGifts,
    getSeasonTimestamps,
    getSeasonPeriod,
    isRedemptionPeriod,
    isWorkingPeriod,
    checkLockChimpStaked,
    getAvailableRewards,
    getAvailableGiftQuota,
    getStakedChimps,
    stakeChimps,
    recallChimpsFromWork,
    getGiftCountries,
    getGiftProducts,
    claimGift,
    getAccountsRewards,
    getGiftOrders,
    sendRedemptionCode,
    getGiftTransaction,
  }
}
