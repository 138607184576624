import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import VideoFrame from "../../components/lore/VideoFrame";
import { ScrollFrame } from "../../components/lore/ScrollFrame";
import Button from "../../components/button/ProgressButton";
import UnlockingProgress from "../../components/lore/UnlockingProgress";
import ContributeModal from "../../components/lore/ContributeModal";
import { useModal } from "../../providers/ModalProvider";
import { useLore } from "../../providers/LoreProvider";
import useChimpayHook from "../../hooks/useChimpayHook";
import useLoreHook from "../../hooks/useLoreHook";
// import BananaToken from '../../assets/images/banana_token.png';
import Speaker from '../../assets/images/trumpet.png';
import ScrollPlaceholder from '../../assets/images/scroll-placerholder.png';
import { Dropdown as DropdownIcon, Arrow } from "../../assets/svgs";
import { TIP_NOT_SEQUENCED, TIP_LOCKED, TIP_UNLOCKED } from '../../constants/lore';
import { ModalTypes, SignInTypes } from "../../types/enums";
import { SceneDetails as SceneProps } from "../../types/interfaces";
import { shortenAddress } from "../../utils/address";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../../constants/labels";
import { INVALID_OWNER } from "../../constants/general";
import styles from './BananaScrolls.module.scss';

const MOVE_DISTANCE = 200;

const VideosInScroll = ({ selectedScene, previousLocked }: {
  selectedScene: SceneProps | undefined;
  previousLocked: boolean;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  // const isMouseDown = useRef(false);
  // const scrollX = useRef(0);
  // const startX = useRef(0);

  const [showScrollIcon, setShowScrollIcon] = useState({ left: false, right: true });

  const { state: { selectSceneId, scenes, myContributions }, selectScene } = useLore();

  // const handleStartDragging = useCallback((e: ReactMouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   if (isMouseDown.current && scrollRef.current) {
  //     scrollRef.current.scrollLeft = scrollX.current - (e.pageX - startX.current);
  //   }
  // }, []);

  // const handleDropDragging = useCallback((e: ReactMouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   scrollRef.current?.classList.remove(styles.scroll_list_active);
  //   isMouseDown.current = false;
  // }, []);

  // const handleScrollMove = useCallback((e: ReactMouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   isMouseDown.current = true;

  //   if (scrollRef.current) {
  //     scrollRef.current.classList.add(styles.scroll_list_active);

  //     scrollX.current = scrollRef.current.scrollLeft;
  //     startX.current = e.pageX;
  //   }
  // }, [])

  const hasContributed = useMemo(() => {
    const contributed = myContributions.find(({ sceneId }) => sceneId === selectSceneId);
    return contributed && contributed.amount > 0;
  }, [myContributions, selectSceneId]);

  const videoLockingMsg = useMemo((): string => {
    if (previousLocked) {
      return 'Please unlock the sequence in order';
    }

    if (selectedScene && !selectedScene.isUnlocked) {
      return 'More contributors needed to unlock Scroll';
    }
    return 'To watch content please contribute Bananas';
  }, [previousLocked, selectedScene])

  const handleScroll = useCallback((e: ReactMouseEvent, direction: number) => {
    e.preventDefault();
    e.stopPropagation();

    if (scrollRef.current) {
      const move = direction * MOVE_DISTANCE;
      scrollRef.current.scrollBy({ left: move, behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    let scrollEle = scrollRef.current;

    const listner = () => {
      if (scrollEle) {
        let left = scrollEle.scrollLeft > 10 ? true : false;
        let right = scrollEle.scrollLeft < (scrollEle.scrollWidth - scrollEle.clientWidth - 10) ? true : false;

        if (showScrollIcon.left !== left || showScrollIcon.right !== right) {
          setShowScrollIcon((prev) => ({
            ...prev,
            left,
            right,
          }))
        }
      }
    }

    scrollEle?.addEventListener('scroll', listner);

    return () => {
      scrollEle?.removeEventListener('scroll', listner);
    }
  }, [showScrollIcon])

  return (
    <div className={styles.scroll_videos}>
      <div className={styles.scroll_main}>
        <VideoFrame 
          isUnlocked={selectedScene?.isUnlocked && hasContributed}
          src={selectedScene?.video?.dashManifestUrl} 
          poster={selectedScene?.video?.thumbnail ? `${selectedScene?.video?.thumbnail}?time=1s&height=720` : ScrollPlaceholder} 
          lockedMsg={videoLockingMsg}
        />
      </div>
      <div className={styles.scroll_list_label}>
        Scroll Sequence
      </div>
      <div className={styles.scroll_wrap}>
        <div 
          className={styles.scroll_list} 
          ref={scrollRef} 
          // onMouseDown={handleScrollMove}
          // onMouseMove={handleStartDragging}
          // onMouseLeave={handleDropDragging}
          // onMouseUp={handleDropDragging}
        >
          <div className={styles.scroll_list_scroll}>
            {
              scenes.map(({ id, isUnlocked, video }) => (
                <ScrollFrame 
                  key={id}
                  src={video?.thumbnail ? `${video?.thumbnail}?time=3s&width=200` : ScrollPlaceholder}
                  selected={id === selectSceneId}
                  onClick={() => selectScene(id)}
                  isUnlocked={isUnlocked}
                />
              ))
            }
          </div>
        </div>
        {
          showScrollIcon.left && 
          <div
            className={`${styles.scroll_icon} ${styles.scroll_icon_prev}`}
            onClick={(e) => handleScroll(e, -1)}
          >
            <DropdownIcon />
          </div>
        }
        {
          showScrollIcon.right && 
          <div
            className={`${styles.scroll_icon} ${styles.scroll_icon_next}`}
            onClick={(e) => handleScroll(e, 1)}
          >
            <DropdownIcon />
          </div>
        }
      </div>
    </div>
  )
}

const SceneDetails = ({ selectedScene, previousLocked }: {
  selectedScene: SceneProps | undefined;
  previousLocked: boolean;
}) => {
  const { state: { selectSceneId, myContributions } } = useLore();
  const { openModal } = useModal();

  const { account } = useWeb3React();
  const { isRedemptionPeriod } = useChimpayHook();

  const myContributedAmount = useMemo(() => 
    myContributions.find(({ sceneId }) => sceneId === selectSceneId)?.amount || 0, [selectSceneId, myContributions]);

  const sceneExisted = useMemo(() => 
    selectedScene?.id && selectedScene.video?.uid && selectedScene.video.uid !== '', [selectedScene]);

  const tipMessage = useMemo(() => {
    if (!selectedScene || previousLocked) {
      return TIP_NOT_SEQUENCED;
    }

    if (selectedScene.isUnlocked) {
      return TIP_UNLOCKED;
    } else {
      return TIP_LOCKED;
    }
  }, [previousLocked, selectedScene]);

  const contributeDisabled = useMemo(() => !sceneExisted || previousLocked, [sceneExisted, previousLocked]);

  const handleClick = useCallback(() => {
    if (!account || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.GENERIC });
    }

    openModal(ModalTypes.CONTRIBUTE)
  }, [openModal, account])

  return (
    <div className={styles.scene_details}>
      <div className={styles.scroll_title_dk}>
        <div className={styles.details_item_label}>
          Scroll {selectSceneId}
        </div>
        <div className={styles.details_title}>
          {selectedScene?.title || '?????'}
        </div>
      </div>
      <div>
        <div className={styles.details_item_label}>
          Description
        </div>
        <div className={styles.details_desc}>
          {contributeDisabled ? '?????' : selectedScene?.description}
        </div>
      </div>
      {
        !selectedScene?.isUnlocked && 
        <div>
          <UnlockingProgress amount={selectedScene?.totalContributors || 0} />
        </div>
      }
      <div>
        <div className={styles.details_info}>
          <div>
            <div className={styles.details_info_label}>
              Top Contributor
            </div>
            <div className={styles.details_info_value}>
              {contributeDisabled ? '--' : shortenAddress(selectedScene?.topContributor || INVALID_OWNER)}
            </div>
          </div>
          <div>
            <div className={styles.details_info_label}>
              My Contribution
            </div>
            <div className={styles.details_info_value2}>
              {contributeDisabled ? '--' : myContributedAmount}
            </div>
          </div>
          <div>
            <div className={styles.details_info_label}>
              Banana Pool
            </div>
            <div className={styles.details_info_value2}>
              {contributeDisabled ? '--' : selectedScene?.value}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.details_tip}>
          {/* <img className={styles.details_tip_icon} src={BananaToken} alt="" /> */}
          <div className={styles.details_tip_msg}>
            <strong>Tips: </strong>
            {tipMessage}
          </div>
        </div>
        <Button
          onClick={handleClick}
          disabled={contributeDisabled || !isRedemptionPeriod()}
        >
          Contribute {myContributedAmount > 0 ? 'More' : ''}
        </Button>
      </div>
    </div>
  )
}

const BananaScrolls = () => {
  const { getSeasonPeriod, isRedemptionPeriod } = useChimpayHook();
  const { loadActiveScenes } = useLoreHook();
  const { redemption: { end } } = useMemo(() => getSeasonPeriod(), [getSeasonPeriod]);

  const { state: { selectSceneId, scenes } } = useLore();

  useEffect(() => {
    loadActiveScenes();
  }, [loadActiveScenes])

  const selectedScene = useMemo(() => scenes.find((sc) => +sc.id === +selectSceneId), [scenes, selectSceneId]);

  const previousLocked = useMemo(() => {
    const previous = scenes.find((sc) => +sc.id === +selectSceneId - 1);
    if (!previous) {
      return false;
    }

    return !previous.isUnlocked;
  }, [scenes, selectSceneId]);

  return (
    <div className={styles.container}>
      <Link to="/chimpay/dashboard" className={styles.goBack}>
        <Arrow />
        <span>Back to Dashboard</span>
      </Link>
      <div className={styles.page_title}>
        Banana Scrolls
      </div>
      {
        isRedemptionPeriod() && 
        <div className={styles.scroll_warning}>
          <img className={styles.scroll_warning_icon} src={Speaker} alt="" />
          <div className={styles.scroll_warning_msg}>
            Lore Leaderboard will finalise in {Math.floor((Math.max(end - Date.now(), 0)) / (24 * 60 * 60 * 1000))} days.
            <br />
            Contribute now to secure your position in final raffle.
          </div>
        </div>
      }
      <div className={styles.body}>
        <div className={styles.scroll_title_mb}>
          <div className={styles.details_item_label}>
            Scroll {selectSceneId}
          </div>
          <div className={styles.details_title}>
            {selectedScene?.title || '?????'}
          </div>
        </div>
        <VideosInScroll 
          selectedScene={selectedScene} 
          previousLocked={previousLocked}
        />
        <SceneDetails 
          selectedScene={selectedScene} 
          previousLocked={previousLocked}
        />
      </div>
      <ContributeModal />
    </div>
  )
}

export default BananaScrolls;
