import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from '../../assets/svgs';
import styles from './Collapse.module.scss';

interface Props {
  label: string,
}

const Collapse: FC<Props> = ({ label, children }) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleCollapse = useCallback(() => {
    setIsOpened(prev => !prev);
  }, [setIsOpened])

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={toggleCollapse} >
        <span className={styles.label}>{label}</span>
        <Dropdown className={classNames(styles.icon, { [styles.icon_closed]: !isOpened })} />
      </div>
      <div className={classNames(styles.content, { [styles.content_opened]: isOpened, [styles.content_closed]: !isOpened })}>
        {children}
      </div>
    </div>
  )
}

export default Collapse;