import { SaleChimpMeta } from "../types/interfaces";

export function addOrUpdateChimp(origins: SaleChimpMeta[], update: SaleChimpMeta): SaleChimpMeta[] {
  const index = origins.findIndex(({ id }) => id === update.id);

  if (index === -1) {
    origins.push(update)
  } else {
    origins[index] = {
      ...origins[index],
      ...update
    }
  }

  return origins;
}

export function mergeChimps(origins: SaleChimpMeta[], newly: SaleChimpMeta[]): SaleChimpMeta[] {
  return newly.reduce((accumulator: SaleChimpMeta[], current: SaleChimpMeta) => {
    return addOrUpdateChimp(accumulator, current);
  }, [...origins])
}
