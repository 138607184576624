import { FC, ReactNode, ReactElement } from "react";
import ReactDOM from "react-dom";
import { Cross } from "../../assets/svgs";
import styles from './ImageModal.module.scss';

const modalRoot = document.getElementById('modal');

const imageContainer = document.createElement('div');

if (modalRoot && imageContainer) {
  modalRoot.appendChild(imageContainer);
}

const ModalContainer: FC<{ 
  children: ReactNode,
  container: HTMLDivElement,
}> = ({ children, container }): ReactElement | null => {
  return ReactDOM.createPortal(children, container);
}

interface Props {
  show: boolean,
  imageUrl: string,
  title: string,
  error?: string,
  onClose: () => void,
}

const ImageModal: FC<Props> = ({ show, imageUrl, title, onClose, error }) => {
  if (!show) {
    return <></>
  }

  return (
    <ModalContainer container={imageContainer}>
      <div className={styles.container}>
        <div className={styles.body}>
          <Cross className={styles.cross} onClick={onClose} />
          <div className={styles.content}>
            {
              !error && !imageUrl && 
              <div className={styles.loading}>
                Loading...
              </div>
            }
            {
              error && 
              <div className={styles.error}>
                {error}
              </div>
            }
            {
              !error && imageUrl &&
              <>
                <img className={styles.displayImage} src={imageUrl || ''} alt="" />
                <div className={styles.download}>
                  <a href={imageUrl} download={title} target="_blank" rel="noopener noreferrer">Download Image</a>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default ImageModal