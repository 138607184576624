
import classNames from "classnames";
import { FC } from "react";
import { Cross, HrefLink, Success, Info } from "../../assets/svgs";
import { CRONOS_EXPLORER } from "../../configs";
import { ChimpOperateTypes } from "../../types/enums";
import Button from "../button/ProgressButton";
import LinkButton from '../button/LinkButton';
import styles from './ChimpModal.module.scss';

interface Props {
  closeModal: () => void,
  trxnId?: string,
  title?: string,
  subTitle?: string,
  description?: string,
  type: ChimpOperateTypes
}

const ChimpSuccessModal: FC<Props> = ({ closeModal, title, subTitle, description, trxnId, type }) => {
  return (
    <div className={classNames(styles.container, styles.success)}>
      <Cross className={styles.cancel} onClick={closeModal} />
      <Success className={styles.success_icon} />
      {title && <span className={styles.success_title}>{title}</span>}
      {subTitle && <span className={styles.success_subtitle}>{subTitle}</span>}
      {description && <span className={styles.success_desc}>{description}</span>}
      {
        trxnId &&
        <div className={styles.success_explore}>
          View transaction on the
          &nbsp;
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={`${CRONOS_EXPLORER}/tx/${trxnId}`}
          >chain</a>
          &nbsp;
          <a
            rel="noreferrer noopener"
            target="_blank"
            href={`${CRONOS_EXPLORER}/tx/${trxnId}`}
          ><HrefLink /></a>
        </div>
      }
      {
        type === ChimpOperateTypes.BUY &&
        <>
          <LinkButton
            className={styles.success_button}
            to="/my-chimps"
            onClick={closeModal}
            isGhost={false}
          >
            View My Chimps
          </LinkButton>
          <LinkButton
            className={styles.success_button}
            to="/market"
            onClick={closeModal}
          >
            Go to Marketplace
          </LinkButton>
        </>
      }
      {
        type === ChimpOperateTypes.REMOVE &&
        <LinkButton
          className={styles.success_button}
          to="/my-chimps"
          onClick={closeModal}
          isGhost={false}
        >
          Go to My Chimps
        </LinkButton>
      }
      {
        type !== ChimpOperateTypes.REMOVE && 
        type !== ChimpOperateTypes.BUY && 
        type !== ChimpOperateTypes.OFFER &&
        <Button classname={styles.success_button} onClick={closeModal}>
          Close
        </Button>
      }
      {type === ChimpOperateTypes.OFFER &&
        <>
          <div className={classNames(styles.info, styles.offer_info)}>
            <Info className={styles.info_icon} />
            <span className={classNames(styles.info_text, styles.offer_info_text)}>
              Make sure you have enough WCRO in your wallet. Offers with not enough WCRO will not be displayed.
            </span>
          </div>
          <LinkButton
            className={styles.success_button}
            to="/my-chimps?view=offers-made"
            onClick={closeModal}
            isGhost={false}
          >
            See all my offers
          </LinkButton>
          <LinkButton
            className={styles.success_button}
            to="/market"
            onClick={closeModal}
          >
            Go to Marketplace
          </LinkButton>
        </>
      }
    </div>
  )
}

export default ChimpSuccessModal;