import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useUser } from "../../providers/UserProvider";
import { useLogin } from "../../hooks/useLogin";
import { useChimpsHook } from "../../hooks/useChimpsHook";
import useChimpayHook from "../../hooks/useChimpayHook";
import { CardTypes, ConnectWalletType, ModalTypes, SignInTypes } from "../../types/enums";
import { convertPriceToDecimal } from '../../utils/price';
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import { Heart, HeartFill, Lock, Timer } from "../../assets/svgs";
import { getChimpDisplayTitle } from "../../utils/title";
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_WALLET_TYPE } from "../../constants/labels";
import { ChimpStakeMeta } from "../../types/interfaces";
import styles from './Chimp.module.scss';

interface WrapperProps {
  type: CardTypes,
  tokenId?: string | number,
  children: ReactNode,
  onClick?: () => void,
  isHonorary?: boolean,
}

const Wrapper = ({ type, tokenId, children, onClick, isHonorary = false }: WrapperProps) => {
  if (type === CardTypes.MARKET) {
    return (
      <Link to={`/chimp/${tokenId}?isHonorary=${isHonorary}&page=${CardTypes.MARKET}`} className={styles.card}>
        {children}
      </Link>
    )
  }

  return (
    <div className={styles.card} onClick={onClick}>
      {children}
    </div>
  )
}

const ChimpCard: FC<{
  data: ChimpStakeMeta;
  type: CardTypes;
}> = ({ type, data }) => {
  const { 
    tokenId: id, image, seller, isForSale = false, price, trait_count, description, isHonorary = false,
    name, isConnectedDiscord = false, favoriteCount, stakedAt,
  } = data;

  const { active, account } = useWeb3React();
  const { openModal } = useModal();
  const { state: { favoriteChimps } } = useUser();
  const { getChimpForBuyOrSale } = useChimp();
  const { addFavoriteChimp, removeFavoriteChimp } = useLogin();
  const { updateFavoriteCountChimps } = useChimpsHook();
  const { isRedemptionPeriod, checkLockChimpStaked } = useChimpayHook();

  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [localFavoriteCount, setLocalFavoriteCount] = useState<number>(Number(favoriteCount));
  const walletType = window.localStorage.getItem(LOCAL_STORAGE_WALLET_TYPE);

  useEffect(() => {
    const hasFavorite = favoriteChimps.some(item => item.tokenId === id && item.isHonorary === isHonorary);
    if (hasFavorite) {
      setIsFavorite(true);
    }
  }, [favoriteChimps, id, isHonorary]);

  useEffect(() => {
    if (walletType === ConnectWalletType.NONE) {
      setIsFavorite(false);
    }
  }, [walletType]);

  const handleClickModal = useCallback(() => {
    if (type === CardTypes.MARKET) return;

    getChimpForBuyOrSale({...data});

    openModal(ModalTypes.PRE_SELL_OR_TRANSFER)
  }, [type, data, openModal, getChimpForBuyOrSale])

  const priceLabel = useMemo(() => {
    if (isForSale && account === seller) {
      return 'Your price'
    }

    if (isForSale && account !== seller) {
      return 'Price';
    }

    return '';
  }, [account, seller, isForSale])

  const { isLock, displayStakeTime } = useMemo(() => 
    checkLockChimpStaked(stakedAt), [stakedAt, checkLockChimpStaked]);

  const handleFavorite = useCallback(async (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!active) {
      return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.FAVORITE_CHIMP });
    }

    if (active && !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.FAVORITE_CHIMP });
    }

    if (!isFavorite) {
      setLocalFavoriteCount(localFavoriteCount + 1);
      setIsFavorite(true);
      await addFavoriteChimp(id, isHonorary);
      await updateFavoriteCountChimps(isForSale, isHonorary);
    } else {
      setIsFavorite(false);
      if (localFavoriteCount > 0) {
        setLocalFavoriteCount(localFavoriteCount - 1);
        await removeFavoriteChimp(id, isHonorary);
        await updateFavoriteCountChimps(isForSale, isHonorary);
      }
    }

  }, [active, isFavorite, updateFavoriteCountChimps, isForSale, isHonorary, openModal, localFavoriteCount, addFavoriteChimp, id, removeFavoriteChimp]);

  return (
    <Wrapper tokenId={id} type={type} onClick={handleClickModal} isHonorary={isHonorary}>
      <div className={classNames(styles.card_content, { [styles.card_content_pb]: !priceLabel })}>
        <div className={styles.card_img_holder}>
          <img className={styles.card_img} src={image} alt="" loading="lazy" />
        </div>
        {isConnectedDiscord ?
          <span className={styles.card_id_with_discord_icon}>{getChimpDisplayTitle(id, name, isHonorary)}</span> :
          <span className={styles.card_id}>{getChimpDisplayTitle(id, name, isHonorary)}</span>
        }
        {
          !isHonorary &&
          <div className={styles.card_attibutes}>
            <div className={styles.card_attr_row}>
              <div className={styles.card_label}>Traits</div>
              <div className={styles.card_value}>{trait_count}</div>
            </div>
            {
              type === CardTypes.MARKET &&
              <div onClick={handleFavorite} className={styles.card_favorite}>
                {isFavorite ? <HeartFill /> : <Heart />}
                <div className={styles.favorite_count}>{localFavoriteCount}</div>
              </div>
            }
          </div>
        }
        {
          isHonorary &&
          <div className={styles.card_attibutes}>
            <div className={styles.card_attr_row}>
              <div className={styles.card_label}>{description}</div>
            </div>
            {
              type === CardTypes.MARKET &&
              <div onClick={handleFavorite} className={styles.card_favorite}>
                {isFavorite ? <HeartFill /> : <Heart />}
                <div className={styles.favorite_count}>{localFavoriteCount}</div>
              </div>
            }
          </div>
        }
      </div>
      {
        priceLabel &&
        <div className={styles.card_price}>
          <div className={styles.card_price_label}>{priceLabel}</div>
          <div className={styles.card_price_value}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(price || ''), 3))} CRO</div>
        </div>
      }
      {
        stakedAt && stakedAt > 0 && isRedemptionPeriod() && 
        <div className={styles.stake_time}>
          {
            isLock 
            ? <><Lock className={styles.stake_icon} />{displayStakeTime}</>
            : <><Timer className={styles.stake_icon} />{displayStakeTime}</>
          }
        </div>
      }
    </Wrapper>
  )
}

export default ChimpCard;