import { useCallback } from 'react';
import { useWeb3React } from "@web3-react/core";
import { useModal } from '../../providers/ModalProvider';
import { useUser } from '../../providers/UserProvider';
import { ModalTypes, SignInTypes } from '../../types/enums';
import styles from './AddToCartButton.module.scss';
import { LOCAL_STORAGE_CART } from "../../constants/labels";
import { MerchandiseMeta, OrderCartMeta } from '../../types/interfaces';
import { generateOrderItem } from '../../utils/shop-merch';
import Button from '../button/ProgressButton';

interface Props {
  item: MerchandiseMeta,
  size?: string,
  quantity?: number | string,
  disabled?: boolean,
  maxLimit?: number,
}

const AddToCartButton: React.FC<Props> = (props) => {
  const { item, size = '', quantity = 1, maxLimit = 0, disabled = false } = props;

  const { openModal } = useModal();
  const { updateOrderCart, updateRecentOrder } = useUser();
  const { active } = useWeb3React();

  const handleAddToCart = useCallback(() => {
    const currentCart = localStorage.getItem(LOCAL_STORAGE_CART);
    const cartOrders: OrderCartMeta[] = currentCart ? JSON.parse(currentCart) : [];

    const createOrderId = cartOrders[cartOrders.length - 1] ? Number(cartOrders[cartOrders.length - 1].orderId) + 1 : 1;
    const orderItem: OrderCartMeta = generateOrderItem(item, {
      orderId: createOrderId,
      size,
      quantity: Number(quantity),
      maxLimit,
    });

    const existingId = cartOrders.findIndex((el) => el.id === item.id && el.size === size && el.currency === item.currency);
    if (existingId !== -1) {
      cartOrders[existingId].quantity += Number(quantity);
    } else {
      cartOrders.push(orderItem);
    }

    localStorage.setItem(LOCAL_STORAGE_CART, JSON.stringify(cartOrders));
    updateOrderCart(cartOrders);
    updateRecentOrder(orderItem);
    openModal(ModalTypes.ADD_TO_CART);
  }, [item, openModal, quantity, size, maxLimit, updateOrderCart, updateRecentOrder]);

  return (
    <Button 
      classname={styles.btn} 
      onClick={
        active ? handleAddToCart : () => openModal(ModalTypes.SIGN_IN, { message: SignInTypes.SHOP_MERCH })
      } 
      disabled={active && disabled}
    >
      Add to cart
    </Button> 
  )
}

export default AddToCartButton;