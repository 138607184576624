
import { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import ClickOutSide from '../click-outside/ClickOutside';
import { Dropdown } from '../../assets/svgs';
import styles from './FilterTabs.module.scss';

interface TabProps {
  title: string,
  selected: boolean,
  onClick: () => void,
}

const Tab: FC<TabProps> = ({ title, selected, onClick }) => {

  return (
    <div className={classNames(styles.tab, { [styles.tab_selected]: selected, [styles.tab_hidden]: selected })} onClick={onClick}>
      {title}
    </div>
  )
}

interface TabsProps {
  filter: any,
  options: any[],
  onFilter: (val: any) => void,
  counts?: any,
}

const FilterTabs = ({ filter, options, onFilter, counts }: TabsProps) => {
  const [show, setShow] = useState(false);

  const handleSelect = useCallback((title) => {
    return () => {
      onFilter(title)
      setShow(false);
    }
  }, [onFilter])

  const getCount = (type: any) => {
    return !counts ? '' : `(${counts[type]})`
  }

  return (
    <ClickOutSide className={styles.outside_width} onClickOutside={() => setShow(false)}>
      <div className={styles.container}>
        <div className={classNames(styles.header, { [styles.header_bd]: show })} onClick={() => setShow(!show)}>
          {filter} {getCount(filter)}
          <Dropdown className={styles.dropdown} style={{ transform: `rotate(${show ? 180 : 0}deg)` }} />
        </div>
        <div className={classNames(styles.tabs, { [styles.tabs_show]: show })}>
        {
          options.map((title) => {
            return <Tab key={title} selected={title === filter} title={`${title} ${getCount(title)}`} onClick={handleSelect(title)} />
          })
        }
        </div>
      </div>
    </ClickOutSide>
  )
}

export default FilterTabs;