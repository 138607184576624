import React from 'react';
import { OrderCartMeta, OrderItemMeta } from '../../types/interfaces';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import OrderMerchandise from './OrderMerchandise';
import { MerchPaymentTypes } from '../../types/enums';

import styles from './OrderItem.module.scss';

interface Props {
  merchandiseItems: (OrderCartMeta | OrderItemMeta)[];
  subTotal: string | number;
  subTotalBananas: string | number;
  shippingPrice?: string | number;
  isCartOrder?: boolean;
  callbackAction?: () => any;
}

const OrderItem: React.FC<Props> = (props) => {
  const { merchandiseItems = [], subTotal = '0', subTotalBananas = '0', shippingPrice = 0, isCartOrder = true, callbackAction } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>Items</div>
      <div className={styles.order_list}>
        <OrderMerchandise merchandiseList={merchandiseItems} isCartOrder={isCartOrder} callbackAction={callbackAction} />
      </div>
      <div className={styles.shipping}>
        <span>Shipping price</span>
        <div className={styles.shipping_price}>
          {!shippingPrice ? '--' : shippingPrice} USDC
        </div>
      </div>

      <div className={styles.order_total}>
        <span>Total</span>
        <div className={styles.order_total_value}>
          <span>
            {+subTotal + +shippingPrice > 0 && `${formatNumberWithComma(roundNumberWithFix(+subTotal + +shippingPrice, 3))} ${MerchPaymentTypes.USDC}`}
          </span>
          <span>
            {+subTotalBananas > 0 && `${formatNumberWithComma(roundNumberWithFix(subTotalBananas, 3))} ${MerchPaymentTypes.BANANAS}`}
          </span>
        </div>
      </div>
    </div>
  )
}

export default OrderItem;