import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Footer from '../../components/footer/Footer';
import useChimpayHook from '../../hooks/useChimpayHook';
import useLoreHook from '../../hooks/useLoreHook';
import { useLore } from '../../providers/LoreProvider';

const EXCLUDED_PATH = ['dashboard', 'scroll', 'playlist'];

const ChimpayContainer = () => {
  const { pathname } = useLocation();

  const { state: { activeChapterId } } = useLore();

  const { getMyContributedScenes, checkNFTShareEarned, getBalanceOfLoreNFT } = useLoreHook();
  const { isRedemptionPeriod } = useChimpayHook();

  useEffect(() => {
    getMyContributedScenes();
  }, [getMyContributedScenes])

  useEffect(() => {
    if (!isRedemptionPeriod()) {
      checkNFTShareEarned();
    }
  }, [checkNFTShareEarned, isRedemptionPeriod])

  useEffect(() => {
    if (!isRedemptionPeriod() && activeChapterId > 0) {
      getBalanceOfLoreNFT(activeChapterId - 1);
    }
  }, [activeChapterId, isRedemptionPeriod, getBalanceOfLoreNFT]);

  return (
    <div>
      <Outlet />
      {EXCLUDED_PATH.every((path) => !pathname.includes(path)) && <Footer />}
    </div>
  )
}

export default ChimpayContainer;
