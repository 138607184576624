import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback } from "react";
import { request, gql } from 'graphql-request';
import { DISCORD_API, OAUTH_REDIRECT } from "../configs";

// This hook allows the user to sign a signature as proof of ownership
export const useConnectDiscord = () => {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const handleRedirect = useCallback(async () => {
    const params = new URLSearchParams(window.location.search);

    const state = params.get('state') || '{}';

    if (!state) {
      throw new Error('no data present');
    }

    const jsonData = JSON.parse(atob(state));

    const mutation = gql`
      mutation discordConnect($code: String!, $signature: String!, $account: String!, $tokenId: TokenId!, $isHonorary: Boolean!) {
        discordConnect(code: $code, signature: $signature, account: $account, tokenId: $tokenId, isHonorary: $isHonorary) {
          id
        }
      }
    `;

    const data = await request(`${DISCORD_API}/graphql`, mutation, {
      code: params.get('code'),
      ...jsonData,
    });

    if (data) {
      return data;
    }

    throw new Error('Something went wrong')
  }, [])

  return {
    connectDiscord: async (tokenId: string, isHonorary: boolean) => {
      try {
        let signature: string = '';

        try {
          // try DeFi Wallet first
          signature = await library!.send('personal_sign', [`I am the owner of Chimp ${tokenId}`])
        } catch (e) {
          signature = await library!.send('personal_sign', [account, `I am the owner of Chimp ${tokenId}`])
        }

        if (!signature) {
          throw new Error('Empty Signature');
        }

        // Set in localstorage
        const data = {
          signature,
          tokenId,
          account,
          isHonorary,
        }

        const state = btoa(JSON.stringify(data));

        window.location.href = OAUTH_REDIRECT + `&state=${state}`;
      } catch (e) {
        console.log(e);
        alert(`It looks like the wallet you're using does not support signing. Please try again with Metamask.`)
      }
    },
    handleRedirect,
  }
}