import { useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { BigNumber } from "bignumber.js";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useOfferContractMethods } from "../../hooks/useOfferContractMethods";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from "./ChimpSuccessModal";
import { useChimpsHook } from '../../hooks/useChimpsHook';
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import { Cross, Info } from "../../assets/svgs";
import { convertPriceToDecimal } from "../../utils/price";
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import styles from './ChimpModal.module.scss';
import { getChimpDisplayTitle } from "../../utils/title";
import { ROYALTY_PERCENTAGE } from "../../configs";
import { OfferChimpMeta } from "../../types/interfaces";

const AcceptOfferChimpModal = () => {
  const [trxnId, setTrxnId] = useState('');
  const [isShowAlertMessage, showAlertMessage] = useState<boolean>(false);

  const { active, account } = useWeb3React();
  const { step, closeModal, openModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { state: { forBuyOrSaleChimp, offerChimp, offers } } = useChimp();

  const { getChimpDetailById } = useChimpsHook();

  const { tokenId, image, isHonorary, name, owner } = forBuyOrSaleChimp;

  const { acceptOffer, getValidOffers } = useOfferContractMethods();

  useEffect(() => {
    showAlertMessage(false);
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep]);

  const handleAcceptOffer = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      if (Object.keys(offerChimp).length > 0) {
        const result = await acceptOffer(isHonorary, tokenId, offerChimp.offerIndex, offerChimp.amount);
        if (result.transactionHash) {
          setTrxnId(result.transactionHash);
          const promises = [getValidOffers(isHonorary, tokenId), getChimpDetailById(tokenId, isHonorary)];
          await Promise.all(promises).then(() => {
            updateOperationStep(ModalChimpStatus.SUCCEESS);
          });
        }
      }
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [updateOperationStep, offerChimp, acceptOffer, isHonorary, tokenId, getValidOffers, getChimpDetailById, onChangeWarnings]);

  const handleClick = useCallback(() => {
    if (!active) {
      openModal(ModalTypes.CONNECT_WALLET);
    } else {
      if (owner === account) {
        if (step === ModalChimpStatus.READY) {
          updateOperationStep(ModalChimpStatus.CONFIRM);
        }

        handleAcceptOffer();
      }
    }
  }, [step, active, owner, account, handleAcceptOffer, openModal, updateOperationStep]);

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;
    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;
    return ButtonStatus.NORMAL;
  }, [step]);

  const calcRoyalty = () => {
    const offerAmount = new BigNumber(offerChimp.amount);
    return offerAmount.multipliedBy(ROYALTY_PERCENTAGE);
  }

  const calcTotalEarnings = () => {
    const offerAmount = new BigNumber(offerChimp.amount);
    const totalEarnings = offerAmount.minus(calcRoyalty());
    return totalEarnings;
  }

  const renderButtonLabel = useMemo((): string => {
    const values: { [key: string]: string } = {
      [ModalChimpStatus.DISABLED]: 'Accept offer',
      [ModalChimpStatus.READY]: 'Accept offer',
      [ModalChimpStatus.CONFIRMING]: 'Confirming...',
      [ModalChimpStatus.FAILED]: 'Retry',
    };
    return values[step];
  }, [step]);

  const isHighestOffer = useMemo(() => {
    const highestOffer = offers.filter((item: OfferChimpMeta) => item.isHighestOffer === true);
    if (Object.keys(offerChimp).length > 0 && highestOffer.length > 0) {
      if (offerChimp.buyer !== highestOffer[0].buyer) {
        showAlertMessage(true);
      }
      return offerChimp.buyer === highestOffer[0].buyer;
    }
    return false;
  }, [offerChimp, offers]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {step !== ModalChimpStatus.SUCCEESS &&
        <div className={styles.container}>
          <Cross className={styles.cancel} onClick={closeModal} />
          <div className={styles.header}>
            <span className={styles.title}>Accept offer</span>
            <div className={styles.summary}>
              <img className={styles.img} src={image || ''} alt="" />
              <div>
                <div className={styles.chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
                <div className={styles.token_id}>Token ID: {tokenId}</div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.content_row}>
              <span className={styles.offer_label}>Offer price</span>
              <span className={styles.offer_value}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(offerChimp.amount), 3))} WCRO</span>
            </div>
            <div className={styles.content_row}>
              <span className={styles.offer_label}>Royalty ({ROYALTY_PERCENTAGE * 100}%)</span>
              <span className={styles.offer_value}>-{+formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(calcRoyalty().toString()), 3))} WCRO</span>
            </div>
            <div className={styles.offer_divider} />
            <div className={styles.content_row}>
              <span className={styles.offer_total_earnings}>Total earnings</span>
              <span className={styles.offer_total_value}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(calcTotalEarnings().toString()), 3))} WCRO</span>
            </div>
            {isShowAlertMessage && !isHighestOffer &&
              <div className={styles.alert}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  You are about to accept a lower offer price than what is available in the market. Please ensure that you have reviewed the amount before accepting.
                </span>
              </div>
            }
            <div className={styles.buttons}>
              <ProgressButton disabled={step === ModalChimpStatus.DISABLED} status={buttonStatus} onClick={handleClick}>
                {active ? renderButtonLabel : 'Connect Wallet'}
              </ProgressButton>
            </div>
            {step === ModalChimpStatus.CONFIRMING &&
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Please confirm the transaction in your wallet.
                </span>
              </div>
            }
          </div>
        </div>
      }
      {step === ModalChimpStatus.SUCCEESS &&
        <ChimpSuccessModal
          closeModal={closeModal}
          subTitle="Offer accepted"
          description={`Congratulation! You sold Chimp #${tokenId} for ${formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(calcTotalEarnings().toString()), 3))} CRO.`}
          trxnId={trxnId}
          type={ChimpOperateTypes.ACCEPT}
        />
      }
    </Modal>
  )
}

export default DisplayModal(AcceptOfferChimpModal, ModalTypes.ACCEPT_OFFER_CHIMP);