import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useWeb3React } from '@web3-react/core';
// import Slider from "react-slick";
// import ImageViewerModal from '../../components/modal/ImageViewerModal';
import AddToCartButton from '../../components/shop/AddToCartButton';
import Button from '../../components/button/ProgressButton';
import Dropdown from '../../components/dropdown/Dropdown';
import useChimpayHook from '../../hooks/useChimpayHook';
import { useMerchandiseHook } from '../../hooks/useMerchandiseHook';
import { useMerchandise } from '../../providers/MerchandiseProvider';
import { useUser } from '../../providers/UserProvider';
import { useChimpay } from '../../providers/ChimpayProvider';
import { roundNumberWithFix } from '../../utils/number';
import { getProductImage, getMeasurementByUnit, getMerchCountInCart } from '../../utils/shop-merch';
import { RemainingSupplyProps } from '../../types/interfaces';
import { Arrow } from '../../assets/svgs';
import styles from './ShopDetails.module.scss';
import { MerchPaymentTypes } from '../../types/enums';

type TableCellProps = {
  colspan: number;
  value: string | number;
}

const MEASURE_UNITS = ['inches', 'cm'] as const;

const ShopDetails: React.FC<{}> = () => {
  const { account } = useWeb3React();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { getMerchDetail, getQuotaDetail, getProductRemainingSupplies } = useMerchandiseHook();
  const { state: { merchandiseDetail, quotaDetail }, resetMerchandiseDetail } = useMerchandise();
  const { state: { orderCart } } = useUser();
  const { state: { availableBananas } } = useChimpay();
  const { isRedemptionPeriod } = useChimpayHook();

  const [quantity, setQuantity] = useState<string>('1');
  const [selectSize, setSelectSize] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>(MerchPaymentTypes.USDC);
  const [measureUnit, setMeasureUnit] = useState<typeof MEASURE_UNITS[number]>(MEASURE_UNITS[0]);

  // const [displayImage, setDisplayImage] = useState<number>(0);
  // const [isOpenImageView, setOpenImageView] = useState<boolean>(false);
  const [remainingSupplies, setRemainingSupplies] = useState<RemainingSupplyProps>({ remainingTotal: 0, subProducts: {} });

  const { contractId, title, price, currency, bananaPrice, variantOptions1, variantOptions2 } = merchandiseDetail;

  useEffect(() => {
    if (account) {
      getQuotaDetail(Number(params.id), String(account));
    }
  }, [getQuotaDetail, params.id, account]);

  useEffect(() => {
    if (params.id) {
      getMerchDetail(Number(params.id))
    }
    return () => {
      resetMerchandiseDetail();
    };
  }, [getMerchDetail, params.id, resetMerchandiseDetail]);

  useEffect(() =>{
    const getRemainingSupplies = async () => {
      if (params.id) {
        const remaining = await getProductRemainingSupplies(Number(params.id));
        if (remaining) {
          setRemainingSupplies(remaining);
        }
      }
    }
    getRemainingSupplies();
  },[getProductRemainingSupplies, params.id]);

  const onChangeQuantity = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (/^[1-9]{0,1}$/.test(value)) {
      setQuantity(value);
    }
  }, [])

  const onSelectSize = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    const size = e.currentTarget.id;
    setSelectSize(size);
  }, []);

  // const onOpenImage = useCallback((index: number) => {
  //   setDisplayImage(index)
  //   setOpenImageView(true)
  // }, []);

  const subProducts = useMemo(() => {
    if (!variantOptions1) return [];

    return variantOptions1.map((size) => {
      return {
        name: size,
        remaining: remainingSupplies.subProducts[size] || 0,
      }
    })
  }, [variantOptions1, remainingSupplies.subProducts])

  const paymentTypes = useMemo(() => {
    const types = [
      {
        text: `${roundNumberWithFix(price, 3)} ${currency}`,
        value: currency || MerchPaymentTypes.USDC
      }
    ];

    if (isRedemptionPeriod()) {
      types.push({
        text: `${roundNumberWithFix(bananaPrice, 3)} Bananas`,
        value: MerchPaymentTypes.BANANAS,
      })
    }
    return types;
  }, [currency, price, bananaPrice, isRedemptionPeriod]);

  const paymentPrice = useMemo(() => {
    return {
      [currency || MerchPaymentTypes.USDC]: price,
      [MerchPaymentTypes.BANANAS]: bananaPrice,
    }[paymentType];
  }, [currency, price, bananaPrice, paymentType]);

  const countInCart = useMemo(() => getMerchCountInCart(orderCart, contractId), [orderCart, contractId]);

  const maxLimit = useMemo(() => {
    let productSupply = remainingSupplies.remainingTotal;

    if (Object.keys(remainingSupplies.subProducts).length > 0) {
      const subProductsRemain = remainingSupplies.subProducts[selectSize]

      if (subProductsRemain) {
        productSupply = subProductsRemain;
      }
    }

    const availableQuota = quotaDetail.length > 0 ? quotaDetail[0].availableQuota : 0;

    return Math.min(productSupply, availableQuota);
  }, [quotaDetail, selectSize, remainingSupplies]);

  const invalidQuantity = useMemo(() => {
    if (!account) return false;

    return +quantity > maxLimit - countInCart;
  }, [account, quantity, maxLimit, countInCart]);

  const sizeUnselected = useMemo(() => {
    return variantOptions1 && variantOptions1.length > 0 && !selectSize;
  }, [variantOptions1, selectSize]);

  const insufficientBananas = useMemo(() => {
    const cost = bananaPrice * +quantity;
    return paymentType === MerchPaymentTypes.BANANAS && cost > availableBananas;
  }, [availableBananas, paymentType, bananaPrice, quantity]);

  const isSoldOut = useMemo(() => remainingSupplies.remainingTotal === 0, [remainingSupplies.remainingTotal]);

  const measurements = useMemo(() => {
    if (contractId && variantOptions2 && contractId === 'bundle') {
      const details = [
        ['Chest'],
        ['Waist'],
        [
          {
            colspan: 1,
            value: 'Socks',
          },
          {
            colspan: 3,
            value: measureUnit === 'cm' ? 'EU 40-44' : 'US 7-10',
          }, {
            colspan: 2,
            value: measureUnit === 'cm' ? 'EU 44-48' : 'US 10-13',
          }
        ]
      ] as (string | TableCellProps)[][]; 

      return variantOptions2.reduce((accum, current) => {
        const [name, chest, waist] = current.split(',');

        const chestSize = getMeasurementByUnit(chest, measureUnit);
        const waistSize = getMeasurementByUnit(waist, measureUnit);

        if (name === 'L') {
          accum[0].push(chestSize, chestSize);
          accum[1].push(waistSize, waistSize);
        } else {
          accum[0].push(chestSize);
          accum[1].push(waistSize);
        }

        return accum;
      }, details);
    }
    return null;
  }, [contractId, variantOptions2, measureUnit])

  return (
    <div className={styles.container}>
      <div className={styles.back} onClick={() => navigate(-1)}>
        <Arrow style={{ cursor: 'pointer' }} />
        <span className={styles.back_label}>Back to Shop</span>
      </div>
      <div className={styles.product_wrap}>
        <div className={styles.product_image}>
          {getProductImage(contractId).map((item: string, index: number) => (
            <div key={index} className={styles.product_image_item}>
              <img src={item} alt="" />
            </div>
          ))}
        </div>
        <div className={styles.product_detail}>
          <div className={styles.product_name}>{title}</div>
          {subProducts.length > 0 &&
            <div className={styles.product_size}>
              <span className={styles.form_label}>Select size</span>
              <div className={styles.product_size_options}>
                {subProducts.map(({ name, remaining }) => (
                  <span 
                    id={name} 
                    key={name} 
                    className={classNames(styles.size_tab, {
                      [styles.size_tab_active]: name === selectSize,
                      [styles.size_tab_disabled]: remaining === 0,
                    })}
                    onClick={remaining > 0 ? onSelectSize : undefined}
                  >
                    {name}
                  </span>
                ))}
              </div>
            </div>
          }
          <div className={styles.product_quantity}>
            <label className={styles.form_label} htmlFor="quantity">
              Quantity (Maximum Limit: {Math.max(maxLimit - countInCart, 0)})
            </label>
            <input 
              id="quantity" 
              type="number" 
              min={0} 
              max={5} 
              value={quantity} 
              onChange={onChangeQuantity} 
              className={classNames(styles.quantity_input, {
                [styles.quantity_input_invalid]: invalidQuantity
              })}
            />
            {
              remainingSupplies.remainingTotal < 20 &&
              <label className={styles.form_warning}>{remainingSupplies.remainingTotal} pieces remaining</label>
            }
          </div>
          <div className={styles.product_payment}>
            <label className={styles.form_label} htmlFor="quantity">
              Pay with
            </label>
            <Dropdown
              className={styles.payment_dropdown}
              select={paymentType}
              onSelect={setPaymentType}
              options={paymentTypes}
            />
            {
              insufficientBananas && 
              <label className={classNames(styles.form_warning, styles.form_warning_red)}>Insufficient Bananas</label>
            }
          </div>

          {isSoldOut ?
            <Button onClick={() => undefined} disabled={true}>
              Sold Out
            </Button> : 
            <AddToCartButton 
              item={{
                ...merchandiseDetail,
                currency: paymentType,
                price: paymentPrice,
              }} 
              size={selectSize} 
              quantity={quantity} 
              disabled={invalidQuantity || insufficientBananas || sizeUnselected} 
              maxLimit={maxLimit}
            /> 
          }
          {
            measurements && (
              <div className={styles.measurements}>
                <div className={styles.measurements_header}>
                  <label className={styles.form_label} style={{ marginBottom: 0 }}>Measurements</label>

                  <div className={styles.measurements_switch}>
                    {
                      MEASURE_UNITS.map((unit) => (
                        <span 
                          className={classNames(styles.switch_tab, {
                            [styles.switch_tab_active]: measureUnit === unit, 
                          })} 
                          key={unit}
                          onClick={() => setMeasureUnit(unit)}
                        >
                          {unit}
                        </span>
                      ))
                    }
                  </div>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Size</th>
                      {variantOptions1.map((size: string) => (
                        <th key={size}>{size}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(measurements).map((row: any) => (
                        <tr key={row}>
                          {
                            (measurements as any)[row].map((cell: any, index: number) => (
                              <td 
                                key={index} 
                                colSpan={typeof cell === 'string' ? 1 : cell.colspan}
                              >
                                {typeof cell === 'string' ? cell : cell.value}
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </div>
      {/* <ImageViewerModal
        data={[imageUrl]}
        currentIndex={displayImage}
        isOpen={isOpenImageView}
        closeModal={() => setOpenImageView(false)}
      /> */}
    </div>
  );
}

export default ShopDetails;