import Footer from "../../components/footer/Footer";
import styles from "./TermOfUse.module.scss";

const TermOfUse = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Terms and Conditions</div>
        <div className={styles.content}>
          <p>
            Chimp Labs Pte. Ltd. (“Chimp Labs”) provides its services (as described below) and related content and functionality 
            to you (“you” or the “user”) through its website and platform located at cronoschimp.club (the “Site”), 
            subject to these Terms and Conditions, as amended from time to time (the “Terms”). 
            All additional terms, guidelines, and rules as set forth on the Site are incorporated by reference into these Terms 
            and expressly agreed to and acknowledged by you. These terms govern your access to and use of the Site, 
            as well as any content, functionality, and services offered on or through the Site, 
            including [Work Assignments] and Chimpay (each as defined and discussed in more detail below, collectively the “Services”) 
            and non-fungible tokens (“NFTs”) made available by us or by other users via the Services 
            (e.g., through the peer-to-peer application accessible through the Site enabling users to buy and sell NFTs peer-to-peer (the “Marketplace”)).         
          </p>
          <p>
            By connecting your third-party crypto asset wallet (e.g., through MetaMask or WalletConnect) to the Site and accessing the Services, 
            or otherwise using and accessing the Services and/or NFTs (including as the owner of any NFT), 
            you acknowledge that you have read and agree to these Terms. 
          </p>
          <p>
            THESE TERMS SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE AND SERVICES. 
            BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), 
            AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT). 
            YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD OR ARE OTHERWISE UNABLE TO BE LEGALLY BOUND BY THESE TERMS. 
            IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.
          </p>
          <p>
            WE MAY UPDATE THE TERMS FROM TIME TO TIME AT OUR SOLE DISCRETION. IF WE DO, WE’LL LET YOU KNOW BY POSTING THE UPDATED TERMS ON THE SITE. 
            IT’S IMPORTANT THAT YOU REVIEW THE TERMS WHENEVER WE UPDATE THEM OR YOU ACCESS THE SITE. 
            IF YOU CONTINUE TO USE THE SERVICES AFTER WE HAVE POSTED UPDATED TERMS, IT MEANS THAT YOU ACCEPT AND AGREE TO THE CHANGES. 
            IF YOU DO NOT AGREE, YOU MAY NOT USE THE SERVICES ANYMORE. BECAUSE OUR SERVICES MAY EVOLVE OVER TIME, 
            WE MAY CHANGE OR DISCONTINUE ALL OR ANY PART OF THE SERVICES, AT ANY TIME AND WITHOUT NOTICE, AT OUR SOLE DISCRETION.
          </p>
          <p>
            THESE TERMS REQUIRE THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, 
            AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.
          </p>

          <br />
          <b>Ⅰ. Access to the Site</b>

          <br /><br />
          1. COMPLIANCE
          <br />
          <p>
            The Services are only available to users in jurisdictions who can use the Services as permitted under applicable law. 
            You certify that you will comply with all applicable laws (e.g., federal, state, local, international, and other laws) when using the Services. 
            Without limiting the foregoing, by using the Services, you represent and warrant that you are not:
          </p>
          <p className={styles.p_level2}>
            (a)	the subject of economic or trade sanctions administered or enforced by any governmental authority 
            or otherwise designated on any list of prohibited or restricted parties 
            (including but not limited to the list maintained by the Office of Foreign Assets Control of the U.S. 
            Department of the Treasury, “OFAC”); or
            </p>
          <p className={styles.p_level2}>
            (b)	a citizen, resident, or organized in a jurisdiction or territory that is the subject 
            of comprehensive country-wide, territory-wide, or regional economic sanctions by the United States.
          </p>
          <p>
            We reserve the right to utilize software or other means to restrict access to the Site 
            for compliance purposes with no advance warning, including, but not limited to, 
            restricting access to any individual or entity on the Specially Designated Nationals 
            and Blocked Persons (“SDN”) List maintained by OFAC. We are under no obligation to make such restrictions, 
            or the rationale underlying such restrictions, public. If we determine in our sole and absolute discretion 
            that you have breached your representations and/or obligations under this section, 
            we will block your access to the Site as well as any interests in property as required 
            by applicable laws and regulations if continued access to the Site could result in us being in violation, 
            or subject to negative consequences, under any sanctions laws or other law or regulation.
          </p>
          2. LICENSE
          <p>
            Chimp Labs and its affiliates own all intellectual property and other rights in the Site and its contents. 
            Subject to these Terms, Chimp Labs grants you a non-transferable, non-exclusive, revocable, 
            limited license to use and access the Site solely for your own personal, noncommercial use.
          </p>
          3. CERTAIN RESTRICTIONS. 
          <p>
            The rights granted to you in these Terms are subject to the following restrictions: 
            (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, 
            or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; 
            (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; 
            (c) you shall not access the Site in order to build a similar or competitive website, product, or service; and 
            (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted 
            or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, 
            or other addition to functionality of the Site shall be subject to these Terms. All copyright and other proprietary notices on the Site 
            (or on any content displayed on the Site) must be retained on all copies thereof.
          </p>
          4. MODIFICATION. 
          <p>
            Chimp Labs reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you. 
            You agree that Chimp Labs will not be liable to you or to any third party for any modification, suspension, 
            or discontinuation of the Site or any part thereof.
          </p>
          5. NO SUPPORT OR MAINTENANCE. 
          <p>
            You acknowledge and agree that Chimp Labs will have no obligation to provide you with any support or maintenance in connection with the Site.
          </p>
          6. OWNERSHIP. 
          <p>
            You acknowledge that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, 
            in the Site and its content, are owned by Chimp Labs or Chimp Labs’ suppliers. Neither these Terms (nor your access to the Site) 
            transfers to you or any third party any rights, title or interest in or to such intellectual property rights, except for the rights expressly set forth in Section III. 
            Chimp Labs and its suppliers reserve all rights not granted in these Terms. There are no implied licenses granted under these Terms.
          </p>
          <p>
            Visa, Amazon, Apple, Razer, Google Play, and other unaffiliated companies and/or brands whose sites and/or logos 
            may be displayed on the Site or linked through Chimpay rewards programs are not sponsors of the Company, 
            including the Chimpay rewards program, or otherwise affiliated with the Company or the Site in any way. 
            The logos and other identifying marks displayed on the Site and/or through third-party providers are trademarks of and 
            owned by each respective company and/or its affiliates. Please visit each company's website for the specific terms and conditions 
            associated with those companies’ brands and products.
          </p>

          <br />
          <b>Ⅱ. Use of the Site and Services</b>
          <p>
            Our Services facilitate interactions with protocols and public blockchains to 
            allow individuals to acquire NFTs offered by other users via the Marketplace. 
            Chimp Labs provides the Site, which is an interface to interact with smart contracts. 
          </p>
          <p>
            You may participate in the Services by linking your digital wallet(s) on supported bridge extensions, 
            which allows you to purchase, store, and engage in transactions. 
            Chimp Labs does not provide any custodial or other services with respect to your assets. 
            The Site assists the user in constructing data inputs for blockchain transactions, 
            which are then transmitted to the user’s digital wallet(s). 
            The user may utilize that data to broadcast transactions to the blockchain network.
          </p>
          <p>
            CHIMP LABS IS NOT A BROKER, FINANCIAL INSTITUTION, UNDERWRITER, OR CREDITOR. THE SERVICES ARE ADMINISTRATIVE ONLY. 
            WE FACILITATE TRANSACTIONS BETWEEN THE PURCHASER AND SELLER ON THE MARKETPLACE BUT ARE NOT A PARTY TO 
            ANY AGREEMENT BETWEEN THE PURCHASER AND SELLER OF NFTS OR BETWEEN ANY USERS.
          </p>
          <p>
            While we offer the Marketplace, we do not buy, sell, or take custody or possession of any NFTs offered to users on the Marketplace, 
            nor does it act as an agent or custodian for any user of the Services or any seller. 
            Instead, transactions on the Marketplace are conducted utilizing blockchain-based smart contracts deployed by us. 
            Each NFT is transferred automatically upon consummation of a transaction through the relevant blockchain network. 
            If you elect to engage in a transaction on the Site, such transaction(s) will be conducted solely 
            through the relevant blockchain network governing such NFT. We may not have the ability to reverse any transactions. 
            Accordingly, we will have no liability to you or to any third party for any claims or damages 
            that may arise as a result of any transactions that you engage in via the Services.
          </p>
          1. FEES
          <p>
            Chimp Labs collects a fee for transactions executed by users via the Marketplace. 
            In addition, interactions with the relevant blockchain may also result in transaction fees 
            imposed by the blockchain, which are solely the user’s responsibility.
          </p>
          2. TAXES
          <p>
            You are solely responsible for all costs incurred by you in using the Services and determining, collecting, 
            reporting, and paying all applicable taxes. You are further solely responsible for independently 
            maintaining the accuracy of any record submitted to any tax authority including any information 
            derived from the Services. We reserve the right to report any activity occurring using the Services 
            to relevant tax authorities as required under applicable law.
          </p>

          <br />
          <b>Ⅲ. Cronos Chimp Club NFTs</b>
          <p>
            Cronos Chimp Club is a collection of digital artworks (the “Art”, each a “Chimp”) represented by NFTs existing on the Cronos network. 
            This website is only an interface allowing participants to exchange NFT-based collectibles. 
            Users are entirely responsible for the safety and management of their own private Cronos wallets and 
            validating all transactions and contracts generated by this website before approval. 
            Furthermore, as the Cronos Chimp Club smart contract runs on the Cronos network, 
            there is no ability to undo, reverse, or restore any transactions.
          </p>
          <p>
            This website and its connected services are provided “as is” and “as available” without warranty of any kind. 
            By using this website you are accepting sole responsibility for any and all transactions involving Cronos Chimp Club NFT collectibles.
          </p>
          <p>
            <u>You Own the NFT.</u> Each Chimp is represented by an NFT on the Cronos blockchain. 
            When you lawfully purchase or otherwise lawfully acquire an NFT, you own the NFT completely. 
            Ownership of the NFT is mediated entirely by the Smart Contract and the Cronos network: 
            at no point may we seize, freeze, or otherwise modify the ownership of any Chimp.
          </p>
          <p>
            <u>Personal Use.</u> Subject to your continued compliance with these Terms, 
            Chimp Labs grants any person who lawfully holds exclusive title to and ownership of an NFT 
            (or, if jointly owned, with the permission of any such co-owner) a worldwide, 
            royalty-free license to use, copy, and display the Art associated with such NFT, 
            along with any extensions that you choose to create or use, solely for the following purposes: 
            (i) for your own personal, non-commercial use; 
            (ii) displayed within a marketplace that permits the purchase and sale of your Chimp NFT, 
            provided that the marketplace cryptographically verifies each Chimp NFT owner’s rights 
            to display the Art for their Chimp to ensure that only the actual owner can display the Art for sale; or 
            (iii) as part of a third party website or application that permits the inclusion, involvement, display, 
            or participation of your Chimp, provided that the website/application cryptographically 
            verifies each Chimp owner’s rights to display the Art for their Chimp to ensure 
            that only the actual owner can display the Art, and provided that the Art is no longer visible 
            once the owner of the Chimp leaves the website/application. 
            In the event of a Cronos chain fork or the Chimps become otherwise portable to a chain other than the Cronos network, 
            the scope of the rights granted under this paragraph shall be deemed expanded to include each person 
            who lawfully holds exclusive title to and ownership of the copies of such NFTs that are included on such other networks.
          </p>
          <p>
            <u>Commercial Use.</u> Subject to your continued compliance with these Terms, Chimp Labs grants you an unlimited, 
            worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works 
            based upon the Art (“Commercial Use”). Examples of such Commercial Use would be the use of the Art to produce 
            and sell merchandise (such as apparel) displaying copies of the Art. For the sake of clarity, 
            nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace 
            that permits the use and sale of Chimp NFTs generally, provided that the marketplace cryptographically 
            verifies each Chimp NFT owner’s rights to display the Art for their Chimp to ensure that only the actual owner 
            can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, 
            involvement, display, or participation of Chimps generally, provided that the third party website or application 
            cryptographically verifies each Chimp owner’s rights to display the Art for their Chimp to ensure 
            that only the actual owner can display the Art, and provided that the Art is no longer visible 
            once the owner of the purchased Chimp leaves the website/application; or (iii) earning revenue from any of 
            the foregoing. In the event of a Cronos chain fork or the Chimps become otherwise portable to a chain 
            other than the Cronos network, the scope of the rights granted under this paragraph shall be deemed expanded 
            to include each person who lawfully holds exclusive title to and ownership of the copies of such NFTs 
            that are included on such other networks.
          </p>
          <p>
            <u>Cronos Chimp IP.</u> Other than the rights to the Art, nothing herein gives you any rights 
            to any other trademarks or other intellectual property rights belonging to Chimp Labs including, 
            without limitation, to Cronos Chimp Club, CCC, and the associated logos. 
            All of these rights are expressly reserved in the name of Chimp Labs.
          </p>
          <p>
            <u>Feedback.</u> You may choose to submit comments, bug reports, ideas or other feedback about the Site, 
            including without limitation about how to improve the Site (collectively, “Feedback”). 
            By submitting any Feedback, you agree that we are free to use such Feedback in any way we choose 
            without additional compensation to you and you hereby grant us a perpetual, irrevocable, nonexclusive, 
            worldwide license to incorporate and use the Feedback for any purpose.
          </p>
          
          <br />
          <b>Ⅳ. CHIMPAY; WORK ASSIGNMENTS</b>
          <p>
            Placing your Chimp NFT on Work Assignment by staking your Chimp NFT to earn Chimpay rewards is at your election and, without limiting anything else in these Terms, is subject to the following:
          </p>
          <p className={styles.p_level2}>
            1. Work Assignments are directed at your own risk, and Chimp Labs accepts no responsibility 
            for smart contract risk associated with such Work Assignments.
          </p>
          <p className={styles.p_level2}>
            2. Directing a Chimp NFT to a Work Assignment and recalling a Chimp NFT 
            from a Work Assignment is done through the Site.
          </p>
          <p className={styles.p_level2}>
            3. We reserve the right to send a Chimp NFT back from a work assignment if that Chimp NFT 
            is listed for sale on the Marketplace or in the event that such Chimp NFT is transferred, 
            or in the case of violating these Terms.
          </p>
          <p className={styles.p_level2}>
            4. Chimpay rewards (“Bananas”) are unique to each Chimp NFT holder’s address and are non-transferrable. 
            Users will retain any Bananas earned, even if their Chimp has been transferred.
          </p>
          <p className={styles.p_level2}>
            5. We are not responsible for attacks or transfers that occur when using third-party applications 
            for Work Assignments or other staking websites. You represent that you are aware you are using 
            any such functionality at your own risk.
          </p>
          <p className={styles.p_level2}>
            6. In the event you lose any Bananas for any reason, you will have no right to any payment 
            or other consideration for any Bananas lost.
          </p>
          <p className={styles.p_level2}>
            7. We only offer Bananas for Work Assignments directed through the Site. 
            We undertake no responsibility to support or honor Bananas that may be earned on 
            or through other sites or service providers.
          </p>
          <p className={styles.p_level2}>
            8. Chimpay program benefits and rewards, whether physical or digital, 
            may be revoked by us at any time for any reason. We may be forced to suspend, discontinue, terminate, 
            or change aspects of Work Assignments and/or Bananas in any jurisdiction, 
            without notice if demanded by regulatory or applicable law or for whatever 
            other reason in our sole and absolute discretion. 
            In such a case, your Chimp NFT could be frozen for an indefinite period of time 
            until the matter is resolved or we may terminate such features and/or eliminate certain rewards.
          </p>
          <p className={styles.p_level2}>
            9. If you intend to sell your Chimp NFT, your Chimp NFT may be recalled from its Work Assignment 
            at any time through the Site. There is no minimum time period for a Work Assignment. 
            Once recalled, the Chimp NFT will be free to sell.
          </p>
          <p className={styles.p_level2}>
            10. Bananas have a limited shelf life. Bananas may be earned by Chimp NFTs on Work Assignment 
            during a set period of time designated by Chimp Labs (a “Season”). 
            Bananas may be spent via the ChimpPay program at any time during the season. 
            At the conclusion of the Season, Bananas will stay ripe for a two-week period, 
            during which any Bananas earned during that season will remain available for use in the Chimpay program. 
            At the conclusion of that two-week period, Bananas will be rotten and must be discarded; 
            once rotten, the user’s Banana balance will revert to zero. The user can then earn more Bananas 
            during the next Season. Chimp Labs will offer no compensation for any rotten Bananas. 
            The risk of losing any Bananas to rot is solely borne by the user.
          </p>
          <p>
            This section is not exhaustive and does not disclose all potential risks 
            associated with Work Assignments and Chimpay.
          </p>
          <p>
            The Chimp Pay program is administered by us at our sole and absolute discretion. 
            We are under no obligation to provide any benefits to participants. 
            Chimpay rewards are non-transferable, non-tradeable, and have no monetary value. 
            Chimpay rewards are tracked solely through the ChimpPay interface available on the Site. 
            Chimpay rewards accrued by a specific user can be viewed by connecting a browser-based wallet 
            to the Site and viewing the accrued rewards through the UI. 
          </p>
          <p>
            The ability to redeem Chimpay rewards, as well as the products, items, and/or experiences Chimpay rewards 
            may be redeemed for, are subject to change at Chimp Labs’ discretion. 
            The Chimpay rewards program may utilize third-party platforms to administer redemptions of rewards, 
            including services such as Reloadly API. Chimp Labs will not be responsible for any losses experienced 
            by the user related to failure of such services. To the extent any rewards claimed via the Chimpay program 
            are physical goods, the user may be required to pay shipping, handling, and related charges. 
            Any such charges shall be subject to third-party provider terms and conditions.
          </p>

          <br />
          <b>Ⅴ. INDEMNIFICATION</b>
          <p>
            You agree to indemnify and hold Chimp Labs (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, 
            from any claim or demand made by any third party due to or arising out of 
            (a) your use of the Site, 
            (b) your violation of these Terms or 
            (c) your violation of applicable laws or regulations. Chimp Labs reserves the right, 
            at your expense, to assume the exclusive defense and control of any matter 
            for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. 
            You agree not to settle any matter without the prior written consent of Chimp Labs. 
            Chimp Labs will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
          </p>

          <br />
          <b>Ⅵ. THIRD PARTY LINKS & ADS; OTHER USERS</b>
          <br /><br />
          1. THIRD-PARTY LINKS & ADS
          <p>
            The Site may contain links to third-party websites and services, and/or display advertisements 
            for third parties (collectively, “Third-Party Links & Ads”). 
            Such Third-Party Links & Ads are not under the control of Chimp Labs, 
            and Chimp Labs is not responsible for any Third-Party Links & Ads. Chimp Labs provides access 
            to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, 
            or make any representations with respect to Third-Party Links & Ads. You use all Third-Party Links & Ads 
            at your own risk, and should apply a suitable level of caution and discretion in doing so. 
            When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, 
            including the third party’s privacy and data gathering practices. You should make whatever investigation 
            you feel necessary or appropriate before proceeding with any transaction in connection with 
            such Third-Party Links & Ads.
          </p>
          2. OTHER USERS
          <p>
            Your interactions with other Site users are solely between you and such users. 
            You agree that Chimp Labs will not be responsible for any loss or damage incurred as the result of 
            any such interactions. If there is a dispute between you and any Site user, 
            we are under no obligation to become involved.
          </p>
          3. RELEASE
          <p>
            You hereby release and forever discharge Chimp Labs (and our officers, employees, agents, successors, and assigns) 
            from, and hereby waive and relinquish, each and every past, present and future dispute, 
            claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature 
            (including property damage), that has arisen or arises directly or indirectly out of, 
            or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, 
            other Site users or any Third-Party Links & Ads). 
            IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, 
            WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT 
            TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM 
            OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
          </p>

          <br />
          <b>Ⅶ. DISCLAIMERS</b>
          <br />
          <p>
            THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, 
            AND CHIMP LABS (AND OUR SUPPLIERS AND SERVICE PROVIDERS) EXPRESSLY DISCLAIMS ANY 
            AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, 
            INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
            TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. 
            WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, 
            WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, 
            OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. 
            IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, 
            ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE. 
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, 
            SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS 
            ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
          </p>
          <p>
            WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, 
            AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF THE SERVICES, 
            INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, 
            OR CLAIMS ARISING FROM: 
            (I) USER ERROR SUCH AS INCORRECTLY CONSTRUCTED TRANSACTIONS OR MISTYPED WALLET ADDRESSED; 
            (II) SERVER FAILURE OR DATA LOSS; 
            (III) BLOCKCHAIN NETWORKS, CRYPTOCURRENCY WALLETS, OR CORRUPT FILES; 
            (IV) UNAUTHORIZED ACCESS TO SERVICES; 
            (V) ANY THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTE FORCING, 
              OR OTHER MEANS OF ATTACH; OR 
            (VI) THE FAILURE OF ANY THIRD-PARTY SERVICE, INCLUDING (BUT NOT LIMITED TO) THOSE SERVICES FACILITATING CHIMPAY.
          </p>
          <p>
            BLOCKCHAIN BASED ASSETS EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN 
            THE APPLICABLE BLOCKCHAIN NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET 
            OCCURS ON THE DECENTRALIZED LEDGER WITHIN SUCH BLOCKCHAIN NETWORK. 
            WE DO NOT GUARANTEE THAT WE CAN EFFECTUATE THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS OR OTHER DIGITAL ASSETS, 
            OR THAT ANY ASSOCIATED PAYMENT WILL BE SUCCESSFUL.
          </p>
          <p>
            THE SERVICES MAY NOT BE AVAILABLE DUE TO ANY NUMBER OF FACTORS INCLUDING, BUT NOT LIMITED TO, 
            PERIODIC SYSTEM MAINTENANCE (SCHEDULED OR UNSCHEDULED), ACTS OF GOD, UNAUTHORIZED ACCESS, VIRUSES, 
            DENIAL OF SERVICE OR OTHER ATTACKS, TECHNICAL FAILURE OF THE SERVICES AND/OR 
            TELECOMMUNICATIONS INFRASTRUCTURE DISRUPTION, AND THEREFORE WE EXPRESSLY DISCLAIM ANY EXPRESS 
            OR IMPLIED WARRANTY REGARDING THE USE AND/OR AVAILABILITY, ACCESSIBILITY, SECURITY, 
            OR PERFORMANCE OF THE SERVICES CAUSED BY SUCH FACTORS. 
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, 
            SOME OF THE ABOVE DISCLAIMERS OF WARRANTIES MAY NOT APPLY TO YOU.
          </p>

          <br />
          <b>Ⅷ. LIMITATION OF LIABILITY</b>
          <p>
            YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, 
            INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR, 
            HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, 
            ANY LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION, 
            LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, 
            EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </p>
          <p>
            YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY AND ALL CLAIMS ARISING OUT OF 
            OR RELATING TO THESE TERMS OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE) 
            ANY PORTION OF THE SITE, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL THEORY, 
            IS LIMITED TO THE GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE TERMS IN THE 
            12 MONTH PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (B) $500.
          </p>
          <p>
            YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AVAILABLE TO YOU AND ENTERED INTO 
            THESE TERMS IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN. 
            WE WOULD NOT BE ABLE TO PROVIDE THE SITE TO YOU WITHOUT THESE LIMITATIONS.
          </p>

          <br />
          <b>Ⅸ. RISK ASSUMPTION</b>
          <br /><br />
          You accept and acknowledge each of the following:
          <p className={styles.p_level2}>
            1. To the extent that you plan to sell your Chimp NFT, please be aware that the prices of NFTs are extremely 
            volatile and fluctuations in the prices of other NFTs may impact the price of your Chimp both positively and negatively. 
            Given this volatility, NFTs such as Chimps should not be considered investable or an investment. 
            Once purchased, you acknowledge there is no expectation whatsoever that you will be able to sell the Chimp in the future, 
            nor is there an expectation that a market will exist for Chimps in perpetuity.
          </p>
          <p className={styles.p_level2}>
            2. Ownership of a Chimp confers ownership of digital artwork for collectible purposes only. 
            Accordingly, no information on this Site (or any other documents mentioned therein) is or may be considered to be advice, 
            solicitation, or an invitation to enter into an agreement for any investment purpose. 
            Further, nothing on this Site is intended to be an offering of securities in any jurisdiction 
            nor does it constitute an offer or an invitation to purchase shares, securities or other financial products. 
            Due to the artistic and collectible nature of the project, Chimps have not been registered with any regulator 
            in any jurisdiction. It remains your sole responsibility to assure that the purchase of the Chimp and 
            the associated art is in compliance with laws and regulations in your jurisdiction.
          </p>
          <p className={styles.p_level2}>
            3. We do not store, send, or receive digital assets, as such assets exist only by virtue of the ownership 
            record maintained on the supported blockchain. Transactions in NFTs may be irreversible and, accordingly, 
            losses due to fraudulent and accidental transactions may not be recoverable. 
          </p>
          <p className={styles.p_level2}>
            4. You assume all risks associated with using an Internet-based currency, including, but not limited to, 
            the risk of hardware, software and Internet connections, the risk of malicious software introduction, 
            and the risk that third parties may obtain unauthorized access to information stored within your wallet.
          </p>
          <p className={styles.p_level2}>
            5. NFTs, cryptocurrencies and blockchain technology are relatively new and the regulatory landscape is unsettled. 
            New regulations could negatively impact such technologies impacting the value for your Chimp. 
            You understand and accept all risks in that regard.
          </p>
          <p className={styles.p_level2}>
            6. By accessing and using the Site, you represent that you understand the inherent risks associated 
            with using cryptographic and blockchain-based systems, and that you have a working knowledge of digital assets. 
            Such systems may have vulnerabilities or other failures, or other abnormal behavior. 
            Chimp Labs is not responsible for any issues with blockchains, including forks, technical node issues, 
            or any other issues causing losses. You acknowledge that the cost and speed of transacting with cryptographic 
            and blockchain-based systems such as Ethereum are variable and may increase at any time. 
            You further acknowledge that we are not responsible for any of these variables or risks 
            and cannot be held liable for any resulting losses that you experience when accessing the Site. 
            Accordingly, you understand and agree to assume full responsibility for all the risks of accessing 
            and using and interacting with the Site.
          </p>


          <br />
          <b>Ⅹ. LIMITATION ON LIABILITY</b>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL CHIMP LABS (OR OUR SUPPLIERS OR SERVICE PROVIDERS) 
            BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT 
            OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, 
            SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, 
            OR INABILITY TO USE, THE SITE, EVEN IF CHIMP LABS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
            ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, 
            AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, 
            OR LOSS OF DATA RESULTING THEREFROM.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, 
            OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT 
            (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), 
            WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). 
            THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. 
            YOU AGREE THAT OUR SUPPLIERS AND SERVICE PROVIDERS WILL HAVE NO LIABILITY OF ANY KIND ARISING 
            FROM OR RELATING TO THIS AGREEMENT.
          </p>
          <p>
            CHIMP LABS DOES NOT EXCLUDE OR LIMIT IN ANY WAY OUR LIABILITY TO YOU WHERE IT WOULD BE UNLAWFUL 
            TO DO SO. THIS INCLUDES LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY CHIMP LABS’ NEGLIGENCE 
            OR THE NEGLIGENCE OF CHIMP LABS’ EMPLOYEES, AGENTS OR SUBCONTRACTORS AND FOR FRAUD 
            OR FRAUDULENT MISREPRESENTATION.
          </p>
          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL 
            OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>

          <br />
          <b>Ⅺ. DISPUTE RESOLUTION; ARBITRATION</b>
          <p>
            Any dispute arising out of or in connection with these Terms, including without limitation your access 
            or use of the Site, or any of the products sold or distributed through the Site, 
            shall be referred to and finally resolved by arbitration administered by the Singapore International 
            Arbitration Centre (“SIAC”) in accordance with the Arbitration Rules of the Singapore International 
            Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated 
            by reference in this clause.
          </p>
          <p>
            WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE TERMS, 
            INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE SITE OR ANY PRODUCTS SOLD 
            OR DISTRIBUTED THROUGH THE SITE, OR THE SMART CONTRACTS: 
            (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY JURY; AND 
            (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT, 
            INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY SUCH DISPUTE.
          </p>

          <br />
          <b>Ⅻ. GENERAL</b>
          <br /><br />
          1. TERM AND TERMINATION
          <p>
            Subject to this Section, these Terms will remain in full force and effect while you use the Site. 
            We may suspend or terminate your rights to use the Site at any time for any reason at our sole discretion, 
            including for any use of the Site in violation of these Terms. Upon termination of your rights 
            under these Terms, your right to access and use the Site will terminate immediately. 
            Chimp Labs will not have any liability whatsoever to you for any termination of your rights 
            under these Terms. Even after your rights under these Terms are terminated, 
            the following provisions of these Terms will remain in effect: Sections I, II, and V - XI.
          </p>
          2. CHANGES
          <p>
            These Terms are subject to occasional revision, and if we make any substantial changes, 
            we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), 
            and/or by prominently posting notice of the changes on our Site. Every time you wish to use the Site, 
            please check these Terms to ensure you understand the Terms that apply at that time. 
            You are responsible for providing us with your most current e-mail address. 
            In the event that the last e-mail address that you have provided us is not valid, 
            or for any reason is not capable of delivering to you the notice described above, 
            our dispatch of the e-mail containing such notice will nonetheless constitute effective notice 
            of the changes described in the notice. Unless otherwise specified, any changes to these Terms 
            will be effective upon our dispatch of an e-mail notice to you (if applicable) 
            or our posting of notice of the changes on our Site. Continued use of our Site following notice 
            of such changes shall indicate your acknowledgement of such changes and agreement 
            to be bound by the terms and conditions of such changes.
          </p>
          3. GOVERNING LAW
          <p>
            These Terms and any action relating thereto will be governed by the laws of Singapore. 
            Except as otherwise set forth in Section XI, the exclusive jurisdiction for all disputes 
            that you and Chimp Labs are not required to arbitrate will be the courts located in Singapore. 
            You and Chimp Labs each waive any objection to jurisdiction and venue in such courts.
          </p>
          4. FORCE MAJEURE
          <p>
            We will not be liable or responsible to you, nor be deemed to have defaulted or breached these Terms, 
            for any failure or delay in our performance under the Terms when and to the extent such failure 
            or delay is caused by or results from acts or circumstances beyond our reasonable control, including, 
            without limitation, acts of God, flood, fire, earthquake, explosion, governmental actions, war, 
            invasion or hostilities (whether war is declared or not), terrorist threats or acts, 
            riot or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts, 
            strikes or other labor disputes (whether or not relating to our workforce), or restraints 
            or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable materials, 
            materials or telecommunication breakdown or power outage.
          </p>
          5. ELECTRONIC COMMUNICATIONS
          <p>
            The communications between you and Chimp Labs use electronic means, whether you use the Site or send us emails, 
            or whether Chimp Labs posts notices on the Site or communicates with you via email. 
            For contractual purposes, you (a) consent to receive communications from Chimp Labs in an electronic form; 
            and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications 
            that Chimp Labs provides to you electronically satisfy any legal requirement that such communications 
            would satisfy if it were be in a hardcopy writing. The foregoing does not affect your non-waivable rights.
          </p>
          6. ENTIRE AGREEMENT
          <p>
            These Terms constitute the entire agreement between you and us regarding the use of the Site. 
            Our failure to exercise or enforce any right or provision of these Terms 
            shall not operate as a waiver of such right or provision. 
            The section titles in these Terms are for convenience only and have no legal or contractual effect. 
            The word “including” means “including without limitation”. If any provision of these Terms is, 
            for any reason, held to be invalid or unenforceable, the other provisions of these 
            Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified 
            so that it is valid and enforceable to the maximum extent permitted by law. 
            Your relationship to Chimp Labs is that of an independent contractor, 
            and neither party is an agent or partner of the other. These Terms, 
            and your rights and obligations herein, may not be assigned, subcontracted, delegated, 
            or otherwise transferred by you without Chimp Labs’ prior written consent, 
            and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing 
            will be null and void. Chimp Labs may freely assign these Terms. 
            The terms and conditions set forth in these Terms shall be binding upon assignees.
          </p>
          7. NO WAIVER
          <p>
            Chimp Labs’ failure to enforce any right or provision of these Terms will not be considered 
            a waiver of such right or provision. The waiver of any such right or provision will be effective 
            only if in writing and signed by a duly authorized representative of Chimp Labs. 
            Except as expressly set forth in these Terms, the exercise by either party of any of its remedies 
            under these Terms will be without prejudice to its other remedies under these Terms or otherwise.
          </p>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermOfUse;
