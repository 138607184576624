import { FC, ReactNode } from "react";
import classNames from "classnames";
import { Info } from "../../assets/svgs";
import styles from './Warning.module.scss';

const Warning: FC<{
  children: ReactNode;
  show?: boolean;
  type?: 'warn' | 'info';
  className?: string;
}> = ({ children, show, className, type = 'warn' }) => {
  if (!show) return <></>;

  return (
    <div 
      className={classNames(styles.component, {
        [styles.component_warn]: type === 'warn',
        [styles.component_info]: type === 'info'
      }, className)}
    >
      <Info className={styles.component_icon} />
      <span className={styles.component_text}>{children}</span>
    </div>
  )
};

export default Warning;
