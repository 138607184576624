import { FC, useMemo, useState } from 'react';
import parse from 'html-react-parser';
import BananaToken from '../../assets/images/banana_token.png';
import CHIMP_FAQS from '../../data/chimpay-faq.json';
import { DiscordOutline } from '../../assets/svgs';
import { DISCORD_LINK } from '../../configs';
import styles from './ChimpayFAQ.module.scss';

const SORTING_OPTIONS = [
  {
    label: 'Chimp Shop',
    value: 'shop',
  },
  {
    label: 'Work Assignment',
    value: 'assignment',
  },
  {
    label: 'Season',
    value: 'season',
  },
  {
    label: 'Bananas',
    value: 'bananas',
  },
  {
    label: 'Banana Scrolls',
    value: 'scrolls',
  }
];

const FAQs = {
  shop: [
    CHIMP_FAQS.shop.slice(0, 4),
    CHIMP_FAQS.shop.slice(4),
  ],
  assignment: [
    CHIMP_FAQS.assignment.slice(0, 3),
    CHIMP_FAQS.assignment.slice(3)
  ],
  season: [
    CHIMP_FAQS.season.slice(0, 3),
    CHIMP_FAQS.season.slice(3)
  ],
  bananas: [
    CHIMP_FAQS.bananas.slice(0, 2),
    CHIMP_FAQS.bananas.slice(2)
  ],
  scrolls: [
    CHIMP_FAQS.scrolls.slice(0, 6),
    CHIMP_FAQS.scrolls.slice(6)
  ],
}

type FAQsTypes = keyof typeof FAQs;

interface FAQItemProps {
  q: string;
  a: string;
  className?: string;
}

export const FAQItem: FC<FAQItemProps> = ({ q, a, className }) => {
  return (
    <div className={`${styles.faqItem} ${className}`}>
      <img className={styles.bananaToken} src={BananaToken} alt="" />
      <div className={styles.faqContent}>
        <div className={styles.question}>
          {parse(q)}
        </div>
        <div className={styles.answer}>
          {parse(a)}
        </div>
      </div>
    </div>
  )
}

const ChimpayFAQs = () => {
  const [first, setFirst] = useState('shop');

  const sort = useMemo(() => {
    const types = SORTING_OPTIONS.map(({ value }) => value);
    const sortedTypes = [...types.filter((t) => t !== first)];

    sortedTypes.unshift(first);
    return sortedTypes;
  }, [first]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <div className={styles.title}>
            Chimpay FAQ
          </div>
          <div className={styles.sortTabs}>
            {
              SORTING_OPTIONS.map(({ label, value }) => (
                <span
                  key={label}
                  className={`${styles.tab} ${first === value ? styles.tab_selected : ''}`}
                  onClick={() => setFirst(value)}
                >
                  {label}
                </span>
              ))
            }
          </div>
        </div>
      </div>
      <div className={styles.body}>
        {
          sort.map((type) => (
            <div className={styles.section} key={type}>
              <div className={styles.sectionLabel}>
                {SORTING_OPTIONS.find(({ value }) => type === value)?.label}
              </div>
              <div className={styles.sectionContent}>
                {
                  FAQs[type as FAQsTypes].map((colData, index) => (
                    <div className={styles.sectionCol} key={index}>
                      {
                        colData.map((faq) => (
                          <FAQItem {...faq} key={faq.q} />
                        ))
                      }
                    </div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
      <div className={styles.contact}>
        <a
          rel="noreferrer noopener"
          target="_blank"
          href={DISCORD_LINK}
          className={styles.contactButton}
        >
          Discord <DiscordOutline />
        </a>
        <div className={styles.contactText}>
          If you are having difficulties with redemption, 
          please raise a support ticket in our Discord, and help will be on your way!
        </div>
      </div>
    </div>
  )
}

export default ChimpayFAQs;
