
import { createContext, FC, ReactChild, useCallback, useContext, useEffect, useReducer } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useContract } from "../hooks/useContract";
import { LoreActionTypes } from "../types/enums";
import { LoreNFTMeta, SceneDetails, SceneState } from "../types/interfaces";
import { INVALID_OWNER } from "../constants/general";

const frames = [...Array(10).keys()];

const initialScenes = frames.map((id) => {
  return {
    id: id + 1,
    isUnlocked: false,
    value: 0,
    totalContributors: 0,
    title: '',
    description: '',
    topContributor: INVALID_OWNER,
    topAmount: 0,
    video: null,
  }
})

interface Contribution {
  sceneId: number;
  amount: number;
}

interface BalanceType {
  [key: string]: number
};

interface RankType {
  ranking: number;
  total: number;
}

interface State {
  activeChapterId: number;
  selectSceneId: number;
  scenes: SceneDetails[];
  myContributions: Contribution[];
  loreNFTBalances: BalanceType;
  rank: RankType;
  nftShared: boolean;
  ownedLoreNFTs: LoreNFTMeta[];
}

interface IContext {
  state: State,
  getScenes: (data: SceneDetails[]) => void,
  getMyContributions: (data: Contribution[]) => void,
  updateScene: (data: SceneState) => void,
  selectScene: (id: number) => void,
  getTokenBalances: (b: BalanceType) => void,
  getRank: (b: RankType) => void,
  checkNFTShared: (shared: boolean) => void,
  getLoreNFTs: (nfts: LoreNFTMeta[]) => void,
}

const initialState = {
  activeChapterId: 0,
  selectSceneId: 1,
  scenes: [...initialScenes],
  myContributions: [],
  loreNFTBalances: {},
  rank: { ranking: 0, total: 0 },
  nftShared: false,
  ownedLoreNFTs: [],
}

const Context = createContext<IContext>({
  state: initialState,
  getScenes: () => {},
  getMyContributions: () => {},
  updateScene: () => {},
  selectScene: () => {},
  getTokenBalances: () => {},
  getRank: () => {},
  checkNFTShared: () => {},
  getLoreNFTs: () => {},
})

export const useLore = () => {
  return useContext(Context);
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case LoreActionTypes.GET_SCENES:
      return {
        ...state,
        scenes: action.data,
      }
    case LoreActionTypes.GET_ACTIVE_CHAPTRE_ID:
      return {
        ...state,
        activeChapterId: action.id,
      }
    case LoreActionTypes.GET_MY_CONTRIBUTIONS:
      return {
        ...state,
        myContributions: action.data,
      }
    case LoreActionTypes.UPDATE_SCENE:
      return {
        ...state,
        scenes: state.scenes.map((item) => item.id === action.data.id ? ({
          ...item,
          ...action.data,
        }) : item),
      }
    case LoreActionTypes.SELECT_SCENE:
      return {
        ...state,
        selectSceneId: action.id,
      }
    case LoreActionTypes.TOKEN_BALANCES:
      return {
        ...state,
        loreNFTBalances: {
          ...state.loreNFTBalances,
          ...action.balances,
        },
      }
    case LoreActionTypes.GET_RANK:
      return {
        ...state,
        rank: action.rank,
      }
    case LoreActionTypes.CHECK_NFT_SHARED:
      return {
        ...state,
        nftShared: action.shared,
      }
    case LoreActionTypes.GET_LORE_NFTS:
      return {
        ...state,
        ownedLoreNFTs: action.nfts,
      }
    case LoreActionTypes.CLEAR_DATA:
      return {
        ...state,
        myContributions: [],
        loreNFTBalances: {},
        rank: { ranking: 0, total: 0 },
        nftShared: false,
        ownedLoreNFTs: [],
      }
    default:
      throw new Error();
  }
}

const LoreProvider: FC<{ children: ReactChild }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { account } = useWeb3React();

  const { loreContract } = useContract();

  const getScenes = useCallback((data: SceneDetails[]) => {
    dispatch({
      type: LoreActionTypes.GET_SCENES,
      data,
    })
  }, [])

  const getMyContributions = useCallback((data: Contribution[]) => {
    dispatch({
      type: LoreActionTypes.GET_MY_CONTRIBUTIONS,
      data,
    })
  }, [])

  const updateScene = useCallback((data: SceneState) => {
    dispatch({
      type: LoreActionTypes.UPDATE_SCENE,
      data,
    })
  }, [])

  const selectScene = useCallback((id: number) => {
    dispatch({
      type: LoreActionTypes.SELECT_SCENE,
      id,
    })
  }, [])

  const getTokenBalances = useCallback((balances: BalanceType) => {
    dispatch({
      type: LoreActionTypes.TOKEN_BALANCES,
      balances,
    })
  }, [])

  const getRank = useCallback((rank: RankType) => {
    dispatch({
      type: LoreActionTypes.GET_RANK,
      rank,
    })
  }, [])
  
  const checkNFTShared = useCallback((shared: boolean) => {
    dispatch({
      type: LoreActionTypes.CHECK_NFT_SHARED,
      shared,
    })
  }, [])

  const getLoreNFTs = useCallback((nfts: LoreNFTMeta[]) => {
    dispatch({
      type: LoreActionTypes.GET_LORE_NFTS,
      nfts,
    })
  }, [])

  useEffect(() => {
    const getData = () => {
      loreContract.activeChapter().then((id: ethers.BigNumber) => {
        if (id) {
          dispatch({
            type: LoreActionTypes.GET_ACTIVE_CHAPTRE_ID,
            id: id.toNumber(),
          })
        }
      })

      loreContract.activeSceneStart().then((id: ethers.BigNumber) => {
        if (id) {
          dispatch({
            type: LoreActionTypes.SELECT_SCENE,
            id: id.toNumber(),
          })
        }
      })
    }

    getData();
  }, [loreContract])

  useEffect(() => {
    if (account) {
      dispatch({
        type: LoreActionTypes.CLEAR_DATA,
      })
    }
  }, [account])

  const context = {
    state,
    getScenes,
    getMyContributions,
    updateScene,
    selectScene,
    getTokenBalances,
    getRank,
    checkNFTShared,
    getLoreNFTs,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default LoreProvider;