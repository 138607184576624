import { FC, ReactNode, MouseEvent, ReactElement, useCallback } from "react";
import ReactDOM from "react-dom";
import ClickOutSide from "../click-outside/ClickOutside";
import { useModal } from "../../providers/ModalProvider";
import { ModalTypes } from "../../types/enums";
import styles from './Modal.module.scss';

const modalRoot = document.getElementById('modal');

const container = document.createElement('div');

if (modalRoot && container) {
  modalRoot.appendChild(container);
}

export const DisplayModal = (Component: FC, type: ModalTypes): FC => {

  return function WrappedComponent(props: any): ReactElement {
    const { modal } = useModal();

    if (modal !== type) {
      return <></>;
    }

    return <Component {...props} />;
  };
}

const ModalContainer: FC<{ children: ReactNode }> = ({ children }): ReactElement | null => {
  return ReactDOM.createPortal(children, container);
}

const Modal: FC<{ children: ReactNode, allowClickOutside?: boolean }> = ({ children, allowClickOutside = true }) => {
  const { closeModal } = useModal();

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (allowClickOutside) {
      closeModal();
    }
  }, [closeModal, allowClickOutside])

  return (
    <ModalContainer>
      <div className={styles.container}>
        <div className={styles.body}>
          <div className={styles.content}>
            <ClickOutSide className={styles.wrapper} onClickOutside={handleClick}>
              {children}
            </ClickOutSide>
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}

export default Modal