
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import Modal, { DisplayModal } from "../modal/Modal";
import { useModal } from "../../providers/ModalProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useLore } from "../../providers/LoreProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import useChimpayHook from "../../hooks/useChimpayHook";
import useLoreHook from "../../hooks/useLoreHook";
import BananaValue from "../chimpay/BananaValue";
import Button from "../button/ProgressButton";
import Warning from "../messages/Warning";
import { Cross } from "../../assets/svgs";
import ScrollPlaceholder from '../../assets/images/scroll-placerholder.png';
import { ButtonStatus, ModalTypes, ModalChimpStatus } from "../../types/enums";
import { LIMIT_TO_CONTRIBUTE } from "../../constants/lore";
import { roundNumberWithFix } from "../../utils/number";
import styles from './ContributeModal.module.scss';

const ContributeModal = () => {
  const [amount, setAmount] = useState('100');
  const [status, setStatus] = useState(ModalChimpStatus.READY);
  const [error, setError] = useState('');

  const { closeModal } = useModal();
  const { onChangeWarnings } = useMessage();
  const { getAvailableRewards } = useChimpayHook();
  const { contributeToScroll, updateSceneData, getMyContributedScenes } = useLoreHook();

  const { state: { scenes, selectSceneId, myContributions } } = useLore();
  const { state: { availableBananas } } = useChimpay();

  const selectedScene = useMemo(() => scenes.find((sc) => +sc.id === +selectSceneId), [scenes, selectSceneId]);

  const thumbnail = useMemo(() => {
    return selectedScene?.video?.thumbnail 
      ? `${selectedScene.video.thumbnail}?time=3s&width=200`
      : ScrollPlaceholder
  }, [selectedScene]);

  const contributedAmount = useMemo(() => 
    myContributions.find(({ sceneId }) => sceneId === selectSceneId)?.amount || 0, [myContributions, selectSceneId]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setError('');

    if (/^[1-9]{0,}\d{0,}$/.test(value)) {
      if (value.startsWith('0')) {
        value = `${+value}`;
      }

      setAmount(value);
    }
  }, [setAmount])

  const handleContribute = useCallback(async () => {
    setStatus(ModalChimpStatus.CONFIRMING);

    try {
      await contributeToScroll(selectSceneId, +amount);

      const load = () => {
        updateSceneData(selectSceneId);
        getMyContributedScenes();
        getAvailableRewards();

        setStatus(ModalChimpStatus.SUCCEESS);

        onChangeWarnings({ 
          type: 'Success',
          title: 'Cheers!',
          message: `You have contribute ${amount} Bananas to Scroll ${selectSceneId}.`
        });

        closeModal();
      }

      const refreshTimer = setTimeout(() => {
        load();
        clearTimeout(refreshTimer);
      }, 3000);
    } catch (error: any) {
      setStatus(ModalChimpStatus.FAILED);
      setError('We cannot complete your request, please try again,');
    }
  }, [contributeToScroll, getAvailableRewards, getMyContributedScenes, updateSceneData, onChangeWarnings, closeModal, amount, selectSceneId]);

  const handleClick = useCallback(() => {
    if (+amount < LIMIT_TO_CONTRIBUTE && +contributedAmount === 0) {
      return setError(`Minimum contribution is ${LIMIT_TO_CONTRIBUTE} Bananas.`);
    }

    if (+amount > +availableBananas) {
      return setError('You have insufficient Bananas.');
    }

    handleContribute();
  }, [handleContribute, amount, availableBananas, contributedAmount]);

  return (
    <Modal allowClickOutside={status !== ModalChimpStatus.CONFIRMING}>
      <div className={styles.container}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <div className={styles.header}>
          <span className={styles.title}>
            Contribute
          </span>
          <div className={styles.scene}>
            <img 
              className={styles.img} 
              src={thumbnail} 
              alt="" 
            />
            <div className={styles.scene_details}>
              <div>
                <div className={styles.scene_title}>
                  {selectedScene?.title}
                </div>
                <div className={styles.scene_id}>
                  Scroll {selectSceneId}
                </div>
              </div>
              <div className={styles.scene_contributed}>
                My Contribution: {roundNumberWithFix(contributedAmount, 2)}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.input_wrap}>
            <div className={styles.input_tip}>
              <span className={styles.input_min}>
                Enter Banana {+contributedAmount === 0 ? `(min.${LIMIT_TO_CONTRIBUTE})` : ''}
              </span>
              <span className={styles.input_avail}>
                <span className={styles.input_avail_label}>
                  Available: 
                </span>
                <BananaValue value={roundNumberWithFix(availableBananas, 2)} />
              </span>
            </div>
            <input 
              className={styles.input} 
              value={amount} 
              onChange={handleChange} 
              placeholder="0" 
            />
          </div>
          <Button 
            // disabled={!!error && +amount > +availableBananas}
            status={status === ModalChimpStatus.CONFIRMING ? ButtonStatus.LOADING : ButtonStatus.NORMAL} 
            onClick={handleClick}
          >
            {status === ModalChimpStatus.CONFIRMING ? 'Confirming...' : 'Confirm'}
          </Button>
          <Warning type="info" show={status === ModalChimpStatus.CONFIRMING}>
            This action cannot be reversed. Please confirm you are contributing the desired amount to the right frame.
          </Warning>
          <Warning show={!!error}>
            {error}
          </Warning>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(ContributeModal, ModalTypes.CONTRIBUTE);