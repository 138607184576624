import { FC, MouseEvent, useCallback } from 'react';
import classNames from 'classnames';
import styles from './GalleryFrame.module.scss';

export function displayDuration(duration: number) {
  if (!duration) return '00:00s';

  let m = Math.floor(+duration / 60);
  let s = Math.floor(+duration % 60);

  let fm = m >= 10 ? s : `0${m}`;
  let fs = s >= 10 ? s : `0${s}`;
  return `${fm}:${fs}s`;
}

interface Props {
  src: string;
  name: string;
  part: string | number;
  duration?: number;
  selected?: boolean;
  onClick?: () => void;
  className?: string;
}

export const GalleryFrame: FC<Props> = (props) => {
  const { src, name, part, selected, onClick, className } = props;

  const handleClick = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (onClick) {
      onClick();
    }
  }, [onClick])

  return (
    <div 
      className={classNames(styles.component, className)}
      onClick={handleClick}
    >
      <div className={classNames(styles.img, { [styles.img_selected]: selected })}>
        <img src={src} alt={''} loading="lazy" />
        {/* <span className={styles.duration}>
          <svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none">
            <path d="M6 4L-3.26266e-07 7.4641L-2.34249e-08 0.535898L6 4Z" fill="#B04700"/>
          </svg>
          <span>
            {displayDuration(duration)}
          </span>
        </span> */}
      </div>
      <div className={styles.name}>
        {name}
      </div>
      <div className={styles.part}>
        Scroll {part}
      </div>
    </div>
  )
}
