import React, { useCallback } from 'react';
import classNames from 'classnames';
import { useUser } from '../../providers/UserProvider';
import { LOCAL_STORAGE_CART } from '../../constants/labels';
import { OrderCartMeta, OrderItemMeta } from '../../types/interfaces';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import { getMerchCountInCart, getProductImage } from '../../utils/shop-merch';

import styles from './OrderMerchandise.module.scss';

type CartMerchParams = (OrderCartMeta | OrderItemMeta) & {
  size?: string;
  variantOption1?: string;
  maxLimit?: number;
};

interface Props {
  merchandiseList: CartMerchParams[];
  isCartOrder?: boolean;
  className?: string;
  callbackAction?: () => any;
}

const OrderMerchandise: React.FC<Props> = (props) => {
  const { merchandiseList, isCartOrder = true, className, callbackAction } = props;

  const { updateOrderCart } = useUser();

  const changeQuantity = useCallback((index: number, type: 'increase' | 'decrease') => {
    const newOrderCart = [...merchandiseList] as OrderCartMeta[];

    const order = newOrderCart[index];

    if (type === 'increase') {
      if (order.maxLimit && getMerchCountInCart(newOrderCart, order.contractId) < order.maxLimit) {
        order.quantity += 1;
      }
    } else {
      if (order.quantity - 1 > 0) {
        order.quantity -= 1;
      } else {
        if (window.confirm("Are you sure want to remove this item?") === true) {
          newOrderCart.splice(index, 1);
        }
      }
    }

    updateOrderCart(newOrderCart);
    localStorage.setItem(LOCAL_STORAGE_CART, JSON.stringify(newOrderCart));

    if (callbackAction) {
      callbackAction();
    }
  }, [merchandiseList, updateOrderCart, callbackAction]);

  if (merchandiseList.length === 0) {
    return (
      <span className={styles.empty}>Oops! Cart is empty!</span>
    )
  };

  return (
    <div className={classNames(styles.list, className)}>
      {merchandiseList.map((item: CartMerchParams, index: number) => (
        <div key={`${item.id}-${item.size || item.variantOption1}`} className={styles.item}>
          <div className={styles.image}>
            <img src={getProductImage(item.contractId)[0]} alt="" />
          </div>
          <div className={styles.details}>
            <div className={styles.item_row}>
              <div>{item.title}</div>
              <div className={styles.price}>
               {formatNumberWithComma(roundNumberWithFix(item.price, 3))} {item.currency}
              </div>
            </div>
            <div className={styles.size}>
              Size: {(item.size || item.variantOption1) ? item.size || item.variantOption1 : '--'}
            </div>
            <div className={styles.quantity}>
              <span className={styles.quantity_value}>
                Quantity: {item.quantity} 
              </span>
              {
                isCartOrder && 
                <div className={styles.quantity_mutation}>
                  <span 
                    className={styles.quantity_mutation_button} 
                    onClick={() => changeQuantity(index, 'decrease')}
                  >-</span>
                  <span
                    className={classNames(styles.quantity_mutation_button, {
                      [styles.quantity_mutation_button_disabled]: getMerchCountInCart(merchandiseList as OrderCartMeta[], item.contractId) >= (item.maxLimit || 0)
                    })} 
                    onClick={() => changeQuantity(index, 'increase')}
                  >+</span>
                </div>
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default OrderMerchandise;