export async function fetchMetadata(url: string) {
  const res = await fetch(url);

  if (res.ok) {
    const data = await res.json();

    return data;
  } else {
    throw new Error('Failed to get honorary chimp metadata');
  }
}

export async function fetchImage(url: string) {
  const res = await fetch(url, {
    headers: {
      'Content-Type': 'image/png',
    },
  });

  if (res.ok) {
    const blob = await res.blob();

    return URL.createObjectURL(blob);
  } else {
    throw new Error('Failed to get chimp image for chistmas card');
  }
}