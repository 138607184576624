import { useWeb3React } from '@web3-react/core';
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';

const INITIAL_FETCH = 0;

const initialState = {
  moreMyChimps: INITIAL_FETCH,
  tokenIds: [],
  honoraryTokenIds: [],
  loadingChimps: false,
  loadingHonoraryChimps: false,
  moreSaleChimps: INITIAL_FETCH,
  loadingSalingChimps: true,
  loadingSalingHonoraryChimps: true,
  searchOwnChimpId: '',
}

interface IContext {
  moreMyChimps: number,
  tokenIds: string[],
  honoraryTokenIds: string[],
  loadingChimps: boolean,
  loadingHonoraryChimps: boolean,
  moreSaleChimps: number,
  loadingSalingChimps: boolean,
  loadingSalingHonoraryChimps: boolean,
  searchOwnChimpId: string,
  setMoreMyChimps: (val: number) => void,
  setTokenIds: (ids: string[]) => void,
  setHonoraryTokenIds: (ids: string[]) => void,
  setLoadingChimps: (loading: boolean) => void,
  setLoadingHonoraryChimps: (loading: boolean) => void,
  setLoadingSalingChimps: (loading: boolean) => void,
  setLoadingSalingHonoraryChimps: (loading: boolean) => void,
  setMoreSaleChimps: (val: number) => void,
  setSearchOwnChimpId: (val: string) => void,
}

const Context = createContext<IContext>({
  ...initialState,
  setMoreMyChimps: () => {},
  setTokenIds: () => {},
  setHonoraryTokenIds: () => {},
  setLoadingChimps: () => {},
  setLoadingHonoraryChimps: () => {},
  setLoadingSalingChimps: () => {},
  setLoadingSalingHonoraryChimps: () => {},
  setMoreSaleChimps: () => {},
  setSearchOwnChimpId: () => {},
})

export const useLoadChimp = () => {
  return useContext(Context);
}

const LoadChimpProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [moreMyChimps, setMoreMyChimps] = useState(initialState.moreMyChimps);
  const [tokenIds, setTokenIds] = useState<string[]>(initialState.tokenIds);
  const [honoraryTokenIds, setHonoraryTokenIds] = useState<string[]>(initialState.honoraryTokenIds);
  const [loadingChimps, setLoadingChimps] = useState(initialState.loadingChimps);
  const [loadingHonoraryChimps, setLoadingHonoraryChimps] = useState(initialState.loadingHonoraryChimps);
  const [moreSaleChimps, setMoreSaleChimps] = useState(initialState.moreSaleChimps);
  const [loadingSalingChimps, setLoadingSalingChimps] = useState(initialState.loadingSalingChimps);
  const [loadingSalingHonoraryChimps, setLoadingSalingHonoraryChimps] = useState(initialState.loadingSalingHonoraryChimps);
  const [searchOwnChimpId, setSearchOwnChimpId] = useState(initialState.searchOwnChimpId);

  const { account } = useWeb3React();

  const context = {
    moreMyChimps,
    tokenIds,
    honoraryTokenIds,
    loadingChimps,
    loadingHonoraryChimps,
    moreSaleChimps,
    loadingSalingChimps,
    loadingSalingHonoraryChimps,
    searchOwnChimpId,
    setMoreMyChimps,
    setTokenIds,
    setHonoraryTokenIds,
    setLoadingChimps,
    setLoadingHonoraryChimps,
    setMoreSaleChimps,
    setLoadingSalingChimps,
    setLoadingSalingHonoraryChimps,
    setSearchOwnChimpId,
  }

  useEffect(() => {
    if (account) {
      setMoreMyChimps(INITIAL_FETCH);
      setTokenIds([]);
      setHonoraryTokenIds([]);
      setSearchOwnChimpId('');
    }
  }, [account])

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default LoadChimpProvider