import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { ButtonStatus } from '../../types/enums';
import styles from './ProgressButton.module.scss';

interface Props {
  onClick: () => void,
  classname?: string,
  disabled?: boolean,
  status?: ButtonStatus,
  isGhost?: boolean
}

const ProgressButton: FC<Props> = ({ classname, disabled, status, onClick, children, isGhost }) => {
  const disabling = useMemo(() => {
    return status === ButtonStatus.WARNING || status === ButtonStatus.LOADING || disabled;
  }, [status, disabled])

  return (
    <button 
      className={classNames(
        styles.container,
        {
          [styles.container_loading]: !isGhost && status === ButtonStatus.LOADING,
          [styles.container_ghost]: isGhost,
          [styles.container_disabled]: disabled
        },
        classname,
      )} 
      disabled={disabling}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

ProgressButton.defaultProps = {
  classname: '',
  isGhost: false,
  status: ButtonStatus.NORMAL,
  disabled: false,
}

export default ProgressButton;