import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useMerchandiseHook } from '../../hooks/useMerchandiseHook';
import useChimpayHook from '../../hooks/useChimpayHook';
import { OrderMeta } from '../../types/interfaces';
import { formatDate, formatDateTime } from '../../utils/time';
import { MerchPaymentTypes } from '../../types/enums';

export const pdfLink = (base64Code: string) => `data:application/pdf;base64,${base64Code}`;

const MerchOrders = () => {
  const { getOrdersByTime } = useMerchandiseHook();
  const { getSeasonPeriod } = useChimpayHook();

  const [orderList, setOrderList] = useState<OrderMeta[]>([]);

  const { start, end } = useMemo(() => getSeasonPeriod().season, [getSeasonPeriod]);

  useEffect(() => {
    if (start) {
      getOrdersByTime(start).then((data) => {
        if (data && Array.isArray(data)) {
          setOrderList(data);
        }
      });
    }
  }, [start, getOrdersByTime])

  const [fromTime, setFromTime] = useState(formatDateTime(start));
  const [toTime, setToTime] = useState(formatDateTime(end));

  const handleSearch = useCallback(() => {
    const isoTimeFormat = /^\d{4}-(0|1){1}\d{1}-[0-3]{1}\d{1} [0-2]{1}\d{1}:[0-5]{1}\d{1}:[0-5]{1}\d{1}/;

    if (isoTimeFormat.test(fromTime) && (!toTime || isoTimeFormat.test(toTime))) {
      getOrdersByTime(fromTime, toTime).then((data) => {
        if (data && Array.isArray(data)) {
          setOrderList(data);
        }
      });
    } else {
      alert('Strictly input as YYYY-MM-DD HH:mm:ss!');
    }
  }, [fromTime, toTime, getOrdersByTime]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <input
          onChange={(e) => setFromTime(e.target.value)}
          value={fromTime}
          placeholder="Search From"
          style={{ width: '150px' }}
        />
        <input
          onChange={(e) => setToTime(e.target.value)}
          value={toTime}
          placeholder="Search To"
          style={{ width: '150px' }}
        />
        &nbsp;
        <button onClick={handleSearch}>
          Search
        </button>
      </div>
      <br />

      <table style={{ width: '100%', textAlign: 'center', lineHeight: '32px', borderCollapse: 'collapse' }}>
        <thead style={{ lineHeight: '48px' }}>
          <tr>
            <th>Order Id</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Total Value</th>
            <th>Shipping Cost</th>
            <th>Name</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Address 1, 2</th>
            <th>City</th>
            <th>State</th>
            <th>Country</th>
            <th>Postal Code</th>
            <th>Items Purchased</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px' }}>
          {
            orderList.sort((a, b) => a.id < b.id ? 1 : -1)
              .map(({ id, createdAt, status, subtotalPrice, subtotalBananaPrice, shippingPrice, firstName, lastName, email, mobile, shippingCountry, shippingCity, shippingState, shippingPostCode, shippingAddress1, shippingAddress2, items }) => (
                <tr key={id} style={{ borderTop: '1px solid #C3C6CE' }}>
                  <td>{id}</td>
                  <td>{formatDate(createdAt, 'numeric', 'short', 'numeric')}</td>
                  <td>{status}</td>
                  <td>
                    <div>
                      {+subtotalPrice > 0 && `${subtotalPrice} ${MerchPaymentTypes.USDC}`}
                    </div>
                    <div>
                      {+subtotalBananaPrice > 0 && `${subtotalBananaPrice} ${MerchPaymentTypes.BANANAS}`}
                    </div>
                  </td>
                  <td>{shippingPrice}</td>
                  <td>{firstName} {lastName}</td>
                  <td>{email}</td>
                  <td>{mobile}</td>
                  <td>
                    {shippingAddress1} 
                    {shippingAddress2 ? `, ${shippingAddress2}` : ''}
                  </td>
                  <td>{shippingCity}</td>
                  <td>{shippingState}</td>
                  <td>{shippingCountry}</td>
                  <td>{shippingPostCode}</td>
                  <td>
                    {
                      items.map((item) => (
                        <div key={item.id}>
                          {item.contractId},{item.variantOption1 || '-'},{item.quantity}
                        </div>
                      ))
                    }
                  </td>
                  {/* <td>
                    {
                      shipmentWaybill && 
                      <a href={pdfLink(shipmentWaybill)} download="Merch-shippment-waybill.pdf">
                        Waybill 
                      </a>
                    }
                    &nbsp;&nbsp;
                    {
                      shipmentLabel && 
                      <a href={pdfLink(shipmentLabel)} download="Merch-shippment-label.pdf">
                        Label 
                      </a>
                    }
                  </td> */}
                </tr>
              ))
          }
        </tbody>
      </table>
    </div>
  )
}

export default MerchOrders;