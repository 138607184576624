import { SEASON_WORKING_ENDTIME, SEASON_REDEMPTION_ENDTIME } from "../configs";

const ONE_DAY = 24 * 60 * 60 * 1000;
const ONE_HOUR = 60 * 60 * 1000;

interface SeasonReturnType {
  season: {
    start: number;
    end: number;
  };
  stake: {
    start: number;
    end: number;
  };
  redemption: {
    start: number;
    end: number;
  };
}

const FIRST_SEASON_START = 1686646800000;

export function isFirstSeasonWaiting() {
  return FIRST_SEASON_START && +FIRST_SEASON_START > Date.now();
}

export function calculateSeasonPeriod(timestamp: number): SeasonReturnType {
  let startTime = timestamp;

  const seasonStartDate = new Date(startTime);

  const seasonYear = seasonStartDate.getUTCFullYear();
  const seasonMonth = seasonStartDate.getUTCMonth();
  const seasonDate = seasonStartDate.getUTCDate();
  const seasonHour = seasonStartDate.getUTCHours();
  const seasonMinute = seasonStartDate.getUTCMinutes();

  const seasonStartTime = Date.UTC(seasonYear, seasonMonth, seasonDate, seasonHour, seasonMinute);
  let seasonEndTime = Date.UTC(seasonYear, seasonMonth + 3, seasonDate, seasonHour, seasonMinute);

  seasonEndTime = Math.max(seasonEndTime, Date.now());

  let stakeEndTime = seasonStartTime + ONE_DAY * 60;
  let redemptionEndTime = seasonStartTime + ONE_DAY * (60 + 21);

  if (SEASON_WORKING_ENDTIME && SEASON_WORKING_ENDTIME > seasonStartTime) {
    stakeEndTime = Math.min(stakeEndTime, SEASON_WORKING_ENDTIME);
  }

  if (SEASON_REDEMPTION_ENDTIME && SEASON_REDEMPTION_ENDTIME > seasonStartTime) {
    redemptionEndTime = Math.min(redemptionEndTime, SEASON_REDEMPTION_ENDTIME);
  }

  return {
    season: {
      start: seasonStartTime,
      end: seasonEndTime,
    },
    stake: {
      start: seasonStartTime,
      end: stakeEndTime,
    },
    redemption: {
      start: seasonStartTime,
      end: redemptionEndTime,
    }
  }
}

export function checkStakedTime(stakeStartTime: number, pauseTime: number): {
  isLock: boolean;
  time: string;
} {
  if (!stakeStartTime || Number.isNaN(stakeStartTime)) return {
    isLock: false,
    time: '',
  };

  const now = Date.now();
  const stakeEndTime = pauseTime > 0 ? Math.min(now, pauseTime) : now;
  const diff = stakeEndTime - stakeStartTime;

  if (diff <= ONE_DAY) {
    const h = Math.floor(diff / ONE_HOUR);

    if (pauseTime > 0 && pauseTime < now) {
      return {
        isLock: false,
        time: h > 1 ? `${h} hours` : `${h} hour`,
      };
    } else {
      const lockLeft = 24 - h;

      return {
        isLock: true,
        time: lockLeft > 1 ? `${lockLeft} hours` : `${lockLeft} hour`,
      };
    }
  } else {
    const d = Math.floor(diff / ONE_DAY);
    const h = Math.floor((diff % ONE_DAY) / ONE_HOUR);

    return {
      isLock: false,
      time: `${d} ${d > 1 ? 'days' : 'day'} ${h} ${h > 1 ? 'hours' : 'hour'}`,
    };
  }
}
