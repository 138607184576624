import { useCallback, useEffect, useMemo } from 'react';
import Dropdown from '../../components/dropdown/Dropdown';
import BananaValue from '../../components/chimpay/BananaValue';
import Footer from '../../components/footer/Footer';
import BannerGift from '../../assets/images/banners_gift.png';
import BannerBanana from '../../assets/images/banners_banana.png';
import BannerBananaOpen from '../../assets/images/banners_banana_open.png';
import BannerStall from '../../assets/images/banners_stall.png';
import BannerBananaChimp from '../../assets/images/banners_banana_chimp.png';
import BannerGifts from '../../assets/images/banners_gifts.png';
import { useChimpay } from '../../providers/ChimpayProvider';
import { countDown, formatUTCSeasonDate } from '../../utils/time';
import { roundNumberWithFix } from '../../utils/number';
import { isFirstSeasonWaiting } from '../../utils/stake-time';
import ChimpMerch from './ChimpMerch';
import GiftsShop from './GiftsShop';
import useChimpayHook from '../../hooks/useChimpayHook';
import { SEASON_ON_LIVE } from '../../configs';
import styles from './ShopContainer.module.scss';

const showGiftsShop = false;

const ShopContainer = () => {
  const {
    state: { countries, availableBananas, giftOrderForm: { country } },
    updateGiftOrderForm,
  } = useChimpay();

  const {
    loadingGifts,
    getGiftProducts, getAvailableGiftQuota, getSeasonPeriod, isRedemptionPeriod,
  } = useChimpayHook();

  const { stake, redemption, season } = useMemo(() => getSeasonPeriod(), [getSeasonPeriod]);

  const isCoolDown = useMemo(() => !isRedemptionPeriod(), [isRedemptionPeriod]);

  const countrySelections = useMemo(() => countries.sort((a, b) => a.name.localeCompare(b.name))
    .map(({ isoName, name }) => ({ text: name, value: isoName })), [countries]);

  const handleChangeCountry = useCallback((value) => {
    updateGiftOrderForm('country', value);
  }, [updateGiftOrderForm])

  useEffect(() => {
    if(!isCoolDown && showGiftsShop) {
      getAvailableGiftQuota();
      getGiftProducts();
    }
  }, [isCoolDown, getGiftProducts, getAvailableGiftQuota]);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.bananaGift}>
            <img className={styles.bannerBanana} src={BannerBanana} alt="" />
            <img className={styles.bannerBananaOpen} src={BannerBananaOpen} alt="" />
            <img className={styles.bannerGift} src={BannerGift} alt="" />
          </div>
          <div className={styles.headerContent}>
            <span className={styles.title}>Welcome to the Chimp Shop!</span>
            {
              !isCoolDown && showGiftsShop && 
              <>
                <div className={styles.season}>Season #{SEASON_ON_LIVE}</div>
                <div className={styles.seasonPeriod}>
                  <span>
                    Working period:{' '}
                  </span>
                  {formatUTCSeasonDate(stake.start)}  -  {formatUTCSeasonDate(stake.end)}
                </div>
                <div className={styles.seasonPeriod}>
                  <span>
                    Gift redemption:{' '}
                  </span>
                  {formatUTCSeasonDate(redemption.start)}  -  {formatUTCSeasonDate(redemption.end)}
                </div>
              </>            
            }
            {
              isCoolDown && false && 
              <>
                <div className={styles.season}>Time left till Season #{isFirstSeasonWaiting() ? 1 : SEASON_ON_LIVE + 1}</div>
                <div className={styles.seasonWait}>
                  <span>
                    {countDown(season.end, 'DHM')}
                  </span>
                  <br />
                  Gifts will be available when the season begins, stay tuned.
                </div>
              </>
            }
          </div>
          <div className={styles.stallScene}>
            <img className={styles.bannerBananaChimp} src={BannerBananaChimp} alt="" />
            <div className={styles.giftsInstall}>
              <img className={styles.bannerGifts} src={BannerGifts} alt="" />
              <img className={styles.bannerStall} src={BannerStall} alt="" />
            </div>
          </div>
        </div>
        <div className={styles.body}>
          {
            !isCoolDown && 
            <div className={styles.summaryBar}>
              <div className={`${styles.summaryData} ${showGiftsShop ? '' : styles.summaryData_inline}`}>
                <div className={styles.dataCol}>
                  <div className={styles.dataLabel}>
                    Banana in my sack
                  </div>
                  <BananaValue
                    value={roundNumberWithFix(availableBananas, 2)}
                    className={styles.bananasOwned}
                  />
                </div>
                <div className={styles.dataCol}>
                  <div className={styles.dataLabel}>
                    Time left to redeem
                  </div>
                  <div className={styles.dataValue}>
                    {countDown(redemption.end, 'DHM')}
                  </div>
                </div>
              </div>
              {
                showGiftsShop && 
                <div className={styles.selectCol}>
                    <div className={styles.dataLabel}>
                    Select Country
                    </div>
                    <Dropdown
                    select={country}
                    onSelect={handleChangeCountry}
                    options={countrySelections}
                    searchable
                    />
                </div>
              }
            </div>
          }
          <ChimpMerch />
          {
            !isCoolDown && loadingGifts && 
            <div className={styles.loading}>
              Loading Gifts...
            </div>
          }
          {
            showGiftsShop && 
            <GiftsShop loading={loadingGifts} />
          }
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ShopContainer;
