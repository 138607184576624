import { useWeb3React } from "@web3-react/core";
import { ethers, Contract } from "ethers";
import { useMemo } from "react";
import { 
  BATCH_CONTRACT_ADDRESS,
  CHIMP_CONTRACT_ADDRESS,
  HONORARY_CHIMP_CONTRACT_ADDRESS,
  OFFER_CONTRACT_ADDRESS,
  HONORARY_OFFER_CONTRACT_ADDRESS,
  WCRO_CONTRACT_ADDRESS,
  MERCHANDISE_CONTRACT_ADDRESS,
  MERCHANDISE_ERC20_CONTRACT_ADDRESS,
  STAKE_CHIMP_CONTRACT_ADDRESS,
  STAKE_HONORARY_CHIMP_CONTRACT_ADDRESS,
  BATCH_STAKE_DATA_CONTRACT_ADDRESS,
  STAKE_CHIMP_CONTRACT_ADDRESS_UPGRADED,
  CHIMP_LORE_CONTRACT_ADDRESS,
} from "../configs";
import { defaultProvider } from "../utils/provider";
import batchABI from '../abi/batchdata.json';
import chimpsABI from '../abi/chimps.json';
import erc20ABI from '../abi/erc20.json';
import honoraryChimpsABI from '../abi/honoraryChimps.json';
import offerABI from '../abi/offer.json';
import merchandiseABI from '../abi/merchandise.json';
import stakeABIDeprecated from '../abi/stakeChimpDeprecated.json';
import batchStakeABI from '../abi/batchStakeData.json';
import stakeABI from '../abi/stakeChimp.json';
import loreABI from '../abi/lore.json';

const getContract = (address: string, abi: any, signer?: ethers.Signer | ethers.providers.Provider) => {
  return new ethers.Contract(address, abi, signer)
}

export function useContract(): {
  contract: Contract,
  honoraryContract: Contract,
  batchContract: Contract,
  offerContract: Contract,
  honoraryOfferContract: Contract,
  WCROContract: Contract,
  merchandiseERC20Contract: Contract,
  merchandiseContract: Contract,
  stakeChimpContract: Contract,
  stakeChimpContractDeprecated: Contract,
  stakeHonoraryChimpContractDeprecated: Contract,
  batchStakeContract: Contract,
  loreContract: Contract,
} {
  const { library } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const web3 = useMemo(() => library ? library?.getSigner() : defaultProvider, [library]);

  const contract = useMemo(() => getContract(CHIMP_CONTRACT_ADDRESS, chimpsABI, web3), [web3]);

  const honoraryContract = useMemo(() => getContract(HONORARY_CHIMP_CONTRACT_ADDRESS, honoraryChimpsABI, web3), [web3]);

  const batchContract = useMemo(() => getContract(BATCH_CONTRACT_ADDRESS, batchABI, web3), [web3]);

  const offerContract = useMemo(() => getContract(OFFER_CONTRACT_ADDRESS, offerABI, web3), [web3]);

  const honoraryOfferContract = useMemo(() => getContract(HONORARY_OFFER_CONTRACT_ADDRESS, offerABI, web3), [web3]);

  const WCROContract = useMemo(() => getContract(WCRO_CONTRACT_ADDRESS, erc20ABI, web3), [web3]);

  const merchandiseERC20Contract = useMemo(() => getContract(MERCHANDISE_ERC20_CONTRACT_ADDRESS, erc20ABI, web3), [web3]);

  const merchandiseContract = useMemo(() => getContract(MERCHANDISE_CONTRACT_ADDRESS, merchandiseABI, web3), [web3]);

  const stakeChimpContractDeprecated = useMemo(() => getContract(STAKE_CHIMP_CONTRACT_ADDRESS, stakeABIDeprecated, web3), [web3]);

  const stakeHonoraryChimpContractDeprecated = useMemo(() => getContract(STAKE_HONORARY_CHIMP_CONTRACT_ADDRESS, stakeABIDeprecated, web3), [web3]);

  const batchStakeContract = useMemo(() => getContract(BATCH_STAKE_DATA_CONTRACT_ADDRESS, batchStakeABI, web3), [web3]);

  const stakeChimpContract = useMemo(() => getContract(STAKE_CHIMP_CONTRACT_ADDRESS_UPGRADED, stakeABI, web3), [web3]);

  const loreContract = useMemo(() => getContract(CHIMP_LORE_CONTRACT_ADDRESS, loreABI, web3), [web3]);

  return {
    contract,
    honoraryContract,
    batchContract,
    offerContract,
    honoraryOfferContract,
    WCROContract,
    merchandiseERC20Contract,
    merchandiseContract,
    stakeChimpContract,
    stakeChimpContractDeprecated,
    stakeHonoraryChimpContractDeprecated,
    batchStakeContract,
    loreContract,
  };
}
