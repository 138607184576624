
import { FC } from 'react';
import {
  OPTIONS_BACKGROUND,
  OPTIONS_BODY,
  OPTIONS_CLOTHES,
  OPTIONS_EARRINGS,
  OPTIONS_EYES,
  OPTIONS_HEADGEAR,
  OPTIONS_MOUTH,
} from '../../constants/options';
import { TraitTypes } from '../../types/enums';
import { calculateTraitPercentage } from '../../utils/chimp-traits';
import styles from './TraitCard.module.css';

interface Props {
  label: TraitTypes,
  trait: string,
}

const TraitsMap: { [traitName in TraitTypes]: any } = {
  [TraitTypes.Background]: OPTIONS_BACKGROUND,
  [TraitTypes.Body]: OPTIONS_BODY,
  [TraitTypes.Clothes]: OPTIONS_CLOTHES,
  [TraitTypes.Earrings]: OPTIONS_EARRINGS,
  [TraitTypes.Eyes]: OPTIONS_EYES,
  [TraitTypes.Headgear]: OPTIONS_HEADGEAR,
  [TraitTypes.Mouth]: OPTIONS_MOUTH,
}

const TraitCard: FC<Props> = ({ label, trait }) => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>{label}</div>
      <div className={styles.trait}>{trait}</div>
      <div className={styles.percentage}>{calculateTraitPercentage(TraitsMap[label][trait])}</div>
    </div>
  )
}

export default TraitCard;