
import { createContext, FC, ReactChild, useCallback, useContext, useReducer } from "react";
import MessageContainer from "../components/messages/MessageContainer";
import { MessageActionTypes } from "../types/enums";

interface Msg {
  type?: 'Success' | 'Failed',
  title?: string,
  message: string,
}

interface State {
  warnings: Msg[],
}

interface IContext {
  state: State,
  onChangeWarnings: (msgBody: Msg) => void,
  removeWarning: (msg: string) => void,
}

const initialState = {
  warnings: [],
}

const Context = createContext<IContext>({
  state: initialState,
  onChangeWarnings: () => {},
  removeWarning: () => {},
})

export const useMessage = () => {
  return useContext(Context);
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case MessageActionTypes.UPDATE_WARNINGS:
      return {
        ...state,
        warnings: action.msg,
      }
    default:
      throw new Error();
  }
}

const MessageProvider: FC<{ children: ReactChild }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { warnings } = state;

  const onChangeWarnings = useCallback(({ type, title, message }: Msg) => {
    if (warnings.some((msg) => msg.message === message)) return;

    warnings.push({
      type: type || 'Failed',
      title: title || 'Error',
      message,
    });

    dispatch({
      type: MessageActionTypes.UPDATE_WARNINGS,
      msg: [...warnings]
    })
  }, [warnings])

  const removeWarning = useCallback((msg: string) => {
    const newState = warnings.filter(({ message }) => message !== msg);

    dispatch({
      type: MessageActionTypes.UPDATE_WARNINGS,
      msg: [...newState]
    })
  }, [warnings])

  const context = {
    state,
    onChangeWarnings,
    removeWarning,
  }

  return (
    <Context.Provider value={context}>
      <MessageContainer />
      {children}
    </Context.Provider>
  )
}

export default MessageProvider;