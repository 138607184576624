import React, { useCallback } from 'react';
import Modal, { DisplayModal } from "./Modal";
import { ModalTypes } from '../../types/enums';
import { useModal } from '../../providers/ModalProvider';
import { useUser } from '../../providers/UserProvider';
import { Cross } from '../../assets/svgs';
import { getProductImage } from '../../utils/shop-merch';
import styles from './CartModal.module.scss';
import classNames from 'classnames';
import Button from '../button/ProgressButton';

const AddToCartModal: React.FC<{}> = () => {
  const { closeModal, openModal } = useModal();
  const { state: { recentOrder } } = useUser();
  const productImages = getProductImage(recentOrder?.contractId || '');

  const onViewCart = useCallback(() => {
    openModal(ModalTypes.VIEW_CART);
  }, [openModal])

  return (
    <Modal>
      <div className={classNames(styles.container, styles.container_without_height)}>
        <Cross className={classNames(styles.cancel)} onClick={closeModal} />
        <div className={styles.header}>
          <div className={styles.product_image}>
            <img src={productImages[0]} alt="" />
          </div>
        </div>
        <div className={classNames(styles.content, styles.content2)}>
          <div className={styles.add_to_cart_image_mobile}>
            <img src={productImages[0]} alt="" />
          </div>
          <span className={styles.message_add_cart}>{recentOrder?.title} added to cart!</span>
          <Button classname={styles.btn} onClick={onViewCart}>
            View cart
          </Button>
          <Button classname={styles.btn} onClick={closeModal} isGhost>
            Continue browsing
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(AddToCartModal, ModalTypes.ADD_TO_CART);