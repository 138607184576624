import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback } from "react";
import { gql, GraphQLClient } from 'graphql-request';
import { useUser } from "../providers/UserProvider";
import { useContract } from "./useContract";
import { useContractMethods } from "./useContractMethods";
import { fetchMetadata } from "../utils/fetch";
import { honoraryChimpImage } from "../utils/chimp-image";
import { buildForOneChimpMeta } from "../utils/chimp-traits";
import { META_ARW } from "../configs";
import { URL_REPLACEMENT } from "../constants/general";
import { ConnectedDiscordChimpMeta, SaleChimpMeta } from "../types/interfaces";
import { DISCORD_API } from "../configs";
import { OrderMeta } from "../types/interfaces";
import { useLogin } from "./useLogin";

const endpoint = `${DISCORD_API}/graphql`;

export const useUserHook = () => {
  const { account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();
  const { getAvatar, getConnectedDiscordChimps, getFavoriteChimps, getOrders, checkAdmin } = useUser();
  const { honoraryContract, batchContract } = useContract();
  const { checkOfferedForSaleBatched } = useContractMethods();

  const { checkValidToken } = useLogin();

  const getRandomAvatar = useCallback(async (connectedDiscordChimps: { chimpsTokenIds: string[], honoraryTokenIds: string[] }) => {
    if (!account) return;

    let { chimpIds, honoraryIds } = await batchContract.getTokenIds(account);
    const connectedChimpIds = [...connectedDiscordChimps.chimpsTokenIds, ...connectedDiscordChimps.honoraryTokenIds];

    const normalChimps = await checkOfferedForSaleBatched(chimpIds, false);
    const honoraryChimps = await checkOfferedForSaleBatched(honoraryIds, true);
    const result = [...normalChimps, ...honoraryChimps];

    const chimps = result.filter((item: SaleChimpMeta) => connectedChimpIds.includes(item.tokenId));
    let image = '';

    if (chimps.length > 0) {
      const randomChimpIndex = Math.floor(Math.random() * chimps.length);
      const randomChimp = chimps[randomChimpIndex];

      const data = buildForOneChimpMeta(randomChimp);
      image = data.image ?? '';

      if (connectedDiscordChimps.honoraryTokenIds.includes(randomChimp.tokenId)) {
        const metaUrl = (await honoraryContract.tokenURI(randomChimp.tokenId)).toString();
        const meta = await fetchMetadata(metaUrl.replace(URL_REPLACEMENT, META_ARW));
        image = honoraryChimpImage(meta.image || '');
      }
    }

    const randomAvatar = image;
    getAvatar(randomAvatar);
  }, [account, batchContract, checkOfferedForSaleBatched, getAvatar, honoraryContract]);

  const getUserData = useCallback(async () => {
    try {
      const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});
      
      const query = gql`
        query account {
          me {
            id
            admin
            discordId
            chimps {
              tokenId
              isHonorary
            }
            favoritedChimps {
              id
              tokenId
              isHonorary
              favoriteCount
            }
            orders {
              id
              firstName
              lastName
              mobile
              email
              shippingCountry
              shippingAddress1
              shippingAddress2
              shippingCity
              shippingPostCode
              status
              items {
                id
                productId
                contractId
                title
                variantName1
                variantOption1
                variantName2
                variantOption2
                variantName3
                variantOption3
                price
                currency
                quantity
              }
              subtotalPrice
              subtotalBananaPrice
              shippingPrice
              shippingTrackingCode
              shippedAt
              updatedAt
              createdAt
            }
          }
        }
      `;

      if (account) {
        const data = await graphQLClient.request(query);

        if (data?.me?.admin) {
          checkAdmin(data?.me?.admin);
        }

        if (data?.me?.discordId && data?.me?.chimps && data?.me?.chimps.length > 0) {
          const chimps: ConnectedDiscordChimpMeta[] = data.me.chimps;
          const chimpsTokenIds = chimps.filter(({ isHonorary }) => !isHonorary).map(({ tokenId }) => tokenId);
          const honoraryTokenIds = chimps.filter(({ isHonorary }) => isHonorary).map(({ tokenId }) => tokenId);
          const connectedDiscordChimps: { chimpsTokenIds: string[], honoraryTokenIds: string[] } = {
            chimpsTokenIds,
            honoraryTokenIds
          }
          getConnectedDiscordChimps(connectedDiscordChimps);
          await getRandomAvatar(connectedDiscordChimps);
        } else {
          getConnectedDiscordChimps({
            chimpsTokenIds: [],
            honoraryTokenIds: []
          })
        }

        if (data?.me?.favoritedChimps.length > 0) {
          const favoritedChimps = data.me.favoritedChimps;
          getFavoriteChimps(favoritedChimps);
        } else {
          getFavoriteChimps([]);
        }

        if (data?.me?.orders.length > 0) {
          const orders: OrderMeta[] = data.me.orders;
          orders.sort((a: OrderMeta, b: OrderMeta) => a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0);
          getOrders(orders);
        } else {
          getOrders([]);
        }
      }
    } catch (e) {
      getOrders([]);
    }
  }, [account, getConnectedDiscordChimps, getFavoriteChimps, getOrders, getRandomAvatar, checkAdmin, checkValidToken]);

  const getUserOrders = useCallback(async () => {
    try {
      
      const accessToken = await checkValidToken();

			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

      const query = gql`
      query account {
        me {
          orders {
            id
            firstName
            lastName
            mobile
            email
            shippingCountry
            shippingAddress1
            shippingAddress2
            shippingCity
            shippingPostCode
            status
            items {
              id
              productId
              contractId
              title
              variantName1
              variantOption1
              variantName2
              variantOption2
              variantName3
              variantOption3
              price
              currency
              quantity
            }
            subtotalPrice
            subtotalBananaPrice
            shippingPrice
            shippingTrackingCode
            shippedAt
            updatedAt
            createdAt
          }
        }
      }
    `;

      if (account) {
        const data = await graphQLClient.request(query);

        if (data?.me?.orders.length > 0) {
          const orders: OrderMeta[] = data.me.orders;
          orders.sort((a: OrderMeta, b: OrderMeta) => a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0);
          getOrders(orders);
        } else {
          getOrders([]);
        }
      }
    } catch (e) {
      console.log(e);
      getOrders([]);
    }
  }, [account, getOrders, checkValidToken]);

  const getUserFavoriteChimps = useCallback(async () => {
    try {
      const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

      const query = gql`
      query account {
        me {
          favoritedChimps {
            id
            tokenId
            isHonorary
            favoriteCount
          }
        }
      }
    `;

      if (account) {
        const data = await graphQLClient.request(query);

        if (data?.me?.favoritedChimps.length > 0) {
          const favoritedChimps = data.me.favoritedChimps;
          getFavoriteChimps(favoritedChimps);
        } else {
          getFavoriteChimps([]);
        }
      }
    } catch (e) {
      getFavoriteChimps([]);
    }
  }, [account, getFavoriteChimps, checkValidToken]);

  const getUserConnectedDiscordChimps = useCallback(async () => {
    try {
      const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

      const query = gql`
      query account {
        me {
          id
          discordId
          chimps {
            tokenId
            isHonorary
          }
        }
      }
    `;

      if (account) {
        const data = await graphQLClient.request(query);

        if (data?.me?.discordId && data?.me?.chimps && data?.me?.chimps.length > 0) {
          const chimps: ConnectedDiscordChimpMeta[] = data.me.chimps;
          const chimpsTokenIds = chimps.filter(({ isHonorary }) => !isHonorary).map(({ tokenId }) => tokenId);
          const honoraryTokenIds = chimps.filter(({ isHonorary }) => isHonorary).map(({ tokenId }) => tokenId);
          const connectedDiscordChimps: { chimpsTokenIds: string[], honoraryTokenIds: string[] } = {
            chimpsTokenIds,
            honoraryTokenIds
          }
          getConnectedDiscordChimps(connectedDiscordChimps);
          await getRandomAvatar(connectedDiscordChimps);
        } else {
          getConnectedDiscordChimps({
            chimpsTokenIds: [],
            honoraryTokenIds: []
          });
        }
      }
    } catch (e) {
      getConnectedDiscordChimps({
        chimpsTokenIds: [],
        honoraryTokenIds: []
      });
    }
  }, [account, getConnectedDiscordChimps, getRandomAvatar, checkValidToken]);

  return {
    getUserData,
    getUserOrders,
    getUserFavoriteChimps,
    getUserConnectedDiscordChimps
  }
}
