import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import classNames from 'classnames';
import InstructionCard from '../../components/instruction-card/InstructionCard';
import instructions from '../../data/mint-instructions.json';
import CoinBeige from '../../assets/images/coin-beige.png';
import SetupCronos from '../../assets/images/setup-cronos.png';
import SetupCronos2 from '../../assets/images/setup-cronos2.png';
import { Dot } from '../../assets/svgs';
import styles from './MintInstructions.module.scss';

const MintInstructions = () => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    setData(instructions);
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <img className={styles.bar_icon} src={CoinBeige} alt="" />
      </div>
      <div className={styles.title}>How To Mint?</div>
      <div className={styles.content}>
      {
        data?.Part1?.title && 
        <InstructionCard title={parse(data.Part1.title)}>
          {
            data?.Part1?.body.map((p: any, index: number) => {
              if (!p) return '';

              if (typeof p === 'string') return <p key={index}>{parse(p)}</p>

              return (
                <span key={index}>
                  <p>{parse(p.point)}</p>
                  <p className={styles.sub1} style={{ display: 'flex', alignItems: 'baseline' }}>
                    <Dot style={{ width: '0.4375em', marginRight: '.5em' }} />
                    {parse(p.subPoint)}
                  </p>
                </span>
              )
            })
          }
        </InstructionCard>
      }
      {
        data?.Part2?.title && 
        <InstructionCard title={data.Part2.title}>
          <p>{data.Part2.subTitle}</p>
          {
            data?.Part2?.body.map((para: any, index: number) => {
              if (!para) return '';

              return (
                <span key={index}>
                  <p className={styles.sub1}>{parse(para.label)}</p>
                  {
                    para?.points.map((p: any, index2: number) => {
                      return (
                        <p className={styles.sub2} key={index2}>
                          {parse(p)}
                        </p>
                      )
                    })
                  }
                </span>
              )
            })
          }
        </InstructionCard>
      }
      {
        data?.Part3?.title && 
        <InstructionCard title={data.Part3.title}>
          <div className={styles.metamask}>
            <div>
              <p>{parse(data?.Part3?.body[0]?.para)}</p>
              {
                data?.Part3?.body[0]?.content.map((para: any, index: number) => {
                  if (!para) return '';

                  return (
                    <span key={index}>
                      <>
                        <p className={styles.sub1}>{parse(para.label)}</p>
                        {
                          para?.points.map((p: any, index2: number) => {
                            return (
                              <p className={styles.sub2} key={index2}>
                                {parse(p)}
                              </p>
                            )
                          })
                        }
                      </>
                      {
                        index === 0 &&
                        <div className={classNames(styles.setup, styles.setup_mnt)}>
                          <img src={SetupCronos} alt="" />
                          <img src={SetupCronos2} alt="" />
                        </div>
                      }
                    </span>
                  )
                })
              }
            </div>
            <div className={classNames(styles.setup, styles.setup_dk)}>
              <img src={SetupCronos} alt="" />
              <img src={SetupCronos2} alt="" />
            </div>
          </div>

          <p>{parse(data?.Part3?.body[1]?.para)}</p>
          {
            data?.Part3?.body[1]?.content.map((para: any, index: number) => {
              if (!para) return '';

              return (
                <span key={index}>
                  <p className={styles.sub1}>{parse(para.label)}</p>
                  {
                    para?.articles.map((a: any, index2: number) => {
                      return (
                        <p className={classNames(styles.sub2, styles.article)} key={index2}>
                          <span className={styles.article_mark} />
                          {parse(a)}
                        </p>
                      )
                    })
                  }
                  {
                    para?.points.map((p: any, index2: number) => {
                      return (
                        <p className={styles.sub2} key={index2}>
                          {parse(p)}
                        </p>
                      )
                    })
                  }
                </span>
              )
            })
          }
        </InstructionCard>
      }
      {
        data?.Part4?.title && 
        <InstructionCard title={parse(data.Part4.title)}>
          {
            data?.Part4?.body.map((p: any, index: number) => {
              if (!p) return '';

              if (typeof p === 'string') return <p key={index}>{parse(p)}</p>

              return (
                <span key={index}>
                  <p>{parse(p.point)}</p>
                  <p className={styles.remark}>
                    {parse(p.subPoint)}
                  </p>
                </span>
              )
            })
          }
        </InstructionCard>
      }
      {
        data?.Part5?.title && 
        <InstructionCard title={parse(data.Part5.title)}>
          {
            data?.Part5?.body.map((p: any, index: number) => {
              if (!p) return '';

              return <p key={index}>{parse(p)}</p>
            })
          }
        </InstructionCard>
      }
      </div>
      <img className={styles.bottom_coin} src={CoinBeige} alt="" />
    </div>
  )
}

export default MintInstructions;