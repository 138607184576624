export enum NFTActionTypes {
  UPDATE_AMOUNT,
  GET_POOL_INFOS,
  GET_BALANCE,
  UPDATE_NEW_CHIMPS,
  UPDATE_REMAINING_SUPPLY,
}

export enum ChimpActionTypes {
  FILTER,
  SORTING,
  SALE_FILTER,
  MARKET_CHIMPS,
  MARKET_ALL_CHIMPS,
  MARKET_HONORARY_CHIMPS,
  GET_OWNED_CHIMPS,
  UPDATE_CHIMPS,
  GET_CHIMP_FOR_BUY_OR_SELL,
  GET_CHIMP_ATTRIBUTES,
  CLEAN_CHIMP_DATA,
  GET_OWNED_FAV_CHIMPS,
  UPDATE_FAV_CHIMPS,
  UPDATE_PRICE_FILTER_RANGES,
  GET_ALL_OFFERS_OF_CHIMP,
  GET_OFFER_CHIMP_BY_INDEX,
  GET_OFFER_USER_MADE,
  GET_ALL_OFFER_USER_MADE,
  GET_CHIMP_TRANSACTIONS,
}

export enum UserActionTypes {
  GET_CONNECTED_DISCORD_CHIMPS,
  GET_FAVORITE_CHIMP,
  UPDATE_ORDER_CART,
  ORDER_CHECKOUT,
  ORDER_UPDATE_STATUS,
  ORDER_UPDATE_RECENT,
  GET_ORDERS,
  GET_AVATAR,
  GET_WCRO_BALANCE,
  CHECK_ADMIN,
}

export enum MerchandiseActionTypes {
  GET_MERCHANDISE_LIST,
  GET_MERCHANDISE_BY_ID,
  RESET_MERCHANDISE_DETAIL,
  GET_QUOTA_LIST,
  GET_QUOTA,
}

export enum ModalActionTypes {
  UPDATE_MODAL_TYPE,
  UPDATE_OPERATION_STEP,
}

export enum MessageActionTypes {
  UPDATE_WARNINGS,
}

export enum LoreActionTypes {
  GET_ACTIVE_CHAPTRE_ID,
  GET_SCENES,
  GET_MY_CONTRIBUTIONS,
  UPDATE_SCENE,
  SELECT_SCENE,
  TOKEN_BALANCES,
  GET_RANK,
  CHECK_NFT_SHARED,
  GET_LORE_NFTS,
  CLEAR_DATA,
}

export enum SortingTypes {
  DEFAULT,
  LOWEST_PRICE = 'Price: low to high',
  HIGHEST_PRICE = 'Price: high to low',
  LOWEST_ID = 'Chimp Id: low to high',
  HIGHEST_ID = 'Chimp Id: high to low',
  TRAITS_FEW = 'Traits: few to many',
  TRAITS_MANY = 'Traits: many to few',
}

export enum ModalTypes {
  NONE,
  BUY_CHIMP,
  CONNECT_WALLET,
  PRE_SELL_OR_TRANSFER,
  SELL_CHIMP,
  TRANSFER_CHIMP,
  REMOVE_CHIMP,
  MAKE_OFFER_CHIMP,
  EDIT_OFFER_CHIMP,
  ACCEPT_OFFER_CHIMP,
  OVERLAY,
  ADD_TO_CART,
  SIGN_IN,
  WRONG_NETWORK,
  SELECT_SIZE,
  VIEW_CART,
  CHECKOUT_CONFIRMING,
  STAKE_CHIMP,
  UNSTAKE_CHIMP,
  CLAIM_GIFT,
  CONTRIBUTE,
}

export enum MintStatus {
  UNCONNECTED,
  UNSTARTED,
  READY,
  MINTING,
  SUCCEESS,
  SOLD_OUT,
}

export enum ModalChimpStatus {
  INITIAL,
  DISABLED,
  READY,
  CONFIRM,
  CONFIRMING,
  SUCCEESS,
  FAILED,
  APPROVE,
}

export enum ViewportSize {
  EXTRA_SMALL,
  SMALL,
  MEDIUM,
  LARGE,
  EXTRA_LARGE,
}

export enum ButtonStatus {
  NORMAL,
  LOADING,
  DONE,
  WARNING,
  FAILED,
}

export enum Directions {
  UP,
  DOWN,
  NULL,
}

export enum CardTypes {
  MARKET = 'market',
  OWNING = 'my-chimps',
}

export enum ChimpOperateTypes {
  BUY,
  SELL,
  ADJUST,
  REMOVE,
  TRANSFER,
  OFFER,
  ACCEPT
}

export enum SaleFilterTabs {
  ALL = 'All Chimps',
  LISTED = 'Listed',
  UNLISTED = 'Unlisted',
  HONORARY = 'Honorary',
  FAVORITES = 'Favorites',
}

export enum TraitTypes {
  Background = 'Background',
  Body = 'Body',
  Clothes = 'Clothes',
  Earrings = 'Earrings',
  Eyes = 'Eyes',
  Headgear = 'Headgear',
  Mouth = 'Mouth',
}

export enum ConnectWalletType {
  NONE = 'NONE',
  METAMASK = 'METAMASK',
  DEFI_WALLET = 'DEFI_WALLET',
  WALLET_CONNECT = 'WALLET_CONNECT',
}

export enum ListedSortingTypes {
  LOWEST_PRICE = 'Lowest Listed Price',
  HIGHEST_PRICE = 'Highest Listed Price',
  LOWEST_ID = 'Lowest Chimp Id',
  HIGHEST_ID = 'Highest Chimp Id',
  LEAST_TRAITS = 'Least Traits',
  MOST_TRAITS = 'Most Traits',
}

export enum OwnChimpType {
  NORMAL = 'Chimps',
  HONORARY = 'Honorary Chimps'
}

export enum MarketSearchParams {
  LIST_TYPE = 'listType',
  SORT = 'sort',
  MIN_PRICE = 'minPrice',
  MAX_PRICE = 'maxPrice',
  CHIMP_ID = 'chimpId',
}

export enum CheckoutOrderActionTypes {
  DISABLED,
  READY,
  CALCULATE_SHIPPING,
  CONFIRMING,
  FAILED,
  FINISH,
}

export enum SignInTypes {
  CHECKOUT = 'CHECKOUT',
  FAVORITE_CHIMP = 'FAVORITE_CHIMP',
  GENERIC = 'GENERIC',
  SHOP_MERCH = 'SHOP_MERCH',
}

export enum ChimpTransactionTypes {
  TRANSFER = 'Transfer',
  MINTED = 'Minted',
  PUT_ON_SALE = 'Put On Sale',
  REMOVE_SALE = 'Remove Sale',
  SOLD = 'Sold',
  OFFER_MADE = 'Offer Made',
  OFFER_WITHDRAWN = 'Offer Withdrawn',
  OFFER_ACCEPTED = 'Offer Accepted',
}

export enum GiftRedemptionTiers {
  GOLD =  'Gold',
  SILVER = 'Silver',
  BRONZE = 'Bronze',
}

export enum MerchPaymentTypes {
  USDC = 'USDC',
  BANANAS = 'Bananas',
}
