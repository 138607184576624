
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useContractMethods } from "../../hooks/useContractMethods";
import { useChimpsHook } from "../../hooks/useChimpsHook";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from './ChimpSuccessModal';
import { Arrow, Cross, Dot, Info } from "../../assets/svgs";
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import { shortenAddress } from "../../utils/address";
import { getChimpDisplayTitle } from "../../utils/title";
import styles from './ChimpModal.module.scss';

const TransferChimpModal = () => {
  const [address, setAddress] = useState('');
  const [trxnId, setTrxnId] = useState('');

  const { step, closeModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { state: { forBuyOrSaleChimp } } = useChimp();
  const { updateChimpData } = useChimpsHook();

  const { tokenId, id: chimpId, image, isHonorary, name } = forBuyOrSaleChimp;

  const { transferChimp } = useContractMethods();

  const handleTransfer = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      const result = await transferChimp(address, tokenId, isHonorary);

      if (result.transactionHash) {
        setTrxnId(result.transactionHash);
      }

      updateOperationStep(ModalChimpStatus.SUCCEESS);

      updateChimpData(tokenId, chimpId, ChimpOperateTypes.TRANSFER, isHonorary);
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);
      
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [transferChimp, onChangeWarnings, updateOperationStep, updateChimpData, tokenId, chimpId, address, isHonorary]);

  const handleClick = useCallback(() => {
    switch (step) {
      case ModalChimpStatus.READY:
        updateOperationStep(ModalChimpStatus.CONFIRM);
        break;
      case ModalChimpStatus.CONFIRM:
        handleTransfer();
        break;
      case ModalChimpStatus.FAILED:
        handleTransfer();
        break;
      default:
        break;
    }
  }, [step, handleTransfer, updateOperationStep])

  const handleBack = useCallback(() => {
    switch (step) {
      case ModalChimpStatus.CONFIRM:
        updateOperationStep(ModalChimpStatus.READY);
        break;
      case ModalChimpStatus.FAILED:
        updateOperationStep(ModalChimpStatus.READY);
        break;
      default:
        break;
    }
  }, [step, updateOperationStep])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value);
  }, [setAddress])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep])

  const confirmInput = useMemo(() => [ModalChimpStatus.CONFIRM, ModalChimpStatus.CONFIRMING, ModalChimpStatus.FAILED].includes(step), [step])

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;

    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;

    return ButtonStatus.NORMAL;
  }, [step])

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRM && step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {
        step !== ModalChimpStatus.SUCCEESS &&
        <div className={styles.container}>
          {step !== ModalChimpStatus.READY && <Arrow className={styles.back} onClick={handleBack} />}
          <Cross className={styles.cancel} onClick={closeModal} />
          <div className={styles.header}>
            <span className={styles.title}>
              {!confirmInput && 'Transfer to another wallet'}
              {confirmInput && 'Review Transfer'}
            </span>
            <div className={styles.summary}>
              <img className={styles.img} src={image || ''} alt="" />
              <div>
                <div className={styles.chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
                <div  className={styles.token_id}>Token ID: {tokenId}</div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <label htmlFor="transfer" className={styles.label}>Which wallet do you want to transfer to? </label>
            <input id="transfer" className={styles.input} disabled={confirmInput} value={address} onChange={handleChange} placeholder="Paste wallet address" />
            <div className={styles.buttons}>
              <ProgressButton disabled={!address} status={buttonStatus} isGhost={step === ModalChimpStatus.READY && !!address} onClick={handleClick}>
                {step === ModalChimpStatus.READY && 'Next'}
                {step === ModalChimpStatus.CONFIRM && 'Confirm'}
                {step === ModalChimpStatus.CONFIRMING && 'Confirming...'}
                {step === ModalChimpStatus.FAILED && 'Retry'}
              </ProgressButton>
            </div>
            {
              !confirmInput && 
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  <p>
                    Make sure your wallet address supports the Cronos Chain network (e.g. Metamask or a DeFi wallet). 
                    If the destination address does not support the Cronos Chain network, you might lose your Chimp.
                  </p>
                  <p>
                    Do not send your Chimp to exchanges or the Crypto.com NFT platform.
                  </p>
                  <p>
                    There is no fee to transfer Chimps.
                  </p>
                </span>
              </div>
            }
            {
              (step === ModalChimpStatus.CONFIRM || step === ModalChimpStatus.FAILED) && 
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  <span className={styles.info_text_row}>By confirming, you have ensured that: </span>
                  <span className={styles.info_text_row}>
                    <Dot className={styles.info_dot} />
                    The transfer address is on the Cronos chain
                  </span>
                  <span className={styles.info_text_row}>
                    <Dot className={styles.info_dot} />
                    <span>
                      The transfer address <b><u>IS NOT</u></b> an Exchange or the Crypto.com platform
                    </span>
                  </span>
                </span>
              </div>
            }
            {
              step === ModalChimpStatus.CONFIRMING && 
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Please confirm the transaction in your wallet.  
                </span>
              </div>
            }
          </div>
        </div>
      }
      {
        step === ModalChimpStatus.SUCCEESS && 
        <ChimpSuccessModal 
          closeModal={closeModal} 
          subTitle="Transaction Confirmed"
          description={`Chimp #${tokenId} transferred to ${shortenAddress(address)}`} 
          trxnId={trxnId} 
          type={ChimpOperateTypes.TRANSFER}
        />
      }
    </Modal>
  )
}

export default DisplayModal(TransferChimpModal, ModalTypes.TRANSFER_CHIMP);