import { createContext, FC, ReactElement, useCallback, useContext, useReducer } from 'react';
import { MerchandiseMeta, QuotaMeta } from '../types/interfaces';
import { MerchandiseActionTypes } from '../types/enums';

export interface State {
  merchandiseList: MerchandiseMeta[],
  merchandiseDetail: MerchandiseMeta,
  quotaList: QuotaMeta[];
  quotaDetail: QuotaMeta[];
};

interface IContext {
  state: State,
  getMerchandiseList: (merchandiseList: MerchandiseMeta[]) => void,
  getMerchandiseDetail: (merchandiseDetail: MerchandiseMeta) => void,
  resetMerchandiseDetail: () => void,
  getAvailableQuotaList: (quotaList: QuotaMeta[]) => void,
  getAvailableQuota : (quotaDetail: QuotaMeta[]) => void,
};

export const initialState: State = {
  merchandiseList: [],
  merchandiseDetail: {
    id: "",
    contractId: "",
    title: "",
    imageUrl: "",
    description: "",
    price: 0,
    currency: "",
    bananaPrice: 0,
    variantName1: "",
    variantName2: "",
    variantName3: "",
    variantOptions1: [],
    variantOptions2: [],
    variantOptions3: [],
  },
  quotaList:[],
  quotaDetail: [],
};

const Context = createContext<IContext>({
  state: initialState,
  getMerchandiseList: () => { },
  getMerchandiseDetail: () => { },
  resetMerchandiseDetail: () => { },
  getAvailableQuotaList: () => { },
  getAvailableQuota: () => { },
});

export const useMerchandise = () => {
  return useContext(Context);
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case MerchandiseActionTypes.GET_MERCHANDISE_LIST:
      return {
        ...state,
        merchandiseList: action.merchandiseList
      }
    case MerchandiseActionTypes.GET_MERCHANDISE_BY_ID:
      return {
        ...state,
        merchandiseDetail: action.merchandiseDetail
      }
    case MerchandiseActionTypes.RESET_MERCHANDISE_DETAIL:
      return {
        ...state,
        merchandiseDetail: {
          id: "",
          contractId: "",
          title: "",
          imageUrl: "",
          description: "",
          price: 0,
          currency: "",
          bananaPrice: 0,
          variantName1: "",
          variantName2: "",
          variantName3: "",
          variantOptions1: [],
          variantOptions2: [],
          variantOptions3: [],
        }
      }
      case MerchandiseActionTypes.GET_QUOTA_LIST:
        return {
          ...state,
          quotaList: action.quotaList
        }
      case MerchandiseActionTypes.GET_QUOTA:
        return {
          ...state,
          quotaDetail: action.quotaDetail
        }
    default:
      throw new Error();
  }
};

const MerchandiseProvider: FC<{ children: ChildNode | ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const getMerchandiseList = useCallback((merchandiseList: MerchandiseMeta[]) => {
    dispatch({
      type: MerchandiseActionTypes.GET_MERCHANDISE_LIST,
      merchandiseList,
    });
  }, []);

  const getMerchandiseDetail = useCallback((merchandiseDetail: MerchandiseMeta) => {
    dispatch({
      type: MerchandiseActionTypes.GET_MERCHANDISE_BY_ID,
      merchandiseDetail,
    })
  }, []);

  const resetMerchandiseDetail = useCallback(() => {
    dispatch({
      type: MerchandiseActionTypes.RESET_MERCHANDISE_DETAIL,
    })
  }, []);
  const getAvailableQuotaList = useCallback((quotaList: QuotaMeta[]) => {
    dispatch({
      type: MerchandiseActionTypes.GET_QUOTA_LIST,
      quotaList,
    })
  },[]);
  const getAvailableQuota = useCallback((quotaDetail: QuotaMeta[]) => {
    dispatch({
      type: MerchandiseActionTypes.GET_QUOTA,
      quotaDetail,
    })
  },[]);

  const context = {
    state,
    getMerchandiseList,
    getMerchandiseDetail,
    resetMerchandiseDetail,
    getAvailableQuotaList,
    getAvailableQuota
  };

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
};

export default MerchandiseProvider;