import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useConnectDiscord } from '../../hooks/useConnectDiscord';
import styles from './DiscordRedirected.module.scss';

enum Status {
  PENDING,
  SUCCESS,
  FAILURE,
}

const DiscordRedirected = () => {
  const [status, setStatus] = useState(Status.PENDING)

  const { handleRedirect } = useConnectDiscord();

  useEffect(() => {
    handleRedirect()
      .then(() => {
        setStatus(Status.SUCCESS)
      })
      .catch(() => {
        setStatus(Status.FAILURE)
      });
  }, [handleRedirect])

  return (
    <div>
      {
        status === Status.PENDING && (
          <div className={styles.errorMsg}>
            Please wait...
          </div>
        )
      }
      {
        status === Status.SUCCESS && (
          <div className={styles.errorMsg}>
            You have been connected to the Cronos Chimp Club Discord and allocated a "Verified Chimp" role! Please check in the Discord.
          </div>
        )
      }
      {
        status === Status.FAILURE && (
          <div className={styles.errorMsg}>
            Oops looks like something went wrong, please try again later.
          </div>
        )
      }
      {
        status !== Status.PENDING && (
          <div className={styles.errorMsg}>
            Go back to <Link to="/my-chimps">My Chimps</Link>
          </div>
        )
      }
    </div>
  )
}

export default DiscordRedirected
