import React, { useCallback } from "react";
import Modal, { DisplayModal } from "./Modal";
import { ButtonStatus, ModalTypes } from "../../types/enums";
import styles from './ChimpModal.module.scss';
import { ErrorIcon, Cross } from "../../assets/svgs";
import { useModal } from "../../providers/ModalProvider";
import { WALLET_WRONG_NETWORK } from "../../constants/errors";
import ProgressButton from "../button/ProgressButton";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { addOrSwitchToCronosNetwork, WALLETS } from "../../utils/connect-wallet";
import { LOCAL_STORAGE_WALLET_TYPE } from "../../constants/labels";

const WrongNetwork: React.FC<{}> = () => {
  const { closeModal } = useModal();
  const { error, activate } = useWeb3React();

  const handleClick = useCallback(async () => {
    try {
      const currentWalletType = window.localStorage.getItem(LOCAL_STORAGE_WALLET_TYPE) || '';
      const { connector } = WALLETS[currentWalletType];
      if (window.ethereum && error instanceof UnsupportedChainIdError) {
        const setup = await addOrSwitchToCronosNetwork();
        if (setup) {
          activate(connector)
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeModal();
    }
  }, [activate, closeModal, error]);

  return (
    <Modal allowClickOutside>
      <div className={`${styles.container} ${styles.container_body}`}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <div className={styles.chimp_logo_wrap}>
          <span className={styles.error_icon}>
            <ErrorIcon />
          </span>
        </div>
        <div className={`${styles.title} ${styles.title_alone}`}>Wrong Network</div>
        <div className={styles.message}>{WALLET_WRONG_NETWORK}</div>

        <div className={styles.buttons}>
          <ProgressButton disabled={false} status={ButtonStatus.NORMAL} onClick={handleClick}>
            Switch to Cronos Chain
          </ProgressButton>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(WrongNetwork, ModalTypes.WRONG_NETWORK);