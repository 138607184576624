import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import OrderItem from '../../components/shop/OrderItem';
import { useUser } from '../../providers/UserProvider';
import { useMessage } from '../../providers/MessageProvider';
import { useModal } from '../../providers/ModalProvider';
import { useMerchandiseHook } from '../../hooks/useMerchandiseHook';
import { useMerchandisContractMethods } from '../../hooks/useMerchandisContractMethods';
import { CheckoutItemForContract } from '../../types/interfaces';
import { useUserHook } from '../../hooks/useUserHook';
import { SuccessOutline, Alert } from '../../assets/svgs';
import styles from './OrderDetail.module.scss';
import { OrderMeta } from '../../types/interfaces';
import { convertOrderStatus } from '../../utils/shop-merch';
import { formatDate } from '../../utils/time';
import { MerchPaymentTypes, ModalTypes, SignInTypes } from '../../types/enums';
import { convertUSDCPriceToDecimal } from '../../utils/price';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import { CHECKOUT_ERROR_GENERIC, CHIMP_ERROR_REJECTED } from '../../constants/errors';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_CART, LOCAL_STORAGE_ORDER_ID } from '../../constants/labels';
import Button from '../../components/button/ProgressButton';

const DEFAULT_ORDER_DETAIL = {
  id: '',
  firstName: '',
  lastName: '',
  mobile: '',
  email: '',
  shippingCountry: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingCity: '',
  shippingPostCode: '',
  status: '',
  items: [],
  subtotalPrice: 0,
  subtotalBananaPrice: 0,
  shippingPrice: 0,
  shippingTrackingCode: '',
  shippedAt: '',
  updatedAt: '',
  createdAt: '',
}

const OrderDetail: React.FC<{}> = () => {
  const params = useParams<{ id: string }>();
  const { active, account } = useWeb3React();
  const { state: { orders }, updateOrderCart } = useUser();
  const { getUserOrders } = useUserHook();
  const { openModal, closeModal } = useModal();
  const { verifyOrderCheckout, cancelOrder } = useMerchandiseHook();
  const { approveWCRO, getWCROBalance, getAllowanceWCRO, checkout, payForShippingCost } = useMerchandisContractMethods();
  const { onChangeWarnings } = useMessage();

  const [orderDetail, setOrderDetail] = useState<OrderMeta>(DEFAULT_ORDER_DETAIL);
  const [WCROBalance, setWCROBalance] = useState('');
  const [allowanceWCRO, setAllowanceWCRO] = useState<string>('');

  useEffect(() => {
    if (orders.data.length > 0) {
      const orderItems = orders.data.filter((item: OrderMeta) => item.id === Number(params.id));
      setOrderDetail(orderItems.length > 0 ? orderItems[0] : DEFAULT_ORDER_DETAIL);
    } else {
      getUserOrders();
    }
  }, [getUserOrders, orders, params.id]);

  useEffect(() => {
    if (account) {
      getWCROBalance().then((balance: string) => {
        setWCROBalance(balance);
      });

      getAllowanceWCRO().then((allowance: string) => {
        setAllowanceWCRO(allowance);
      });
    }
  }, [getWCROBalance, account, getAllowanceWCRO]);

  const makePayment = useCallback(async () => {
    try {
      if (!active || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
        return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
      }

      openModal(ModalTypes.CHECKOUT_CONFIRMING, {
        totalPrice: orderDetail.subtotalPrice + orderDetail.shippingPrice > 0 ? 
          `${formatNumberWithComma(roundNumberWithFix(orderDetail.subtotalPrice + orderDetail.shippingPrice, 3))} ${MerchPaymentTypes.USDC}` : '',
        totalBananasPrice: orderDetail.subtotalBananaPrice > 0 ? 
          `${formatNumberWithComma(roundNumberWithFix(orderDetail.subtotalBananaPrice.toString(), 3))} ${MerchPaymentTypes.BANANAS}` : '',
      });

      if (orderDetail.id) {
        const wcroBalance = new BigNumber(convertUSDCPriceToDecimal(WCROBalance));
        const amount = new BigNumber(orderDetail.shippingPrice).plus(orderDetail.subtotalPrice);

        if (wcroBalance.lt(amount)) {
          onChangeWarnings({ message: 'Not enough USDC balance' });
        } else {
          if (orderDetail.status === 'PAYMENT_PENDING') {
            if (amount.gt(new BigNumber(convertUSDCPriceToDecimal(allowanceWCRO)))) {
              await approveWCRO(amount.toString());
            }
  
            const payloadContract = orderDetail.items.reduce((arr, item) => {
              for (let i = 0; i < item.quantity; i++) {
                if (item.currency !== MerchPaymentTypes.BANANAS) {
                  arr.push({
                    productId: item.contractId,
                    subProduct: item.variantOption1,
                  });
                }
              }
              return arr;
            }, [] as CheckoutItemForContract[]);

            if (payloadContract.length > 0) {
              await checkout(Number(orderDetail.id), orderDetail.shippingPrice, payloadContract);
            } else {
              await payForShippingCost(orderDetail.shippingPrice);
            }
          }

          if (orderDetail.id) {
            const verify = await verifyOrderCheckout(Number(orderDetail.id));
            if (verify.id) {
              window.localStorage.removeItem(LOCAL_STORAGE_CART);
              window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
              updateOrderCart([]);
              await getUserOrders();
            }
          }
        }
      }
    } catch (error: any) {
      if (error?.response && error?.response?.errors[0]?.extensions.code === 'BAD_USER_INPUT') {
        onChangeWarnings({ message: error.response.errors[0].message });
      }

      if (error?.code) {
        switch (error.code) {
          case 4001:
            onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
            break;
          case -32603:
            const errMsg = error.data.message;
            const message = 
              errMsg && typeof errMsg === 'string' ? errMsg.split(':')[1] : '';
            onChangeWarnings({ message });
            break;
          default:
            onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
            break;
        }
      } else {
        onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
      }
    } finally {
      closeModal();
    }
  }, [
    WCROBalance, active, allowanceWCRO, orderDetail, 
    approveWCRO, checkout, payForShippingCost, closeModal, getUserOrders, onChangeWarnings, openModal, updateOrderCart, verifyOrderCheckout
  ]);

  const handleCancelOrder = useCallback(async () => {
    try {
      if (!active || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
        return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
      }

      let text = "Are you sure want to cancel this order?";
      if (window.confirm(text) === true) {
        if (orderDetail.id) {
          openModal(ModalTypes.OVERLAY);
          const cancel = await cancelOrder(Number(orderDetail.id));
          if (cancel?.status === "CANCELED") {
            window.localStorage.removeItem(LOCAL_STORAGE_CART);
            window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
            updateOrderCart([]);
            await getUserOrders();
          }
        }
      }
    } catch (error: any) {
      onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
    } finally {
      closeModal();
    }
  }, [active, cancelOrder, closeModal, getUserOrders, onChangeWarnings, openModal, orderDetail.id, updateOrderCart]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{`Order #${params.id || ''}`}</h1>
      <div className={styles.wrapper}>
        <div className={styles.info_wrapper}>
          <div className={styles.info_panel}>
            <div className={styles.info_title}>Order status</div>
            {orderDetail.status &&
              <div className={classNames(styles.status, { [styles.status_shipped]: orderDetail.status === 'SHIPPED' })}>
                {
                  ['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(orderDetail.status)
                  ? <Alert /> : <SuccessOutline />
                } 
                {convertOrderStatus(orderDetail.status)}
              </div>
            }
            {['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(orderDetail.status) &&
              <div className={styles.btn_group}>
                <Button
                  classname={styles.primary_btn}
                  onClick={makePayment}
                >
                  {orderDetail.status === 'PAYMENT_PENDING' ? 'Make payment' : 'Confirm Order'}
                </Button>
                {
                  orderDetail.status === 'PAYMENT_PENDING' && 
                  <Button
                    classname={styles.primary_btn}
                    onClick={handleCancelOrder}
                    isGhost
                  >
                    Cancel order
                  </Button>
                }
              </div>
            }
            {orderDetail.status === 'SHIPPED' &&
              <>
                <div className={styles.field_text}>
                  <span>Courier</span>
                  <span className={styles.field_value}>DHL</span>
                </div>
                <div className={styles.field_text}>
                  <span>Tracking number</span>
                  <a className={styles.field_value_href} rel="noreferrer noopener" target="_blank" href={`https://www.dhl.com/hk-en/home/tracking.html?tracking-id=${orderDetail.shippingTrackingCode}`}>{orderDetail.shippingTrackingCode}</a>
                </div>
                <div className={styles.field_text}>
                  <span>Ship date</span>
                  <span className={styles.field_value}>{orderDetail.shippedAt ? formatDate(orderDetail.shippedAt, 'numeric', 'long', 'numeric') : '-'}</span>
                </div>
              </>
            }

          </div>
          <div className={styles.info_panel}>
            <div className={styles.info_title}>Order details</div>
            <div className={styles.field_text}>
              <span>Order date</span>
              <span className={styles.field_value}>{orderDetail.createdAt ? formatDate(orderDetail.createdAt, 'numeric', 'long', 'numeric') : '-'}</span>
            </div>
          </div>
          <div className={styles.info_panel}>
            <div className={styles.info_title}>Shipping details</div>
            <div className={classNames(styles.field_text, styles.receiver)}>{`${orderDetail.firstName} ${orderDetail.lastName}`}</div>
            <div className={styles.field_text}>{`${orderDetail.shippingAddress2 ? `${orderDetail.shippingAddress1}, ${orderDetail.shippingAddress2}` : orderDetail.shippingAddress1}`}</div>
            <div className={styles.field_text}>{`${orderDetail.shippingCity ? `${orderDetail.shippingCity}, ${orderDetail.shippingCountry}` : orderDetail.shippingCountry}`}</div>
            <div className={classNames(styles.field_text, styles.email)}>{orderDetail.email}</div>
            <div className={styles.field_text}>{orderDetail.mobile}</div>
          </div>
        </div>
        <div className={styles.detail_wrapper}>
          <div className={styles.detail_content}>
            <OrderItem
              merchandiseItems={orderDetail.items}
              subTotal={orderDetail.subtotalPrice}
              subTotalBananas={orderDetail.subtotalBananaPrice}
              shippingPrice={orderDetail.shippingPrice}
              isCartOrder={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetail;