import { useMemo } from 'react';
import { LIMIT_TO_UNLOCK } from '../../constants/lore';
import styles from './UnlockingProgress.module.scss';

interface Props {
  amount: number;
}

const UnlockingProgress = ({ amount }: Props) => {
  const percentage = useMemo(() => Number(amount / LIMIT_TO_UNLOCK * 100).toFixed(2), [amount]);

  return (
    <div className={styles.component}>
      <input
        className={styles.bar}
        type="range"
        value={+percentage}
        style={{
          background: `linear-gradient(to right, var(--highlight-color) 0%, var(--highlight-color) ${percentage}%, var(--strip-bar-bg) ${percentage}%, var(--strip-bar-bg) 100%)`
        }}
        disabled
      />
      
      <div className={styles.info}>
        <span>{amount} contributors</span>
        <span>{LIMIT_TO_UNLOCK} to unlock</span>
      </div>
    </div>
  )
}

export default UnlockingProgress;
