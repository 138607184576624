import { GIFT_CARD_LOGO, GIFT_TYPES, PRODUCT_DESCRIPTION, REDEEM_LINKS } from "../constants/chimpay";

export function getProductDescription(name: string) {
  if (!name) return '';

  const type = GIFT_TYPES.find((t) => name.toLowerCase().includes(t.toLowerCase())) || '';
  return PRODUCT_DESCRIPTION[type as (keyof typeof PRODUCT_DESCRIPTION)]; 
};

export function getLogoType(name: string) {
  if (!name) return '';

  const type = GIFT_TYPES.find((t) => name.toLowerCase().includes(t.toLowerCase())) || '';
  return GIFT_CARD_LOGO[type as (keyof typeof GIFT_CARD_LOGO)];
}

export function getRedeemLink(name: string) {
  const type = Object.keys(REDEEM_LINKS).find((key) => name.toLowerCase().includes(key.toLowerCase()));
  return REDEEM_LINKS[type as keyof typeof REDEEM_LINKS] || '';
}

