import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useChimpayHook from '../../hooks/useChimpayHook';
import { GiftOrder } from '../../types/interfaces';
import { formatDateTime } from '../../utils/time';
import { getRedeemLink } from '../../utils/gift';

const CSV_COLUMNS = [
  'id',
  'status',
  'giftId',
  'giftName',
  'giftPrice',
  'currency',
  'country',
  'costBananas',
  'claimedAt',
  'transactionId',
  'recipientEmail',
  'recipientAccount',
  'recipientName'
]

const getDownloadLink = (data: GiftOrder[]) => {
  let str = 'data:text/csv;charset=utf-8,';
  str += CSV_COLUMNS.join(',') + '\r\n';

  data.forEach((item) => {
    const rowValue = CSV_COLUMNS.map((key) => (item as any)[key]);
    const row = rowValue.join(",");
    str += row + "\r\n";
  });

  return str;
};

const GiftOrders = () => {
  const { getSeasonPeriod, getGiftOrders, sendRedemptionCode } = useChimpayHook();

  const [orderList, setOrderList] = useState<GiftOrder[]>([]);

  const { start, end } = useMemo(() => getSeasonPeriod().season, [getSeasonPeriod]);

  useEffect(() => {
    if (start && end) {
      getGiftOrders(new Date(start).toISOString(), new Date(end).toISOString()).then((data) => {
        if (data && Array.isArray(data)) {
          setOrderList(data);
        }
      });
    }
  }, [start, end, getGiftOrders])

  const [fromTime, setFromTime] = useState(formatDateTime(start));
  const [toTime, setToTime] = useState(formatDateTime(end));

  const handleSearch = useCallback(() => {
    const isoTimeFormat = /^\d{4}-(0|1){1}\d{1}-[0-3]{1}\d{1} [0-2]{1}\d{1}:[0-5]{1}\d{1}:[0-5]{1}\d{1}/;

    if (isoTimeFormat.test(fromTime) && isoTimeFormat.test(toTime)) {
      getGiftOrders(new Date(fromTime).toISOString(), new Date(toTime).toISOString()).then((data) => {
        if (data && Array.isArray(data)) {
          setOrderList(data);
        }
      });
    } else {
      alert('Strictly input as YYYY-MM-DD HH:mm:ss!');
    }
  }, [fromTime, toTime, getGiftOrders]);

  const [sendingId, setSendingId] = useState<string | number>('');

  const handleSendRedemptionCode = useCallback(async (order: GiftOrder) => {
    try {
      const c = window.confirm(`Do you want to send the redemption code to ${order.recipientEmail}`);
      let email = order.recipientEmail;

      if (!c) {
        const p = window.prompt('Please input a valid email address: ');
        email = p || '';
      }

      if (email) {
        setSendingId(order.id);
        await sendRedemptionCode({ 
          orderId: order.id,
          redemptionLink: getRedeemLink(order.giftName),
          receiverEmail: email
        });
      }
    } catch (error) {
      alert('Failed to send redemption email!');
    } finally {
      setSendingId('');
      await handleSearch();
    }
  }, [sendRedemptionCode, handleSearch]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <input
          onChange={(e) => setFromTime(e.target.value)}
          value={fromTime}
          placeholder="Search From"
          style={{ width: '150px' }}
        />
        <input
          onChange={(e) => setToTime(e.target.value)}
          value={toTime}
          placeholder="Search To"
          style={{ width: '150px' }}
        />
        &nbsp;
        <button onClick={handleSearch}>
          Search
        </button>

        <a href={getDownloadLink(orderList)} download="Gift-Orders.csv" style={{ marginLeft: '20px' }}>
          Download CSV 
        </a>
      </div>
      <br />

      <table style={{ width: '100%', textAlign: 'center', lineHeight: '32px', borderCollapse: 'collapse' }}>
        <thead style={{ lineHeight: '48px' }}>
          <tr>
            <th>Order Id</th>
            <th>Status</th>
            <th>Card Id</th>
            <th>Card Name</th>
            <th>Card Price</th>
            <th>Card Currency</th>
            <th>Card Country</th>
            <th>Banana Cost</th>
            <th>Claimed At</th>
            <th>Reloadly Tx Id</th>
            <th>Recipient Email</th>
            <th>Recipient Account</th>
            <th>Recipient Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: '14px' }}>
          {
            orderList.map((item) => {
              const { id, status, giftId, giftName, giftPrice, currency, country, costBananas, claimedAt, transactionId, recipientEmail, recipientAccount, recipientName } = item;
              return (
                <tr key={id} style={{ borderTop: '1px solid #C3C6CE' }}>
                  <td>{id}</td>
                  <td>{status}</td>
                  <td>{giftId}</td>
                  <td>{giftName}</td>
                  <td>{giftPrice}</td>
                  <td>{currency}</td>
                  <td>{country}</td>
                  <td>{costBananas}</td>
                  <td>{formatDateTime(claimedAt)}</td>
                  <td>{transactionId}</td>
                  <td>{recipientEmail}</td>
                  <td>{recipientAccount}</td>
                  <td>{recipientName}</td>
                  <td>
                    {
                      (status === 'COMPLETED' || status === 'PENDING') && transactionId && 
                      <button onClick={() => handleSendRedemptionCode(item)}>
                        {sendingId === item.id ? 'Sending...' : 'Email Redemption Code'}
                      </button>
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default GiftOrders;
