import { createContext, FC, ReactElement, useCallback, useContext, useEffect, useReducer } from 'react';
import { useWeb3React } from "@web3-react/core";
import { FavoriteChimpMeta, OrderCartMeta, OrderMeta } from '../types/interfaces';
import { UserActionTypes } from '../types/enums';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_CART, LOCAL_STORAGE_ORDER_ID } from '../constants/labels';

export interface State {
  connectedDiscordChimps: {
    chimpsTokenIds: string[],
    honoraryTokenIds: string[],
  },
  favoriteChimps: FavoriteChimpMeta[],
  orders: {
    data: OrderMeta[],
    isLoading: boolean,
  }
  orderCart: OrderCartMeta[],
  recentOrder: OrderCartMeta | null,
  avatar: string,
  WCROBalance: number,
  admin: boolean;
};

interface IContext {
  state: State,
  getConnectedDiscordChimps: (connectedDiscordChimps: { chimpsTokenIds: string[], honoraryTokenIds: string[] }) => void,
  getFavoriteChimps: (favoriteChimps: FavoriteChimpMeta[]) => void,
  updateOrderCart: (orderCart: OrderCartMeta[]) => void,
  updateRecentOrder: (orderItem: OrderCartMeta) => void,
  getOrders: (orders: OrderMeta[]) => void,
  getAvatar: (avatar: string) => void,
  getWCROBalance: (balance: number) => void,
  checkAdmin: (admin: boolean) => void,
};

export const initialState: State = {
  connectedDiscordChimps: {
    chimpsTokenIds: [],
    honoraryTokenIds: [],
  },
  favoriteChimps: [],
  orders: {
    data: [],
    isLoading: true,
  },
  orderCart: [],
  recentOrder: null,
  avatar: '',
  WCROBalance: 0,
  admin: false,
};

const Context = createContext<IContext>({
  state: initialState,
  getConnectedDiscordChimps: () => { },
  getFavoriteChimps: () => { },
  updateOrderCart: () => { },
  updateRecentOrder: () => { },
  getOrders: () => { },
  getAvatar: () => { },
  getWCROBalance: () => { },
  checkAdmin: () => {},
});

export const useUser = () => {
  return useContext(Context);
};

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case UserActionTypes.GET_CONNECTED_DISCORD_CHIMPS:
      return {
        ...state,
        connectedDiscordChimps: action.connectedDiscordChimps
      }
    case UserActionTypes.GET_FAVORITE_CHIMP:
      return {
        ...state,
        favoriteChimps: action.favoriteChimps
      }
    case UserActionTypes.UPDATE_ORDER_CART:
      return {
        ...state,
        orderCart: action.orderCart
      }
    case UserActionTypes.ORDER_UPDATE_RECENT:
      return {
        ...state,
        recentOrder: action.orderItem
      }
    case UserActionTypes.GET_ORDERS:
      return {
        ...state,
        orders: {
          ...state.orders,
          data: action.orders,
          isLoading: false,
        }
      }
    case UserActionTypes.GET_AVATAR:
      return {
        ...state,
        avatar: action.avatar,
      }
    case UserActionTypes.GET_WCRO_BALANCE:
      return {
        ...state,
        WCROBalance: action.balance,
      }
    case UserActionTypes.CHECK_ADMIN: 
      return {
        ...state,
        admin: action.admin,
      }
    default:
      throw new Error();
  }
};

const UserProvider: FC<{ children: ChildNode | ReactElement }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const { connector: connectedWallet } = useWeb3React();

  const getConnectedDiscordChimps = useCallback((connectedDiscordChimps: { chimpsTokenIds: string[], honoraryTokenIds: string[] }) => {
    dispatch({
      type: UserActionTypes.GET_CONNECTED_DISCORD_CHIMPS,
      connectedDiscordChimps,
    });
  }, []);

  const getFavoriteChimps = useCallback((favoriteChimps: FavoriteChimpMeta[]) => {
    dispatch({
      type: UserActionTypes.GET_FAVORITE_CHIMP,
      favoriteChimps,
    });
  }, []);

  const updateOrderCart = useCallback((orderCart: OrderCartMeta[]) => {
    dispatch({
      type: UserActionTypes.UPDATE_ORDER_CART,
      orderCart
    })
  }, []);

  const updateRecentOrder = useCallback((orderItem: OrderCartMeta) => {
    dispatch({
      type: UserActionTypes.ORDER_UPDATE_RECENT,
      orderItem
    })
  }, []);

  const getOrders = useCallback((orders: OrderMeta[]) => {
    dispatch({
      type: UserActionTypes.GET_ORDERS,
      orders
    })
  }, []);

  const getAvatar = useCallback((avatar: string) => {
    dispatch({
      type: UserActionTypes.GET_AVATAR,
      avatar,
    });
  }, []);

  const getWCROBalance = useCallback((balance: number) => {
    dispatch({
      type: UserActionTypes.GET_WCRO_BALANCE,
      balance,
    });
  }, []);

  const checkAdmin = useCallback((isAdmin: boolean) => {
    dispatch({
      type: UserActionTypes.CHECK_ADMIN,
      admin: isAdmin,
    });
  }, []);

  const context = {
    state,
    getConnectedDiscordChimps,
    getFavoriteChimps,
    updateOrderCart,
    updateRecentOrder,
    getOrders,
    getAvatar,
    getWCROBalance,
    checkAdmin,
  };

  useEffect(() => {
    if (connectedWallet) {
      connectedWallet.getProvider().then((provider) => {
        provider.on('accountsChanged', () => {
          window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
          window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
          window.localStorage.removeItem(LOCAL_STORAGE_CART);
          window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
          window.location.reload();
        });
      });
    }
  }, [connectedWallet]);

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
};

export default UserProvider;