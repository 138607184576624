import { MouseEvent, TouchEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { DirectionUp } from '../../assets/svgs';
import styles from './BackToTop.module.scss';

const BACK_TOP_PAGES = ['/market', '/my-chimps', '/chimpay/faqs'];

const BackToTop = () => {
  const [show, setShow] = useState(false);

  const location = useLocation();

  const scrollable = useMemo(() => {
    return BACK_TOP_PAGES.includes(location?.pathname)
  }, [location])

  const checkScroll = useCallback(() => {
    const scrollTop = document.documentElement.scrollTop;

    if (!show && scrollTop > 200) {
      setShow(true);
    } else if (show && scrollTop <= 200) {
      setShow(false)
    }
  }, [show])

  const handleClick = useCallback((e: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', checkScroll)

    return () => {
      window.removeEventListener('scroll', checkScroll)
    }
  }, [checkScroll])

  return (
    <button className={classNames(styles.button, { [styles.button_show]: scrollable && show })} onClick={handleClick} onTouchEnd={handleClick}>
      Back to top
      <DirectionUp className={styles.icon} />
    </button>
  )
}

export default BackToTop;