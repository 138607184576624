import { FC, ReactNode, useCallback, useEffect, useRef } from "react";

interface Props {
  className?: string,
  children: ReactNode,
  onClickOutside: (e: any) => void,
}

const ClickOutSide: FC<Props> = ({ className, children, onClickOutside }) => {
  const ref: any = useRef();

  const handleClickOutSide = useCallback((e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      onClickOutside(e);
    }
  }, [onClickOutside, ref])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutSide);

    return () => document.removeEventListener('mousedown', handleClickOutSide);
  }, [handleClickOutSide])

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  )
}

export default ClickOutSide;