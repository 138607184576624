import { useEffect } from "react";
import { useUserHook } from "../hooks/useUserHook";
import { useChimpsHook } from "../hooks/useChimpsHook";
import useChimpayHook from "./useChimpayHook";
import { useMerchandiseHook } from "./useMerchandiseHook";

export default function useInitiateData() {
  const { getUserData } = useUserHook();
  const { getIds, getChimps, getMyHonoraryChimps } = useChimpsHook();
  const { getSeasonTimestamps, getStakedChimps, getAvailableRewards } = useChimpayHook();
  const { getMerchList } = useMerchandiseHook();

  useEffect(() => {
    getUserData();
  }, [getUserData])

  useEffect(() => {
    getIds();
  }, [getIds])

  useEffect(() => {
    getChimps();
  }, [getChimps])

  useEffect(() => {
    getMyHonoraryChimps();
  }, [getMyHonoraryChimps])

  useEffect(() => {
    getSeasonTimestamps();
  }, [getSeasonTimestamps])

  useEffect(() => {
    getStakedChimps();
  }, [getStakedChimps])

  useEffect(() => {
    getMerchList();
  }, [getMerchList]);

  useEffect(() => {
    getAvailableRewards();
  }, [getAvailableRewards]);
}
