import { FC, MouseEvent, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import classNames from 'classnames';
import styles from './LinkButton.module.scss';

interface Props {
  to: To;
  children: ReactNode;
  className?: string,
  isGhost?: boolean;
  disabled?: boolean;
  onClick?: (e: MouseEvent) => void,
}

const LinkButton: FC<Props> = ({ children, onClick, to, className, isGhost, disabled }) => {
  const props = {
    className: classNames(
      styles.component,
      {
        [styles.component_ghost]: isGhost,
        [styles.component_disabled]: disabled,
      },
      className,
    ),
    to,
    onClick: disabled ? undefined : onClick,
  }

  if (disabled) {
    return (
      <span {...props}>
        {children}
      </span>
    );
  }

  return (
    <Link {...props}>
      {children}
    </Link>
  )
}

LinkButton.defaultProps = {
  className: '',
  isGhost: true,
}

export default LinkButton;