import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import Selection from "./Selection";
import {
  OPTIONS_BACKGROUND,
  OPTIONS_BODY,
  OPTIONS_CLOTHES,
  OPTIONS_EARRINGS,
  OPTIONS_EYES,
  OPTIONS_HEADGEAR,
  OPTIONS_MOUTH,
} from '../../constants/options';
import { useChimp } from "../../providers/ChimpProvider";
import { useSearchMarket, initialState as initialFilterStates } from "../../providers/SearchMarketProvider";
import { Cross, FilterMenu } from '../../assets/svgs/index';
import { SaleFilterTabs, TraitTypes } from "../../types/enums";
import { ChimpFilters } from "../../types/interfaces";
import { getChimpCountOptionsByTrait } from "../../utils/chimp-traits";
import { TITLE_TRAIT_NUMBER } from "../../constants/general";
import styles from './TraitFilters.module.scss';

const TraitFilters = () => {
  const [showMenu, setShowMenu] = useState(false);

  const { state: { traitsCountForSales, allSaleChimps } } = useChimp();
  const { filterParams: { traits, listType }, filterByTraits, clearTraitsFilter } = useSearchMarket();

  const [forwardSelects, setForwardSelects] = useState<ChimpFilters>(traits);

  const handleClearAll = useCallback(() => {
    clearTraitsFilter('All')
    setForwardSelects(initialFilterStates.filterParams.traits);
  }, [clearTraitsFilter]);
  
  const getOptions = useCallback((title: TraitTypes, allOptions) => {
    if (listType === SaleFilterTabs.LISTED) {
      return traitsCountForSales[title];
    }

    if (listType === SaleFilterTabs.UNLISTED) {
      const opts: any = {}

      Object.keys(allOptions).forEach((key) => {
        const remain = allOptions[key] - (traitsCountForSales[title][key] || 0);

        if (remain > 0) {
          opts[key] = remain;
        }
      })

      return opts;
    }

    return allOptions
  }, [traitsCountForSales, listType])

  const chimpCountByTrait = useMemo(() => {
    return getChimpCountOptionsByTrait(allSaleChimps, listType);
  }, [allSaleChimps, listType]);

  const handleChangeForwardSelects = useCallback((key) => {
    return (selects: string[]) => {
      setForwardSelects({
        ...forwardSelects,
        [key]: selects,
      })
    }
  }, [forwardSelects])

  const lists = (
    <>
      <Selection 
        title={'# Traits'} 
        options={chimpCountByTrait} 
        values={traits[TITLE_TRAIT_NUMBER]} 
        shouldSorting={false}
        onChangeOptions={(val) => filterByTraits(TITLE_TRAIT_NUMBER, val)} 
        onClearFilter={() => clearTraitsFilter(TITLE_TRAIT_NUMBER)}
        forwardSelects={forwardSelects[TITLE_TRAIT_NUMBER]}
        onChangeForwardSelects={handleChangeForwardSelects(TITLE_TRAIT_NUMBER)}
      />
      <Selection 
        title={TraitTypes.Earrings} 
        options={getOptions(TraitTypes.Earrings, OPTIONS_EARRINGS)} 
        values={traits[TraitTypes.Earrings]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Earrings, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Earrings)}
        forwardSelects={forwardSelects[TraitTypes.Earrings]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Earrings)}
      />
      <Selection 
        title={TraitTypes.Mouth} 
        options={getOptions(TraitTypes.Mouth, OPTIONS_MOUTH)} 
        values={traits[TraitTypes.Mouth]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Mouth, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Mouth)} 
        forwardSelects={forwardSelects[TraitTypes.Mouth]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Mouth)}
      />
      <Selection 
        title={TraitTypes.Eyes} 
        options={getOptions(TraitTypes.Eyes, OPTIONS_EYES)} 
        values={traits[TraitTypes.Eyes]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Eyes, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Eyes)} 
        forwardSelects={forwardSelects[TraitTypes.Eyes]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Eyes)}
      />
      <Selection 
        title={TraitTypes.Headgear} 
        options={getOptions(TraitTypes.Headgear, OPTIONS_HEADGEAR)} 
        listAlignRight 
        values={traits[TraitTypes.Headgear]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Headgear, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Headgear)} 
        forwardSelects={forwardSelects[TraitTypes.Headgear]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Headgear)}
      />
      <Selection 
        title={TraitTypes.Clothes} 
        options={getOptions(TraitTypes.Clothes, OPTIONS_CLOTHES)} 
        listAlignRight 
        values={traits[TraitTypes.Clothes]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Clothes, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Clothes)} 
        forwardSelects={forwardSelects[TraitTypes.Clothes]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Clothes)}
      />
      <Selection 
        title={TraitTypes.Body} 
        options={getOptions(TraitTypes.Body, OPTIONS_BODY)} 
        listAlignRight 
        values={traits[TraitTypes.Body]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Body, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Body)} 
        forwardSelects={forwardSelects[TraitTypes.Body]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Body)}
      />
      <Selection 
        title={TraitTypes.Background} 
        options={getOptions(TraitTypes.Background, OPTIONS_BACKGROUND)} 
        listAlignRight 
        values={traits[TraitTypes.Background]} 
        onChangeOptions={(val) => filterByTraits(TraitTypes.Background, val)} 
        onClearFilter={() => clearTraitsFilter(TraitTypes.Background)} 
        forwardSelects={forwardSelects[TraitTypes.Background]}
        onChangeForwardSelects={handleChangeForwardSelects(TraitTypes.Background)}
      />
    </>
  )

  const filterCount = useMemo(() => {
    return Object.keys(traits).reduce((count, key) => {
      if ((traits as any)[key].length > 0) {
        count += (traits as any)[key].length;
      }

      return count
    }, 0)
  }, [traits]);

  const handleShowMemu = useCallback(() => {
    setShowMenu(true);
    setForwardSelects(traits);
  }, [traits])

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={handleShowMemu}>
        <span className={styles.label}>
          <span className={styles.label_text}>Filters</span>
          {filterCount > 0 && <span className={styles.clear_dk} onClick={handleClearAll}>Clear all</span>}
          {filterCount > 0 && <span className={styles.select_count}>({filterCount})</span>}
        </span>
        <FilterMenu className={styles.filter_menu} />
      </div>

      <div className={classNames(styles.content, { [styles.content_show]: showMenu })}>
        <div className={styles.menu_header}>
          <span className={styles.clear_mb} onClick={handleClearAll}>Clear all</span>
          <Cross onClick={() => setShowMenu(false)} style={{ cursor: 'pointer' }} />
        </div>
        <div className={styles.filters}>
          {lists}
        </div>
      </div>
    </div>
  )
}

export default TraitFilters;