import { FavoriteChimpMeta } from './../types/interfaces';
import { TITLE_TRAIT_NUMBER } from "../constants/general";
import { SaleFilterTabs, SortingTypes, TraitTypes } from "../types/enums";
import { ChimpFilters, FilterParams, PriceFilterRange, SaleChimpMeta } from "../types/interfaces";
import { convertPriceToDecimal } from "./price";
import { State as ChimpStates } from "../providers/ChimpProvider";

export function filterBySpecifications(originals: SaleChimpMeta[], filters: ChimpFilters): any[] {
  const data = originals.filter((item: SaleChimpMeta) => {
    return (
      (!filters[TITLE_TRAIT_NUMBER].length || filters[TITLE_TRAIT_NUMBER].includes(`${item.trait_count} Traits`))
      && (!filters[TraitTypes.Earrings].length || filters[TraitTypes.Earrings].includes(item.earrings))
      && (!filters[TraitTypes.Mouth].length || filters[TraitTypes.Mouth].includes(item.mouth))
      && (!filters[TraitTypes.Eyes].length || filters[TraitTypes.Eyes].includes(item.eyes))
      && (!filters[TraitTypes.Headgear].length || filters[TraitTypes.Headgear].includes(item.headgear))
      && (!filters[TraitTypes.Clothes].length || filters[TraitTypes.Clothes].includes(item.clothes))
      && (!filters[TraitTypes.Body].length || filters[TraitTypes.Body].includes(item.body))
      && (!filters[TraitTypes.Background].length || filters[TraitTypes.Background].includes(item.background))
    )
  })

  return data;
}

export function filterListedOrUnlisted(originals: SaleChimpMeta[], type: SaleFilterTabs): SaleChimpMeta[] {
  if (type === SaleFilterTabs.LISTED) {
    originals = originals.filter(({ isForSale }) => isForSale);
  }

  if (type === SaleFilterTabs.UNLISTED) {
    originals = originals.filter(({ isForSale }) => !isForSale);
  }

  return originals;
}

export function filterChimpsByPrice(chimps: SaleChimpMeta[], priceRange: PriceFilterRange) {
  if (priceRange.min && priceRange.max) {
    return chimps.filter((it) => +convertPriceToDecimal(it.price) >= +priceRange.min && +convertPriceToDecimal(it.price) <= +priceRange.max)
  } else if (priceRange.min && !priceRange.max) {
    return chimps.filter((it) => +convertPriceToDecimal(it.price) >= +priceRange.min)
  } else if (!priceRange.min && priceRange.max) {
    return chimps.filter((it) => +convertPriceToDecimal(it.price) <= +priceRange.max)
  }

  return chimps;
}

export function sortChimps(chimps: SaleChimpMeta[], type: SortingTypes) {
  if (type === SortingTypes.LOWEST_ID) {
    return chimps.sort((a, b) => {
      return +a.tokenId - +b.tokenId;
    })
  }

  if (type === SortingTypes.HIGHEST_ID) {
    return chimps.sort((a, b) => {
      return +b.tokenId - +a.tokenId;
    })
  }

  if (type === SortingTypes.LOWEST_PRICE) {
    return chimps.sort((a, b) => {
      return +a.price - +b.price;
    })
  }

  if (type === SortingTypes.HIGHEST_PRICE) {
    return chimps.sort((a, b) => {
      return +b.price - +a.price;
    })
  }

  if (type === SortingTypes.TRAITS_FEW) {
    return chimps.sort((a, b) => {
      return +a.trait_count - +b.trait_count;
    })
  }

  if (type === SortingTypes.TRAITS_MANY) {
    return chimps.sort((a, b) => {
      return +b.trait_count - +a.trait_count;
    })
  }

  return chimps;
}

export function filterMarketChimpsForDisplay(states: ChimpStates, params: FilterParams, favoriteChimps: FavoriteChimpMeta[]) {
  const { allChimps, allSaleChimps, marketHonoraryChimps } = states;
  const { listType, sort, minPrice, maxPrice, chimpId, traits } = params;

  const defaultListType = listType ? listType : SaleFilterTabs.LISTED;
  let defaultSort = sort ? sort : SortingTypes.LOWEST_PRICE;

  

  let data = allChimps;

  if (defaultListType === SaleFilterTabs.LISTED) {
    data = allSaleChimps;
  } else if (defaultListType === SaleFilterTabs.UNLISTED) {
    data = filterListedOrUnlisted(allChimps, SaleFilterTabs.UNLISTED);
    defaultSort = SortingTypes.LOWEST_ID;
  } else if (defaultListType === SaleFilterTabs.HONORARY) {
    data = marketHonoraryChimps;
  } else if (defaultListType === SaleFilterTabs.FAVORITES) {
    const normalChimps = allChimps;
    const honoraryChimps = marketHonoraryChimps;
    const all: SaleChimpMeta[] = [...normalChimps, ...honoraryChimps];
    const myFavoriteChimps = all.filter((el) => {
      return favoriteChimps.some((f) => {
        return f.tokenId === el.tokenId && f.isHonorary === el.isHonorary;
      });
    });
    data = myFavoriteChimps;
  }

  if (defaultListType === SaleFilterTabs.LISTED) {
    data = filterChimpsByPrice(data, { min: minPrice, max: maxPrice });
  }

  if (defaultListType !== SaleFilterTabs.HONORARY) {
    data = filterBySpecifications(data, traits);
  }

  data = sortChimps(data, defaultSort);

  return !chimpId ? data : data.filter(({ tokenId }) => tokenId === chimpId);
}

export function changeSortingDefaultToChimpId(saleType: SaleFilterTabs, sortTyle: SortingTypes) {
  if (
    ([SaleFilterTabs.ALL, SaleFilterTabs.UNLISTED, SaleFilterTabs.FAVORITES].includes(saleType) && [SortingTypes.LOWEST_PRICE, SortingTypes.HIGHEST_PRICE].includes(sortTyle))
    || ([SaleFilterTabs.HONORARY].includes(saleType) && [SortingTypes.TRAITS_FEW, SortingTypes.TRAITS_MANY].includes(sortTyle))
    || sortTyle === null
  ) {
    return SortingTypes.LOWEST_ID;
  }

  if (([SaleFilterTabs.LISTED].includes(saleType) && [SortingTypes.LOWEST_ID].includes(sortTyle))) {
    return SortingTypes.LOWEST_PRICE;
  }

  return sortTyle;
}