import moment from "moment";

export function countDown(
  time: number,
  mode: 'HMS' | 'DHM' = 'HMS',
  lowerCase: boolean = false,
) {
  const now = Date.now();
  let difference = Math.max(time - now, 0);

  const duration = moment.duration(difference);

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  const fm = (x: number) => x < 10 ? `0${x}` : x;

  let timer = `${fm(hours)}H : ${fm(minutes)}M : ${fm(seconds)}S`;

  if (mode === 'DHM') {
    timer = `${fm(days)}D : ${fm(hours)}H : ${fm(minutes)}M`;
  }

  if (lowerCase) {
    timer = timer.toLowerCase();
  }

  return timer;
}

export function formatUTCSeasonDate(date: moment.MomentInput) {
  return moment(date).utc().format('h:mma, D MMM UTC');
}

export function formatDateTime(date: moment.MomentInput) {
  return moment(date).utc().format('YYYY-MM-DD HH:mm:ss');
}

export function formatDate(
  timestamp: string | number,
  formatDay: "numeric" | "2-digit",
  formatMonth: "numeric" | "2-digit" | "long" | "short" | "narrow",
  formatYear: "numeric" | "2-digit"
) {
  const date = new Date(Number(timestamp));
  const formatDate = new Intl.DateTimeFormat("en-GB", {
    day: formatDay,
    month: formatMonth,
    year: formatYear,
  }).format(date);

  return formatDate;
}
