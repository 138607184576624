
import { FC, MouseEvent, useCallback } from 'react';
import { Locked } from '../../assets/svgs';
import styles from './ScrollFrame.module.scss';

interface Props {
  src: string;
  isUnlocked?: boolean;
  alt?: string;
  className?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const ScrollFrame: FC<Props> = (props) => {
  const { src, alt, className, selected = false, onClick, isUnlocked = false } = props;

  const handleSelect = useCallback((ev: MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (onClick) {
      onClick();
    }
  }, [onClick])

  return (
    <div 
      className={`${styles.component} ${selected ? styles.component_selected : '' } ${className}`}
      onClick={handleSelect}
    >
      <img className={styles.img} src={src} alt={alt} />
      {
        !isUnlocked && 
        <div className={styles.locked}>
          <Locked className={styles.locked_icon} />
        </div>
      }
    </div>
  )
}
