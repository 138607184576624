import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import classNames from 'classnames';
import DetailsHeader from '../../components/chimps/DetailsHeader';
import TraitCard from '../../components/chimps/TraitCard';
import OfferList from '../../components/chimps/OfferList';
import Collapse from '../../components/collapse/Collapse';
import { useChimp } from '../../providers/ChimpProvider';
import { useChimpsHook } from '../../hooks/useChimpsHook';
import { useOfferContractMethods } from '../../hooks/useOfferContractMethods';
import { Arrow } from '../../assets/svgs';
import {CardTypes, ChimpTransactionTypes} from '../../types/enums';
import { shortenAddress } from '../../utils/address';
import { CHIMP_CONTRACT_ADDRESS, CRONOS_EXPLORER, HONORARY_CHIMP_CONTRACT_ADDRESS } from '../../configs';
import { HIDEN_HONORARY_CHIMP_ID } from '../../constants/general';
import styles from './ChimpDetails.module.scss';
import {formatNumberWithComma} from "../../utils/number";
import {formatDate} from "../../utils/time";

const ChimpDetails = () => {
  const { account } = useWeb3React();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isHonorary = useMemo(() => searchParams.get('isHonorary') === 'true' ? true : false, [searchParams]);
  const pageType = useMemo(() => 
    searchParams.get('page') ? searchParams.get('page') as CardTypes : CardTypes.MARKET, [searchParams]);

  const { getChimpDetailById, getChimpTransactionsById } = useChimpsHook();
  const { getValidOffers, getUserOfferIndex } = useOfferContractMethods();
  const { state: { allChimps, marketHonoraryChimps, ownedChimps, ownedHonoraryChimps, forBuyOrSaleChimp: { tokenId, attributes, owner }, transactions }, getChimpForBuyOrSale } = useChimp();
  const [isLoading, setLoading] = useState<boolean>(false);

  const isOwner = useMemo(() => owner === account, [owner, account]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate])

  useEffect(() => {
    if (owner) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [owner])

  useEffect(() => {
    if (tokenId) {
      getUserOfferIndex(isHonorary, tokenId);
    }
  }, [getUserOfferIndex, isHonorary, tokenId]);

  useEffect(() => {
    let chimp
    
    if (pageType === CardTypes.MARKET) {
      if (isHonorary) {
        chimp = marketHonoraryChimps.find((item) => item.tokenId === params.id);
      } else {
        chimp = allChimps.find((item) => item.tokenId === params.id);
      }
    } else {
      if (isHonorary) {
        chimp = ownedHonoraryChimps.find((item) => item.tokenId === params.id);
      } else {
        chimp = ownedChimps.find((item) => item.tokenId === params.id);
      }
    }

    if (chimp) {
      getChimpForBuyOrSale(chimp);
      getChimpDetailById(params.id || '', isHonorary);
    } else {
      getChimpDetailById(params.id || '', isHonorary);
    }

    return () => getChimpForBuyOrSale({})
  }, [allChimps, marketHonoraryChimps, ownedChimps, ownedHonoraryChimps, isHonorary, pageType, params.id, getChimpForBuyOrSale, getChimpDetailById])

  useEffect(() => {
    if (tokenId) {
      getValidOffers(isHonorary, tokenId);
    }
  }, [getValidOffers, isHonorary, tokenId]);

  useEffect(() => {
    if (tokenId) {
      getChimpTransactionsById(tokenId, isHonorary);
    }
  }, [getChimpTransactionsById, tokenId, isHonorary]);

  if (isHonorary && HIDEN_HONORARY_CHIMP_ID.includes(params.id || '')) {
    return (
      <div className={styles.notFound}>
        Not found
      </div>
    )
  }

  return (
    <div className={classNames(styles.container, { [styles.container_wider]: isHonorary })}>
      <div className={styles.back} onClick={handleBack}>
        <Arrow style={{ cursor: 'pointer' }} />
        <span className={styles.back_label}>{pageType === CardTypes.MARKET ? 'Back to Marketplace' : 'Back to My Chimps'}</span>
      </div>
      <DetailsHeader pageType={pageType} isOwner={isOwner} isLoading={isLoading} />
      <Collapse fluid label="Offers">
        <OfferList isOwner={isOwner} />
      </Collapse>
      {
        attributes && Object.keys(attributes).length > 0 &&
        <Collapse label="Traits">
          <div className={styles.traits}>
            {
              !!attributes && Object.keys(attributes).map((trait: any) => {
                if ((attributes as any)[trait] === 'none' || !(attributes as any)[trait]) return '';

                return (
                  <TraitCard key={trait} label={trait} trait={(attributes as any)[trait]} />
                )
              })
            }
          </div>
        </Collapse>
      }

      <Collapse label="Transactions">
        {!!transactions.length && (
            <table className={styles.transaction_table}>
              <thead>
              <tr className={styles.transaction_header_row}>
                <th className={styles.transaction_header_column} scope="col">Date</th>
                <th className={styles.transaction_header_column} scope="col">Transaction Type</th>
                <th className={styles.transaction_header_column} scope="col">Amount</th>
                <th className={styles.transaction_header_column} scope="col">Transaction ID</th>
              </tr>
              </thead>
              <tbody>
              {transactions.map(({timestamp, type, amount, hash}) => (
                <tr key={hash} className={styles.transaction_row}>
                  <td className={styles.transaction_column}
                      data-label="Date">{formatDate(timestamp * 1000, '2-digit', 'short', '2-digit')}</td>
                  <td className={styles.transaction_column} data-label="Transaction Type">{ChimpTransactionTypes[type]}</td>
                  <td className={styles.transaction_column}
                      data-label="Amount">{amount ? `${formatNumberWithComma(amount)} CRO` : '-'}</td>
                  <td className={styles.transaction_column} data-label="Transaction ID">
                    <a href={`${CRONOS_EXPLORER}/tx/${hash}`} target="_blank" rel="noopener noreferrer">
                      {shortenAddress(hash)}
                    </a>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
        )}
        {!transactions.length && (
            <div>There are no transactions on this Chimp!</div>
        )}
      </Collapse>

      <Collapse label="Details">
        <div className={styles.detail_row}>
          <span className={styles.detail_label}>Contract address</span>
          <span className={styles.detail_value}>
            <a href={`${CRONOS_EXPLORER}/address/${isHonorary ? HONORARY_CHIMP_CONTRACT_ADDRESS : CHIMP_CONTRACT_ADDRESS}`} target="_blank" rel="noopener noreferrer">
              {shortenAddress(isHonorary ? HONORARY_CHIMP_CONTRACT_ADDRESS : CHIMP_CONTRACT_ADDRESS, 6, 4)}
            </a>
          </span>
        </div>
        <div className={styles.detail_row}>
          <span className={styles.detail_label}>Token ID</span>
          <span className={styles.detail_value}>{ tokenId }</span>
        </div>
        <div className={styles.detail_row}>
          <span className={styles.detail_label}>Current Owner</span>
          <span className={styles.detail_value}>
            <a href={`${CRONOS_EXPLORER}/address/${owner}`} target="_blank" rel="noopener noreferrer">
              {shortenAddress(owner || '', 6, 4)}
            </a>
          </span>
        </div>
      </Collapse>
    </div>
  )
}

export default ChimpDetails