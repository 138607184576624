import React, { Fragment, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useWeb3React } from "@web3-react/core";
import { OfferChimpMeta } from '../../types/interfaces';
import styles from './OfferList.module.scss';
import WalletIcon from '../../assets/images/wallet-icon.png';
import { useModal } from '../../providers/ModalProvider';
import { useMessage } from "../../providers/MessageProvider";
import { useChimp } from '../../providers/ChimpProvider';
import { useOfferContractMethods } from '../../hooks/useOfferContractMethods';
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import { convertPriceToDecimal } from '../../utils/price';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import { shortenAddress } from '../../utils/address';
import { Cancel, Cross, Dropdown, Pencil, CheckedOutline } from '../../assets/svgs';
import { ModalTypes } from '../../types/enums';

interface Props {
  isOwner: boolean;
}

const OfferList: React.FC<Props> = ({ isOwner }) => {
  const { account } = useWeb3React();
  const { openModal, closeModal } = useModal();
  const { getOfferByIndex, cancelOffer, getValidOffers } = useOfferContractMethods();
  const { onChangeWarnings } = useMessage();
  const { state: { forBuyOrSaleChimp, offers, offerUserMade } } = useChimp();
  const { isHonorary } = forBuyOrSaleChimp;

  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);
  const [selectedTokenId, setSelectedTokenId] = useState<string>('');

  const handleEditOffer = useCallback(() => {
    openModal(ModalTypes.EDIT_OFFER_CHIMP)
    setOpenBottomSheet(false);
  }, [openModal]);

  const handleAcceptOffer = useCallback(async (tokenId: string, offerIndex: string) => {
    openModal(ModalTypes.ACCEPT_OFFER_CHIMP)
    await getOfferByIndex(isHonorary, tokenId, offerIndex);
  }, [getOfferByIndex, isHonorary, openModal]);

  const handleCancelOffer = useCallback(async (tokenId: string) => {
    setOpenBottomSheet(false);
    openModal(ModalTypes.OVERLAY);
    try {
      const result = await cancelOffer(isHonorary, tokenId);
      if (result.transactionHash) {
        await getValidOffers(isHonorary, tokenId);
        closeModal();
      }
    } catch (error: any) {
      console.log(error);
      closeModal();
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [cancelOffer, closeModal, getValidOffers, isHonorary, onChangeWarnings, openModal]);

  const handleOpenBottomSheet = useCallback((tokenId: string) => {
    setOpenBottomSheet(true);
    setSelectedTokenId(tokenId);
  }, []);

  return (
    <div className={styles.container}>
      {offers.length > 0 ?
        <div className={styles.table}>
          <div className={styles.th}>FROM</div>
          <div className={styles.th}>AMOUNT</div>
          <div className={styles.th}></div>
          <>
            {offers.map((item: OfferChimpMeta) => (
              <div className={styles.tr} key={item.offerIndex}>
                <div className={styles.td}>
                  <img className={styles.icon} src={WalletIcon} alt="" />
                  {account === item.buyer ?
                    <span className={styles.bold_text}>You</span> :
                    <>
                      <span className={styles.address}>{shortenAddress(item.buyer || '', 8, 4)}</span>
                      <span className={styles.address_mobile}>{shortenAddress(item.buyer || '', 4, 4)}</span>
                    </>
                  }
                </div>
                <div className={classNames(styles.td, styles.bold_text)}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(item.amount), 3))} WCRO</div>
                <div className={styles.controls_wrap}>
                  {isOwner && <button className={styles.offer_button} onClick={() => handleAcceptOffer(item.tokenId, item.offerIndex)}>Accept offer</button>}
                  {!isOwner && offerUserMade.offerMade && item.offerIndex === offerUserMade.indexValue && <div className={styles.edit_button_wrap}>
                    <button className={styles.edit_button} onClick={handleEditOffer}>Edit offer</button>
                    <button className={classNames(styles.edit_button, styles.edit_dropdown_button)}><Dropdown /></button>
                    <ul className={styles.edit_dropdown_content}>
                      <li className={styles.edit_dropdown_item} onMouseDown={() => handleCancelOffer(item.tokenId)}>Cancel offer</li>
                    </ul>
                  </div>}
                  {/* Mobile */}
                  {isOwner &&
                    <div className={styles.edit_button_mobile} onClick={() => handleAcceptOffer(item.tokenId, item.offerIndex)}>
                      <CheckedOutline />
                    </div>
                  }
                  {!isOwner && offerUserMade.offerMade && item.offerIndex === offerUserMade.indexValue &&
                    <div className={styles.edit_button_mobile} onClick={() => handleOpenBottomSheet(item.tokenId)}>
                      <Dropdown />
                    </div>
                  }
                </div>

              </div>
            ))}
          </>
        </div> :
        <div className={styles.empty_messagge}>There are no offers on this Chimp!</div>}
      {openBottomSheet &&
        <div className={styles.bottom_sheet_wrap}>
          <div className={styles.bottom_sheet_block_layer} onClick={() => setOpenBottomSheet(false)}></div>
          <div className={styles.bottom_sheet}>
            <div className={styles.bottom_sheet_header}><Cross onClick={() => setOpenBottomSheet(false)} />My offer</div>
            <div className={styles.bottom_sheet_list_menu}>
              <div className={styles.bottm_sheet_menu_item} onClick={handleEditOffer}><Pencil /><span>Edit offer</span></div>
              <div className={styles.bottm_sheet_menu_item} onClick={() => handleCancelOffer(selectedTokenId)}><Cancel /><span>Cancel offer</span></div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default OfferList;