import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import ClickOutSide from '../click-outside/ClickOutside';
import { useSearchMarket } from '../../providers/SearchMarketProvider';
import { Dropdown as DropDownIcon } from '../../assets/svgs';
import { PriceFilterRange } from '../../types/interfaces';
import { formatNumberWithComma } from '../../utils/number';
import styles from './PriceFilter.module.scss';

const PriceFilter = () => {
  const { filterParams: { minPrice, maxPrice }, filterByPrice } = useSearchMarket();

  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState<PriceFilterRange>({ min: '', max: '' });

  const handleChange = useCallback((key, e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value;

    if (/^[1-9]{0,1}\d{0,}$/.test(value)) {

      if (key === 'min' && !!filters.max && +value > +filters.max) return;

      setFilters({
        ...filters,
        [key]: value,
      })
    }
  }, [filters])

  const applyFilters = useCallback(() => {
    filterByPrice(filters);
  }, [filterByPrice, filters]);

  const clearFilters = useCallback(() => {
    filterByPrice({ min: '', max: '' });
    setFilters({ min: '', max: '' });
  }, [filterByPrice]);

  const displayHeader = useMemo(() => {
    if (minPrice && !maxPrice) {
      return `Min ${formatNumberWithComma(minPrice)} CRO`
    } else if (!minPrice && maxPrice) {
      return `Max ${formatNumberWithComma(maxPrice)} CRO`
    } else if (minPrice && maxPrice) {
      return `${formatNumberWithComma(minPrice)} - ${formatNumberWithComma(maxPrice)} CRO`
    }

    return 'Price Range';
  }, [minPrice, maxPrice])

  return (
    <ClickOutSide onClickOutside={() => setShow(false)}>
      <div className={styles.container}>
        <div 
          className={classNames(styles.header, { [styles.header_filtered]: minPrice || maxPrice })} 
          onClick={() => setShow(!show)}
          title={`${displayHeader === 'Price Range' ? '' : displayHeader}`}
        >
          <span className={styles.header_text}>
            {displayHeader}
          </span>
          <DropDownIcon className={styles.dropdown_icon} style={{ transform: `rotate(${show ? 180 : 0}deg)` }} />
        </div>
        <div className={classNames(styles.content, { [styles.content_show]: show })}>
          <div className={styles.inputs}>
            <input className={styles.input} type="text" title={`${formatNumberWithComma(filters.min)} CRO`} value={filters.min} onChange={(e) => handleChange('min', e)} placeholder="Min" />
            <span className={styles.line} />
            <input className={styles.input} type="text" title={`${formatNumberWithComma(filters.max)} CRO`} value={filters.max} onChange={(e) => handleChange('max', e)} placeholder="Max" />
          </div>
          <div className={styles.buttons}>
            <button className={classNames(styles.button, styles.button_clear)} onClick={clearFilters}>Clear</button>
            <button className={classNames(styles.button, styles.button_apply)} onClick={applyFilters}>Apply</button>
          </div>
        </div>
      </div>
    </ClickOutSide>
  )
}

export default PriceFilter;