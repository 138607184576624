import { useMerchandise } from '../../providers/MerchandiseProvider';
import { TierStar } from '../../assets/svgs';
import useChimpayHook from '../../hooks/useChimpayHook';
import styles from './ChimpMerch.module.scss';
import LinkButton from '../../components/button/LinkButton';

const ChimpMerch = () => {
  const { state: { merchandiseList } } = useMerchandise();
  const { isRedemptionPeriod } = useChimpayHook();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <TierStar className={styles.starIcon} />
          <span>Chimp Merch</span>
        </div>
        <span className={styles.spec}>
          *Special purchase with USDC {isRedemptionPeriod() ? ' or Bananas' : ''}
        </span>
      </div>
      <div className={styles.merchProducts}>
        {
          merchandiseList.map((merch) => (
            <div key={merch.contractId} className={styles.merch}>
              <div className={styles.merchCol}>
                <img src={merch.imageUrl} alt="" />
              </div>
              <div className={styles.merchCol}>
                <div className={styles.merchContent}>
                  <span className={styles.merchTitle}>
                    {merch.title}
                  </span>
                  {/* {
                    merch.contractId === 'bundle' &&
                    <span className={styles.merchBundleSizes}>
                      Size <b>S/M/L</b> available
                    </span>
                  }
                  <span className={styles.merchPrice}>
                    {merch.price} {merch.currency}
                  </span> */}
                </div>

                <LinkButton
                  isGhost={false} 
                  to={`/shop/details/${merch.id}`}
                  className={styles.purchaseButton}
                >
                  Purchase
                </LinkButton>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default ChimpMerch;
