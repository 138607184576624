import { SaleChimpMeta, ChimpTraits, FilterCountsForSales } from "../types/interfaces";
import { SaleFilterTabs, TraitTypes } from "../types/enums";
import allChimpsAttributes from '../data/attributes.json';
import { roundNumberWithFix } from "./number";
import { chimpImage } from "./chimp-image";

export function buildForOneChimpMeta(saleChimp: SaleChimpMeta): SaleChimpMeta {
  const attribute: ChimpTraits = saleChimp.isHonorary ? ({} as any) : (allChimpsAttributes as ChimpTraits[])[+saleChimp.tokenId]
  return {
    ...saleChimp,
    isConnectedDiscord: saleChimp.isConnectedDiscord || false,
    isForSale: saleChimp.isForSale || false,
    isHonorary: saleChimp.isHonorary || false,
    seller: saleChimp.seller || '',
    owner: saleChimp.owner || '',
    price: saleChimp.price || '',
    id: saleChimp.isHonorary ? `Honorary ${saleChimp.tokenId}` : `Normal ${saleChimp.tokenId}`,
    name: saleChimp.name || '',
    image: saleChimp.isHonorary ? saleChimp.image : chimpImage(+saleChimp.tokenId),
    hdImage: saleChimp.isHonorary ? saleChimp.image : chimpImage(+saleChimp.tokenId, true),
    description: saleChimp.description || '',
    attributes: attribute,
    trait_count: !attribute ? 0 : Object.keys(attribute).filter((name) => !!(attribute as any)[name] && (attribute as any)[name] !== 'none').length,
    background: attribute?.Background || 'none',
    body: attribute?.Body || 'none',
    clothes: attribute?.Clothes || 'none',
    earrings: attribute?.Earrings || 'none',
    eyes: attribute?.Eyes || 'none',
    headgear: attribute?.Headgear || 'none',
    mouth: attribute?.Mouth || 'none',
    favoriteCount: saleChimp.favoriteCount || 0,
  }
}

export function buildForChimpsMeta(saleChimps: SaleChimpMeta[]): SaleChimpMeta[] {
  return saleChimps.map((sale) => {
    return buildForOneChimpMeta(sale)
  })
}

export function calculateTraitPercentage(count: number | string): string {
  if (!count) count = 0;

  return `${roundNumberWithFix((+count * 100) / 10000, 1)}%`
}

export function countTraitsForListedChimps(chimps: SaleChimpMeta[]) {
  const initails = {
    [TraitTypes.Earrings]: {},
    [TraitTypes.Mouth]: {},
    [TraitTypes.Eyes]: {},
    [TraitTypes.Headgear]: {},
    [TraitTypes.Clothes]: {},
    [TraitTypes.Body]: {},
    [TraitTypes.Background]: {},
  };

  const keys = Object.keys(initails);

  return chimps.reduce((counts: FilterCountsForSales, current: SaleChimpMeta) => {
    if (current.attributes && current.isForSale) {

      keys.forEach((key) => {
        const trait = (current.attributes as any)[key];

        if (trait) {
          if ((counts as any)[key][trait]) {
            (counts as any)[key][trait] += 1;
          } else {
            (counts as any)[key][trait] = 1;
          }
        }
      })
    }
    return counts
  }, initails) 
}

export function getChimpCountOptionsByTrait(sales: SaleChimpMeta[], type: SaleFilterTabs) {
  if (type === SaleFilterTabs.HONORARY) return {};

  const defaultCounts = {
    '4 Traits': 0,
    '5 Traits': 0,
    '6 Traits': 0,
    '7 Traits': 0,
  }

  if (type === SaleFilterTabs.LISTED) {
    return sales.reduce((count: any, current: SaleChimpMeta) => {
      const length = current.trait_count || 0;

      if (length) {
        if (count[`${length} Traits`]) {
          count[`${length} Traits`] += 1;
        } else {
          count[`${length} Traits`] = 1;
        }
      }
  
      return count;
    }, defaultCounts)
  } else {
    return allChimpsAttributes.reduce((count: any, current: any, index: number) => {
      if (type === SaleFilterTabs.UNLISTED && sales.some(({ tokenId }) => +tokenId === index)) {
        return count;
      }

      const length = Object.keys(current).filter((name) => current[name] !== 'none').length || 0;

      if (length) {
        if (count[`${length} Traits`]) {
          count[`${length} Traits`] += 1;
        } else {
          count[`${length} Traits`] = 1;
        }
      }
  
      return count;
    }, defaultCounts)
  }
}