import { DISCORD_LINK, TWITTER_LINK, INSTAGRAM_LINK, TIKTOK_LINK } from '../configs';
import { DiscordOutline, Twitter, Instagram, Tiktok } from '../assets/svgs';

export const SOCIALS = [
  {
    to: DISCORD_LINK,
    label: 'Discord',
    Icon: DiscordOutline,
  },
  {
    to: TWITTER_LINK,
    label: 'Twitter',
    Icon: Twitter,
  },
  {
    to: INSTAGRAM_LINK,
    label: 'Instagram',
    Icon: Instagram,
  },
  {
    to: TIKTOK_LINK,
    label: 'TikTok',
    Icon: Tiktok,
  }
]

