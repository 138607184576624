import { useCallback, useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from 'ethers';
import { useContract } from "../../hooks/useContract";
import useLoreHook from "../../hooks/useLoreHook";
import { SCROLL_CHIMPION_AMOUNT } from "../../constants/lore";
import { ChimpionState } from "../../types/interfaces";

interface ScrollState {
  id: ethers.BigNumber;
  isUnlocked: boolean;
  value: ethers.BigNumber;
  totalContributors: ethers.BigNumber;
}

const LoreManagement = () => {
  const { account } = useWeb3React();
  const { loreContract } = useContract();
  const { archiveScrolls, getChimpionList } = useLoreHook();

  const [owner, setOwner] = useState('');
  const [unlockedScrolls, setUnlockedScrolls] = useState<number[]>([]);

  useEffect(() => {
    loreContract.owner().then((address: string) => {
      setOwner(address);
    })
  }, [loreContract])

  useEffect(() => {
    loreContract.activeSceneStart().then((id: ethers.BigNumber) => {
      const start = id.toNumber();
      if (start > 0) {
        const ids = [...Array(10).keys()].map((key) => key + Number(start));

        loreContract.getScenesByIds(ids).then((data: ScrollState[]) => {
          if (Array.isArray(data)) {
            const unlockedIds = data.filter(({ isUnlocked }) => isUnlocked).map(({ id }) => id.toNumber())
            setUnlockedScrolls(unlockedIds);
          }
        })
      }
    })
  }, [loreContract])

  const [newOwner, setNewOwner] = useState('');

  const handleTransferOwnership = useCallback(async () => {
    await loreContract.transferOwnership(newOwner);
    alert(`Ownership transferred to ${newOwner}`);
  }, [loreContract, newOwner]);

  const [metadataId, setMetadataId] = useState('');
  const [metadataURI, setMetadataURI] = useState();
  const [tokenId, setTokenId] = useState('');

  const handleMint = useCallback(async () => {
    const tx = await loreContract.mint(SCROLL_CHIMPION_AMOUNT, metadataId);
    await tx.wait();

    const counter: ethers.BigNumber = await loreContract.counter();
    const token = counter.toNumber() - 1;
    setTokenId(token.toString());

    const uri = await loreContract.uri(token);
    setMetadataURI(uri);

    alert(`Token #${token} has been minted`);
  }, [loreContract, metadataId]);

  const handleTransferShares = useCallback(async () => {
    const list: ChimpionState[] = await getChimpionList(Number(tokenId) + 1);
    const chimpions = list.slice(0, SCROLL_CHIMPION_AMOUNT).map(({ account }) => account);

    const tx = await loreContract.batchTransferShareTo(tokenId, chimpions);
    await tx.wait();

    alert(`Total ${chimpions.length} wallets have earned the ownership of Lore NFT #${tokenId}`);
  }, [loreContract, tokenId, getChimpionList]);

  const handleArchiveScrolls = useCallback(async () => {
    try {
      await archiveScrolls();
      alert('Unlocked scrolls archived');
    } catch (error) {}
  }, [archiveScrolls]);

  const handleNewChapter = useCallback(async () => {
    await loreContract.startNewChapter();
    alert('New Chapter Started');
  }, [loreContract]);

  if (account !== owner) {
    return (
      <div>
        You are not owner of staking contract.
      </div>
    );
  }

  return (
    <div>
      <div>
        <h3>Change Lore Ownership</h3>
        <input style={{ width: '350px' }} onChange={(e) => setNewOwner(e.target.value)} value={newOwner} />
        <button onClick={handleTransferOwnership}>Transfer Ownership</button>
      </div>
      <hr />

      <div>
        <h3>Mint NFT</h3>
        <input 
          style={{ width: '350px' }} 
          onChange={(e) => setMetadataId(e.target.value)} value={metadataId} 
          placeholder="arweave metadata id"
        />
        <button onClick={handleMint}>Mint</button>
        {
          metadataURI && 
          <p>
            NFT(#{tokenId}) metadata: 
            <a href={metadataURI} target="_blank" rel="noopener noreferrer">
              {metadataURI}
            </a>
          </p>
        }
      </div>
      <hr />

      <div>
        <h3>Send NFT shares</h3>
        <input 
          style={{ width: '100px' }} 
          onChange={(e) => setTokenId(e.target.value)} value={tokenId} 
          placeholder="token id"
        />
        <button onClick={handleTransferShares}>Send Shares</button>
      </div>
      <hr />

      <div>
        <h3>Archive for this season</h3>
        <p style={{ color: 'green' }}>
          <strong>Unlocked Scrolls: {`[${unlockedScrolls.join(',')}]`}</strong>
        </p>
        <button onClick={handleArchiveScrolls} style={{ marginRight: '8px' }}>
          Archive
        </button>
      </div>
      <hr />

      <div>
        <h3>Start new chapter</h3>
        <button onClick={handleNewChapter} style={{ marginRight: '8px' }}>
          New Chapter
        </button>
      </div>
      <hr />
    </div>
  )
}

export default LoreManagement;
