import { useCallback } from "react";
import { GraphQLClient, gql } from 'graphql-request';
import { DISCORD_API } from "../configs";
import { useLogin } from "./useLogin";

const endpoint = `${DISCORD_API}/graphql`;

export default function useAppData() {
  const { checkValidToken } = useLogin();

  const postNewEvent = useCallback(async (payload: {
    message: string;
    startAt: string;
    endAt: string;
  }) => {
    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
    const mutation = gql`
      mutation NewEvent($data: EventCheckout!) {
        newEvent(data: $data) {
          id
          message
          type
          queuedAt
          startAt
          endAt
        }
      }    
    `;

    await graphQLClient.request(mutation, { 
      data: {
        ...payload,
      }
    });
  }, [checkValidToken]);

  const getLatestBannerData = useCallback(async () => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query LatestEvent {
          latestEvent {
            id
            message
            type
            queuedAt
            startAt
            endAt
          }
        }
      `;

      const data = await graphQLClient.request(query);

      return data?.latestEvent;
    } catch (error) {}
  }, [])

  return {
    postNewEvent,
    getLatestBannerData,
  };
}

