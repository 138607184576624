import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Modal, { DisplayModal } from "../modal/Modal";
import CheckButton from "../checkbutton/CheckButton";
import Button from "../button/ProgressButton";
import CheckBox from "../checkbox/CheckBox";
import { useModal } from "../../providers/ModalProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import { useMessage } from "../../providers/MessageProvider";
import useChimpayHook from "../../hooks/useChimpayHook";
import WorkingChimp2 from '../../assets/images/banners_working_chimp2.png';
import { Cross } from "../../assets/svgs";
import { getDailyBananaProduction } from "../../utils/shop-price";
import { ModalTypes, ModalChimpStatus } from "../../types/enums";
import styles from './StakeModal.module.scss';

interface Selects {
  id: string;
  suspended: boolean;
}

const UnstakeModal = () => {
  const [selects, setSelects] = useState<Selects[]>([]);
  const [honorarySelects, setHonorarySelects] = useState<Selects[]>([]);
  const [statementChecked, setStatementChecked] = useState(false);

  const { step, props, closeModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();
  const { state: { stakedTokens } } = useChimpay();

  const { recallChimpsFromWork, checkLockChimpStaked, isWorkingPeriod } = useChimpayHook();

  const totalSelectsLength = useMemo(() => 
    selects.length + honorarySelects.length, [selects, honorarySelects]);

  const hasStakingExpired = useMemo(() => selects.findIndex(({ suspended }) => suspended) >= 0 
    || honorarySelects.findIndex(({ suspended }) => suspended) >= 0, [selects, honorarySelects]);

  const chimpsStaked = useMemo(() => {
    return [...stakedTokens.normal, ...stakedTokens.honorary];
  }, [stakedTokens]);

  const availableChimps = useMemo(() => {
    return chimpsStaked.map((chimp) => 
      ({ ...chimp, ...checkLockChimpStaked(chimp.stakedAt) })).filter(({ isLock, stakingExpired }) => !isLock || stakingExpired);
  }, [chimpsStaked, checkLockChimpStaked]);

  const selectedChimps = useMemo(() => {
    return availableChimps.filter(({ tokenId, isHonorary }) => 
      (!isHonorary && selects.some((sit) => +sit.id === +tokenId)) || (isHonorary && honorarySelects.some((hsit) => +hsit.id === +tokenId)));
  }, [availableChimps, selects, honorarySelects]);

  const handleToggleSelect = useCallback((id: string, isHonorary: boolean, stakingExpired: boolean) => {
    const updates = isHonorary ? [...honorarySelects] : [...selects];
    const index = updates.findIndex((it) => it.id === id);

    if (index === -1) {
      updates.push({ id, suspended: stakingExpired });
    } else {
      updates.splice(index, 1);
    }

    if (isHonorary) {
      setHonorarySelects(updates);
    } else {
      setSelects(updates);
    }
  }, [selects, honorarySelects, setSelects, setHonorarySelects]);

  const handleStake = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      await recallChimpsFromWork(selects, honorarySelects);

      onChangeWarnings({
        type: 'Success',
        title: 'Cheers!',
        message: `You have successfully unstaked ${selects.length + honorarySelects.length} Chimps.`,
      });
    } catch (err) {
      onChangeWarnings({
        message: 'Transaction unsuccessful, please try again.',
      });
    } finally {
      closeModal();
    }
  }, [selects, honorarySelects, updateOperationStep, closeModal, onChangeWarnings, recallChimpsFromWork]);

  useEffect(() => {
    if (props && (props.ids || props.honoraryIds)) {
      if (props.ids && props.ids.length > 0) {
        setSelects(props.ids);
      }

      if (props.honoraryIds && props.honoraryIds.length > 0) {
        setHonorarySelects(props.honoraryIds);
      }

      updateOperationStep(ModalChimpStatus.CONFIRM);
    } else {
      updateOperationStep(ModalChimpStatus.READY);
    }
  }, [updateOperationStep, props]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING}>
      <div className={styles.container}>
        <div className={`${styles.header} ${step === ModalChimpStatus.CONFIRM ? styles.header_bg : ''}`}>
          {
            step !== ModalChimpStatus.CONFIRMING &&
            <span className={styles.title}>
              {hasStakingExpired ? 'Recall Chimps' : 'Recall Chimps from work'}
            </span>
          }
          <Cross className={styles.close} onClick={closeModal} />
        </div>
        {
          step === ModalChimpStatus.READY &&
          <div className={styles.selectWrap}>
            {
              availableChimps.length > 0 && 
              <div className={styles.selectChimps}>
                {
                  availableChimps.map(({ tokenId, image, isHonorary, stakingExpired }) => {
                    const selected = isHonorary ? 
                      honorarySelects.some((it) => +it.id === +tokenId) : selects.some((it) => +it.id === +tokenId);

                    return (
                      <div 
                        key={tokenId} 
                        className={styles.chimpItem}
                        onClick={() => handleToggleSelect(tokenId, isHonorary, stakingExpired)}
                      >
                        <div className={classNames(styles.chimpImg, {
                          [styles.chimpImg_selected]: selected
                        })}>
                          <img src={image} alt="" />
                          {
                            isHonorary && 
                            <span className={styles.honoraryLabel_1}>
                              Honorary
                            </span>
                          }
                          <CheckButton
                            checked={true}
                            className={classNames(styles.checkIcon, {
                              [styles.checkIcon_checked]: selected
                            })}
                            fillStyle="cover"
                          />
                        </div>
                        <div className={styles.chimpId}>
                          Chimp #{tokenId}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              availableChimps.length === 0 && 
              <div className={styles.noSelectableChimps}>
                Sorry, Chimps are locked. You will be able to recall them after 24 hours.
              </div>
            }
            <Button
              onClick={() => updateOperationStep(ModalChimpStatus.CONFIRM)}
              disabled={totalSelectsLength === 0}
            >
              Next ({totalSelectsLength})
            </Button>
          </div>
        }
        {
          step === ModalChimpStatus.CONFIRM &&
          <div className={styles.confirmWrap}>
            <div className={styles.selectedChimps}>
              {
                selectedChimps.map(({ image, tokenId, isHonorary }) => (
                  <div key={tokenId} className={styles.selectedChimp}>
                    <img src={image} alt="" />
                    {
                      isHonorary && 
                      <span className={styles.honoraryLabel_2}>
                        Honorary
                      </span>
                    }
                  </div>
                ))
              }
            </div>
            <div className={styles.confirmBody}>
              {
                isWorkingPeriod() && !hasStakingExpired && 
                <div className={styles.dbp}>
                  <p className={styles.dbpDesc}>
                    Daily Banana production will change
                  </p>
                  <div className={styles.dbpChange}>
                    <span className={styles.dbpValue}>
                      {getDailyBananaProduction(chimpsStaked.length)}
                    </span>
                    <span className={styles.dbpChangeArrow}>→</span>
                    <span className={styles.dbpValue}>
                      {getDailyBananaProduction(chimpsStaked.length - totalSelectsLength)}
                    </span>
                  </div>
                </div>
              }
              <div className={styles.confirmDesc}>
                {
                  !hasStakingExpired && 
                  <>
                    <p className={styles.textParag}>
                      {
                        isWorkingPeriod()  
                        ? `
                          When you recall selected Chimps from work, 
                          it will no longer contribute to multiplier effect and daily Banana production.
                        `
                        : `
                          The Chimpay season has ended and there will be no Banana production.
                          Please recall all Chimps and let them enjoy their well-deserved holiday!
                        `
                      }
                    </p>
                    <p className={styles.textParag}>
                      Learn more about Chimpay <Link to="/terms" target="_blank">T&C</Link>.
                    </p>
                  </>
                }
                {
                  hasStakingExpired && 
                  <>
                    <p className={styles.textParag}>
                      <strong>Important Update: Chimpay Contract Migration Alert</strong>
                    </p>
                    <p className={styles.textParag}>
                      We're moving to a new contract for an even better Chimpay experience! 
                      To join the upcoming season, please recall the Chimps above from the old contract. 
                      This is a crucial step to continue your adventure with us. 
                    </p>
                    <p className={styles.textParag}>
                      Learn more about <Link to="/chimpay/faqs" target="_blank">Chimpay x Scroll</Link>.
                    </p>
                  </>
                }
              </div>
              {
                !hasStakingExpired && 
                <div className={styles.statement}>
                  <CheckBox
                    checked={statementChecked}
                    onCheck={() => setStatementChecked(!statementChecked)}
                    label="I have read the above T&C and I agree"
                    reversed
                  />
                </div>
              }

              <Button
                onClick={handleStake}
                disabled={!hasStakingExpired && !statementChecked}
                classname={styles.confirmButton}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
        {
          step === ModalChimpStatus.CONFIRMING && 
          <div className={styles.confirmingWrap}>
            <img className={styles.confirmingImg} src={WorkingChimp2} alt="" />
            <div className={styles.confirmingTitle}>
              Your Chimp is returning...
            </div>
            <div className={styles.confirmingSubTitle}>
              Remember to redeem gifts before the Bananas expire.
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default DisplayModal(UnstakeModal, ModalTypes.UNSTAKE_CHIMP);
