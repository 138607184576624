import { useCallback } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { GraphQLClient, gql } from 'graphql-request';
import { useLore } from "../providers/LoreProvider";
import { useContract } from "./useContract";
import { DISCORD_API } from "../configs";
import { computeEstimateGas } from "../utils/price";
import { useLogin } from "./useLogin";
import { fetchMetadata } from "../utils/fetch";

const endpoint = `${DISCORD_API}/graphql`;

export default function useLoreHook() {
  const {
    state: { activeChapterId },
    getScenes, getMyContributions, updateScene, getTokenBalances, getRank, checkNFTShared, getLoreNFTs
  } = useLore();
  const { account } = useWeb3React();

  const { checkValidToken } = useLogin();
  const { loreContract } = useContract();

  const loadActiveScenes = useCallback(async () => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);

      const query = gql`
        query ActiveScenes {
          activeScenes {
            id
            isUnlocked
            value
            totalContributors
            title
            description
            topContributor
            topAmount
            video {
              uid
              thumbnail
              filename
              created
              duration
              hlsManifestUrl
              dashManifestUrl
            }
          }
        }
      `;

      const res = await graphQLClient.request(query);

      if (res.activeScenes) {
        getScenes(res.activeScenes)
      }
    } catch (error) {}
  }, [getScenes])

  const loadAllArchivedScrolls = useCallback(async () => {
    try {
      if (!account) return;

      const graphQLClient = new GraphQLClient(endpoint);

      const query = gql`
        query ArchivedScrolls($accountId: String!) {
          archivedScrolls(accountId: $accountId) {
            archived
          }
        }
      `;

      const res = await graphQLClient.request(query, { 
        accountId: account,
      });

      if (res.archivedScrolls && res.archivedScrolls.archived) {
        return res.archivedScrolls.archived;
      }
    } catch (error) {}
  }, [account])

  const loadChapterScrolls = useCallback(async (chapterId: number) => {
    try {
      if (!account) return;

      const graphQLClient = new GraphQLClient(endpoint);

      const query = gql`
        query ChapterScrolls($accountId: String!, $chapterId: Int!) {
          chapterScrolls(accountId: $accountId, chapterId: $chapterId) {
            id
            sceneId
            chapterId
            title
            description
            status
            video {
              uid
              thumbnail
              name
              filename
              created
              duration
              hlsManifestUrl
              dashManifestUrl
            }
          }
        }
    
      `;

      const res = await graphQLClient.request(query, { 
        accountId: account,
        chapterId,
      });

      if (res.chapterScrolls) {
        return res.chapterScrolls;
      }
    } catch (error) {}
  }, [account])

  const updateSceneData = useCallback(async (sceneId: number) => {
    const data: {
      id: ethers.BigNumber;
      isUnlocked: boolean;
      value: ethers.BigNumber;
      totalContributors: ethers.BigNumber;
      top: {
        contributor: string;
        amount: ethers.BigNumber;
      }
    } = await loreContract.scenes(sceneId - 1);

    if (data) {
      updateScene({
        id: data.id.toNumber(),
        isUnlocked: data.isUnlocked,
        value: data.value.toNumber(),
        totalContributors: data.totalContributors.toNumber(),
        topContributor: data.top.contributor,
        topAmount: data.top.amount.toNumber(),
      });
    }
  }, [loreContract, updateScene]);

  const getMyContributedScenes = useCallback(async () => {
    try {
      if (!account || !activeChapterId) return;

      const data = await loreContract.getContributionsForContributor(account, activeChapterId);

      if (data && Array.isArray(data)) {
        const contributions = data.map((item: { amount: ethers.BigNumber, sceneId: ethers.BigNumber }) => ({
          sceneId: item.sceneId.toNumber(),
          amount: item.amount.toNumber(),
        }));

        getMyContributions(contributions);
      }
    } catch (error) {}
  }, [account, activeChapterId, loreContract, getMyContributions]);

  const contributeToScroll = useCallback(async (sceneId: number, amount: number) => {
    if (!account) return;
    const data = await loreContract.estimateGas.contribute(sceneId, amount);
    const tx = await loreContract.contribute(sceneId, amount, { gasLimit: computeEstimateGas(data) });
    await tx.wait(3);

    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation Contribute($chapterId: Int!, $sceneId: Int!, $amount: Float!) {
        contribute(chapterId: $chapterId, sceneId: $sceneId, amount: $amount) {
          id
          loreId
          sceneId
          amount
          contributedAt
          accountId
        }
      }
    `;

    await graphQLClient.request(mutation, {
      chapterId: activeChapterId,
      sceneId,
      amount
    });
  }, [account, activeChapterId, loreContract, checkValidToken]);

  const getContributionRanking = useCallback(async () => {
    try {
      if (!account) return;
      const graphQLClient = new GraphQLClient(endpoint);
    
      const mutation = gql`
        query RequestRanking($accountId: String!, $loreId: Int!) {
          requestRanking(accountId: $accountId, loreId: $loreId) {
            ranking
            total
          }
        }
      `;

      const data = await graphQLClient.request(mutation, { 
        accountId: account,
        loreId: activeChapterId,
      });

      if (data?.requestRanking) {
        getRank(data.requestRanking)
      }
    } catch (error) {}
  }, [account, activeChapterId, getRank]);

  const getChimpionList = useCallback(async (chapterId: number) => {
    try {
      const graphQLClient = new GraphQLClient(endpoint);
      const query = gql`
        query ChimpionsList($chapterId: Int!, $top: Int!) {
          chimpionsList(chapterId: $chapterId, top: $top) {
            rank
            account
            amountContributed
            scollsAmount
            chimpAmountOwned
          }
        }
      `;

      const data = await graphQLClient.request(query, {
        chapterId,
        top: 50,
      });

      return data?.chimpionsList;
    } catch (error) {}
  }, [])

  const getBalanceOfLoreNFT = useCallback(async (tokenId: number) => {
    try {
      if (!account) return;
      const balance: ethers.BigNumber = await loreContract.balanceOf(account, tokenId);
      getTokenBalances({
        [tokenId]: balance.toNumber(),
      })
    } catch (error) {}
  }, [account, loreContract, getTokenBalances]);

  const checkNFTShareEarned = useCallback(async () => {
    try {
      if (!activeChapterId) return;
      const owner = await loreContract.owner();
      const balanceOfOwner: ethers.BigNumber = await loreContract.balanceOf(owner, activeChapterId - 1);
      const total: ethers.BigNumber = await loreContract.totalSupply(activeChapterId - 1);

      if (balanceOfOwner.toNumber() !== total.toNumber()) {
        checkNFTShared(true);
      }
    } catch (error) {}
  }, [activeChapterId, loreContract, checkNFTShared]);

  const getLoreNFTOwned = useCallback(async () => {
    try {
      if (!account || !activeChapterId) return;

      const tokenIds = [...Array(activeChapterId).keys()];
      const accounts = [...Array(activeChapterId).keys()].map((_) => account);
  
      const balancesBatched: ethers.BigNumber[] = await loreContract.balanceOfBatch(accounts, tokenIds);

      const ownedTokenIds = tokenIds.filter((id) => balancesBatched[id].toNumber() === 1);

      const urls = await Promise.all(ownedTokenIds.map((id) => {
        return loreContract.uri(id);
      }))

      const data = await Promise.all(urls.map((url) => {
        return fetchMetadata(url)
      }))

      if (data && data.length === ownedTokenIds.length) {
        const nfts = ownedTokenIds.map((id) => ({
          tokenId: id,
          ...(data[id] || {})
        }))
  
        getLoreNFTs(nfts);
      }
    } catch (error) {}
  }, [account, activeChapterId, loreContract, getLoreNFTs]);

  const archiveScrolls = useCallback(async () => {
    if (!activeChapterId) return;

    const tx = await loreContract.archiveScenes();
    await tx.wait();

    const accessToken = await checkValidToken();
    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });

    const mutation = gql`
      mutation ScenesArchive($chapterId: Int!) {
        scenesArchive(chapterId: $chapterId) {
          id
          sceneId
          chapterId
          title
          description
          status
          addedAt
          updatedAt
        }
      }
    `;

    await graphQLClient.request(mutation, {
      chapterId: activeChapterId,
    });
  }, [activeChapterId, loreContract, checkValidToken]);

  return {
    loadActiveScenes,
    loadAllArchivedScrolls,
    loadChapterScrolls,
    getMyContributedScenes,
    contributeToScroll,
    getContributionRanking,
    getChimpionList,
    getBalanceOfLoreNFT,
    checkNFTShareEarned,
    getLoreNFTOwned,
    archiveScrolls,
    updateSceneData,
  };
}
