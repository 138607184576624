
import { useCallback, MouseEvent, useMemo } from "react";
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import copyToClipboard from 'copy-to-clipboard'
import { useModal } from "../../providers/ModalProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useWalletHook } from "../../hooks/useWalletHook";
import { ConnectWalletType } from "../../types/enums";
import WalletCoin from '../../assets/images/wallet-coin.png';
import { Cross, Copy, HrefLink } from "../../assets/svgs";
import { WALLETS, handleConnectionError, addOrSwitchToCronosNetwork } from '../../utils/connect-wallet';
import { LOCAL_STORAGE_WALLET_TYPE } from "../../constants/labels";
import { shortenAddress } from "../../utils/address";
import styles from './ConnectWallet.module.scss';
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import { convertPriceToDecimal } from "../../utils/price";
import { SWAP_WCRO_LINK } from "../../configs";

interface Props {
  onConnectEnd?: () => void,
  CROBalance?: string,
  WCROBalance?: string,
}

const ConnectWallet: React.FC<Props> = ({ onConnectEnd, CROBalance = '', WCROBalance = '' }) => {
  const { active, account, error, activate } = useWeb3React();
  const { closeModal } = useModal();
  const { onChangeWarnings } = useMessage();
  const { disconnectWallet } = useWalletHook();

  const handleConnect = useCallback(async (wallet: any, type: ConnectWalletType) => {
    try {
      window.localStorage.setItem(LOCAL_STORAGE_WALLET_TYPE, type);

      await activate(wallet, async (error) => {
        onChangeWarnings({ message: handleConnectionError(error) });

        if (window.ethereum && error instanceof UnsupportedChainIdError) {
          const setup = await addOrSwitchToCronosNetwork();

          if (setup) {
            activate(wallet);
          }
        } else {
          // clear the walletconnect provider if failed
          if (wallet instanceof WalletConnectConnector && wallet.walletConnectProvider) {
            wallet.walletConnectProvider = undefined
          }
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      closeModal();
      onConnectEnd && onConnectEnd();
    }
  }, [activate, closeModal, onChangeWarnings, onConnectEnd]);

  const handleDisconnect = useCallback((e: MouseEvent | TouchEvent) => {
    e.stopPropagation();
    disconnectWallet();
  }, [disconnectWallet]);

  const copyAddress = useCallback(() => {
    copyToClipboard(account || '');
  }, [account])

  const switchToCronos = useMemo(() => {
    return error instanceof UnsupportedChainIdError && window.ethereum && window.localStorage.getItem(LOCAL_STORAGE_WALLET_TYPE) === ConnectWalletType.METAMASK
  }, [error])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <img className={styles.wallet_coin} src={WalletCoin} alt="" />
        <Cross className={styles.close} onClick={closeModal} />
      </div>
      <div className={styles.title}>{active ? 'My Wallet' : 'Connect Wallet'}</div>
      {
        active &&
        <>
          <div className={styles.address}>
            <span className={styles.label}>Wallet address</span>
            <span className={styles.account}>
              {shortenAddress(account || '', 15)}&nbsp;
              <Copy className={styles.copy} onClick={copyAddress} />
            </span>
          </div>
          <div className={styles.divider} />
          <div className={styles.balance}>
            <span className={styles.label}>CRO Balance</span>
            <span className={styles.amount}>{formatNumberWithComma(roundNumberWithFix(CROBalance, 3))}</span>
          </div>
          <br />
          <div className={styles.balance}>
            <span className={styles.label}>WCRO Balance</span>
            <span className={styles.amount}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(WCROBalance), 3))}</span>
          </div>
          <div className={styles.divider} />
          <div className={styles.swap_wcro}>
            <span>Get WCRO</span>
            <a className={styles.swap_href} href={SWAP_WCRO_LINK} rel="noreferrer noopener" target="_blank"><HrefLink /></a>
          </div>
          <button className={styles.disconnect} onClick={handleDisconnect}>
            Disconnect wallet
          </button>
        </>
      }
      {
        !active &&
        <ul className={styles.wallets}>
          {
            Object.keys(WALLETS).map((wk) => {
              const { name, iconURL, connector } = WALLETS[wk];
              return (
                <li className={styles.wallet} key={name}
                  onClick={(e) => handleConnect(connector, wk as any)}
                >
                  <span className={styles.wallet_name}>
                    {switchToCronos && connector instanceof InjectedConnector ? `${name} (Switch to Cronos)` : name}
                  </span>
                  <img className={styles.icon} src={iconURL} alt="" />
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  )
}

export default ConnectWallet;