
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useContract } from '../../hooks/useContract';
import useChimpayHook from '../../hooks/useChimpayHook';
import { formatDateTime } from '../../utils/time';
import { useChimpay } from '../../providers/ChimpayProvider';

const StakingSetting = () => {
  const { account } = useWeb3React();

  const { stakeChimpContract } = useContract();
  const { getSeasonPeriod, getSeasonTimestamps, isRedemptionPeriod } = useChimpayHook();

  const { state: { stakePauseTime } } = useChimpay();

  const [stakeOwner, setStakeOwner] = useState('');

  useEffect(() => {
    stakeChimpContract.owner().then((id: string) => {
      setStakeOwner(id);
    })
  }, [setStakeOwner, stakeChimpContract])

  const handleStartSeason = useCallback(async () => {
    await stakeChimpContract.startNewSeason();

    alert('New Season Start');
    getSeasonTimestamps();
  }, [stakeChimpContract, getSeasonTimestamps])

  const handleResetRewards = useCallback(async () => {
    await stakeChimpContract.resetAllRewards();
    alert('All Rewards Reset');
  }, [stakeChimpContract])

  const { stake: { end } } = useMemo(() => getSeasonPeriod(), [getSeasonPeriod]);

  const [pauseTime, setPauseTime] = useState('');

  const handleChangePauseTime = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setPauseTime(e.target.value);
  }, [setPauseTime])

  const handlePauseRewards = useCallback(async () => {
    const regex = /^\d{4}-(0|1){1}\d{1}-[0-3]{1}\d{1} [0-2]{1}\d{1}:[0-5]{1}\d{1}:[0-5]{1}\d{1}/;

    if (regex.test(pauseTime)) {
      const time = Date.parse(pauseTime);
      await stakeChimpContract.pauseRewards(time / 1000);

      getSeasonTimestamps();

      alert(`Rewards Paused at ${pauseTime} in seconds`);
    } else {
      alert('Strictly input as YYYY-MM-DD HH:mm:ss!');
    }
  }, [stakeChimpContract, pauseTime, getSeasonTimestamps])

  const [stakeOwnership, setStakeOwnership] = useState('');

  const handleChangeStakeOwnerAddress = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setStakeOwnership(e.target.value);
  }, [setStakeOwnership]);

  const handleTransferStakeOwnership = useCallback(async () => {
    await stakeChimpContract.transferOwnership(stakeOwnership);

    alert(`Ownership transferred to ${stakeOwnership}`);
  }, [stakeChimpContract, stakeOwnership])

  const [rewardsStaker, setRewardsStaker] = useState('');
  const [changingRewards, setChangingRewards] = useState('');

  const handleRewardsChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (/^\d{0,}\.?\d{0,4}$/.test(value)) {
      setChangingRewards(value);
    }
  }, []);

  const handleUpdateRewards = useCallback(async (operator: '-' | '+') => {
    try {
      let value = Number(new BigNumber(changingRewards).multipliedBy(10000).toFixed(0));

      let tx;

      if (operator === '+') {
        tx = await stakeChimpContract.addRewards([rewardsStaker], [value]);
      } else {
        tx = await stakeChimpContract.deductRewards([rewardsStaker], [value]);
      }

      await tx.wait();

      alert(`${changingRewards} rewards have been ${operator === '-' ? 'subtracted from' : 'added to'} wallet ${rewardsStaker}`);
    } catch (error) {
      alert(error); 
    }
  }, [rewardsStaker, changingRewards, stakeChimpContract]);

  if (account !== stakeOwner) {
    return (
      <div>
        You are not owner of staking contract.
      </div>
    );
  }

  return (
    <div>
      <div>
        <h3>Change Stake Ownership</h3>
        <input style={{ width: '350px' }} onChange={handleChangeStakeOwnerAddress} value={stakeOwnership} />
        <button onClick={handleTransferStakeOwnership}>Transfer Ownership</button>
      </div>
      <hr />

      <div>
        <h3>Pause Earning Rewards</h3>

        <div>
          {
            stakePauseTime > 0 && 
            <p>
              {
                stakePauseTime <= Date.now() &&
                `It has stopped earning rewards at ${new Date(stakePauseTime).toLocaleString()}`
              }
              {
                stakePauseTime > Date.now() &&
                `It will stop earning rewards at ${new Date(stakePauseTime).toLocaleString()}`
              }
            </p>
          }
          <input
            onChange={handleChangePauseTime}
            value={pauseTime || formatDateTime(end)}
            placeholder="Pause at time in seconds"
            style={{ width: '200px' }}
          />
          <button onClick={handlePauseRewards}>
            Pause Rewards
          </button>
          <p style={{ fontSize: '14px', margin: '8px 0px' }}>
            Default set pause time at end of working period
          </p>
        </div>
      </div>
      <hr />

      <div>
        <h3>Control Season</h3>
        <button onClick={handleStartSeason} style={{ marginRight: '8px' }}>
          Start New Season
        </button>
        <button onClick={handleResetRewards} disabled={isRedemptionPeriod()}>
          Reset All Rewards
        </button>
      </div>
      <hr />

      <div>
        <h3>Add or Subtract Rewards</h3>
        <input style={{ width: '350px' }} onChange={(e) => setRewardsStaker(e.target.value)} value={rewardsStaker} placeholder="Input staker's wallet address" />
        <div style={{ marginTop: '8px', width: '350px', display: 'flex', justifyContent: 'space-between' }}>
          <input style={{ width: '150px' }} onChange={handleRewardsChange} value={changingRewards} placeholder="Amount" />
          <button style={{ width: '80px' }} onClick={() => handleUpdateRewards('+')}>Add</button>
          <button style={{ width: '80px' }} onClick={() => handleUpdateRewards('-')}>Subtract</button>
        </div>
      </div>
    </div>
  )
}

export default StakingSetting;
