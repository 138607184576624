import { useEffect, useMemo, useRef } from "react";
import { debounce } from "../utils/debounce";

export default function useDebounce(callback: Function, wait = 1000) {
  const ref = useRef<Function>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      if (ref.current) {
        ref.current();
      }
    };

    return debounce(func, wait);
  }, [wait]);

  return debouncedCallback;
}
