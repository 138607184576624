import { useCallback, useEffect, useState } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { useMessage } from "../providers/MessageProvider";
import { metamask, handleConnectionError } from "../utils/connect-wallet";
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN, LOCAL_STORAGE_WALLET_TYPE } from "../constants/labels";
import { ConnectWalletType } from "../types/enums";
import { isMobile } from "../utils/userAgent";

export function useWalletHook() {
  const { active, error, activate, deactivate, connector: connectedWallet } = useWeb3React();
  const { onChangeWarnings } = useMessage();
  const [isWrongNetwork, setIsWrongNetwork] = useState<boolean>(false);

  // Should disconnect the mobile wallet connect when refresh or close page
  useEffect(() => {
    // Remove wallet connect on refresh
    localStorage.removeItem('walletconnect')
  }, []);

  // Automaticly connect to metamask if it is unlocked and authorized when refresh page
  useEffect(() => {
    const savedWallet = window.localStorage.getItem(LOCAL_STORAGE_WALLET_TYPE);

    const shouldConnectMetamask = savedWallet === ConnectWalletType.METAMASK || 
    (isMobile && (savedWallet === ConnectWalletType.NONE || !savedWallet));
    
    if (shouldConnectMetamask && !active) {
      metamask.isAuthorized().then((isAuthorized) => {
        if (isAuthorized && window.ethereum) {
          activate(metamask);
        } else {
          if (isMobile && window.ethereum) {
            
            // Eagerly activate for mobile
            activate(metamask, undefined, true)
          }
        }
      })
    }
  }, [active, activate]);

  // Automaticly switch to Cronos Main if using incorrect network for metamask
  useEffect(() => {
    if (
      window.localStorage.getItem(LOCAL_STORAGE_WALLET_TYPE) === ConnectWalletType.METAMASK
      && window.ethereum
      && error instanceof UnsupportedChainIdError
    ) {
      onChangeWarnings({ message: handleConnectionError(error) });
      setIsWrongNetwork(true);
    } else {
      setIsWrongNetwork(false);
    }
  }, [error, onChangeWarnings]);
  
  const disconnectWallet = useCallback(() => {
    deactivate();

    if (
      window.localStorage.getItem('walletconnect')
      && connectedWallet instanceof WalletConnectConnector
    ) {
      window.localStorage.removeItem('walletconnect'); // remove walletconnect setting
      connectedWallet.close();
      connectedWallet.walletConnectProvider = undefined
    }

    window.localStorage.setItem(LOCAL_STORAGE_WALLET_TYPE, ConnectWalletType.NONE);
    window.localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }, [deactivate, connectedWallet]);

  return {
    disconnectWallet,
    isWrongNetwork,
  };
}
