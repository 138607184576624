import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import Modal, { DisplayModal } from "./Modal";
import { ButtonStatus, ModalChimpStatus, ModalTypes, SignInTypes } from "../../types/enums";
import styles from './ChimpModal.module.scss';
import { Cross, Info } from "../../assets/svgs";
import { useModal } from "../../providers/ModalProvider";
import { WARNING_SIGN_IN_TO_FAVORITE, CHIMP_ERROR_DENIED_SIGN, CHIMP_ERROR_GENERIC, WARNING_SIGN_IN_TO_CHECKOUT, WARNING_SIGN_IN_GENERIC, WARNING_SIGN_IN_TO_MERCH } from "../../constants/errors";
import ChimpFace from '../../assets/images/chimpface.png';
import ProgressButton from "../button/ProgressButton";
import { useMessage } from "../../providers/MessageProvider";
import { useLogin } from "../../hooks/useLogin";
import { useUserHook } from "../../hooks/useUserHook";

const SignInModal: React.FC<{}> = () => {
  const { active } = useWeb3React();
  const { step, closeModal, openModal, updateOperationStep, props } = useModal();
  const { onChangeWarnings } = useMessage();
  const { login, getMyFavoriteChimp } = useLogin();
  const { getUserData } = useUserHook();

  const [error, setError] = useState('');

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep]);

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;
    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;
    return ButtonStatus.NORMAL;
  }, [step]);

  const renderButtonLabel = useMemo((): string => {
    const values: { [key: string]: string } = {
      [ModalChimpStatus.DISABLED]: 'Sign in',
      [ModalChimpStatus.READY]: 'Sign in',
      [ModalChimpStatus.CONFIRMING]: 'Signing...',
      [ModalChimpStatus.FAILED]: 'Retry',
    };
    return values[step];
  }, [step]);

  const renderSignInMessage = useMemo((): string => {
    const from = props ? props.message : SignInTypes.GENERIC;
    const values: { [key: string]: string } = {
      [SignInTypes.CHECKOUT]: WARNING_SIGN_IN_TO_CHECKOUT,
      [SignInTypes.FAVORITE_CHIMP]: WARNING_SIGN_IN_TO_FAVORITE,
      [SignInTypes.GENERIC]: WARNING_SIGN_IN_GENERIC,
      [SignInTypes.SHOP_MERCH]: WARNING_SIGN_IN_TO_MERCH,
    };
    return values[from];
  }, [props]);

  const handleSignIn = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);
    try {
      await login();
      getMyFavoriteChimp();
      getUserData();
      closeModal();
    } catch (error: any) {
      if (error?.response && error?.response?.errors[0]?.extensions.code === 'UNAUTHENTICATED') {
        updateOperationStep(ModalChimpStatus.DISABLED);
        setError(error.response.errors[0].message);
      } else {
        updateOperationStep(ModalChimpStatus.FAILED);
        if (error.code && error.code === 4001) {
          onChangeWarnings({ message: CHIMP_ERROR_DENIED_SIGN });
          setError(CHIMP_ERROR_DENIED_SIGN);
        } else {
          onChangeWarnings({ message: CHIMP_ERROR_GENERIC });
          setError(CHIMP_ERROR_GENERIC);
        }
      }
    }
  }, [closeModal, getMyFavoriteChimp, getUserData, login, onChangeWarnings, updateOperationStep]);

  const handleClick = useCallback(() => {
    if (!active) {
      openModal(ModalTypes.CONNECT_WALLET);
    } else {
      updateOperationStep(ModalChimpStatus.CONFIRM);
      setError('');
      handleSignIn();
    }
  }, [active, openModal, updateOperationStep, handleSignIn]);

  return (
    <Modal allowClickOutside>
      <div className={`${styles.container} ${styles.container_body}`}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <div className={styles.chimp_logo_wrap}>
          <span className={styles.chimp_logo}>
            <img src={ChimpFace} alt="" />
          </span>
        </div>
        <div className={`${styles.title} ${styles.title_alone}`}>Sign in with your wallet</div>
        <div className={styles.message}>{renderSignInMessage}</div>
        <div className={styles.warnings}>
          {
            !active &&
            <div className={styles.warning}>
              <Info className={styles.warning_icon} />
              <span className={styles.warning_text}>Please connect your wallet to continue.</span>
            </div>
          }
          {
            !!error &&
            <div className={styles.warning}>
              <Info className={styles.warning_icon} />
              <span className={styles.warning_text}>{error}</span>
            </div>
          }
        </div>
        <div className={styles.buttons}>
          <ProgressButton disabled={step === ModalChimpStatus.DISABLED} status={buttonStatus} onClick={handleClick}>
            {active ? renderButtonLabel : 'Connect Wallet'}
          </ProgressButton>
        </div>
        {step === ModalChimpStatus.CONFIRMING &&
          <div className={styles.info}>
            <Info className={styles.info_icon} />
            <span className={styles.info_text}>
              Please sign the message in your wallet to continue.
            </span>
          </div>
        }
      </div>
    </Modal>
  )
}

export default DisplayModal(SignInModal, ModalTypes.SIGN_IN);