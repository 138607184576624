import { useCallback, useEffect, useMemo } from "react";
import Modal, { DisplayModal } from "./Modal";
import HolidayCard from "../christmas-card/HolidayCard";
import Button from "../button/ProgressButton";
import LinkButton from "../button/LinkButton";
import { useWeb3React } from "@web3-react/core";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import { useConnectDiscord } from "../../hooks/useConnectDiscord";
import useChimpayHook from "../../hooks/useChimpayHook";
import { ModalTypes, CardTypes, ModalChimpStatus, SignInTypes } from "../../types/enums";
import { ChimpStakeMeta } from "../../types/interfaces";
import { Cross } from "../../assets/svgs";
import { DISCORD_API, HIDE_MARKETPLACE } from "../../configs";
import { getChimpDisplayTitle } from "../../utils/title";
import { MAX_STAKING_ALLOWED } from "../../constants/chimpay";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../../constants/labels";
import styles from './ChimpModal.module.scss';

const NORAML_CHIMP_IMAGE_URL = 'https://fdu5ph5bppgruc5msyxpsirlmpoaszae3rag3h3coxybxhcmauaa.arweave.net/KOnXn6F7zRoLrJYu-SIrY9wJZATcQG2fYnXwG5xMBQA';
const HONORARY_CHIMP_IMAGE_URL = 'https://qily77vllap6rutprxx5sln5yagebewcyzbhb4il3oilx4ya27na.arweave.net'

const PreSellOrTransferModal = () => {
  const { account } = useWeb3React();
  const { closeModal, openModal, updateOperationStep } = useModal();

  const { connectDiscord } = useConnectDiscord();
  const { isWorkingPeriod, checkLockChimpStaked } = useChimpayHook();

  const { state: { forBuyOrSaleChimp } } = useChimp();
  const { state: { stakedTokens } } = useChimpay();

  const { tokenId, image, seller, isForSale, hdImage, isHonorary, name, stakedAt, stakingExpired } = forBuyOrSaleChimp as ChimpStakeMeta;

  const handleSell = useCallback(() => {
    openModal(ModalTypes.SELL_CHIMP);
  }, [openModal])

  const handleRemove = useCallback(() => {
    openModal(ModalTypes.REMOVE_CHIMP)
  }, [openModal])

  const handleTransfer = useCallback(() => {
    openModal(ModalTypes.TRANSFER_CHIMP);
  }, [openModal])

  const handleConnectDiscord = useCallback(() => {
    connectDiscord(tokenId, isHonorary); 
  }, [connectDiscord, tokenId, isHonorary])

  const handleStakeOrUnstake = useCallback((type: 'stake' | 'unstake') => {
    if (!account || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.GENERIC });
    }

    if (type === 'stake') {
      openModal(ModalTypes.STAKE_CHIMP, { tokenId, isHonorary });
    } else {
      openModal(
        ModalTypes.UNSTAKE_CHIMP,
        isHonorary 
        ? { honoraryIds: [{ id: tokenId, suspended: stakingExpired }] } 
        : { ids: [{ id: tokenId, suspended: stakingExpired }] } 
      );
    }
  }, [openModal, account, tokenId, isHonorary, stakingExpired])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.INITIAL);
  }, [updateOperationStep])

  const noStakingExpired = useMemo(() => 
    [...stakedTokens.normal, ...stakedTokens.honorary].filter(({ stakingExpired }) => stakingExpired).length === 0, [stakedTokens]);

  const canSell = useMemo(() => !isForSale && !HIDE_MARKETPLACE, [isForSale]);
  const isOnSale = useMemo(() => seller === account && isForSale && !HIDE_MARKETPLACE, [seller, account, isForSale]);
  const isStaked = useMemo(() => stakedAt && stakedAt > 0, [stakedAt]);

  const holidayCardImage = useMemo(() => {
    if (isHonorary) {
      return hdImage.replace('https://arweave.net', HONORARY_CHIMP_IMAGE_URL);
    }

    return `${NORAML_CHIMP_IMAGE_URL}/${tokenId}.png`;
  }, [tokenId, hdImage, isHonorary])

  return (
    <Modal>
      <div className={`${styles.container} ${styles.container_body}`}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <span className={styles.title}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</span>
        <div className={styles.pre_header}>
          <img className={styles.pre_img} src={image || ''} alt="" />
          <div className={styles.pre_summary}>
            <div className={styles.pre_chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
            <a className={styles.pre_download} rel="noopener noreferrer" href={hdImage} target="_blank">View Full Image</a> 
            <HolidayCard chimpId={tokenId} hdImage={holidayCardImage} />
          </div>
        </div>
        <div className={styles.buttons}>
          {
            canSell && !isStaked && 
            <Button classname={styles.pre_button} onClick={handleSell}>Sell</Button>
          }
          {
            isOnSale && 
            <>
              <Button classname={styles.pre_button} onClick={handleSell}>Adjust sale price</Button>
              <Button classname={styles.pre_button} onClick={handleRemove} isGhost>Remove from market</Button>
            </>
          }
          {
            !isStaked && 
            <Button classname={styles.pre_button} onClick={handleTransfer} isGhost>Transfer</Button>
          }
          {
            canSell && 
            <>
              {
                !isStaked && isWorkingPeriod() && noStakingExpired && 
                <Button 
                  classname={styles.pre_button} 
                  isGhost 
                  onClick={() => handleStakeOrUnstake('stake')}
                  disabled={stakedTokens.normal.length + stakedTokens.honorary.length >= MAX_STAKING_ALLOWED}
                >
                  Send to Work
                </Button>
              }
              {
                isStaked && 
                <Button 
                  classname={styles.pre_button} 
                  isGhost 
                  onClick={() => handleStakeOrUnstake('unstake')} 
                  disabled={checkLockChimpStaked(stakedAt).isLock}
                >
                  Recall from Work
                </Button>
              }
            </> 
          }
          {
            !!DISCORD_API && 
            <Button classname={styles.pre_button} onClick={handleConnectDiscord} isGhost>Connect Discord</Button>
          }
          <LinkButton
            className={styles.pre_button}
            to={`/chimp/${tokenId}?isHonorary=${isHonorary}&page=${CardTypes.OWNING}`}
            onClick={closeModal}
          >
            View Chimp Details
          </LinkButton>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(PreSellOrTransferModal, ModalTypes.PRE_SELL_OR_TRANSFER);