import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import classNames from 'classnames';
import { ethers } from 'ethers';
import { useNFTMint } from '../../providers/NFTProvider';
import { useMessage } from '../../providers/MessageProvider';
import { useModal } from '../../providers/ModalProvider';
import { useMintPrice } from '../../providers/PriceProvider';
import { Timer, Info } from '../../assets/svgs';
import { useContractMethods } from '../../hooks/useContractMethods';
import ProgressButton from '../button/ProgressButton';
import { MAX_PER_MINT, LAUNCH_TIME } from "../../configs";
import { countDown } from '../../utils/time';
import { computePrice, getMintingAmountForBalance } from '../../utils/price';
import {
  MINT_ERROR_GENERIC,
  MINT_ERROR_REJECTED,
} from '../../constants/errors';
import { ButtonStatus, MintStatus, ModalTypes } from '../../types/enums';
import styles from './NFT.module.scss';

export const MintInput = ({ status }: { status: MintStatus, }) => {
  const { active } = useWeb3React();

  const { state: { amount, balance, remainingSupply }, updateAmount } = useNFTMint();

  const mintPrice = useMintPrice();

  const mintMaxForBalance = useMemo(() => getMintingAmountForBalance(balance, mintPrice), [balance, mintPrice]);

  const maxValue = useMemo(() => active ? Math.min(+mintMaxForBalance, remainingSupply, MAX_PER_MINT).toString() : MAX_PER_MINT.toString(), [active, remainingSupply, mintMaxForBalance]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    updateAmount(e.target.value);
  }, [updateAmount])

  const inputDisabled = useMemo(() => !active || status === MintStatus.MINTING, [status, active])

  return (
    <div className={styles.input_wrapper}>
      <input type="number" className={classNames(styles.input, { [styles.input_error]: +amount > +maxValue })} disabled={inputDisabled} value={amount} min={1} max={10} onChange={handleChange} />
      {
        active && +remainingSupply >= MAX_PER_MINT && +mintMaxForBalance >= MAX_PER_MINT && +amount > +maxValue &&
        <div className={styles.error}>
          <Info className={styles.error_icon} />
          <span className={styles.error_text}>Maximum of {maxValue} chimps per transaction</span>
        </div>
      }
      {
        active && +mintMaxForBalance < MAX_PER_MINT && +amount > +mintMaxForBalance &&
        <div className={styles.error}>
          <Info className={styles.error_icon} />
          <span className={styles.error_text}>Insufficient CRO Balance</span>
        </div>
      }
      {
        active && +remainingSupply < MAX_PER_MINT && +amount > +remainingSupply &&
        <div className={styles.error}>
          <Info className={styles.error_icon} />
          <span className={styles.error_text}>Only {remainingSupply} Chimps Left</span>
        </div>
      }
    </div>
  )
}


interface Props {
  status: MintStatus,
  setStatus: (st: MintStatus) => void,
}

export const MintButton = ({ status, setStatus }: Props) => {
  const [timer, setTimer] = useState('');

  const { active } = useWeb3React();

  const { openModal } = useModal();
  const { onChangeWarnings } = useMessage();

  const mintPrice = useMintPrice();

  const { mintNFT } = useContractMethods();
  const { state: { amount, remainingSupply, startTimestamp, balance }, updateNewTrxnChimps } = useNFTMint();

  const maxValue = useMemo(() => active ? Math.min(+getMintingAmountForBalance(balance, mintPrice), MAX_PER_MINT, remainingSupply).toString() : MAX_PER_MINT.toString(), [active, balance, remainingSupply, mintPrice]);

  const handleMint = useCallback(async () => {
    if (!amount) return;

    setStatus(MintStatus.MINTING);

    try {
      const result = await mintNFT(amount);

      setStatus(MintStatus.SUCCEESS);

      if (result.events) {
        const transfer = result.events.find((evt: any) => evt.event === 'ChimpsMinted');

        let ids = [];
        if (Array.isArray(transfer.args.tokenIds)) {
          ids = transfer.args.tokenIds.map((id: ethers.BigNumber) => id.toString());
        }

        updateNewTrxnChimps(result.transactionHash, ids)
      }
    } catch (error: any) {
      if (error.code && error.code === 4001 && error.message.includes('MetaMask')) {
        onChangeWarnings({ message: MINT_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: MINT_ERROR_GENERIC });
      }
      setStatus(MintStatus.READY);
    }
  }, [mintNFT, updateNewTrxnChimps, onChangeWarnings, setStatus, amount]);

  const handleButonClick = useCallback(() => {
    switch (status) {
      case MintStatus.UNCONNECTED:
        openModal(ModalTypes.CONNECT_WALLET);
        break;
      case MintStatus.READY:
        handleMint();
        break;
      default:
        break;
    }
  }, [status, handleMint, openModal])

  const buttonStatus = useMemo(() => {
    if (status === MintStatus.MINTING) {
      return ButtonStatus.LOADING
    }

    if (status === MintStatus.SUCCEESS) {
      return ButtonStatus.DONE
    }

    if (status === MintStatus.SOLD_OUT) {
      return ButtonStatus.WARNING
    }

    return ButtonStatus.NORMAL
  }, [status])

  const buttonDisabled = useMemo(() => {
    return status === MintStatus.UNSTARTED || +amount > +maxValue || amount === '0'
  }, [status, amount, maxValue])

  useEffect(() => {
    let interval: any;

    let count = countDown(startTimestamp * 1000);

    if (count) {
      setStatus(MintStatus.UNSTARTED);
      setTimer(count)

      interval = setInterval(() => {
        count = countDown(startTimestamp * 1000);
  
        if (!count) {
          clearInterval(interval);
  
          setStatus(MintStatus.READY);
        }
  
        setTimer(count)
      }, 1000)
    } else {
      if (!active) {
        setStatus(MintStatus.UNCONNECTED);
      } else if (remainingSupply <= 0) {
        setStatus(MintStatus.SOLD_OUT);
      } else {
        setStatus(MintStatus.READY);
      }
    }

    return () => clearInterval(interval);
  }, [setTimer, setStatus, remainingSupply, startTimestamp, active]);

  if (!mintPrice) return null;

  return (
    <>
      <ProgressButton
        onClick={handleButonClick}
        disabled={buttonDisabled}
        status={buttonStatus}
        classname={styles.mint_button}
      >
        {status === MintStatus.UNCONNECTED && <>Connect Wallet</>}
        {status === MintStatus.UNSTARTED && <><Timer />&nbsp;&nbsp;{timer}</>}
        {status === MintStatus.SOLD_OUT && <>Sold Out</>}
        {status === MintStatus.READY && <>Adopt Chimps - {computePrice(+amount, mintPrice)} CRO</>}
        {status === MintStatus.MINTING && 'Adopting...'}
      </ProgressButton>
      {status === MintStatus.UNSTARTED && <span className={styles.desc}>Launching on {LAUNCH_TIME}</span>}
    </>
  )
}
