import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import Toggle from './Toggle';
import { LOCAL_STORAGE_THEME_MODE } from '../../constants/labels';
import { DarkTheme, LightTheme } from '../../assets/svgs';
import styles from './ThemeToggle.module.scss';

const getThemeStorage = () => window.localStorage.getItem(LOCAL_STORAGE_THEME_MODE);
const setThemeStorage = (mode: string) => window.localStorage.setItem(LOCAL_STORAGE_THEME_MODE, mode);

export type ThemeTypes = 'light' | 'dark';

const ThemeToggle: FC = () => {
  const [theme, setTheme] = useState<ThemeTypes>('light');

  const handleToggleTheme = useCallback((e: MouseEvent) => {
    e.stopPropagation();

    const dom = document.documentElement;
    const reverse = dom.className !== 'dark' ? 'dark' : 'light';

    dom.className = reverse;
    setTheme(reverse);
    setThemeStorage(reverse);
  }, [])

  useEffect(() => {
    const theme = getThemeStorage() || 'light';

    setTheme(theme as ThemeTypes);
    setThemeStorage(theme);
    document.documentElement.className = theme;
  }, []);

  return (
    <>
      <Toggle className={styles.mb} label='Dark mode' checked={theme === 'dark'} onToggle={handleToggleTheme} />
      <div className={styles.dk} onClick={handleToggleTheme}>
        {
          theme === 'dark' ? <DarkTheme /> : <LightTheme />
        }
      </div>
    </>
  );
};

export default ThemeToggle;
