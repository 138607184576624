import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useNFTMint } from '../../providers/NFTProvider';
import { HrefLink } from '../../assets/svgs';
import GroupRects from '../../assets/images/group-rects.png';
import GuessChimp from '../../assets/images/guess-chimp.png';
import { MintStatus } from '../../types/enums';
import { CRONOS_EXPLORER } from "../../configs";
import { chimpImage } from '../../utils/chimp-image';
import styles from './NFT.module.scss';
import LinkButton from '../button/LinkButton';
import ProgressButton from '../button/ProgressButton';

const Success = ({ setStatus }: { setStatus: (st: MintStatus) => void }) => {
  const [loadedIndex, setLoadedIndex] = useState<number[]>([]);

  const { state: { newChimps: { trxnId, tokenIds: ids } } } = useNFTMint();

  const jointIDs = useMemo(() => {
    const nids = ids.map((id) => `#${id}`)
    if (nids.length <= 1) return nids[0]

    return nids.join(', ')
  }, [ids])

  return (
    <div className={styles.success}>
      <div className={styles.images}>
      {
        ids.slice(0, 3).map((id: string, index: number) => {
          return (
            <div
              key={id} 
              className={classNames(styles.img, { [styles.img_mg]: ids.length < 3, [styles.img_anticlockwise]: ids.length >= 3 && index === 0, [styles.img_clockwise]: ids.length >= 3 && index === 2 })} 
            >
              <img src={chimpImage(+id)} alt="" onLoad={() => setLoadedIndex([...loadedIndex, index])} style={{ display: loadedIndex.includes(index) ? 'initial' : 'none' }} />
              <img src={GuessChimp} style={{ backgroundColor: '#555a69', display: loadedIndex.includes(index) ? 'none' : 'initial' }} alt="" />
            </div>
          )
        })
      }
      {
        ids.length > 3 &&
        <div className={styles.remaining}>
          <img src={GroupRects} alt="" />
          <span className={styles.remaining_text}>+{ids.length - 3}</span>
        </div>
      }
      </div>
      <span className={styles.success_title}>Congratulations!</span>
      <span className={styles.success_description}>You have adopted {ids.length} {ids.length > 1 ? <>chimps: <br /></> : 'chimp: '} {jointIDs}.</span>
      <div className={styles.success_explore}>
        View transaction on the
        &nbsp;
        <a
          rel="noreferrer noopener"
          target="_blank"
          href={`${CRONOS_EXPLORER}/tx/${trxnId}`}
        >chain</a>
        &nbsp;
        <a
          rel="noreferrer noopener"
          target="_blank"
          href={`${CRONOS_EXPLORER}/tx/${trxnId}`}
        ><HrefLink /></a>
      </div>
      <LinkButton
        to='/my-chimps'
        className={styles.button}
      >
        View My {ids.length > 1 ? 'Chimps' : 'Chimp'}
      </LinkButton>
      <ProgressButton
        classname={styles.button}
        onClick={() => setStatus(MintStatus.READY)}
      >
        Adopt again
      </ProgressButton>
    </div>
  )
}

export default Success;