import { createContext, FC, ReactNode, useCallback, useContext, useReducer } from 'react';
import BuyChimpModal from '../components/modal/BuyChimpModal';
import PreSellOrTransferModal from '../components/modal/PreSellOrTransferModal';
import SellChimpModal from '../components/modal/SellChimpModal';
import TransferChimpModal from '../components/modal/TransferChimpModal';
import RemoveChimpModal from '../components/modal/RemoveChimpModal';
import ConnectWalletModal from '../components/modal/ConnectWalletModal';
import MakeOfferChimpModal from '../components/modal/MakeOfferChimpModal';
import EditOfferChimpModal from '../components/modal/EditOfferChimpModal';
import AcceptOfferChimpModal from '../components/modal/AcceptOfferChimpModal';
import SignInModal from '../components/modal/SignInModal';
import OverlayModal from '../components/modal/OverlayModal';
import WrongNetworkModal from '../components/modal/WrongNetworkModal';
import AddToCartModal from '../components/modal/AddToCartModal';
import ViewCartModal from '../components/modal/ViewCartModal';
import CheckoutConfirmModal from '../components/modal/CheckoutConfirmModal';
import StakeModal from '../components/chimpay/StakeModal';
import UnstakeModal from '../components/chimpay/UnstakeModal';
import ClaimGiftModal from '../components/chimpay/ClaimGift';
import { ModalTypes, ModalActionTypes, ModalChimpStatus } from '../types/enums';
import { ModalPropsMeta } from '../types/interfaces';


interface State {
  modal: ModalTypes,
  step: ModalChimpStatus,
  props?: ModalPropsMeta
}

interface IContext {
  state: State,
  openModal: (type: ModalTypes, props?: ModalPropsMeta) => void,
  closeModal: () => void,
  updateOperationStep: (type: ModalChimpStatus) => void,
}

const initialState: State = {
  modal: ModalTypes.NONE,
  step: ModalChimpStatus.INITIAL,
  props: {}
}

const Context = createContext<IContext>({
  state: initialState,
  openModal: () => {},
  closeModal: () => {},
  updateOperationStep: () => {},
})

export const useModal = () => {
  const { state: { modal, step, props }, openModal, closeModal, updateOperationStep } = useContext(Context);

  return {
    modal,
    step,
    openModal,
    closeModal,
    updateOperationStep,
    props,
  };
}

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case ModalActionTypes.UPDATE_MODAL_TYPE:
      return {
        ...state,
        modal: action.modalType,
        props: action.props || {}
      }
    case ModalActionTypes.UPDATE_OPERATION_STEP:
      return {
        ...state,
        step: action.step,
      }
    default:
      throw new Error();
  }
}

const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const openModal = useCallback((type: ModalTypes, props?: ModalPropsMeta) => {
    dispatch({
      type: ModalActionTypes.UPDATE_MODAL_TYPE,
      modalType: type,
      props
    });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({
      type: ModalActionTypes.UPDATE_MODAL_TYPE,
      modalType: ModalTypes.NONE,
    });
  }, [])

  const updateOperationStep = useCallback((step: ModalChimpStatus) => {
    dispatch({
      type: ModalActionTypes.UPDATE_OPERATION_STEP,
      step,
    });
  }, [])

  const context = {
    state,
    openModal,
    closeModal,
    updateOperationStep,
  }

  return (
    <Context.Provider value={context}>
      <BuyChimpModal />
      <PreSellOrTransferModal />
      <SellChimpModal />
      <TransferChimpModal />
      <RemoveChimpModal />
      <ConnectWalletModal />
      <MakeOfferChimpModal />
      <EditOfferChimpModal />
      <AcceptOfferChimpModal />
      <SignInModal />
      <OverlayModal />
      <AddToCartModal/>
      <WrongNetworkModal />
      <ViewCartModal />
      <CheckoutConfirmModal />
      <StakeModal />
      <UnstakeModal />
      <ClaimGiftModal />
      {children}
    </Context.Provider>
  )
}

export default ModalProvider