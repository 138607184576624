import { FC, MouseEvent, useCallback } from 'react';
import classNames from 'classnames';
import styles from './Toggle.module.scss';

interface Props {
  label: string;
  checked: boolean;
  onToggle: (e: MouseEvent) => void;
  className?: string;
}

const Toggle: FC<Props> = ({ label, checked, onToggle, className }) => {
  const handleToggle = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onToggle(e);
  }, [onToggle])

  return (
    <div className={classNames(styles.component, className)}>
      <span className={styles.label}>{label}</span>
      <div
        className={classNames(styles.button, { [styles.button_checked]: checked })}
        onClick={handleToggle}
      >
        <div className={classNames(styles.circle, { [styles.circle_checked]: checked })} />
      </div>
    </div>
  );
};

export default Toggle;
