import { FC, MouseEvent, useCallback } from "react";

interface Props {
  checked: boolean;
  onCheck?: (val: boolean) => void;
  disabled?: boolean;
  className?: string;
  fillStyle?: 'cover' | 'none';
}

const CheckButton: FC<Props> = ({ className, checked, onCheck, disabled, fillStyle }) => {
  const handleClick = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (onCheck) onCheck(!checked);
  }, [checked, onCheck]);

  return (
    <svg
      style={{ cursor: disabled ? 'default' : 'pointer' }}
      className={className}
      onClick={disabled ? undefined : handleClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="0.5" y="0.5"
        width="31" height="31"
        rx="3.5"
        fill={checked && fillStyle === 'cover' ? 'var(--svg-fill-primary)' : '#FFF'}
        stroke={checked && fillStyle === 'cover' ? 'var(--svg-fill-primary)' : 'var(--label-color)'}
      />
      {
        checked &&
        <path
          d="M13.3187 20.2437L24.8525 7.98633L26.628 9.87166L13.3187 24.0143L5.33333 15.529L7.10758 13.6437L13.3187 20.2437Z"
          fill={fillStyle === 'cover' ? '#FFF' : 'var(--svg-fill-primary)'}
        />
      }
    </svg>
  )
}

CheckButton.defaultProps = {
  fillStyle: 'none',
  disabled: false,
}

export default CheckButton;
