import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/forms/TextInput';
import EmailInput from '../../components/forms/EmailInput';
import DataListInput from '../../components/forms/DataListInput';
import OrderItem from '../../components/shop/OrderItem';
import Collapse from '../../components/shop/Collapse';
import ProgressButton from '../../components/button/ProgressButton';
import { ButtonStatus, CheckoutOrderActionTypes, MerchPaymentTypes, ModalTypes, SignInTypes } from '../../types/enums';
import { useMerchandiseHook } from '../../hooks/useMerchandiseHook';
import { useMessage } from '../../providers/MessageProvider';
import { useModal } from "../../providers/ModalProvider";
import { useUser } from '../../providers/UserProvider';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import { OrderCartMeta, CheckoutForm, CheckoutInputMeta, CheckoutItemMeta, CheckoutItemForContract, RemainingProductMeta, RequestShippingPriceItem } from '../../types/interfaces';
import countries from '../../data/countries.json';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_CART, LOCAL_STORAGE_ORDER_ID } from '../../constants/labels';
import styles from './ShopCheckout.module.scss';
import { CHECKOUT_ERROR_GENERIC, CHIMP_ERROR_REJECTED } from '../../constants/errors';
import { useMerchandisContractMethods } from '../../hooks/useMerchandisContractMethods';
import { useUserHook } from '../../hooks/useUserHook';
import useDebounce from '../../hooks/useDebounce';
import { convertUSDCPriceToDecimal } from '../../utils/price';
import { Info } from '../../assets/svgs';

const ShopCheckout: React.FC<{}> = () => {
  const { active, account } = useWeb3React();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { updateOrderCart, state: { orderCart } } = useUser();
  const { calculateShippingPrice, orderCheckout, verifyOrderCheckout, getOrderById } = useMerchandiseHook();
  const { getUserOrders } = useUserHook();
  const { approveWCRO, getWCROBalance, getAllowanceWCRO, checkout, payForShippingCost, getProductRemainingSupplies } = useMerchandisContractMethods();
  const { onChangeWarnings } = useMessage();

  const [stepProcess, setStepProcess] = useState(CheckoutOrderActionTypes.READY);
  const [shipping, setShipping] = useState({
    shippingPrice: 0,
    shippingPriceSecret: ''
  });
  const [values, setValues] = useState<CheckoutForm>({
    email: '',
    areaCode: '',
    mobile: '',
    firstName: '',
    lastName: '',
    shippingCountry: '',
    shippingAddress1: '',
    shippingCity: '',
    shippingPostCode: ''
  });
  const [errors, setErrors] = useState<CheckoutForm>({});
  const [WCROBalance, setWCROBalance] = useState('');
  const [allowanceWCRO, setAllowanceWCRO] = useState<string>('');
  const [remainingSupplies, setRemainingSupplies] = useState<RemainingProductMeta[]>([]);
  const [messageSoldOut, setMessageSoldOut] = useState<string>('');

  useEffect(() => {
    const cart = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_CART) || '[]');
    updateOrderCart(cart);
  }, [updateOrderCart]);

  useEffect(() => {
    const getRemainingSupplies = async () => {
      if (orderCart.length > 0) {
        const contractIds = orderCart.map((item: OrderCartMeta) => item.contractId);
        const remaining = await getProductRemainingSupplies(contractIds);
        setRemainingSupplies(remaining);
      }
    }
    getRemainingSupplies();
  }, [getProductRemainingSupplies, orderCart]);

  useEffect(() => {
    if (account) {
      getWCROBalance().then((balance: string) => {
        setWCROBalance(balance);
      });

      getAllowanceWCRO().then((allowance: string) => {
        setAllowanceWCRO(allowance);
      });
    }
  }, [getWCROBalance, account, getAllowanceWCRO]);

  useEffect(() => {
    if (remainingSupplies.length > 0) {
      const products = remainingSupplies.filter((item: RemainingProductMeta) => item.areLimited && item.remainingSupplies === 0);
      const productSoldOut = orderCart.filter((item: OrderCartMeta) => {
        return products.some((f) => {
          return f.contractId === item.contractId;
        });
      });

      const messages = productSoldOut.map((item: OrderCartMeta) => `${item.title}${item.size ? ', size ' + item.size : ''}`);
      const message = `${messages.join('')} is sold out. Please remove item from your cart to continue.`;
      if (productSoldOut.length > 0) {
        onChangeWarnings({ message });
        setMessageSoldOut(message);
      }
    }
  }, [onChangeWarnings, orderCart, remainingSupplies]);

  const totalCalculated = useMemo(() => {
    let subTotal = new BigNumber(0);
    let subTotalBananas = new BigNumber(0);

    if (orderCart.length > 0) {
      orderCart.forEach((item: OrderCartMeta) => {
        const add = new BigNumber(item.price).multipliedBy(item.quantity);
        if (item.currency === MerchPaymentTypes.BANANAS) {
          subTotalBananas = subTotalBananas.plus(add);
        } else {
          subTotal = subTotal.plus(add);
        }
      })
    }

    return {
      subTotal,
      subTotalBananas,
    };
  }, [orderCart]);

  const cartItems = useMemo(() => {
    return orderCart.reduce((items, item) => {
      const index = items.findIndex(({ productId }) => productId === +item.id);

      if (index !== -1) {
        items[index].quantity += 1; 
      } else {
        items.push({
          productId: Number(item.id),
          quantity: item.quantity,
        });
      }

      return items;
    }, [] as RequestShippingPriceItem[]);
  } , [orderCart])

  const shippingCountryCode = useMemo(() => 
    countries.find(({ label }) => label === values.shippingCountry)?.value || '', [values.shippingCountry]);

  const requestShippingPrice = useCallback(() => {
    if (
      values.shippingCountry 
      && values.shippingCity
      && values.shippingPostCode
      && cartItems.length > 0
    ) {
      setStepProcess(CheckoutOrderActionTypes.CALCULATE_SHIPPING);

      const requestData = {
        postalCode: values.shippingPostCode,
        city: values.shippingCity,
        country: shippingCountryCode,
        items: cartItems,
      };

      calculateShippingPrice(requestData).then((data) => {
        if (data?.shippingPrice) {
          setShipping(data);
        }
      }).finally(() => {
        setStepProcess(CheckoutOrderActionTypes.READY);
      })
    }
  }, [cartItems, values.shippingCountry, values.shippingCity, values.shippingPostCode, shippingCountryCode, calculateShippingPrice]);

  const getShippingPriceDebounce = useDebounce(requestShippingPrice, 1000);

  const onChangeForm = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let updatedErrors = errors;
    delete updatedErrors[name];
    setErrors(updatedErrors);

    setValues(values => ({ ...values, [name]: value }));
  }, [errors]);

  const debounceChangeForm = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setShipping({
      shippingPrice: 0,
      shippingPriceSecret: ''
    });

    onChangeForm(e);
    getShippingPriceDebounce();
  };

  const validateForm = useCallback((values: CheckoutForm) => {
    const errors: CheckoutForm = {};
    const fields = ['firstName', 'lastName', 'areaCode', 'mobile', 'email', 'shippingCountry', 'shippingAddress1', 'shippingCity', 'shippingState', 'shippingPostCode'];

    fields.forEach((field: string) => {
      if (!values[field]) {
        errors[field] = "This field cannot be empty";
      }
    });

    if (values.email && !/^[^\s@]+@[^\s@\\.]+\.[^\s@]{2,}$/.test(values.email)) {
      errors.email = "Invalid email address"
    }

    if (values.mobile && values.mobile.length < 8) {
      errors.mobile = "Mobile should be at least 8 digits"
    }

    const regexPhoneNumber = /^\+[0-9- ]*$|^[0-9- ]*$/;
    if (values.mobile && !regexPhoneNumber.test(values.mobile)) {
      errors.mobile = "Invalid mobile number"
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(async () => {
    if (stepProcess === CheckoutOrderActionTypes.READY || stepProcess === CheckoutOrderActionTypes.FAILED) {
      const formErrors = validateForm(values);
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setStepProcess(CheckoutOrderActionTypes.FAILED);
      } else if (!shipping.shippingPrice) {
        onChangeWarnings({ message: 'Shipping cost not detected. Please refresh and try again.' });
      } else {
        try {
          openModal(ModalTypes.CHECKOUT_CONFIRMING, {
            totalPrice: totalCalculated.subTotal.gt(0) ? 
              `${formatNumberWithComma(roundNumberWithFix(totalCalculated.subTotal.toString(), 3))} ${MerchPaymentTypes.USDC}` : '',
            totalBananasPrice: totalCalculated.subTotalBananas.gt(0) ? 
              `${formatNumberWithComma(roundNumberWithFix(totalCalculated.subTotalBananas.toString(), 3))} ${MerchPaymentTypes.BANANAS}` : '',
          });

          const orderCheckoutItems = orderCart.map((item: OrderCartMeta): CheckoutItemMeta => {
            return {
              contractId: item.contractId,
              productId: Number(item.id),
              variantOption1: item.size || '',
              variantOption2: '',
              variantOption3: '',
              quantity: item.quantity,
              paymentType: item.currency,
            }
          });

          const payload: CheckoutInputMeta = {
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            mobile: values.mobile ? `(${values.areaCode}) ${values.mobile}` : '',
            email: values.email || '',
            shippingCountry: values.shippingCountry || '',
            shippingCountryCode: shippingCountryCode,
            shippingAddress1: values.shippingAddress1 || '',
            shippingAddress2: values.shippingAddress2 || '',
            shippingCity: values.shippingCity!,
            shippingState: values.shippingState!,
            shippingPostCode: values.shippingPostCode!,
            items: orderCheckoutItems,
            shippingPrice: shipping.shippingPrice,
            shippingPriceSecret: shipping.shippingPriceSecret
          }

          let orderId = window.localStorage.getItem(LOCAL_STORAGE_ORDER_ID);
          let requestingOrder;

          if (!orderId) {
            requestingOrder = await orderCheckout(payload);
            orderId = requestingOrder.id.toString();
            window.localStorage.setItem(LOCAL_STORAGE_ORDER_ID, requestingOrder.id.toString());
          } else {
            requestingOrder = await getOrderById(+orderId);
          }

          if (orderId) {
            const wcroBalance = new BigNumber(convertUSDCPriceToDecimal(WCROBalance));
            const amount = new BigNumber(shipping.shippingPrice).plus(totalCalculated.subTotal);

            if (wcroBalance.lt(amount)) {
              setStepProcess(CheckoutOrderActionTypes.FAILED);
              onChangeWarnings({ message: 'Not enough USDC balance' });
            } else {
              if (requestingOrder.status === 'PAYMENT_PENDING') {
                if (amount.gt(new BigNumber(convertUSDCPriceToDecimal(allowanceWCRO)))) {
                  await approveWCRO(amount.toString());
                }

                const payloadContract = orderCart.reduce((arr, item) => {
                  for (let i = 0; i < item.quantity; i++) {
                    if (item.currency !== MerchPaymentTypes.BANANAS) {
                      arr.push({
                        productId: item.contractId,
                        subProduct: item.size || '',
                      });
                    }
                  }
                  return arr;
                }, [] as CheckoutItemForContract[]);
  
                if (payloadContract.length > 0) {
                  await checkout(Number(orderId), shipping.shippingPrice, payloadContract);
                } else {
                  await payForShippingCost(shipping.shippingPrice);
                }
              }

              if (orderId) {
                const verify = await verifyOrderCheckout(Number(orderId));
                if (verify.id) {
                  window.localStorage.removeItem(LOCAL_STORAGE_CART);
                  window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
                  setStepProcess(CheckoutOrderActionTypes.FINISH);

                  updateOrderCart([]);
                  navigate(`/shop/orders/${verify.id}`);
                }
              }
            }
          }
        } catch (error: any) {
          setStepProcess(CheckoutOrderActionTypes.FAILED);

          if (error?.response && error?.response?.errors[0]?.extensions.code === 'BAD_USER_INPUT') {
            onChangeWarnings({ message: error.response.errors[0].message });
          } else {
            if (error?.code) {
              switch (error.code) {
                case 4001:
                  onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
                  break;
                case -32603:
                  const errMsg = error.data.message;
                  const message = 
                    errMsg && typeof errMsg === 'string' ? errMsg.split(':')[1] : '';
                  onChangeWarnings({ message });
                  break;
                default:
                  onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
                  break;
              }
            } else {
              onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
            }
          }
        } finally {
          await getUserOrders();
          closeModal();
        }
      }
    }
  }, [
    WCROBalance, allowanceWCRO, orderCart, shipping, stepProcess, totalCalculated, values, shippingCountryCode,
    approveWCRO, checkout, payForShippingCost, closeModal, getUserOrders, navigate, onChangeWarnings, openModal, updateOrderCart, validateForm, orderCheckout, verifyOrderCheckout, getOrderById
  ]);

  const handleClick = useCallback(async () => {
    if (!active) {
      openModal(ModalTypes.CONNECT_WALLET);
    } else if (!window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
      openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
    } else {
      handleSubmit();
    }
  }, [active, handleSubmit, openModal]);

  const buttonStatus = useMemo(() => {
    if (stepProcess === CheckoutOrderActionTypes.CALCULATE_SHIPPING) return ButtonStatus.LOADING;
    if (stepProcess === CheckoutOrderActionTypes.CONFIRMING) return ButtonStatus.LOADING;
    if (stepProcess === CheckoutOrderActionTypes.FAILED) return ButtonStatus.FAILED;
    return ButtonStatus.NORMAL;
  }, [stepProcess]);

  const renderButtonLabel = useMemo((): string => {
    const values: { [key: string]: string } = {
      [CheckoutOrderActionTypes.CALCULATE_SHIPPING]: 'Calculating shipping...',
      [CheckoutOrderActionTypes.READY]: 'Place order',
      [CheckoutOrderActionTypes.DISABLED]: 'Place order',
      [CheckoutOrderActionTypes.FAILED]: 'Retry',
      [CheckoutOrderActionTypes.CONFIRMING]: 'Confirming...',
    };
    return values[stepProcess] || 'Place order';
  }, [stepProcess]);

  const countryOptions = useMemo(() => countries.map(({ label, value }) => ({ label: label, value: label })), []);

  return (
    <div className={styles.container}>
      {messageSoldOut && <div className={styles.error}><Info className={styles.error_icon} />{messageSoldOut}</div>}
      <h1 className={styles.heading}>Checkout</h1>
      <div className={styles.wrapper}>
        <div className={styles.info_wrapper}>
          <form className={styles.shipping_info}>
            <h1 className={styles.heading_mobile}>Checkout</h1>
            <div className={styles.form}>
              <div className={styles.form_title}>Contact details</div>
              <EmailInput
                className={styles.field}
                label="Email address"
                name="email"
                value={values.email || ''}
                maxLength={50}
                onChange={onChangeForm}
                error={errors.email}
              />
              <div className={styles.field_group_phone}>
                <TextInput
                  id="dialInCodes"
                  className={styles.field}
                  label="Area Code"
                  name="areaCode"
                  value={values.areaCode || ''}
                  onChange={onChangeForm}
                  error={errors.areaCode}
                />
                <TextInput
                  className={styles.field}
                  label="Mobile number"
                  name="mobile"
                  value={values.mobile || ''}
                  minLength={8}
                  maxLength={15}
                  onChange={onChangeForm}
                  error={errors.mobile}
                />
              </div>
            </div>
            <div className={styles.form}>
              <div className={styles.form_title}>Shipping address</div>
              <DataListInput
                id="countries"
                className={styles.field}
                label="Country / Region"
                option={countryOptions}
                name="shippingCountry"
                value={values.shippingCountry || ''}
                onChange={debounceChangeForm}
                disabled={stepProcess === CheckoutOrderActionTypes.CALCULATE_SHIPPING}
                error={errors.shippingCountry}
              />
              <div className={styles.field_group}>
                <TextInput
                  className={styles.field}
                  label="First name"
                  name="firstName"
                  value={values.firstName || ''}
                  maxLength={50}
                  onChange={onChangeForm}
                  error={errors.firstName}
                />

                <TextInput
                  className={styles.field}
                  label="Last name"
                  name="lastName"
                  value={values.lastName || ''}
                  maxLength={50}
                  onChange={onChangeForm}
                  error={errors.lastName}
                />
              </div>
              <TextInput
                className={styles.field}
                label="Street"
                name="shippingAddress1"
                value={values.shippingAddress1 || ''}
                maxLength={150}
                onChange={onChangeForm}
                error={errors.shippingAddress1}
              />
              <TextInput
                className={styles.field}
                label="Apartment number, suite (optional)"
                name="shippingAddress2"
                maxLength={150}
                value={values.shippingAddress2 || ''}
                onChange={onChangeForm}
              />
              <TextInput
                className={styles.field}
                label="City"
                name="shippingCity"
                value={values.shippingCity || ''}
                maxLength={50}
                onChange={debounceChangeForm}
                error={errors.shippingCity}
                disabled={stepProcess === CheckoutOrderActionTypes.CALCULATE_SHIPPING}
              />
              <TextInput
                className={styles.field}
                label="State"
                name="shippingState"
                value={values.shippingState || ''}
                maxLength={50}
                onChange={onChangeForm}
                error={errors.shippingState}
              />
              <TextInput
                label="Post code"
                name="shippingPostCode"
                value={values.shippingPostCode || ''}
                onChange={debounceChangeForm}
                maxLength={50}
                error={errors.shippingPostCode}
                disabled={stepProcess === CheckoutOrderActionTypes.CALCULATE_SHIPPING}
              />
            </div>
          </form>
          <div className={styles.order_btn}>
            <ProgressButton disabled={stepProcess === CheckoutOrderActionTypes.DISABLED} status={buttonStatus} onClick={handleClick}>
              {renderButtonLabel}
            </ProgressButton>
          </div>
        </div>
        <div className={styles.detail_wrapper}>
          <div className={styles.detail_content}>
            <OrderItem
              merchandiseItems={orderCart}
              subTotal={totalCalculated.subTotal.toString()}
              subTotalBananas={totalCalculated.subTotalBananas.toString()}
              shippingPrice={shipping.shippingPrice}
              callbackAction={getShippingPriceDebounce}
            />
          </div>
          <div className={styles.detail_content_mb}>
            <Collapse label={
              `Order summary 
                ${totalCalculated.subTotal.gt(0) ? ` (${totalCalculated.subTotal} USDC)`: ''}
                ${totalCalculated.subTotalBananas.gt(0) ? ` (${totalCalculated.subTotalBananas} Bananas)`: ''}
              `
            }>
              <OrderItem
                merchandiseItems={orderCart}
                subTotal={totalCalculated.subTotal.toString()}
                subTotalBananas={totalCalculated.subTotalBananas.toString()}
                shippingPrice={shipping.shippingPrice}
                callbackAction={getShippingPriceDebounce}
              />
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShopCheckout;