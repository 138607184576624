import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useCallback } from "react";
import jwt from "jsonwebtoken";
import { GraphQLClient, request, gql } from 'graphql-request';
import { useUser } from "../providers/UserProvider";
import { DISCORD_API } from "../configs";
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN } from "../constants/labels";

const endpoint = `${DISCORD_API}/graphql`;

export const useLogin = () => {
	const { library, account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();
	const { getFavoriteChimps } = useUser();

	const login = useCallback(async () => {
		let accessToken = '';
		let refreshToken = '';

		const mutationLoginNonce = gql`
			mutation requestLoginNonce($account: String!) {
				requestLoginNonce(account: $account) {
					nonce
				}
			}
		`;

		const data = await request(endpoint, mutationLoginNonce, { account });
		if (data?.requestLoginNonce?.nonce) {
			const nonce = data.requestLoginNonce.nonce;
			let signature: string = '';

			try {
				// // try DeFi Wallet first 
				// signature = await library!.send('personal_sign', [`I am trying to login by my one-time nonce: ${nonce}`]);
				signature = await library!.send('personal_sign', [account, `I am trying to login by my one-time nonce: ${nonce}`]);
			} catch (e) {
				// signature = await library!.send('personal_sign', [account, `I am trying to login by my one-time nonce: ${nonce}`]);
				throw new Error('Personal Sign Failed');
			}

			if (!signature) {
				throw new Error('Empty Signature');
			}

			const mutationLogin = gql`
				mutation login($account: String!,  $signature: String!) {
					login(account: $account, signature: $signature) {
						accessToken
						refreshToken
					}
				}
			`;

			const requestLogin = await request(endpoint, mutationLogin, { account, signature });
			accessToken = requestLogin?.login?.accessToken || '';
			refreshToken = requestLogin?.login?.refreshToken || '';
		}

		if (accessToken && refreshToken) {
			window.localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, accessToken);
			window.localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken);
			return true;
		}
		throw new Error('Cannot get AccessToken');
	}, [account, library]);

	const checkValidToken = useCallback(async () => {
		let accessToken = window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
		let refreshToken = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
		const decodeData: any = accessToken && jwt.decode(accessToken);
		if (decodeData?.exp && decodeData?.exp * 1000 < Date.now()) {
			const mutation = gql`
				mutation refreshToken($refreshToken: String!) {
					refreshToken(refreshToken: $refreshToken) {
						accountId
						accessToken
					}
				}
			`;

			const data = await request(endpoint, mutation, { refreshToken });
			accessToken = data?.accessToken || '';
		}
		return accessToken;
	}, []);

	const getMyFavoriteChimp = useCallback(async () => {
		try {
			const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

			const query = gql`
				query {
					me {
						id
						favoritedChimps {
							tokenId
							isHonorary
							favoriteCount
						}
					}
				}
			`;

			const data = await graphQLClient.request(query);
			const favoritedChimps = data?.me?.favoritedChimps || [];
			getFavoriteChimps(favoritedChimps);
		} catch (e) {
			getFavoriteChimps([]);
		}
	}, [checkValidToken, getFavoriteChimps]);

	const addFavoriteChimp = useCallback(async (tokenId: string, isHonorary: boolean) => {
		try {
			const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

			const mutation = gql`
				mutation addFavoriteChimp($tokenId: TokenId!, $isHonorary: Boolean!) {
					addFavoriteChimp(tokenId: $tokenId, isHonorary: $isHonorary) {
						tokenId
						isHonorary
					}
				}
			`;

			const variables = {
				tokenId,
				isHonorary
			};

			const result = await graphQLClient.request(mutation, variables);

			if (result?.addFavoriteChimp?.tokenId) {
				return { error: '', data: result?.addFavoriteChimp?.tokenId };
			}

			if (result?.errors?.length > 0) {
				return { error: result.errors[0] };
			}

		} catch (e) {
			console.log(e);
		}
	}, [checkValidToken]);

	const removeFavoriteChimp = useCallback(async (tokenId: string, isHonorary: boolean) => {
		try {
			const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

			const mutation = gql`
				mutation removeFavoriteChimp($tokenId: TokenId!, $isHonorary: Boolean!) {
					removeFavoriteChimp(tokenId: $tokenId, isHonorary: $isHonorary) {
						tokenId
						isHonorary
					}
				}
			`;

			const variables = {
				tokenId,
				isHonorary
			}

			const result = await graphQLClient.request(mutation, variables);

			if (result?.addFavoriteChimp?.tokenId) {
				return { error: '', data: result?.removeFavoriteChimp?.tokenId };
			}

			if (result?.errors?.length > 0) {
				return { error: result.errors[0] };
			}

		} catch (e) {
			console.log(e);
		}
	}, [checkValidToken]);

	const getFavoriteCountChimp = useCallback(async (tokenId: string | number, isHonorary: boolean) => {
		try {
			const accessToken = await checkValidToken();
			const graphQLClient = new GraphQLClient(endpoint, {
				headers: {
					authorization: `Bearer ${accessToken}`,
				},
			});

			const query = gql`
				query chimp($tokenId: Int!, $isHonorary: Boolean!) {
					chimp(tokenId: $tokenId, isHonorary: $isHonorary) {
						tokenId
						isHonorary
						favoriteCount
					}
				}
			`;

			const variables = {
				tokenId,
				isHonorary
			}

			const data = await graphQLClient.request(query, variables);
			const favoriteCount = data?.chimp?.favoriteCount || 0;
			return favoriteCount;
		} catch (e) {
			return 0;
		}
	}, [checkValidToken]);

	const getFavoriteCountAllChimp = useCallback(async (tokenIds: string[] | number[], honoraryTokenIds: string[] | number[]) => {
		try {
			const query = gql`
				query chimps($tokenIds: [Int!], $honoraryTokenIds: [Int!]) {
					normalChimps: chimps(tokenIds: $tokenIds, isHonorary: false) {
						tokenId
						isHonorary
						favoriteCount
					}
          honoraryChimps: chimps(tokenIds: $honoraryTokenIds, isHonorary: true) {
						tokenId
						isHonorary
						favoriteCount
					}
				}
			`;

			const variables = {
				tokenIds,
				honoraryTokenIds
			}

			const data = await request(endpoint, query, variables);
			return data;
		} catch (e) {
			return {
				normalChimps: [],
				honoraryChimps: []
			};
		}
	}, []);

	return {
		login,
		checkValidToken,
		getMyFavoriteChimp,
		addFavoriteChimp,
		removeFavoriteChimp,
		getFavoriteCountChimp,
		getFavoriteCountAllChimp
	}
}

