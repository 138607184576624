import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useWeb3React } from "@web3-react/core";
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { Dropdown, Alert, ThreeDots } from '../../assets/svgs';
import { useUser } from '../../providers/UserProvider';
import { useMessage } from '../../providers/MessageProvider';
import { useModal } from '../../providers/ModalProvider';
import { useMerchandiseHook } from '../../hooks/useMerchandiseHook';
import { useMerchandisContractMethods } from '../../hooks/useMerchandisContractMethods';
import { useUserHook } from '../../hooks/useUserHook';
import { CheckoutItemForContract, OrderMeta } from '../../types/interfaces';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import { convertOrderStatus } from '../../utils/shop-merch';
import { formatDate } from '../../utils/time';
import { convertUSDCPriceToDecimal } from '../../utils/price';
import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_CART, LOCAL_STORAGE_ORDER_ID } from '../../constants/labels';
import { CHECKOUT_ERROR_GENERIC, CHIMP_ERROR_REJECTED } from '../../constants/errors';
import { MerchPaymentTypes, ModalTypes, SignInTypes } from '../../types/enums';
import styles from './Orders.module.scss';
import Button from '../../components/button/ProgressButton';

const Orders: React.FC<{}> = () => {
  const { active, account } = useWeb3React();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();
  const { updateOrderCart, state: { orders } } = useUser();
  const { verifyOrderCheckout, cancelOrder } = useMerchandiseHook();
  const { getUserOrders } = useUserHook();
  const { approveWCRO, getWCROBalance, getAllowanceWCRO, checkout, payForShippingCost } = useMerchandisContractMethods();
  const { onChangeWarnings } = useMessage();

  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);
  const [indexCollapse, setIndexCollapse] = useState<number>(0);
  const [WCROBalance, setWCROBalance] = useState('');
  const [allowanceWCRO, setAllowanceWCRO] = useState<string>('');

  useEffect(() => {
    if (account) {
      getWCROBalance().then((balance: string) => {
        setWCROBalance(balance);
      });

      getAllowanceWCRO().then((allowance: string) => {
        setAllowanceWCRO(allowance);
      });
    }
  }, [getWCROBalance, account, getAllowanceWCRO]);

  const toggleCollapse = useCallback((index: number) => {
    if (index === indexCollapse && isOpenCollapse) {
      setIsOpenCollapse(false);
    } else {
      setIndexCollapse(index);
      setIsOpenCollapse(true);
    }
  }, [indexCollapse, isOpenCollapse]);

  const makePayment = useCallback(async (order: OrderMeta) => {
    try {
      if (!active || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
        return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
      }

      openModal(ModalTypes.CHECKOUT_CONFIRMING, {
        totalPrice: order.subtotalPrice + order.shippingPrice > 0 ? 
          `${formatNumberWithComma(roundNumberWithFix(order.subtotalPrice + order.shippingPrice, 3))} ${MerchPaymentTypes.USDC}` : '',
        totalBananasPrice: order.subtotalBananaPrice > 0 ? 
          `${formatNumberWithComma(roundNumberWithFix(order.subtotalBananaPrice, 3))} ${MerchPaymentTypes.BANANAS}` : '',
      });

      if (order.id) {
        const wcroBalance = new BigNumber(convertUSDCPriceToDecimal(WCROBalance));
        const amount = new BigNumber(order.shippingPrice).plus(order.subtotalPrice);

        if (wcroBalance.lt(amount)) {
          onChangeWarnings({ message: 'Not enough USDC balance' });
        } else {
          if (order.status === 'PAYMENT_PENDING') {
            if (amount.gt(new BigNumber(convertUSDCPriceToDecimal(allowanceWCRO)))) {
              await approveWCRO(amount.toString());
            }

            const payloadContract = order.items.reduce((arr, item) => {
              for (let i = 0; i < item.quantity; i++) {
                if (item.currency !== MerchPaymentTypes.BANANAS) {
                  arr.push({
                    productId: item.contractId,
                    subProduct: item.variantOption1,
                  });
                }
              }
              return arr;
            }, [] as CheckoutItemForContract[]);
  
            if (payloadContract.length > 0) {
              await checkout(Number(order.id), order.shippingPrice, payloadContract);
            } else {
              await payForShippingCost(order.shippingPrice);
            }
          }

          if (order.id) {
            const verify = await verifyOrderCheckout(Number(order.id));
            if (verify.id) {
              window.localStorage.removeItem(LOCAL_STORAGE_CART);
              window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
              updateOrderCart([]);
              await getUserOrders();
              navigate(`/shop/orders/${verify.id}`);
            }
          }
        }
      }
    } catch (error: any) {
      if (error?.response && error?.response?.errors[0]?.extensions.code === 'BAD_USER_INPUT') {
        onChangeWarnings({ message: error.response.errors[0].message });
      }

      if (error?.code) {
        switch (error.code) {
          case 4001:
            onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
            break;
          case -32603:
            const errMsg = error.data.message;
            const message = 
              errMsg && typeof errMsg === 'string' ? errMsg.split(':')[1] : '';
            onChangeWarnings({ message });
            break;
          default:
            onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
            break;
        }
      } else {
        onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
      }
    } finally {
      closeModal();
    }
  }, [
    WCROBalance, active, allowanceWCRO, 
    approveWCRO, checkout, payForShippingCost, closeModal, getUserOrders, navigate, onChangeWarnings, openModal, updateOrderCart, verifyOrderCheckout
  ]);

  const handleCancelOrder = useCallback(async (orderId: number) => {
    try {
      if (!active || !window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)) {
        return openModal(ModalTypes.SIGN_IN, { message: SignInTypes.CHECKOUT });
      }

      let text = "Are you sure want to cancel this order?";
      if (window.confirm(text) === true) {
        if (orderId) {
          openModal(ModalTypes.OVERLAY);
          const cancel = await cancelOrder(orderId);
          if (cancel?.status === "CANCELED") {
            window.localStorage.removeItem(LOCAL_STORAGE_CART);
            window.localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
            updateOrderCart([]);
            await getUserOrders();
          }
        }
      }
    } catch (error: any) {
      onChangeWarnings({ message: CHECKOUT_ERROR_GENERIC });
    } finally {
      closeModal();
    }
  }, [active, cancelOrder, closeModal, getUserOrders, onChangeWarnings, openModal, updateOrderCart]);

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Orders</h1>
      <div className={styles.wrapper}>
        {orders.isLoading && <div className={styles.spinner}><b>Loading...</b></div>}
        {!orders.isLoading && orders.data.length === 0 && <div className={styles.empty_offers}><b>No orders yet.</b></div>}
        {!orders.isLoading && orders.data.length > 0 &&
          <div className={styles.table}>
            <div className={styles.th}>Order number</div>
            <div className={styles.th}>Order date</div>
            <div className={styles.th}>Status</div>
            <div className={styles.th}>Total</div>
            <div className={styles.th}>Action</div>

            {orders.data.length > 0 && orders.data.map((item: OrderMeta, index: number) => (
              <Fragment key={item.id}>
                <div className={styles.tr}>
                  <div className={styles.td}>
                    <Link to={`/shop/orders/${item.id}`} className={styles.name}><span>{`Order #${item.id}`}</span></Link>
                  </div>
                  <div className={styles.td}>{formatDate(item.createdAt, 'numeric', 'short', 'numeric')}</div>
                  <div className={classNames(styles.td, styles.status_wrap)}>
                    <span className={styles.status}>
                      {
                        ['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(item.status)
                        && <Alert className={styles.error_icon} />
                      }
                      <strong>{convertOrderStatus(item.status)}</strong>
                    </span>
                    {item.status === 'SHIPPED' && <span className={styles.tracking_number}>DHL</span>}
                  </div>
                  <div className={styles.td}>
                    <span>
                      {+item.subtotalPrice + +(item.shippingPrice || 0) > 0 && `${formatNumberWithComma(roundNumberWithFix(+item.subtotalPrice + +(item.shippingPrice || 0), 3))} ${MerchPaymentTypes.USDC}`}
                    </span>
                    <span>
                      {+item.subtotalBananaPrice > 0 && `${formatNumberWithComma(roundNumberWithFix(item.subtotalBananaPrice, 3))} ${MerchPaymentTypes.BANANAS}`}
                    </span>
                  </div>
                  <div className={styles.td}>
                    {
                      ['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(item.status) &&
                      <div className={styles.btn_group}>
                        <Button
                          classname={styles.primary_btn}
                          onClick={() => makePayment(item)}
                        >
                          {item.status === 'PAYMENT_PENDING' ? 'Make payment' : 'Confirm Order'}
                        </Button>
                        {
                          item.status === 'PAYMENT_PENDING' && 
                          <div className={classNames(styles.dropdown_btn, styles.overflow_btn)}>
                            <ThreeDots />
                            <div>
                              <ul className={styles.dropdown_content}>
                                <li className={styles.dropdown_item} onClick={() => handleCancelOrder(Number(item.id))}>Cancel order</li>
                              </ul>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>

                {/* Mobile */}
                <div className={styles.card} onClick={() => toggleCollapse(index)}>
                  <div className={styles.card_content}>
                    <Link to={`/shop/orders/${item.id}`} className={styles.name}><span>{`Order #${item.id}`}</span></Link>
                    <span className={styles.status_label}>Status</span>
                    <span className={styles.status}>
                      {
                        ['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(item.status) 
                        && <Alert className={styles.error_icon} />
                      }
                      <strong>{convertOrderStatus(item.status)}</strong>
                    </span>
                    {['PAYMENT_PENDING', 'PAYMENT_COMPLETED'].includes(item.status) &&
                      <div className={styles.btn_group}>
                        <Button
                          classname={styles.primary_btn}
                          onClick={() => makePayment(item)}
                        >
                          {item.status === 'PAYMENT_PENDING' ? 'Make payment' : 'Confirm Order'}
                        </Button>
                        {
                          item.status === 'PAYMENT_PENDING' && 
                          <Button
                            classname={styles.primary_btn}
                            onClick={() => handleCancelOrder(Number(item.id))}
                            isGhost
                          >
                            Cancel order
                          </Button>
                        }
                      </div>
                    }
                    {item.status === 'SHIPPED' && <span className={styles.tracking_number}>DHL</span>}
                  </div>
                  <div className={classNames(styles.collapse_arrow, { [styles.collapse_arrow_opened]: isOpenCollapse && index === indexCollapse })} onClick={() => toggleCollapse(index)}>
                    <Dropdown />
                  </div>
                </div>
                <div className={classNames(styles.collapse_content, { [styles.collapse_opened]: isOpenCollapse && index === indexCollapse })}>
                  <div className={styles.detail}>
                    <span className={styles.detail_label}>Order date</span>
                    <span className={styles.detail_value}>{formatDate(item.createdAt, 'numeric', 'short', 'numeric')}</span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detail_label}>Total</span>
                    <span className={styles.detail_value}>
                      <span>
                        {+item.subtotalPrice > 0 && `${formatNumberWithComma(roundNumberWithFix(item.subtotalPrice, 3))} ${MerchPaymentTypes.USDC}`}
                      </span>
                      <span>
                        {+item.subtotalBananaPrice > 0 && `${formatNumberWithComma(roundNumberWithFix(item.subtotalBananaPrice, 3))} ${MerchPaymentTypes.BANANAS}`}
                      </span>
                    </span>
                  </div>
                </div>
                {/* Mobile */}
              </Fragment>
            ))}
          </div>
        }
      </div>
    </div>
  );
};

export default Orders;