import { CSSProperties, FC, memo } from 'react';
import BananaCoin from '../../assets/images/banana_token.png';
import styles from './BananaValue.module.scss';

const BananaValue: FC<{
  value: string | number;
  style?: CSSProperties;
  className?: string;
}> = ({ value, style, className }) => {
  return (
    <div
      className={`${styles.component} ${className}`}
      style={style}
    >
      <img className={styles.token} src={BananaCoin} alt="" />
      <span className={styles.value}>
        {value}
      </span>
    </div>
  )
}

export default memo(BananaValue);
