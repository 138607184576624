import { Web3ReactProvider } from '@web3-react/core';
import Routes from './pages/Routes';
import ChimpProvider from './providers/ChimpProvider';
import ChimpayProvider from './providers/ChimpayProvider';
import LoreProvider from './providers/LoreProvider';
import NFTProvider from './providers/NFTProvider';
import MessageProvider from './providers/MessageProvider';
import PriceProvider from './providers/PriceProvider';
import LoadChimpProvider from './providers/LoadChimpProvider';
import UserProvider from './providers/UserProvider';
import MerchandiseProvider from './providers/MerchandiseProvider';
import { getLibrary } from './utils/provider';

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <UserProvider>
        <MerchandiseProvider>
          <ChimpProvider>
            <ChimpayProvider>
              <LoreProvider>
                <NFTProvider>
                  <MessageProvider>
                    <PriceProvider>
                      <LoadChimpProvider>
                        <Routes />
                      </LoadChimpProvider>
                    </PriceProvider>
                  </MessageProvider>
                </NFTProvider>
              </LoreProvider>
            </ChimpayProvider>
          </ChimpProvider>
        </MerchandiseProvider>
      </UserProvider>
    </Web3ReactProvider>
  );
}

export default App;
