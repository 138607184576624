import { useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useChimpsHook } from "../../hooks/useChimpsHook";
import { useContractMethods } from "../../hooks/useContractMethods";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from "./ChimpSuccessModal";
import { Arrow, Cross } from "../../assets/svgs";
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import styles from './ChimpModal.module.scss';

const RemoveChimpModal = () => {
  const [trxnId, setTrxnId] = useState('');

  const { pullBackFromMarket } = useContractMethods();

  const { onChangeWarnings } = useMessage();
  const { step, openModal, closeModal, updateOperationStep } = useModal();

  const { state: { forBuyOrSaleChimp } } = useChimp();
  const { updateChimpData } = useChimpsHook();

  const { tokenId, id: chimpId, isHonorary } = forBuyOrSaleChimp;

  const handleRemoveFromMarket = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      const result = await pullBackFromMarket(tokenId, isHonorary);

      if (result.transactionHash) {
        setTrxnId(result.transactionHash);
      }

      updateOperationStep(ModalChimpStatus.SUCCEESS);

      updateChimpData(tokenId, chimpId, ChimpOperateTypes.REMOVE, isHonorary);
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);

      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [updateOperationStep, onChangeWarnings, pullBackFromMarket, setTrxnId, updateChimpData, tokenId, chimpId, isHonorary])

  const handleClick = useCallback(() => {
    switch (step) {
      case ModalChimpStatus.READY:
        updateOperationStep(ModalChimpStatus.CONFIRM);
        break;
      case ModalChimpStatus.CONFIRM:
        handleRemoveFromMarket();
        break;
      case ModalChimpStatus.FAILED:
        updateOperationStep(ModalChimpStatus.READY);
        break;
      default:
        break;
    }
  }, [step, updateOperationStep, handleRemoveFromMarket])

  const handleBack = useCallback(() => {
    if (step === ModalChimpStatus.CONFIRMING) return

    if (step === ModalChimpStatus.CONFIRM) {
      updateOperationStep(ModalChimpStatus.READY);
      return;
    } 
    
    openModal(ModalTypes.PRE_SELL_OR_TRANSFER);
  }, [step, updateOperationStep, openModal])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep])

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;

    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;

    return ButtonStatus.NORMAL;
  }, [step])

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {
        step !== ModalChimpStatus.SUCCEESS &&
        <div className={`${styles.container} ${styles.container_body}`}>
          <Arrow className={styles.back} onClick={handleBack} />
          <Cross className={styles.cancel} onClick={closeModal} />
          <span className={styles.title}>Remove from market</span>
          {
            step === ModalChimpStatus.READY && 
            <div className={styles.remove_content}>
              Removing Chimp from market will return it to your wallet. Do you want to continue? 
            </div>
          }
          {
            step !== ModalChimpStatus.READY && 
            <div className={styles.remove_content}>
              Please confirm the transaction in your wallet. 
            </div>
          }
          <ProgressButton disabled={false} status={buttonStatus} onClick={handleClick}>
            {step === ModalChimpStatus.READY && 'Remove from Market'}
            {step === ModalChimpStatus.CONFIRM && 'Confirm'}
            {step === ModalChimpStatus.CONFIRMING && 'Confirming...'}
            {step === ModalChimpStatus.FAILED && 'Retry'}
          </ProgressButton>
        </div>
      }
      {
        step === ModalChimpStatus.SUCCEESS && 
        <ChimpSuccessModal 
          closeModal={closeModal} 
          subTitle="Transaction Confirmed"
          description={`Chimp #${tokenId} has been removed from the Market`} 
          trxnId={trxnId}
          type={ChimpOperateTypes.REMOVE}
        />
      }
    </Modal>
  )
}

export default DisplayModal(RemoveChimpModal, ModalTypes.REMOVE_CHIMP);