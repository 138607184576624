import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useWeb3React } from "@web3-react/core";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useNFTMint } from "../../providers/NFTProvider";
import { useOfferContractMethods } from "../../hooks/useOfferContractMethods";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from "./ChimpSuccessModal";
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes, CardTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC, WARNING_WALLET_UNCONNECTED, WARNING_CAN_NOT_MAKE_OFFER_OWNED_CHIMP, WARNING_NOT_ENOUGH_BALANCE } from "../../constants/errors";
import { Cross, Info } from "../../assets/svgs";
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import styles from './ChimpModal.module.scss';
import { getChimpDisplayTitle } from "../../utils/title";
import classNames from "classnames";
import { useLocation } from "react-router-dom";
import { BigNumber } from "bignumber.js";
import { convertPriceToDecimal } from "../../utils/price";

const EditOfferChimpModal = () => {
  const { pathname } = useLocation();
  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');
  const [WCROBalance, setWCROBalance] = useState('');
  const [allowanceWCRO, setAllowanceWCRO] = useState<string>('');

  const { active, account } = useWeb3React();
  const { step, closeModal, openModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { state: { forBuyOrSaleChimp, offerUserMade } } = useChimp();
  const { state: { balance } } = useNFTMint();

  const { tokenId, image, isHonorary, name, seller } = forBuyOrSaleChimp;

  const { makeOffer, getWCROBalance, getValidOffers, getUserOfferIndex, approveWCROForOffersContract, getAllowanceWCROForOffersContract, getAllOfferMade } = useOfferContractMethods();

  const pageType = useMemo(() => pathname.includes(CardTypes.MARKET) ? CardTypes.MARKET : CardTypes.OWNING, [pathname]);

  useEffect(() => {
    getWCROBalance().then((balance) => {
      setWCROBalance(balance);
    });

    getAllowanceWCROForOffersContract(isHonorary).then((allowance: string) => {
      setAllowanceWCRO(allowance);
    });
  }, [getAllowanceWCROForOffersContract, getWCROBalance, isHonorary])

  useEffect(() => {
    if (tokenId) {
      getUserOfferIndex(isHonorary, tokenId);
    }
  }, [getUserOfferIndex, isHonorary, tokenId])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep]);

  const handleMakeOffer = useCallback(async () => {
    if (+amount === 0) {
      return setError('The selling price must be more than zero');
    }
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      const wcroBalance = new BigNumber(convertPriceToDecimal(WCROBalance));
      const offerAmount = new BigNumber(amount);

      let topupAmount = new BigNumber(0);
      if (wcroBalance.lt(offerAmount)) {
        topupAmount = offerAmount.minus(wcroBalance);
      }

      const result = await makeOffer(isHonorary, tokenId, amount, topupAmount.toString(), offerUserMade.indexValue);
      if (result.transactionHash) {
        if (pageType === CardTypes.OWNING) {
          const promises = [getValidOffers(isHonorary, tokenId), getUserOfferIndex(isHonorary, tokenId), getAllOfferMade()];
          await Promise.all(promises).then(() => {
            updateOperationStep(ModalChimpStatus.SUCCEESS);
          });
          updateOperationStep(ModalChimpStatus.SUCCEESS);
        } else {
          const promises = [getValidOffers(isHonorary, tokenId), getUserOfferIndex(isHonorary, tokenId)];
          await Promise.all(promises).then(() => {
            updateOperationStep(ModalChimpStatus.SUCCEESS);
          });
        }
      }
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [amount, updateOperationStep, WCROBalance, makeOffer, isHonorary, tokenId, offerUserMade.indexValue, pageType, getAllOfferMade, getValidOffers, getUserOfferIndex, onChangeWarnings]);

  const handleApproveWCRO = useCallback(async () => {
    try {
      updateOperationStep(ModalChimpStatus.CONFIRMING);
      await approveWCROForOffersContract(isHonorary);
      const allowance = await getAllowanceWCROForOffersContract(isHonorary);
      setAllowanceWCRO(allowance);
      updateOperationStep(ModalChimpStatus.READY);
    } catch (error: any) {
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
        updateOperationStep(ModalChimpStatus.APPROVE);
      }
    }
  }, [approveWCROForOffersContract, getAllowanceWCROForOffersContract, isHonorary, onChangeWarnings, updateOperationStep]);

  const handleClick = useCallback(() => {
    if (!active) {
      openModal(ModalTypes.CONNECT_WALLET);
    } else {
      switch (step) {
        case ModalChimpStatus.READY:
          updateOperationStep(ModalChimpStatus.CONFIRM);
          handleMakeOffer();
          break;
        case ModalChimpStatus.CONFIRM:
          handleMakeOffer();
          break;
        case ModalChimpStatus.FAILED:
          handleMakeOffer();
          break;
        case ModalChimpStatus.APPROVE:
          handleApproveWCRO();
          break;
        default:
          break;
      }
    }
  }, [active, openModal, step, updateOperationStep, handleMakeOffer, handleApproveWCRO])

  const isEnoughBalance = useCallback(() => {
    const CROBalance = new BigNumber(balance);
    const wcroBalance = new BigNumber(convertPriceToDecimal(WCROBalance));
    const totalBalance = CROBalance.plus(wcroBalance)
    const offerAmount = new BigNumber(amount ? amount : 0);
    return totalBalance.gte(offerAmount);
  }, [WCROBalance, amount, balance]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const wcroBalance = new BigNumber(convertPriceToDecimal(WCROBalance));
    const offerAmount = new BigNumber(value);

    setError('');
    if (/^[1-9]{0,}\d{0,}$/.test(value)) {
      if (+value > 10_000_000_000) {
        return;
      }
      if (value.startsWith('0')) {
        value = `${+value}`;
      }
      setAmount(value);
      if (wcroBalance.lt(offerAmount) && isEnoughBalance()) {
        if (new BigNumber(offerAmount).gt(new BigNumber(convertPriceToDecimal(allowanceWCRO)))) {
          updateOperationStep(ModalChimpStatus.APPROVE);
        } else {
          updateOperationStep(ModalChimpStatus.READY);
        }
      } else {
        if (new BigNumber(convertPriceToDecimal(allowanceWCRO)).isZero()) {
          updateOperationStep(ModalChimpStatus.APPROVE);
        } else {
          updateOperationStep(ModalChimpStatus.READY);
        }
      }
    }
  }, [WCROBalance, allowanceWCRO, isEnoughBalance, updateOperationStep]);

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;
    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;
    return ButtonStatus.NORMAL;
  }, [step])

  const confirmInput = useMemo(() => [ModalChimpStatus.CONFIRM, ModalChimpStatus.CONFIRMING].includes(step), [step]);

  const disableButton = useMemo(() => {
    return active && (!isEnoughBalance() || seller === account || !!error || +amount === 0);
  }, [account, active, amount, error, isEnoughBalance, seller]);

  const renderButtonLabel = useMemo((): string => {
    const values: { [key: string]: string } = {
      [ModalChimpStatus.DISABLED]: 'Make offer',
      [ModalChimpStatus.READY]: 'Make offer',
      [ModalChimpStatus.CONFIRMING]: 'Confirming...',
      [ModalChimpStatus.FAILED]: 'Retry',
      [ModalChimpStatus.APPROVE]: 'Approve WCRO'
    };
    return values[step];
  }, [step]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {step !== ModalChimpStatus.SUCCEESS &&
        <div className={styles.container}>
          <Cross className={styles.cancel} onClick={closeModal} />
          <div className={styles.header}>
            <span className={styles.title}>Make an offer</span>
            <div className={styles.summary}>
              <img className={styles.img} src={image || ''} alt="" />
              <div>
                <div className={styles.chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
                <div className={styles.token_id}>Token ID: {tokenId}</div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <label htmlFor="offer-amount" className={styles.label}>Offer price (WCRO / CRO)</label>
            <input
              id="offer-amount"
              className={classNames(styles.input, styles.offer_input)}
              value={amount}
              disabled={confirmInput}
              onChange={handleChange}
              placeholder="0"
            />
            <span className={styles.input_remark}>
              {`Balance: ${formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(WCROBalance), 3))} WCRO + ${formatNumberWithComma(roundNumberWithFix(balance, 3))} CRO`}
            </span>
            <div className={styles.warnings}>
              {
                !active &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_WALLET_UNCONNECTED}</span>
                </div>
              }
              {
                active && !isEnoughBalance() &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_NOT_ENOUGH_BALANCE}</span>
                </div>
              }
              {
                !!error &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{error}</span>
                </div>
              }
              {
                seller === account &&
                <div className={styles.warning}>
                  <Info className={styles.warning_icon} />
                  <span className={styles.warning_text}>{WARNING_CAN_NOT_MAKE_OFFER_OWNED_CHIMP}</span>
                </div>
              }
            </div>
            <div className={styles.buttons}>
              <ProgressButton disabled={disableButton} status={buttonStatus} onClick={handleClick}>
                {active ? renderButtonLabel : 'Connect Wallet'}
              </ProgressButton>
            </div>
            {step === ModalChimpStatus.CONFIRMING &&
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Please confirm the transaction in your wallet.
                </span>
              </div>
            }
            {step === ModalChimpStatus.READY &&
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  We'll automatically convert your CRO to WCRO 1:1.
                </span>
              </div>
            }
          </div>
        </div>
      }
      {step === ModalChimpStatus.SUCCEESS &&
        <ChimpSuccessModal
          closeModal={closeModal}
          subTitle="Offer submited"
          description={`Your offer for Chimp #${tokenId} for ${formatNumberWithComma(roundNumberWithFix(amount, 3))} WCRO has been submitted.`}
          type={ChimpOperateTypes.OFFER}
        />
      }
    </Modal>
  )
}

export default DisplayModal(EditOfferChimpModal, ModalTypes.EDIT_OFFER_CHIMP);