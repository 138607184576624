import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useContractMethods } from '../../hooks/useContractMethods';

const MintFunctions = () => {
  const { getOwner, transferOwnership, updateMintPrice, adminMintChimp, adminUpdateBaseURI } = useContractMethods();
  const { account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const [owner, setOwner] = useState('')

  const [baseURI, setBaseURI] = useState('')

  useEffect(() => {
    getOwner().then((id) => {
      setOwner(id);
    })
  }, [setOwner, getOwner])

  const [newAccount, setNewAccount] = useState('')

  const handleSetNewAccount = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNewAccount(e.target.value) 
    },
    [setNewAccount],
  )

  const handleChangeAccount = useCallback(
    async () => {
      await transferOwnership(newAccount);

      alert('done')
    },
    [newAccount, transferOwnership],
  )

  const [price, setPrice] = useState('')

  const handleUpdatePrice = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      setPrice(e.target.value)
    },
    [setPrice]
  )

  const handleUpdateMintPrice = useCallback(async () => {
    await updateMintPrice(price)

    alert('Price Updated');
  }, [updateMintPrice, price])

  const [mintAmount, setMintAmount] = useState('');
  const [mintTo, setMintTo] = useState('')

  const handleUpdateMintAmount = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setMintAmount(e.target.value)
  }, [setMintAmount])

  const handleUpdateMintTo = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setMintTo(e.target.value);
  }, [setMintTo])

  const handleUpdateBaseURI = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    setBaseURI(e.target.value);
  }, [setBaseURI])

  const handleAdminMintChimp = useCallback(async () => {
    await adminMintChimp(mintAmount, mintTo);

    alert('minted')
  }, [mintAmount, mintTo, adminMintChimp])

  const handleAdminUpdateBaseURI = useCallback(async () => {
    await adminUpdateBaseURI(baseURI)

    alert('baseURI Updated');
  }, [adminUpdateBaseURI, baseURI])

  if (account !== owner) {
    return (
      <div>
        You are not owner of mint contract.
      </div>
    );
  };

  return (
    <div>
      <div>
        <h3>Change Ownership</h3>

        <input onChange={handleSetNewAccount} value={newAccount} />
        <button onClick={handleChangeAccount}>Transfer Ownership</button>
      </div>
      <hr />

      <div>
        <h3>Update Mint Price</h3>

        <input onChange={handleUpdatePrice} value={price} />
        <button onClick={handleUpdateMintPrice}>Update Price</button>
      </div>
      <hr />

      <div>
        <h3>Update Base URI</h3>

        <input onChange={handleUpdateBaseURI} value={baseURI} />
        <button onClick={handleAdminUpdateBaseURI}>Update Base URI</button>
      </div>
      <hr />

      <div>
        <h3>Admin Mint</h3>
        <div>Can only use this before mint has started</div>
        <input onChange={handleUpdateMintAmount} value={mintAmount} placeholder="amount" />
        <input onChange={handleUpdateMintTo} value={mintTo} placeholder="to user" />
        <button onClick={handleAdminMintChimp}>Mint</button>
      </div>
    </div>
  )
}

export default MintFunctions;