import { useCallback } from "react";
import { ethers } from "ethers";
import { useWeb3React } from '@web3-react/core';
import { convertPriceToUint, computePrice, computeEstimateGas } from "../utils/price";
import { SaleChimpMeta } from "../types/interfaces";
import { useContract } from "./useContract";
import { useMintPrice } from "../providers/PriceProvider";

interface Methods {
  mintNFT: (amount: string) => any,
  getPoolDetails: () => any,
  getNFTBalance: () => any,
  getHonorayNFTBalance: () => any,
  checkOfferedForSale: (tokenId: string, isHonorary: boolean) => any,
  checkOfferedForSaleBatched: (ids: string[], isHonorary: boolean) => any,
  checkOnSaleChimps: (start: number, end: number) => any,
  postChimpToMarket: (tokenId: string, amount: string, isHonorary: boolean) => any,
  pullBackFromMarket: (tokenId: string, isHonorary: boolean) => any,
  buyChimp: (tokenId: string, value: string, isHonorary: boolean) => any,
  getAccountBalance: (addr: string) => any,
  transferChimp: (addr: string, id: string, isHonorary: boolean) => any,
  getMarketVolume: () => any,
  getRemainingQuota: () => any,
  getOwner: () => Promise<string>,
  getOwnerOf: (tokenId: string, isHonorary: boolean) => Promise<string>;
  transferOwnership: (newOwner: string) => Promise<void>,
  updateMintPrice: (price: string) => Promise<void>,
  adminMintChimp: (amount: string, mintTo: string) => Promise<void>
  adminUpdateBaseURI: (baseURI: string) => Promise<void>
}

export function useContractMethods(): Methods {
  const { library, account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const { contract, honoraryContract } = useContract();

  const mintPrice = useMintPrice();

  const mintNFT = useCallback(async (amount: string) => {
    try {
      const data = await contract.estimateGas.mintChimp(amount, { value: convertPriceToUint(computePrice(+amount, mintPrice)) })

      const tx = await contract.mintChimp(amount, { value: convertPriceToUint(computePrice(+amount, mintPrice)), gasLimit: computeEstimateGas(data) });
      return (await tx.wait());
    } catch (e) {
      console.log(e);
    }
  }, [contract, mintPrice])

  const getPoolDetails = useCallback(async () => {
    return Promise.all([contract.maxSupply(), contract.chimpsRemainingToAssign(), contract.saleStartTimestamp()]);
  }, [contract])

  const getNFTBalance = useCallback(async () => {
    return (await contract.balanceOf(account)).toNumber();
  }, [contract, account])

  const getHonorayNFTBalance = useCallback(async () => {
    return (await honoraryContract.balanceOf(account)).toNumber();
  }, [honoraryContract, account])

  const checkOfferedForSale = useCallback(async (tokenId: string, isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const sale: SaleChimpMeta = await usingContract.chimpsForSale(tokenId);

    return {
      ...sale,
      tokenId: sale.tokenId.toString(),
      price: sale.price.toString(),
    }
  }, [contract, honoraryContract])

  const checkOfferedForSaleBatched = useCallback(async (ids: string[], isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const requests = ids.map((id: string) => {
      return usingContract.chimpsForSale(id);
    })

    return (await Promise.all(requests)).map((sale: SaleChimpMeta) => {
      return {
        ...sale,
        tokenId: sale.tokenId.toString(),
        price: sale.price.toString(),
      }
    });
  }, [contract, honoraryContract])

  const checkOnSaleChimps = useCallback(async (start: number, end: number) => {
    const data = (await contract.getOnSaleTokenIds(start, end));

    return data.tokenIds.map((id: ethers.BigNumber, index: number) => ({
      tokenId: id.toString(),
      isForSale: true,
      seller: data.sellers[index],
      price: data.prices[index].toString(),
    }))
  }, [contract])

  const postChimpToMarket = useCallback(async (tokenId: string, amount: string, isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const data = await usingContract.estimateGas.putChimpForSale(tokenId, convertPriceToUint(amount));

    const tx = await usingContract.putChimpForSale(tokenId, convertPriceToUint(amount), { gasLimit: computeEstimateGas(data) });
    return (await tx.wait());
  }, [contract, honoraryContract])

  const pullBackFromMarket = useCallback(async (tokenId: string, isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const data = await usingContract.estimateGas.chimpNoLongerForSale(tokenId);

    const tx = await usingContract.chimpNoLongerForSale(tokenId, { gasLimit: computeEstimateGas(data) });
    return (await tx.wait());
  }, [contract, honoraryContract])

  const buyChimp = useCallback(async (tokenId: string, value: string, isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const data = await usingContract.estimateGas.buyChimp(tokenId, { value });

    const tx = await usingContract.buyChimp(tokenId, { value, gasLimit: computeEstimateGas(data) });
    return (await tx.wait());
  }, [contract, honoraryContract])

  const transferChimp = useCallback(async (transferTo: string, tokenId: string, isHonorary: boolean) => {
    let usingContract = contract;

    if (isHonorary) {
      usingContract = honoraryContract;
    }

    const data = await usingContract.estimateGas.transferFrom(account, transferTo, tokenId);

    const tx = await usingContract.transferFrom(account, transferTo, tokenId, { gasLimit: computeEstimateGas(data) });

    return (await tx.wait());
  }, [contract, honoraryContract, account])

  const getAccountBalance = useCallback(async (address: string) => {
    const data = (await library?.getBalance(address))?.toString();

    return data;
  }, [library])

  const getMarketVolume = useCallback(async () => {
    const [volume1, volume2]: ethers.BigNumber[] = await Promise.all([contract.volume(), honoraryContract.volume()]);
    return (+volume1.toString()) + (+volume2.toString());
  }, [contract, honoraryContract])

  const getRemainingQuota = useCallback(async () => {
    const result = (await contract.chimpsRemainingToAssign()).toString();
    return result;
  }, [contract])

  const getOwner = useCallback(() => {
    return contract.owner();
  }, [contract])

  const getOwnerOf = useCallback(async (tokenId: string, isHonorary: boolean) => {
    if (isHonorary) {
      const owner = await honoraryContract.ownerOf(tokenId);
      return owner || '';
    } else {
      const owner = await contract.ownerOf(tokenId);
      return owner || '';
    }
  }, [contract, honoraryContract])

  const transferOwnership = useCallback(
    async (newOwner: string) => {
      return contract.transferOwnership(newOwner);
    },
    [contract],
  )

  const updateMintPrice = useCallback(
    async (mintPrice: string) => {
      return contract.updateMintPrice(convertPriceToUint(mintPrice));
    },
    [contract]
  )

  const adminMintChimp = useCallback(
    async (amount: string, mintTo: string) => {
      return contract.adminMintChimp(amount, mintTo);
    }, 
    [contract]
  )

  const adminUpdateBaseURI = useCallback(
    async (baseURI: string) => {
      return contract.updateBaseURI(baseURI);
    }, 
    [contract]
  )

  return {
    mintNFT,
    getPoolDetails,
    getNFTBalance,
    getHonorayNFTBalance,
    checkOfferedForSale,
    checkOfferedForSaleBatched,
    checkOnSaleChimps,
    postChimpToMarket,
    pullBackFromMarket,
    buyChimp,
    getAccountBalance,
    transferChimp,
    getMarketVolume,
    getRemainingQuota,
    getOwner,
    getOwnerOf,
    transferOwnership,
    updateMintPrice,
    adminMintChimp,
    adminUpdateBaseURI,
  }
}
