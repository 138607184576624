import Messages from './Messages';
import styles from './Message.module.scss';

const MessageContainer = () => {
  return (
    <div className={styles.container}>
      <Messages />
    </div>
  )
}

export default MessageContainer;