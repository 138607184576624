import React, { ChangeEvent, useCallback, useState } from 'react';
import useAppData from '../../hooks/useAppData';
import { formatDateTime } from '../../utils/time';

const BannerSetting = () => {
  const { postNewEvent } = useAppData();

  const [eventData, setEventData] = useState({
    message: '',
    startAt: formatDateTime(new Date()),
    endAt: formatDateTime(new Date())
  });

  const handleChangeForm = useCallback(async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    setEventData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      }
    })
  }, [setEventData])

  const handleSetNewEvent = useCallback(async () => {
    try {
      const regex = /^\d{4}-(0|1){1}\d{1}-[0-3]{1}\d{1} [0-2]{1}\d{1}:[0-5]{1}\d{1}:[0-5]{1}\d{1}/;

      if (!eventData.message) {
        return alert('Please input valid event description in text area');
      }

      if (!regex.test(eventData.startAt) || !regex.test(eventData.endAt)) {
        return alert('Strictly set event time as YYYY-MM-DD HH:mm:ss!');
      }

      await postNewEvent(eventData);
      alert('New banner message set successfully!');
    } catch (error: any) {
      alert(error);
    }
  }, [postNewEvent, eventData]);

  return (
    <div>
      <br />
      <textarea
        onChange={(e) => handleChangeForm(e, 'message')}
        value={eventData.message}
        placeholder="banner message"
        style={{ width: '545px', marginBottom: '8px' }}
      />
      <br />
      <input
        onChange={(e) => handleChangeForm(e, 'startAt')}
        value={eventData.startAt}
        placeholder="start time"
        style={{ width: '200px', marginRight: '16px' }}
      />
      <input
        onChange={(e) => handleChangeForm(e, 'endAt')}
        value={eventData.endAt}
        placeholder="end time"
        style={{ width: '200px', marginRight: '16px' }}
      />
      <button onClick={handleSetNewEvent}>
        Set New Banner
      </button>
    </div>
  )
}

export default BannerSetting;
