import React, { useCallback, useMemo, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useChimp } from '../../providers/ChimpProvider';
import { useModal } from "../../providers/ModalProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useOfferContractMethods } from "../../hooks/useOfferContractMethods";
import { CardTypes, ModalTypes } from "../../types/enums";
import { convertPriceToDecimal } from "../../utils/price";
import { formatNumberWithComma, roundNumberWithFix } from "../../utils/number";
import { getChimpDisplayTitle } from "../../utils/title";
import { HIDE_MARKETPLACE } from "../../configs";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import { SuccessOutline, ThreeDots, Dropdown, HandCoinLine, Cross, Transfer, Cancel, Pencil, SellTag } from "../../assets/svgs";
import styles from './DetailsHeader.module.scss';
import { OfferChimpMeta } from "../../types/interfaces";
import classNames from "classnames";
import Button from "../button/ProgressButton";

interface Props {
  pageType: CardTypes,
  isOwner: boolean,
  isLoading: boolean,
}

const DetailsHeader: React.FC<Props> = (props) => {
  const { isOwner, isLoading } = props;
  const { openModal, closeModal } = useModal();
  const { account } = useWeb3React();
  const { state: { forBuyOrSaleChimp, offers } } = useChimp();
  const { cancelOffer, getOfferByIndex, getValidOffers } = useOfferContractMethods();
  const { onChangeWarnings } = useMessage();

  const [openBottomSheet, setOpenBottomSheet] = useState<boolean>(false);

  const { tokenId, hdImage, price, isForSale, isHonorary, description, name } = forBuyOrSaleChimp;

  const highestOffer = offers.filter((item: OfferChimpMeta) => item.isHighestOffer === true);

  const handleClickModal = useCallback(() => {
    openModal(ModalTypes.BUY_CHIMP);
  }, [openModal]);

  const handleRemove = useCallback(() => {
    openModal(ModalTypes.REMOVE_CHIMP);
    setOpenBottomSheet(false);
  }, [openModal])

  const handleSell = useCallback(() => {
    openModal(ModalTypes.SELL_CHIMP);
    setOpenBottomSheet(false);
  }, [openModal]);

  const handleTransfer = useCallback(() => {
    openModal(ModalTypes.TRANSFER_CHIMP);
    setOpenBottomSheet(false);
  }, [openModal]);

  const handleMakeOffer = useCallback(() => {
    openModal(ModalTypes.MAKE_OFFER_CHIMP);
  }, [openModal]);

  const handleEditOffer = useCallback(() => {
    openModal(ModalTypes.EDIT_OFFER_CHIMP);
    setOpenBottomSheet(false);
  }, [openModal]);

  const handleAcceptOffer = useCallback(async () => {
    openModal(ModalTypes.ACCEPT_OFFER_CHIMP);
    if (highestOffer.length > 0) {
      await getOfferByIndex(isHonorary, highestOffer[0].tokenId, highestOffer[0].offerIndex);
    }
  }, [getOfferByIndex, highestOffer, isHonorary, openModal]);

  const canBuy = useMemo(() => !isLoading && !isOwner && isForSale, [isLoading, isOwner, isForSale]);
  const canSell = useMemo(() => !isLoading && isOwner && !isForSale, [isLoading, isOwner, isForSale]);
  const canAdjustPrice = useMemo(() => !isLoading && isOwner && isForSale, [isLoading, isOwner, isForSale]);
  const canTransfer = useMemo(() => !isLoading && isOwner, [isLoading, isOwner]);
  const isNotForSale = useMemo(() => !isLoading && !isForSale, [isForSale, isLoading]);
  const hasOffer = useMemo(() => offers.length > 0, [offers.length]);

  const isHighestOffer = useMemo(() => {
    if (account) {
      const offer: OfferChimpMeta[] = offers.filter((item: OfferChimpMeta) => item.buyer === account);
      return offer.length > 0 ? offer[0].isHighestOffer : false;
    }
    return false;
  }, [account, offers]);

  const isMadeOffer = useMemo(() => {
    if (account) {
      const offer: OfferChimpMeta[] = offers.filter((item: OfferChimpMeta) => item.buyer === account);
      return offer.length > 0 ? true : false;
    }
    return false;
  }, [account, offers]);

  const yourOffer = useMemo(() => {
    if (account) {
      const offer: OfferChimpMeta[] = offers.filter((item: OfferChimpMeta) => item.buyer === account) || [];
      return offer.length > 0 ? offer[0].amount : '';
    }
    return '';
  }, [account, offers]);


  const handleCancelOffer = useCallback(async () => {
    openModal(ModalTypes.OVERLAY);
    setOpenBottomSheet(false);
    try {
      const result = await cancelOffer(isHonorary, tokenId);
      if (result.transactionHash) {
        await getValidOffers(isHonorary, tokenId);
        closeModal();
      }
    } catch (error: any) {
      console.log(error);
      closeModal();
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [cancelOffer, closeModal, getValidOffers, isHonorary, onChangeWarnings, openModal, tokenId]);

  const subPriceElement = () => <>
    {isHighestOffer && <span className={styles.sub_price_label}><SuccessOutline />You have the highest offer!</span>}
    {yourOffer && !isHighestOffer && <span className={styles.sub_price_label}><HandCoinLine />Your offer <span className={styles.sub_price_bold}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(yourOffer), 3))} WCRO</span></span>}
  </>;

  const editOfferButton = () => <div className={styles.edit_button_wrap}>
    <button className={styles.edit_button} onClick={handleEditOffer}>Edit offer</button>
    <button className={classNames(styles.edit_button, styles.edit_dropdown_button, styles.hide_on_desktop)} onClick={() => setOpenBottomSheet(true)}><Dropdown /></button>
    <button className={classNames(styles.edit_button, styles.edit_dropdown_button, styles.hide_on_mobile, styles.edit_dropdown_button_trigger)}><Dropdown /></button>
    <ul className={styles.edit_dropdown_content}>
      <li className={styles.edit_dropdown_item} onMouseDown={handleCancelOffer}>Cancel offer</li>
    </ul>
  </div>;

  const controlsElement = () => <>
    {
      isForSale &&
      <div className={styles.price}>
        <span className={styles.label}>{isOwner ? 'Your selling price' : 'Current price'}</span>
        <div className={styles.sub_price_wrap}>
          <span className={styles.value}>{formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(price), 3))} CRO</span>
          {subPriceElement()}
        </div>
      </div>
    }
    {
      isNotForSale &&
      <>
        {hasOffer ?
          <div className={styles.price}>
            <span className={styles.label}>Highest offer</span>
            <div className={styles.sub_price_wrap}>
              <span className={styles.value}>
                {formatNumberWithComma(roundNumberWithFix(convertPriceToDecimal(Math.max.apply(Math, offers.map((item: OfferChimpMeta) => Number(item.amount)))), 3))} CRO
              </span>
              {subPriceElement()}
            </div>
          </div> :
          <div className={styles.not_sale}>This Chimp is not listed for sale.</div>
        }
        <div className={styles.group_button}>
          {
            isOwner && offers.length > 0 &&
            <Button classname={styles.btn} onClick={handleAcceptOffer}>
              Accept offer
            </Button>
          }
          {!isOwner && isMadeOffer && editOfferButton()}
          {
            !isOwner && !isMadeOffer && 
            <Button classname={styles.btn} onClick={handleMakeOffer} isGhost>
              Make offer
            </Button>
          }
        </div>
      </>
    }
    {canBuy &&
      <div className={styles.group_button}>
        <Button classname={styles.btn} onClick={handleClickModal}>
          Buy now
        </Button>
        <div className={styles.divider}></div>
        {isMadeOffer ?
          editOfferButton() :
          <>
            {
              !isOwner && 
              <Button classname={styles.btn} onClick={handleMakeOffer} isGhost>
                Make offer
              </Button>
            }
          </>
        }
      </div>
    }
    {canAdjustPrice && !HIDE_MARKETPLACE &&
      <Button classname={styles.btn} onClick={handleSell} isGhost>
        Adjust price
      </Button>
    }
  </>;

  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={styles.img_wrapper}>
          <img className={styles.img} src={hdImage} alt="" />
          {isOwner &&
            <div className={classNames(styles.overflow_button, styles.hide_on_desktop)} onClick={() => setOpenBottomSheet(true)}>
              <ThreeDots />
            </div>
          }
        </div>
        <div className={styles.content}>
          {isOwner &&
            <>
              <div className={classNames(styles.overflow_button, styles.hide_on_mobile)}>
                <ThreeDots />
                <div>
                  <ul className={styles.dropdown_content}>
                    {canSell && !HIDE_MARKETPLACE && <li className={styles.dropdown_item} onClick={handleSell}>Sell Chimp</li>}
                    {canTransfer && <li className={styles.dropdown_item} onClick={handleTransfer}>Transfer Chimp</li>}
                    {canAdjustPrice && !HIDE_MARKETPLACE && <li className={styles.dropdown_item} onClick={handleRemove}>Remove from Market</li>}
                  </ul>
                </div>
              </div>
            </>
          }
          {isHonorary && <span className={styles.description}>{description}</span>}
          <div className={styles.title}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
          <div className={classNames(styles.control_wrapper, styles.hide_on_mobile)}>
            {tokenId && controlsElement()}
          </div>
        </div>
      </div>
      <div className={classNames(styles.container, styles.contrainer_no_margin)}>
        {tokenId && controlsElement()}
      </div>
      {openBottomSheet && <div className={classNames(styles.bottom_sheet_wrap, styles.hide_on_desktop)}>
        <div className={styles.bottom_sheet_block_layer} onClick={() => setOpenBottomSheet(false)}></div>
        <div className={styles.bottom_sheet}>
          <div className={styles.bottom_sheet_header}><Cross onClick={() => setOpenBottomSheet(false)} />{isMadeOffer ? 'My offer' : 'Actions'}</div>
          <div className={styles.bottom_sheet_list_menu}>
            {isOwner && canSell && !HIDE_MARKETPLACE && <div className={styles.bottm_sheet_menu_item} onClick={handleSell}><SellTag /><span>Sell Chimp</span></div>}
            {isOwner && canTransfer && <div className={styles.bottm_sheet_menu_item} onClick={handleTransfer}><Transfer /><span>Transfer Chimp</span></div>}
            {isOwner && canAdjustPrice && !HIDE_MARKETPLACE && <div className={styles.bottm_sheet_menu_item} onClick={handleRemove}><Cancel /><span>Remove from Market</span></div>}
            {isMadeOffer && <div className={styles.bottm_sheet_menu_item} onClick={handleEditOffer}><Pencil /><span>Edit offer</span></div>}
            {isMadeOffer && <div className={styles.bottm_sheet_menu_item} onClick={handleCancelOffer}><Cancel /><span>Cancel offer</span></div>}
          </div>
        </div>
      </div>}
    </>
  )
}

export default DetailsHeader