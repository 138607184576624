import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import React, { useState } from 'react'
import { useUser } from '../../providers/UserProvider';
import FilterTabs from '../../components/filter/FilterTabs';
import StakingSetting from './StakingSetting';
import BannerSetting from './BannerSetting';
import MerchOrders from './MerchOrders';
import GiftOrders from './GiftOrders';
import LoreManagement from './LoreManagement';
import MintFunctions from './MintFunctions';
import { LOCAL_STORAGE_ACCESS_TOKEN } from '../../constants/labels';

const ADMIN_TABS = ['Staking', 'Lore', 'Banner', 'Merch Orders', 'Gift Orders', 'Mint'];

const Admin = () => {
  const { account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();
  const { state: { admin } } = useUser();

  const [tab, setTab] = useState(ADMIN_TABS[0]);

  const isSignIn = account && window.localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

  if (!isSignIn) {
    return (
      <div style={{
        padding: '5vh',
        textAlign: 'center'
      }}>You have not signed in!</div>
    );
  }

  if (!admin) {
    return (
      <div style={{
        padding: '5vh',
        textAlign: 'center'
      }}>You are not the admin</div>
    );
  }

  return (
    <div style={{ padding: '5vh' }}>
      <h1>Admin</h1> 

      <div style={{ width: 'fit-content' }}>
        <FilterTabs filter={tab} options={ADMIN_TABS} onFilter={setTab} />
      </div>

      <br />

      {tab === ADMIN_TABS[0] && <StakingSetting />}
      {tab === ADMIN_TABS[1] && <LoreManagement />}
      {tab === ADMIN_TABS[2] && <BannerSetting />}
      {tab === ADMIN_TABS[3] && <MerchOrders />}
      {tab === ADMIN_TABS[4] && <GiftOrders />}
      {tab === ADMIN_TABS[5] && <MintFunctions />}
    </div>
  )
}

export default Admin;