import { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { TITLE_TRAIT_NUMBER } from '../constants/general';
import { useLogin } from '../hooks/useLogin';
import { MarketSearchParams, SaleFilterTabs, SortingTypes, TraitTypes } from '../types/enums';
import { PriceFilterRange, FilterParams } from '../types/interfaces';
import { changeSortingDefaultToChimpId } from '../utils/filter-data';
import { setOrDeleteSearchParams } from '../utils/params';

export const initialState = {
  filterParams: {
    traits: {
      [TITLE_TRAIT_NUMBER]: [],
      [TraitTypes.Earrings]: [],
      [TraitTypes.Mouth]: [],
      [TraitTypes.Eyes]: [],
      [TraitTypes.Headgear]: [],
      [TraitTypes.Clothes]: [],
      [TraitTypes.Body]: [],
      [TraitTypes.Background]: [],
    },
    [MarketSearchParams.LIST_TYPE]: SaleFilterTabs.LISTED,
    [MarketSearchParams.SORT]: SortingTypes.LOWEST_PRICE,
    [MarketSearchParams.MIN_PRICE]: '',
    [MarketSearchParams.MAX_PRICE]: '',
    [MarketSearchParams.CHIMP_ID]: '',
  }
}

interface IContext {
  filterParams: FilterParams,
  filterForListed: (key: SaleFilterTabs) => void,
  sortChimp: (key: SortingTypes) => void,
  filterByPrice: (value: PriceFilterRange) => void,
  filterByChimpId: (id: string) => void,
  filterByTraits: (key: TraitTypes | typeof TITLE_TRAIT_NUMBER, value: string) => void,
  clearTraitsFilter: (key: TraitTypes | typeof TITLE_TRAIT_NUMBER | 'All') => void,
}

const Context = createContext<IContext>({
  ...initialState,
  filterForListed: () => {},
  sortChimp: () => {},
  filterByPrice: () => {},
  filterByChimpId: () => {},
  filterByTraits: () => {},
  clearTraitsFilter: () => {},
})

export const useSearchMarket = () => {
  return useContext(Context);
}

const SearchMarketProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filterParams, setFilterParams] = useState(initialState.filterParams);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { getMyFavoriteChimp } = useLogin();

  const { traits } = filterParams;

  useEffect(() => {
    if (location.pathname === '/market' && !location.search) {
      setSearchParams({
        listType: 'Listed',
        sort: 'Price: low to high',
      }, { replace: true })
    }
  }, [location, setSearchParams])

  useEffect(() => {
    const params: any = {
      traits: {
        [TITLE_TRAIT_NUMBER]: [],
        [TraitTypes.Earrings]: [],
        [TraitTypes.Mouth]: [],
        [TraitTypes.Eyes]: [],
        [TraitTypes.Headgear]: [],
        [TraitTypes.Clothes]: [],
        [TraitTypes.Body]: [],
        [TraitTypes.Background]: [],
      }
    };

    for (const pair of searchParams.entries()) {
      if (Object.keys(TraitTypes).includes(pair[0]) || pair[0] === TITLE_TRAIT_NUMBER) {
        params.traits[pair[0]] = pair[1].split(',');
      } else {
        params[pair[0]] = pair[1];
      }
    }

    setFilterParams(params);
  }, [searchParams])

  const filterForListed = useCallback((key: SaleFilterTabs) => {
    if (key === SaleFilterTabs.FAVORITES) {
      getMyFavoriteChimp();
    }

    if (searchParams.get(MarketSearchParams.LIST_TYPE) === key) return;

    searchParams.set(MarketSearchParams.LIST_TYPE, key);
    searchParams.set(MarketSearchParams.SORT, changeSortingDefaultToChimpId(key, searchParams.get(MarketSearchParams.SORT) as any) as string);

    setSearchParams(searchParams, { replace: true });
  }, [getMyFavoriteChimp, searchParams, setSearchParams])

  const sortChimp = useCallback((tab: SortingTypes) => {
    if (searchParams.get(MarketSearchParams.SORT) === tab) return;

    searchParams.set(MarketSearchParams.SORT, tab as string);

    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams])

  const filterByPrice = useCallback((value: PriceFilterRange) => {
    let params = setOrDeleteSearchParams(searchParams, MarketSearchParams.MIN_PRICE, value.min);
    params = setOrDeleteSearchParams(searchParams, MarketSearchParams.MAX_PRICE, value.max);

    setSearchParams(params, { replace: true });
  }, [searchParams, setSearchParams])

  const filterByChimpId = useCallback((id: string) => {
    const params = setOrDeleteSearchParams(searchParams, MarketSearchParams.CHIMP_ID, id);
    setSearchParams(params, { replace: true });
  }, [searchParams, setSearchParams])

  const filterByTraits = useCallback((key: TraitTypes| typeof TITLE_TRAIT_NUMBER, value: string) => {
    let selects: any = traits[key];

    if (selects.includes(value)) {
      selects = selects.filter((val: string) => val !== value);
    } else {
      selects.push(value);
    }

    const params = setOrDeleteSearchParams(searchParams, key, selects.join(','));
    setSearchParams(params, { replace: true });
  }, [searchParams, traits, setSearchParams])

  const clearTraitsFilter = useCallback((key: TraitTypes | typeof TITLE_TRAIT_NUMBER | 'All') => {
    let selects: any = traits;

    if (key === 'All') {
      Object.keys(selects).forEach((tr: string) => {
        selects[tr] = []
        searchParams.delete(tr);
      });
    } else {
      selects[key] = []
      searchParams.delete(key);
    }

    setSearchParams(searchParams, { replace: true });
  }, [searchParams, traits, setSearchParams])

  const context = {
    filterParams,
    filterForListed,
    sortChimp,
    filterByPrice,
    filterByChimpId,
    filterByTraits,
    clearTraitsFilter,
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  )
}

export default SearchMarketProvider