import { useCallback } from "react";
import { ethers, BigNumber as BN } from "ethers";
import { useWeb3React } from '@web3-react/core';
import Web3 from "web3";
import { useContract } from "./useContract";
import { computeEstimateGas, convertUSDCPriceToUint } from "../utils/price";
import { CheckoutItemForContract } from "../types/interfaces";

interface Methods {
  approveWCRO: (amount?: string) => any,
  getAllowanceWCRO: () => any,
  getWCROBalance: () => any,
  checkout: (orderId: number, shippingPrice: number, items: CheckoutItemForContract[]) => any,
  getOrderedProductsByAccount: () => any,
  getOrderById: (orderId: number) => any,
  getProductRemainingSupplies: (productIds: string[]) => any,
  getSubProducts: (productId: string, names: string[]) => any,
  payForShippingCost: (price: number | string) => any,
}

export function useMerchandisContractMethods(): Methods {
  const { merchandiseERC20Contract, merchandiseContract } = useContract();
  const { account } = useWeb3React<ethers.providers.Web3Provider | ethers.providers.StaticJsonRpcProvider>();

  const approveWCRO = useCallback(async (amount?: string) => {
    console.log('approving', amount)
    const approveAmount = amount ? convertUSDCPriceToUint(amount) : BN.from("2").pow(BN.from("256").sub(BN.from("1")));
    const data = await merchandiseERC20Contract.estimateGas.approve(merchandiseContract.address, approveAmount);
    const tx = await merchandiseERC20Contract.approve(merchandiseContract.address, approveAmount, { gasLimit: computeEstimateGas(data) });
    return await tx.wait();
  }, [merchandiseERC20Contract, merchandiseContract]);

  const getAllowanceWCRO = useCallback(async () => {
    const allowanceAmount = (await merchandiseERC20Contract.allowance(account, merchandiseContract.address)).toString();
    return allowanceAmount;
  }, [merchandiseERC20Contract, account, merchandiseContract.address]);

  const getWCROBalance = useCallback(async () => {
    try {
      return (await merchandiseERC20Contract.balanceOf(account)).toString();
    } catch (error) {
      console.log(error);
    }
  }, [merchandiseERC20Contract, account]);

  const checkout = useCallback(async (orderId: number, shippingPrice: number, items: CheckoutItemForContract[]) => {
    const productIds = items.map((item: CheckoutItemForContract) => item.productId);
    const subProducts = items.map((item: CheckoutItemForContract) => item.subProduct);
    const checkoutShippingPrice = convertUSDCPriceToUint(shippingPrice);

    const data = await merchandiseContract.estimateGas.checkout(orderId, productIds, subProducts, checkoutShippingPrice);
    const tx = await merchandiseContract.checkout(orderId, productIds, subProducts, checkoutShippingPrice, { gasLimit: computeEstimateGas(data) });
    const result = await tx.wait();
    return result;
  }, [merchandiseContract]);

  const getOrderById = useCallback(async (orderId: number) => {
    const data = await merchandiseContract.getOrderById(orderId);
    const items = [];
    for (let i = 0; i < data[0].length; i++) {
      items.push({ contractId: data[0][i], quantity: data[1][i].toNumber() });
    }
    const order = {
      items,
      subtotalPrice: Web3.utils.fromWei(data[2].toString()),
      shippingPrice: Web3.utils.fromWei(data[3].toString()),
      account: data[4],
    }
    return order;
  }, [merchandiseContract]);

  const getOrderedProductsByAccount = useCallback(async () => {
    try {
      if (account) {
        const result = await merchandiseContract.getOrderedProductsByAccount(account);
        const orders = result[0].map((item: string | number, index: number) => {
          return {
            contractId: item,
            quantity: result[1][index].toNumber()
          }
        });
        return orders;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error);
    }
  }, [account, merchandiseContract]);

  const getProductRemainingSupplies = useCallback(async (contractId: string[]) => {
    try {
      const result = await merchandiseContract.getProductRemainingSupplies(contractId);
      const remainingSupplies = result[0].map((item: string | number, index: number) => {
        return {
          contractId: contractId[index],
          areLimited: item,
          remainingSupplies: result[1][index].toNumber()
        }
      });
      return remainingSupplies;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [merchandiseContract]);

  const getSubProducts = useCallback(async (productId: string, names: string[]) => {
    const result = await merchandiseContract.getSubProducts(productId, names);

    return result;
  }, [merchandiseContract])

  const payForShippingCost = useCallback(async (price: number | string) => {
    const owner = await merchandiseContract.owner();
    const xferTx = await merchandiseERC20Contract.transfer(owner, convertUSDCPriceToUint(price));
    return await xferTx.wait();
  }, [merchandiseContract, merchandiseERC20Contract])

  return {
    approveWCRO,
    getAllowanceWCRO,
    getWCROBalance,
    checkout,
    getOrderedProductsByAccount,
    getOrderById,
    getProductRemainingSupplies,
    getSubProducts,
    payForShippingCost,
  }
}
