import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useContract } from "./useContract";
import { useContractMethods } from "./useContractMethods";
import { useLogin } from "./useLogin";
import { useChimp } from "../providers/ChimpProvider";
import { useLoadChimp } from "../providers/LoadChimpProvider";
import { useNFTMint } from "../providers/NFTProvider";
import { useUser } from "../providers/UserProvider";
import { fetchMetadata } from "../utils/fetch";
import { honoraryChimpImage } from "../utils/chimp-image";
import { buildForOneChimpMeta } from "../utils/chimp-traits";
import { ChimpOperateTypes } from "../types/enums";
import { META_ARW, TOTAL_SUPPLY } from "../configs";
import { HIDEN_HONORARY_CHIMP_ID, URL_REPLACEMENT } from "../constants/general";
import { FavoriteCountChimp, HonoraryChimpMeta } from "../types/interfaces";
import {fetchChimpTransactions} from "../utils/fetch-graphql";

export function useChimpsHook() {
  const { account } = useWeb3React();
  const { 
    tokenIds, setTokenIds, honoraryTokenIds, setHonoraryTokenIds, 
    setLoadingChimps, setLoadingHonoraryChimps, setLoadingSalingChimps, setLoadingSalingHonoraryChimps
  } = useLoadChimp();

  const { honoraryContract, batchContract } = useContract();
  const { checkOfferedForSale, checkOfferedForSaleBatched, checkOnSaleChimps, getOwnerOf } = useContractMethods();
  const { getOwnedHonoraryChimps, getOwnedChimps, getChimpForBuyOrSale, updateChimps, updateHonoraryChimps, getAllChimpsInMarket, getSalingChimps, getSalingHonoraryChimps, updateFavoriteCount, getChimpTransactions } = useChimp();
  const { getBalanceOf } = useNFTMint();
  const { getFavoriteCountAllChimp } = useLogin();
  const { state: { connectedDiscordChimps } } = useUser();

  const getIds = useCallback(async () => {
    try {
      if (account) {
        let { chimpIds, honoraryIds } = await batchContract.getTokenIds(account);

        chimpIds = chimpIds.map((id: ethers.BigNumber) => id.toString());
        honoraryIds = honoraryIds.map((id: ethers.BigNumber) => id.toString());

        setTokenIds(chimpIds);
        setHonoraryTokenIds(honoraryIds);
      }
    } catch (error) {
      setTimeout(() => {
        getIds();
      }, 3000)
    }
  }, [account, batchContract, setTokenIds, setHonoraryTokenIds]);

  const getChimps = useCallback(async (begin: number = 0) => {
    if (tokenIds.length === 0) return;
    setLoadingChimps(true);

    const RANGE = 16;

    let i = begin;

    try {
      const connectedDiscordIds = connectedDiscordChimps.chimpsTokenIds;
      for (; i < tokenIds.length; i+=RANGE) {
        const result = await checkOfferedForSaleBatched(tokenIds.slice(i, i + RANGE), false);
        const sales = result.map((sale: any, index: number) => {
          return {
            ...sale,
            isConnectedDiscord: connectedDiscordIds.includes(sale.tokenId),
          }
        });
        if (i >= tokenIds.length - RANGE) {
          setLoadingChimps(false);
        }

        getOwnedChimps(sales);
      }
    } catch (error) {
      setTimeout(() => {
        getChimps(i);
      }, 15000);
    }
  }, [tokenIds, connectedDiscordChimps.chimpsTokenIds, checkOfferedForSaleBatched, getOwnedChimps, setLoadingChimps]);

  const getHonoraryMetas = useCallback(async (ids: string[]): Promise<HonoraryChimpMeta[]> => {
    try {
      const urls: string[] = await Promise.all(ids.map((id) => {
        return honoraryContract.tokenURI(id)
      }))

      const data: HonoraryChimpMeta[] = await Promise.all(urls.map((url) => {
        return fetchMetadata(url.replace(URL_REPLACEMENT, META_ARW))
      }))

      return data.map((item) => ({
        ...item,
        image: honoraryChimpImage(item.image || ''),
      }))
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [honoraryContract])

  const getMyHonoraryChimps = useCallback(async (begin: number = 0) => {
    if (honoraryTokenIds.length === 0) return;
    setLoadingHonoraryChimps(true);

    const RANGE = 8;

    let i = begin;

    try {
      const connectedDiscordIds = connectedDiscordChimps.honoraryTokenIds;
      for (; i < honoraryTokenIds.length; i += RANGE) {
        const data = await getHonoraryMetas(honoraryTokenIds.slice(i, i + RANGE));

        const result = await checkOfferedForSaleBatched(honoraryTokenIds.slice(i, i + RANGE), true);
  
        const sales = result.map((sale: any, index: number) => {
          return {
            ...sale,
            ...data[index],
            isHonorary: true,
            isConnectedDiscord: connectedDiscordIds.includes(sale.tokenId),
          }
        })

        getOwnedHonoraryChimps(sales);

        if (i >= honoraryTokenIds.length - RANGE) {
          setLoadingHonoraryChimps(false);
        }
      }
    } catch (error) {
      setTimeout(() => {
        getMyHonoraryChimps(i);
      }, 12000)
    } 
  }, [honoraryTokenIds, connectedDiscordChimps.honoraryTokenIds, getHonoraryMetas, checkOfferedForSaleBatched, getOwnedHonoraryChimps, setLoadingHonoraryChimps])

  const getChimpDetailById = useCallback(async (id: string, isHonorary: boolean) => {
    try {
      let sale = await checkOfferedForSale(id, isHonorary);

      const owner = await getOwnerOf(id, isHonorary);

      if (isHonorary) {
        const metaUrl = (await honoraryContract.tokenURI(id)).toString();
  
        const meta = await fetchMetadata(metaUrl.replace(URL_REPLACEMENT, META_ARW))

        sale = {
          ...sale,
          name: meta.name,
          image: honoraryChimpImage(meta.image || ''),
          description: meta.description,
          isHonorary: true,
          owner
        }
      } else {
        sale = {
          ...sale,
          owner,
        }
      }

      const data = buildForOneChimpMeta(sale);

      getChimpForBuyOrSale(data);
    } catch (error) {
        console.log(error);
    }
  }, [checkOfferedForSale, getOwnerOf, getChimpForBuyOrSale, honoraryContract])

  const getMarketChimps = useCallback(async () => {
    const NORMAL_RANGE = 2000;
    const TOTAL_NORMAL_SUPPLY = TOTAL_SUPPLY;

    const HONORARY_RANGE = 50;
    const honorarySupply: ethers.BigNumber = await honoraryContract.totalSupply();
    const TOTAL_HONORARY_SUPPLY = honorarySupply.toNumber();

    const favoriteCountAllChimps = await getFavoriteCountAllChimp([...Array(TOTAL_NORMAL_SUPPLY).keys()], [...Array(TOTAL_HONORARY_SUPPLY).keys()]);
    
    getAllChimpsInMarket(favoriteCountAllChimps.normalChimps);

    async function getOnSaleChimps(start: number) {
      let sales = await checkOnSaleChimps(start, start + NORMAL_RANGE);

      sales = sales.map((sale: any, index: number) => {
        const favoriteChimp = favoriteCountAllChimps.normalChimps.filter((item: FavoriteCountChimp) => item.tokenId === sale.tokenId);
        return {
          ...sale,
          favoriteCount: favoriteChimp.length > 0 ? favoriteChimp[0].favoriteCount : 0
        }
      });
      getSalingChimps(sales);

      if (start >= TOTAL_NORMAL_SUPPLY - NORMAL_RANGE) {
        setLoadingSalingChimps(false);
      }
    }

    const honoraryIds = Array.from({ length: TOTAL_HONORARY_SUPPLY }, (_, x) => x.toString()).filter((id) => !HIDEN_HONORARY_CHIMP_ID.includes(id));

    async function getOnSaleHonoraryChimps(startHonorary: number) {
      let sales = await checkOfferedForSaleBatched(honoraryIds.slice(startHonorary, startHonorary + HONORARY_RANGE), true);

      const data = await getHonoraryMetas(sales.map((s: any) => s.tokenId));

      sales = sales.map((sale: any, index: number) => {
        const favoriteChimp = favoriteCountAllChimps.honoraryChimps.filter((item: any) => item.tokenId === sale.tokenId);
        const favoriteCount = favoriteChimp.length > 0 ? favoriteChimp[0].favoriteCount : 0
        return {
          ...sale,
          ...data[index],
          isHonorary: true,
          favoriteCount
        }
      });

      getSalingHonoraryChimps(sales);

      if (startHonorary >= TOTAL_HONORARY_SUPPLY - HONORARY_RANGE) {
        setLoadingSalingHonoraryChimps(false);
      }
    }

    try {
      let getOnSaleChimpsPromises = [];

      for (let i = 0; i < TOTAL_SUPPLY; i += NORMAL_RANGE) {
        getOnSaleChimpsPromises.push(getOnSaleChimps(i))
      }

      for (let i = 0; i < TOTAL_HONORARY_SUPPLY; i+=HONORARY_RANGE) {
        getOnSaleChimpsPromises.push(getOnSaleHonoraryChimps(i))
      }

      await Promise.all(getOnSaleChimpsPromises);
    } catch (error) {
      console.log(error);
    }
  }, [getAllChimpsInMarket, honoraryContract, checkOnSaleChimps, getSalingChimps, setLoadingSalingChimps, getFavoriteCountAllChimp, checkOfferedForSaleBatched, getHonoraryMetas, getSalingHonoraryChimps, setLoadingSalingHonoraryChimps]);

  const updateChimpData = useCallback(async (tokenId: string, chimpId: string, type: ChimpOperateTypes, isHonorary: boolean) => {
    if (isHonorary) {
      updateHonoraryChimps(tokenId, chimpId, type);
    } else {
      updateChimps(tokenId, chimpId, type);
    }

    if (
      [
        ChimpOperateTypes.BUY,
        ChimpOperateTypes.TRANSFER,
        ChimpOperateTypes.ACCEPT,
      ].some((t) => t === type)
    ) {
      getIds();
    }

    getBalanceOf();
  }, [updateChimps, updateHonoraryChimps, getBalanceOf, getIds])

  const updateFavoriteCountChimps = useCallback(async (isForSale: boolean, isHonorary: boolean) => {
    const honorarySupply: ethers.BigNumber = await honoraryContract.totalSupply();
    const TOTAL_HONORARY_SUPPLY = honorarySupply.toNumber();
    const updateChimps = await getFavoriteCountAllChimp([...Array(TOTAL_SUPPLY).keys()], [...Array(TOTAL_HONORARY_SUPPLY).keys()]);
    updateFavoriteCount(updateChimps, isForSale, isHonorary);
  }, [getFavoriteCountAllChimp, honoraryContract, updateFavoriteCount]);

  const getChimpTransactionsById = useCallback(async (tokenId: string | number, isHonorary: boolean) => {
    try {
      const transactions = await fetchChimpTransactions(tokenId, isHonorary);
      getChimpTransactions(transactions);
    } catch (error) {
      console.log(error);
    }
  }, [getChimpTransactions]);

  return {
    getIds,
    getChimps,
    getHonoraryMetas,
    getMyHonoraryChimps,
    getChimpDetailById,
    getMarketChimps,
    updateChimpData,
    updateFavoriteCountChimps,
    getChimpTransactionsById
  };
}