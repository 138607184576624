import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Modal, { DisplayModal } from "../modal/Modal";
import CheckButton from "../checkbutton/CheckButton";
import Button from "../button/ProgressButton";
import CheckBox from "../checkbox/CheckBox";
import Warning from "../messages/Warning";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useChimpay } from "../../providers/ChimpayProvider";
import { useMessage } from "../../providers/MessageProvider";
import useChimpayHook from "../../hooks/useChimpayHook";
import { ModalTypes, ModalChimpStatus } from "../../types/enums";
import { Cross } from "../../assets/svgs";
import WorkingChimp1 from '../../assets/images/banners_working_chimp1.png';
import { getDailyBananaProduction } from "../../utils/shop-price";
import { WARNING_EXCEED_MAX_STAKE } from "../../constants/errors";
import { MAX_STAKING_ALLOWED } from "../../constants/chimpay";
import styles from './StakeModal.module.scss';

const StakeModal = () => {
  const [selects, setSelects] = useState<string[]>([]);
  const [honorarySelects, setHonorarySelects] = useState<string[]>([]);
  const [statementChecked, setStatementChecked] = useState(false);
  
  const { stakeChimps } = useChimpayHook();

  const { step, props, closeModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { state: { stakedTokens } } = useChimpay();
  const { state: { ownedChimps, ownedHonoraryChimps } } = useChimp();

  const amountStaked = useMemo(() => 
    stakedTokens.normal.length +stakedTokens.honorary.length, [stakedTokens]);

  const availableChimps = useMemo(() => {
    const availableOwnedChimps = ownedChimps.filter(({ isForSale, tokenId }) => 
      !isForSale && stakedTokens.normal.findIndex((st) => +tokenId === +st.tokenId) === -1);
    const availableHonoraryChimps = ownedHonoraryChimps.filter(({ isForSale, tokenId }) => 
      !isForSale && stakedTokens.honorary.findIndex((st) => +tokenId === +st.tokenId) === -1);

    return [...availableOwnedChimps, ...availableHonoraryChimps];
  }, [ownedChimps, ownedHonoraryChimps, stakedTokens]);

  const selectedChimps = useMemo(() => {
    return availableChimps.filter(({ tokenId, isHonorary }) => 
      (!isHonorary && selects.includes(tokenId)) || (isHonorary && honorarySelects.includes(tokenId)));
  }, [availableChimps, selects, honorarySelects]);

  const totalSelectsLength = useMemo(() => 
    selects.length + honorarySelects.length, [selects, honorarySelects]);

  const hasStaked20Chimps = useMemo(() => 
    amountStaked + totalSelectsLength > MAX_STAKING_ALLOWED, [amountStaked, totalSelectsLength]);

  const handleToggleSelect = useCallback((id: string, isHonorary: boolean) => {
    const updates = isHonorary ? [...honorarySelects] : [...selects];
    const index = updates.findIndex((tid) => tid === id);

    if (index === -1) {
      updates.push(id);
    } else {
      updates.splice(index, 1);
    }

    if (isHonorary) {
      setHonorarySelects(updates);
    } else {
      setSelects(updates);
    }
  }, [selects, honorarySelects, setSelects, setHonorarySelects]);

  const handleStake = useCallback(async () => {
    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      await stakeChimps(selects, honorarySelects);

      onChangeWarnings({
        type: 'Success',
        title: 'Cheers!',
        message: `You have successfully staked ${selects.length + honorarySelects.length} Chimps.`,
      });
    } catch (err) {
      onChangeWarnings({
        message: 'Transaction unsuccessful, please try again.',
      });
    } finally {
      closeModal();
    }
  }, [selects, honorarySelects, updateOperationStep, closeModal, onChangeWarnings, stakeChimps]);

  useEffect(() => {
    if (props && props.tokenId > 0) {
      if (props.isHonorary) {
        setHonorarySelects([props.tokenId]);
      } else {
        setSelects([props.tokenId]);
      }
      updateOperationStep(ModalChimpStatus.CONFIRM);
    } else {
      updateOperationStep(ModalChimpStatus.READY);
    }
  }, [updateOperationStep, props]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRMING}>
      <div className={styles.container}>
        <div className={`${styles.header} ${step === ModalChimpStatus.CONFIRM ? styles.header_bg : ''}`}>
          {
            step !== ModalChimpStatus.CONFIRMING &&
            <span className={styles.title}>Send Chimps to work</span>
          }
          <Cross className={styles.close} onClick={closeModal} />
        </div>
        {
          step === ModalChimpStatus.READY &&
          <div className={styles.selectWrap}>
            <div className={styles.selectChimps}>
              {
                availableChimps.map(({ tokenId, image, isHonorary }) => {
                  const selected = isHonorary ? honorarySelects.includes(tokenId) : selects.includes(tokenId);

                  return (
                    <div 
                      key={tokenId} 
                      className={styles.chimpItem}
                      onClick={() => handleToggleSelect(tokenId, isHonorary)}
                    >
                      <div className={classNames(styles.chimpImg, {
                        [styles.chimpImg_selected]: selected
                      })}>
                        <img src={image} alt="" />
                        {
                          isHonorary && 
                          <span className={styles.honoraryLabel_1}>
                            Honorary
                          </span>
                        }
                        <CheckButton
                          checked={true}
                          className={classNames(styles.checkIcon, {
                            [styles.checkIcon_checked]: selected
                          })}
                          fillStyle="cover"
                        />
                      </div>
                      <div className={styles.chimpId}>
                        Chimp #{tokenId}
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <Warning className={styles.blockTip} show={hasStaked20Chimps}>
              {WARNING_EXCEED_MAX_STAKE}
            </Warning>
            <Button
              onClick={() => updateOperationStep(ModalChimpStatus.CONFIRM)}
              disabled={totalSelectsLength === 0 || hasStaked20Chimps}
            >
              Next ({totalSelectsLength})
            </Button>
          </div>
        }
        {
          step === ModalChimpStatus.CONFIRM &&
          <div className={styles.confirmWrap}>
            <div className={styles.selectedChimps}>
              {
                selectedChimps.map(({ image, tokenId, isHonorary }) => (
                  <div key={tokenId} className={styles.selectedChimp}>
                    {
                      isHonorary && 
                      <span className={styles.honoraryLabel_2}>
                        Honorary
                      </span>
                    }
                    <img src={image} alt="" />
                  </div>
                ))
              }
            </div>
            <div className={styles.confirmBody}>
              <div className={styles.dbp}>
                <p className={styles.dbpDesc}>
                  Daily Banana production will change
                </p>
                <div className={styles.dbpChange}>
                  <span className={styles.dbpValue}>
                    {getDailyBananaProduction(amountStaked)}
                  </span>
                  <span className={styles.dbpChangeArrow}>→</span>
                  <span className={styles.dbpValue}>
                    {getDailyBananaProduction(amountStaked + totalSelectsLength)}
                  </span>
                </div>
              </div>
              <div className={styles.confirmDesc}>
                <p className={styles.textParag}>
                  While your Chimp is working, you will not be able to list it on any secondary marketplace. 
                  To do so, please recall your Chimps first.
                </p>
                <p className={styles.textParag}>
                  Working Chimps will be locked for the first 24 hours.
                </p>
                <p className={styles.textParag}>
                  Learn more about Chimpay <Link to="/terms" target="_blank">T&C</Link>.
                </p>
              </div>
              <div className={styles.statement}>
                <CheckBox
                  checked={statementChecked}
                  onCheck={() => setStatementChecked(!statementChecked)}
                  label="I have read the above T&C and I agree"
                  reversed
                />
              </div>

              <Button
                onClick={handleStake}
                disabled={!statementChecked}
                classname={styles.confirmButton}
              >
                Confirm
              </Button>
            </div>
          </div>
        }
        {
          step === ModalChimpStatus.CONFIRMING && 
          <div className={styles.confirmingWrap}>
            <img className={styles.confirmingImg} src={WorkingChimp1} alt="" />
            <div className={styles.confirmingTitle}>
              Your Chimp is heading to work...
            </div>
            <div className={styles.confirmingSubTitle}>
              Almost there! Please approve in wallet.
            </div>
          </div>
        }
      </div>
    </Modal>
  )
}

export default DisplayModal(StakeModal, ModalTypes.STAKE_CHIMP);
