import { ChangeEvent, FC, useCallback } from 'react';
import { Cross, Search } from '../../assets/svgs';
import styles from './SearchChimps.module.scss';

interface Props {
  value: string,
  onChange: (val: string) => void,
}

const SearchChimps: FC<Props> = ({ value, onChange }) => {
  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    if (/^[1-9]{0,1}\d{0,5}$/.test(val)) {
      onChange(val);
    }
  }, [onChange])

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange])

  return (
    <span className={styles.wrapper}>
      <input type="text" className={styles.input} placeholder="Search Chimp ID" value={value} onChange={handleChange} />
      {
        !value ? <Search className={styles.icon} /> : <Cross className={styles.icon} style={{ cursor: 'pointer' }} onClick={handleClear} />
      }
    </span>
  )
}

export default SearchChimps