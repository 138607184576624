
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import Modal, { DisplayModal } from "./Modal";
import { useModal } from "../../providers/ModalProvider";
import { useChimp } from "../../providers/ChimpProvider";
import { useMessage } from "../../providers/MessageProvider";
import { useContractMethods } from "../../hooks/useContractMethods";
import { useChimpsHook } from "../../hooks/useChimpsHook";
import ProgressButton from "../button/ProgressButton";
import ChimpSuccessModal from './ChimpSuccessModal';
import { Arrow, Cross, Info } from "../../assets/svgs";
import { ButtonStatus, ModalTypes, ModalChimpStatus, ChimpOperateTypes } from "../../types/enums";
import { CHIMP_ERROR_REJECTED, CHIMP_ERROR_TRXN_GENERIC } from "../../constants/errors";
import { ROYALTY_PERCENTAGE } from "../../configs";
import { roundNumberWithFix } from "../../utils/number";
import { getChimpDisplayTitle } from "../../utils/title";
import styles from './ChimpModal.module.scss';
import classNames from "classnames";

const SellChimpModal = () => {
  const [amount, setAmount] = useState('');
  const [trxnId, setTrxnId] = useState('');
  const [error, setError] = useState('');

  const { step, closeModal, updateOperationStep } = useModal();
  const { onChangeWarnings } = useMessage();

  const { updateChimpData } = useChimpsHook();
  const { state: { forBuyOrSaleChimp } } = useChimp();

  const { tokenId, id: chimpId, image, isForSale, isHonorary, name } = forBuyOrSaleChimp;

  const { postChimpToMarket } = useContractMethods();

  const handleSell = useCallback(async () => {
    if (+amount === 0) {
      return setError('The selling price must be more than zero');
    }

    updateOperationStep(ModalChimpStatus.CONFIRMING);

    try {
      const result = await postChimpToMarket(tokenId, amount, isHonorary);

      if (result.transactionHash) {
        setTrxnId(result.transactionHash);
      }

      updateOperationStep(ModalChimpStatus.SUCCEESS);

      updateChimpData(tokenId, chimpId, ChimpOperateTypes.SELL, isHonorary);
    } catch (error: any) {
      updateOperationStep(ModalChimpStatus.FAILED);
      
      if (error.code && error.code === 4001) {
        onChangeWarnings({ message: CHIMP_ERROR_REJECTED });
      } else {
        onChangeWarnings({ message: CHIMP_ERROR_TRXN_GENERIC });
      }
    }
  }, [postChimpToMarket, onChangeWarnings, updateOperationStep, updateChimpData, tokenId, chimpId, amount, isHonorary]);

  const handleClick = useCallback(() => {
    switch (step) {
      case ModalChimpStatus.READY:
        updateOperationStep(ModalChimpStatus.CONFIRM);
        break;
      case ModalChimpStatus.CONFIRM:
        handleSell();

        break;
      case ModalChimpStatus.FAILED:
        handleSell();
        break;
      default:
        break;
    }
  }, [step, handleSell, updateOperationStep])

  const handleBack = useCallback(() => {
    switch (step) {
      case ModalChimpStatus.CONFIRM:
        updateOperationStep(ModalChimpStatus.READY);
        break;
      case ModalChimpStatus.FAILED:
        updateOperationStep(ModalChimpStatus.READY);
        break;
      default:
        break;
    }
  }, [step, updateOperationStep])

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    setError('');
    
    if (/^[1-9]{0,}\d{0,}$/.test(value)) {
      if (+value > 10_000_000_000) {
        return;
      } 

      if (value.startsWith('0')) {
        value = `${+value}`;
      }

      setAmount(value);
    }
  }, [setAmount])

  useEffect(() => {
    updateOperationStep(ModalChimpStatus.READY);
  }, [updateOperationStep])

  const confirmInput = useMemo(() => [ModalChimpStatus.CONFIRM, ModalChimpStatus.CONFIRMING, ModalChimpStatus.FAILED].includes(step), [step])

  const buttonStatus = useMemo(() => {
    if (step === ModalChimpStatus.CONFIRMING) return ButtonStatus.LOADING;

    if (step === ModalChimpStatus.FAILED) return ButtonStatus.FAILED;

    return ButtonStatus.NORMAL;
  }, [step])

  const isAdjustPrice = useMemo(() => isForSale, [isForSale]);

  return (
    <Modal allowClickOutside={step !== ModalChimpStatus.CONFIRM && step !== ModalChimpStatus.CONFIRMING && step !== ModalChimpStatus.FAILED}>
      {
        step !== ModalChimpStatus.SUCCEESS &&
        <div className={styles.container}>
          {step !== ModalChimpStatus.READY && <Arrow className={styles.back} onClick={handleBack} />}
          <Cross className={styles.cancel} onClick={closeModal} />
          <div className={styles.header}>
            <span className={styles.title}>
              {!confirmInput && isAdjustPrice && 'Adjust sale price'}
              {confirmInput && isAdjustPrice && 'Review Adjusting'}
              {!confirmInput && !isAdjustPrice && 'List in Marketplace'}
              {confirmInput && !isAdjustPrice && 'Review Listing'}
            </span>
            <div className={styles.summary}>
              <img className={styles.img} src={image || ''} alt="" />
              <div>
                <div className={styles.chimp_id}>{getChimpDisplayTitle(tokenId, name, isHonorary)}</div>
                <div  className={styles.token_id}>Token ID: {tokenId}</div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            {
              !confirmInput &&
              <>
                <label htmlFor="sell-price" className={styles.label}>Enter {isAdjustPrice ? 'adjusting' : 'selling'} price (CRO)</label>
                <input 
                  id="sell-price" 
                  className={styles.input} 
                  disabled={confirmInput} 
                  value={amount} 
                  onChange={handleChange} 
                  placeholder="0" 
                />
                {
                  +amount > 0 &&
                  <span className={styles.input_remark}>Royalty when sold: {+roundNumberWithFix(+amount * ROYALTY_PERCENTAGE, 3)} CRO ({ROYALTY_PERCENTAGE * 100}%)</span>
                }
              </>
            }
            {
              confirmInput && 
              <>
                <div className={styles.content_row}>
                  <span className={styles.content_label}>{isAdjustPrice ? 'Adjusting' : 'Selling'} price</span>
                  <span>{amount} CRO</span>
                </div>
                <div className={styles.content_row}>
                  <span className={styles.content_label}>Royalty when sold {ROYALTY_PERCENTAGE * 100}%</span>
                  <span>{+roundNumberWithFix(+amount * ROYALTY_PERCENTAGE, 3)} CRO</span>
                </div>
              </>
            }
            {
              !!error &&
              <div className={styles.warning}>
                <Info className={styles.warning_icon} />
                <span className={styles.warning_text}>{error}</span>
              </div>
            }
            <div className={styles.buttons}>
              <ProgressButton disabled={!!error || +amount === 0} status={buttonStatus} isGhost={step === ModalChimpStatus.READY && +amount > 0} onClick={handleClick}>
                {step === ModalChimpStatus.READY && 'Next'}
                {step === ModalChimpStatus.CONFIRM && 'Confirm'}
                {step === ModalChimpStatus.CONFIRMING && 'Confirming...'}
                {step === ModalChimpStatus.FAILED && 'Retry'}
              </ProgressButton>
            </div>
            {
              (step === ModalChimpStatus.CONFIRM || step === ModalChimpStatus.FAILED) && 
              <div className={classNames(styles.info)}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Chimp #{tokenId} will be placed for sale in the Cronos Chimp Club Marketplace. 
                  You can unlist your Chimp anytime from the Marketplace.
                </span>
              </div>
            }
            {
              step === ModalChimpStatus.CONFIRMING && 
              <div className={styles.info}>
                <Info className={styles.info_icon} />
                <span className={styles.info_text}>
                  Please confirm the transaction in your wallet.  
                </span>
              </div>
            }
          </div>
        </div>
      }
      {
        step === ModalChimpStatus.SUCCEESS && 
        <ChimpSuccessModal 
          closeModal={closeModal} 
          subTitle="Listing Confirmed"
          description={`Chimp #${tokenId} is now listed on the Marketplace`} 
          trxnId={trxnId} 
          type={ChimpOperateTypes.SELL}
        />
      }
    </Modal>
  )
}

export default DisplayModal(SellChimpModal, ModalTypes.SELL_CHIMP);