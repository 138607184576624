import React, { useEffect, useState } from 'react'

interface Props {
  className: string,
  images: string[]
}

const ImageLoop = ({ className, images }: Props) => {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const time = 500 + 3000 * Math.random()

    const timeout = setTimeout(() => {
      setCounter((counter + 1) % images.length);
    }, time)

    return () => {
      clearTimeout(timeout);
    }
  }, [counter, setCounter, images.length])

  return (
    <img className={className} src={images[counter]} alt="chimp" />
  )
}

export default ImageLoop
