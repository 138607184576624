import { UnsupportedChainIdError } from "@web3-react/core";
import {
  InjectedConnector,
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from "@web3-react/walletconnect-connector";
import { DeFiWeb3Connector } from "deficonnect";
import MetamaskICON from "../assets/images/metamask.png";
import DefiWalletIcon from "../assets/images/defi-wallet-icon.webp";
import WalletConnectIcon from "../assets/images/wallet-connect.png";
import {
  CRONOS_EXPLORER,
  DEFAULT_RPC,
  RPC_CURRENCY,
  RPC_ID,
  RPC_NAME,
} from "../configs";
import { WalletInfo } from "../types/interfaces";
import { ConnectWalletType } from "../types/enums";
import {
  WALLET_ERROR_NO_EXTENSION,
  WALLET_ERROR_UNAUTHORIZED,
  WALLET_ERROR_UNKNOWN,
  WALLET_ERROR_UNSUPPORTED_NETWORK,
} from "../constants/errors";

export const metamask = new InjectedConnector({ supportedChainIds: [RPC_ID] });

export const walletconnect = new WalletConnectConnector({
  chainId: RPC_ID,
  rpc: { [RPC_ID]: DEFAULT_RPC },
  qrcode: true,
});

const defiWalletConnector = new DeFiWeb3Connector({
  supportedChainIds: [RPC_ID],
  rpc: {
    [RPC_ID]: DEFAULT_RPC,
  },
});

export const WALLETS: { [key: string]: WalletInfo } = {
  [ConnectWalletType.METAMASK]: {
    connector: metamask,
    name: "MetaMask",
    iconURL: MetamaskICON,
  },
  [ConnectWalletType.DEFI_WALLET]: {
    connector: defiWalletConnector,
    name: "Defi Wallet",
    iconURL: DefiWalletIcon,
  },
  [ConnectWalletType.WALLET_CONNECT]: {
    connector: walletconnect,
    name: "Wallet Connect",
    iconURL: WalletConnectIcon,
  },
};

export function handleConnectionError(error?: Error): string {
  if (error instanceof NoEthereumProviderError) {
    return WALLET_ERROR_NO_EXTENSION;
  } else if (error instanceof UnsupportedChainIdError) {
    return WALLET_ERROR_UNSUPPORTED_NETWORK;
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    return WALLET_ERROR_UNAUTHORIZED;
  } else {
    console.error(error);
    return WALLET_ERROR_UNKNOWN;
  }
}

export async function addOrSwitchToCronosNetwork() {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${Number(RPC_ID).toString(16)}` }],
    });

    return true;
  } catch (switchError: any) {
    // alert(switchError.code)
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902 || switchError.code === -32603) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: `0x${Number(RPC_ID).toString(16)}`,
              chainName: RPC_NAME,
              nativeCurrency: {
                name: RPC_CURRENCY,
                symbol: RPC_CURRENCY,
                decimals: 18,
              },
              rpcUrls: [DEFAULT_RPC],
              blockExplorerUrls: [CRONOS_EXPLORER],
            },
          ],
        });

        return true;
      } catch (addError) {
        console.log("Failed to add network", addError);
        return false;
      }
    }

    return false;
  }
}
