import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import Modal, { DisplayModal } from "./Modal";
import { MerchPaymentTypes, ModalTypes } from '../../types/enums';
import { useModal } from '../../providers/ModalProvider';
import { useUser } from '../../providers/UserProvider';
import { Cross } from '../../assets/svgs';
import styles from './CartModal.module.scss';
import { OrderCartMeta } from '../../types/interfaces';
import { formatNumberWithComma, roundNumberWithFix } from '../../utils/number';
import Button from '../button/ProgressButton';
import OrderMerchandise from '../shop/OrderMerchandise';

const ViewCartModal: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { closeModal } = useModal();
  const { state: { orderCart }, updateOrderCart } = useUser();

  useEffect(() => {
    const cart = JSON.parse(window.localStorage.getItem('CART') || '[]');
    updateOrderCart(cart);
  }, [updateOrderCart]);

  const subTotal = useMemo(() => {
    let usdcTotal = new BigNumber(0);
    let bananasTotal = new BigNumber(0);

    if (orderCart.length > 0) {
      orderCart.forEach((item: OrderCartMeta) => {
        const add = new BigNumber(item.price).multipliedBy(item.quantity);
        if (item.currency === MerchPaymentTypes.BANANAS) {
          bananasTotal = bananasTotal.plus(add);
        } else {
          usdcTotal = usdcTotal.plus(add);
        }
      })
    }

    return [usdcTotal.toNumber(), bananasTotal.toNumber()];
  }, [orderCart]);

  const onCheckout = useCallback(() => {
    navigate('/shop/checkout');
    closeModal();
  }, [navigate, closeModal]);

  return (
    <Modal>
      <div className={classNames(styles.container, styles.view_cart_container)}>
        <Cross className={styles.cancel} onClick={closeModal} />
        <div className={styles.header_view_cart}>
          <span className={styles.title}>View cart</span>
          <OrderMerchandise merchandiseList={orderCart} className={styles.cart_list} />
        </div>
        <div className={classNames(styles.content_view_cart)}>
          <div className={styles.sub_total}>
            <span className={styles.sub_total_label}>Total</span>
            <div className={styles.sub_total_value}>
              <span>
                {subTotal[0] > 0 && `${formatNumberWithComma(roundNumberWithFix(subTotal[0], 3))} ${MerchPaymentTypes.USDC}`}
              </span>
              <span>
                {subTotal[1] > 0 && `${formatNumberWithComma(roundNumberWithFix(subTotal[1], 3))} ${MerchPaymentTypes.BANANAS}`}
              </span>
            </div>
          </div>
          <Button classname={styles.btn} onClick={onCheckout} disabled={orderCart.length === 0}>
            Checkout
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DisplayModal(ViewCartModal, ModalTypes.VIEW_CART);