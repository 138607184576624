import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import ClickOutSide from '../click-outside/ClickOutside';
import CheckBox from '../checkbox/CheckBox';
import { Cross, Dropdown, TriangleDown } from '../../assets/svgs';
import { isMobile } from "../../utils/userAgent";
import styles from './Selection.module.scss';

interface Props {
  title: string,
  options: any,
  values: string[],
  onChangeOptions: (val: string) => void,
  onClearFilter: () => void,
  listAlignRight?: boolean,
  forwardSelects: string[],
  onChangeForwardSelects: (selects: any) => void,
  shouldSorting?: boolean,
}

const Selection: FC<Props> = ({ title, options, values, onChangeOptions, onClearFilter, listAlignRight, forwardSelects, onChangeForwardSelects, shouldSorting }) => {
  const [show, setShow] = useState(false);
  const [smallToLarge, setSmallToLarge] = useState(true);

  const handleChange = useCallback((val: string) => {
    onChangeOptions(val);

    onChangeForwardSelects(forwardSelects.filter((forward) => val !== forward));
  }, [onChangeOptions, forwardSelects, onChangeForwardSelects]);

  const handleClear = useCallback((e: MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();

    onClearFilter();
    onChangeForwardSelects([]);
  }, [onClearFilter, onChangeForwardSelects])

  const handleClose = useCallback(() => {
    if (!isMobile) {
      setShow(false);
    }
  }, []);

  const toggleShow = useCallback(() => {
    if (!show) {
      onChangeForwardSelects(values);
    }

    setShow(!show)
  }, [show, values, onChangeForwardSelects])

  const hasSelectedFilters = useMemo(() => values.length > 0, [values]);

  return (
    <ClickOutSide onClickOutside={handleClose}>
      <div className={classNames(styles.selection, { [styles.selection_selected]: hasSelectedFilters })}>
        <div className={classNames(styles.header, { [styles.header_selecting]: hasSelectedFilters })} onClick={toggleShow}>
          <span style={{ textTransform: 'capitalize' }}>
            {title}
            {values.length > 0 && <span>&nbsp;({values.length})</span>}
          </span>
          <Dropdown 
            className={classNames(styles.icon_dropdown,
              {
                [styles.icon_dropdown_hidden]: values.length > 0
              }
            )} 
            style={{ transform: `rotate(${show ? 180 : 0}deg)` }}
          />
          {hasSelectedFilters && <Cross className={styles.icon_cross} onClick={handleClear} />}
        </div>
        {
          show &&
          <div className={classNames(styles.content, { [styles.content_right]: listAlignRight })}>
            <div className={styles.options_title}>
              <span>Name</span>
              <span>Count</span>
              <TriangleDown className={styles.sort_icon} onClick={() => setSmallToLarge(!smallToLarge)} />
            </div>
            <div className={styles.options}>
              {
                Object.keys(options).sort((a, b) => {
                  if (!shouldSorting) return 0;

                  if (forwardSelects.includes(a)) return -1;
                  if (forwardSelects.includes(b)) return 1;

                  if (a === 'none') return -1;
                  if (b === 'none') return 1;

                  return smallToLarge ? options[a] - options[b] : options[b] - options[a];
                }).map((key: any) => {
                  const checked = values.includes(key);

                  return (
                    <CheckBox 
                      key={key} 
                      onCheck={() => handleChange(key)} 
                      label={key} 
                      count={options[key]} 
                      checked={checked} 
                      className={checked ? styles.optionItem : ''}
                    />
                  )
                })
              }
            </div>
          </div>
        }
      </div>
    </ClickOutSide>
  )
}

Selection.defaultProps = {
  shouldSorting: true,
  listAlignRight: false,
}

export default Selection;