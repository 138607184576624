import { OfferChimpMeta } from "../types/interfaces";

export function buildForOneOfferMeta(offer: OfferChimpMeta): OfferChimpMeta {
  return {
    offerIndex: offer.offerIndex || '',
    tokenId: offer.tokenId || '',
    buyer: offer.buyer || '',
    amount: offer.amount || '',
    expiryTime: offer.expiryTime || '',
    isHighestOffer: offer.isHighestOffer || false,
  }
}

export function buildForOffersMeta(offers: OfferChimpMeta[]): OfferChimpMeta[] {
  return offers.map((offer) => {
    return buildForOneOfferMeta(offer)
  })
}

